import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Input, Row, Col } from 'antd';
import axios from 'axios';
import Header from '../components/Header';
import { Link, useNavigate } from 'react-router-dom';
import { isValidEmail } from '../utils/utils';

function EmailRegister() {
  const { t } = useTranslation();
  const [email, setEmail] = useState({ mail: '', error: '', isExist: false });
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const onfinish = (values) => {
    if (!isValidEmail(values.email)) {
      setEmail({
        email: values.email,
        error: t('share.emailNotUsed'),
        isExist: false
      });
      return;
    }
    const url = '/api/registration';
    axios.post(url, { mail: values.email }).then(({ data }) => {
      if (data.exist) {
        setEmail({
          mail: data.mail,
          isExist: data.exist,
          error: t('emailRegister.error.emailExist')
        });
      } else {
        navigate('/registerEmailCompletion');
      }
    });
  };
  return (
    <React.Fragment>
      <Header />
      <div
        className="container-custom modal-primary email-register"
        gutter={16}
      >
        <div className="box-title">{t('emailregister.title')}</div>
        <div className="box-body">
          <div className="ant-form-item description">
            {' '}
            {t('emailregister.des')}{' '}
          </div>
        </div>
        <Form
          form={form}
          onFinish={(values) => onfinish(values)}
          className="mb-primary ml-primary"
        >
          <div className="message-error mb-primary">{email.error}</div>
          <Row gutter={16}>
            <Col
              className="gutter-row col-lbl"
              md={{ span: 4 }}
              xs={{ span: 5 }}
            >
              E-mail:
            </Col>
            <Col
              className="gutter-row center"
              md={{ span: 20 }}
              xs={{ span: 19 }}
            >
              <Form.Item
                name="email"
                rules={[
                  {
                    required: true,
                    message: t('emailRegister.error.emailBlank')
                  }
                ]}
              >
                <Input
                  placeholder="xxx.xxxx@xyz.com"
                  className="txt-primary txt-email"
                />
              </Form.Item>
              <Form.Item>
                <button
                  type="submit"
                  style={{ margin: '0 8px' }}
                  className="btn-primary cursor-pointer"
                >
                  {t('button.send')}
                </button>
              </Form.Item>
              <div>
                <Link to="/login" className="link">
                  {t('emailregister.return.to.login.screen')}
                </Link>
              </div>
            </Col>
          </Row>
        </Form>
      </div>
    </React.Fragment>
  );
}
export default EmailRegister;
