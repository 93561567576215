import React, { useEffect } from 'react';
import Header from '../components/Header';
import { Row, Col, Breadcrumb } from 'antd';
import { useTranslation } from 'react-i18next';
import FooterComponent from '../components/FooterComponent';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

function CardChangeComplete() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const params = new URLSearchParams(window.location.search);
  const planId = params.get('planId');
  const planBranchNum = params.get('planBranchNum');
  const customerCardId = params.get('customer_card_id');

  useEffect(() => {
    if (!planId || !planBranchNum || !customerCardId) {
      navigate('/', { replace: true });
    }

    regChangeCard(planId, planBranchNum, customerCardId);
  }, []);

  const regChangeCard = (planId, planBranchNum, customerCardId) => {
    axios({
      method: 'post',
      url: '/api/regChangeCard',
      data: {
        planId,
        planBranchNum,
        customerCardId
      }
    });
  };

  return (
    <>
      <Header />
      <div className="white-space"></div>
      <div className="container-custom-primary campaign-code-added-input">
        <Row className="title mb-primary">
          <Col md={{ span: 8 }} className="page-title">
            {t('cannotCreditCardChange.title')}
          </Col>
          <Col md={{ span: 16 }} className="text-right">
            <Breadcrumb separator=">">
              <Breadcrumb.Item>
                {t('cannotCreditCardChange.title')}
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                {t('paymentRegistration.externalSite')}
              </Breadcrumb.Item>
              <Breadcrumb.Item className="current bold">
                {t('share.completion')}
              </Breadcrumb.Item>
            </Breadcrumb>
          </Col>
        </Row>
        <div className="white-space"></div>
        <div className="mb-primary">{t('cardChangeComplete.des')}</div>
        <div className="white-space"></div>
        <div className="bg-dancer text-center p-5">
          <button
            className="btn-submit cursor-pointer"
            onClick={() => {
              navigate(
                `/planDetail?planId=${planId}&planBranchNum=${planBranchNum}`,
                {
                  replace: true
                }
              );
            }}
          >
            {t('optionChangeCompletion.returnToPlanDetails')}
          </button>
        </div>
      </div>
      <FooterComponent />
    </>
  );
}
export default CardChangeComplete;
