import React, { useEffect, useState } from 'react';
import Header from '../components/Header';
import { Row, Col, Breadcrumb, Form, Button } from 'antd';
import { useTranslation } from 'react-i18next';
import FooterComponent from '../components/FooterComponent';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';

function CampaignCodeAddedConfirm() {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { state } = useLocation();
  const [campaingData, setCampaignData] = useState();

  useEffect(() => {
    if (!state?.values?.planId) {
      navigate('/', { replace: true });
      return;
    }

    const campaignCodes = [
      ...(state.values.campaignCodes?.filter((item) => item !== '') || []),
      ...state.values.campaignCodesExists
    ];

    if (campaignCodes.length > 0 && campaignCodes[0] !== '') {
      axios
        .post('api/getCampaignInfoByListCampaignCode', {
          campaignCodes,
          startDate: state.values.useStartDate
        })
        .then((res) => {
          setCampaignData(res.data);
        });
    }
  }, []);

  const addCampaignWithPlanId = () => {
    if (
      state.values.campaignCodes.length > 0 &&
      state.values.campaignCodes[0] !== ''
    ) {
      const url = '/api/addCampaignWithPlanId';
      axios
        .post(url, {
          planId: state.values.planId,
          campaignCodes: state.values.campaignCodes,
          projectCode: state.values.projectCode,
          startDate: state.values.useStartDate
        })
        .then(() => {
          navigate('/campaignCodeAddedCompletion', { state });
        });
    } else {
      navigate('/campaignCodeAddedCompletion', { state });
    }
  };

  return (
    <>
      <Header />
      <div className="white-space"></div>
      <div className="container-custom-primary campaign-code-added-confirm">
        <Row className="title mb-primary">
          <Col md={{ span: 6 }} className="page-title">
            {t('share.campaignCodeAdd')}
            {' / '}
            {t('share.confirm')}
          </Col>
          <Col md={{ span: 18 }} className="text-right">
            <Breadcrumb separator=">">
              <Breadcrumb.Item>{t('share.input')}</Breadcrumb.Item>
              <Breadcrumb.Item className="current bold">
                {t('share.confirm')}
              </Breadcrumb.Item>
              <Breadcrumb.Item>{t('share.completion')}</Breadcrumb.Item>
            </Breadcrumb>
          </Col>
        </Row>
        <Form
          name="frmCampaignCodeAddedConfirm"
          form={form}
          autoComplete="off"
          onFinish={() => {
            addCampaignWithPlanId();
          }}
        >
          <div className="usage-plan mb-primary">
            <Col className="title-sub color-primary">
              {t('share.usagePlan')}
            </Col>
            <Col md={{ span: 10 }} className="border-default mb-primary ml-15">
              <Row className="border-bottom-default">
                <Col className="bg-primary border-right-default pl-5 col-left-lbl">
                  {t('share.planId')}
                </Col>
                <Col>
                  <Form.Item name="planDetail.planId" className="mb-0 pl-15">
                    {state?.values?.planId}
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col className="bg-primary border-right-default pl-5 col-left-lbl">
                  {t('planDetail.planUsageStartDate')}
                </Col>
                <Col>
                  <Form.Item name="" className="mb-0 pl-15">
                    {state?.values?.useStartDate}
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </div>
          <div className="campaign">
            <Col md={{ span: 4 }} className="title-sub color-primary">
              {t('share.campaignCode')}
            </Col>
            <Row className="border-default ml-15">
              <Col
                md={{ span: 4 }}
                className="bg-primary border-right-default pl-5"
              >
                {t('CampaignCodeAddedConfirm.campaign')}
                <br />
                &nbsp;
              </Col>
              <Col md={{ span: 20 }}>
                <Row className="bg-primary  border-bottom-default">
                  <Col md={{ span: 3 }} className="pl-15">
                    {t('share.code')}
                  </Col>
                  <Col md={{ span: 10 }}>{t('share.campaignName')}</Col>
                  <Col md={{ span: 6 }}>{t('share.applicablePeriod')}</Col>
                  <Col md={{ span: 5 }}>{t('share.benefits')}</Col>
                </Row>
                {campaingData?.map((item) => (
                  <Row>
                    <Col md={{ span: 3 }} className="pl-15">
                      {item.campaignCode}
                    </Col>
                    <Col md={{ span: 10 }}>{item.campaignName}</Col>
                    <Col md={{ span: 6 }}>{item.campaignPeriod}</Col>
                    <Col md={{ span: 4 }}>{item.campaignBenefits}</Col>
                    <Col md={{ span: 1 }} style={{ color: 'red' }}>
                      {state.values.campaignCodes?.includes(item.campaignCode)
                        ? '*'
                        : ''}
                    </Col>
                  </Row>
                ))}
              </Col>
            </Row>
          </div>
          <div className="white-space"></div>
          <div className="bg-dancer p-5">
            <Row>
              <Col md={{ span: 12 }} className="text-right">
                <button type="submit" className="btn-submit cursor-pointer">
                  {t('cancellationOfReservationInput.execution')}
                </button>
              </Col>
              <Col md={{ span: 12 }} className="text-right">
                <Button
                  onClick={() => {
                    navigate(
                      `/planDetail?planId=${state?.values?.planId}&planBranchNum=${state?.values?.planBranchNum}`,
                      {
                        replace: true
                      }
                    );
                  }}
                  className="btn-cancel"
                >
                  {t('share.cancel')}
                </Button>
              </Col>
            </Row>
          </div>
        </Form>
        <div className="line-primary"></div>
        <Button
          onClick={() => {
            navigate(`/campaignCodeAddedInput`, {
              state
            });
          }}
          className="btn-return"
        >
          {t('share.return')}
        </Button>
      </div>
      <FooterComponent />
    </>
  );
}
export default CampaignCodeAddedConfirm;
