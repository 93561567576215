import React, { useEffect } from 'react';
import Header from '../components/Header';
import { useTranslation } from 'react-i18next';
import { Row, Col, Button, Breadcrumb } from 'antd';
import FooterComponent from '../components/FooterComponent';
import { useForceUpdate } from '../utils/utils';
import { useNavigate, useLocation } from 'react-router-dom';
function CancellationOfReservationCompletion() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { state } = useLocation();
  const forceUpdate = useForceUpdate();

  useEffect(() => {
    forceUpdate();
  }, []);

  return (
    <>
      <Header />
      <div className="white-space"></div>
      <div className="container-custom-primary">
        <Row className="title mb-primary">
          <Col md={{ span: 12 }} className="page-title">
            {t('cancellationOfReservationInput.title')}
            {' / '}
            {t('share.completion')}
          </Col>
          <Col md={{ span: 12 }} className="text-right">
            <Breadcrumb separator=">">
              <Breadcrumb.Item>{t('share.input')}</Breadcrumb.Item>
              <Breadcrumb.Item>{t('share.confirm')}</Breadcrumb.Item>
              <Breadcrumb.Item className="bold current">
                {t('share.completion')}
              </Breadcrumb.Item>
            </Breadcrumb>
          </Col>
        </Row>
        {state.values.display == 1 && (
          <>
            <div>{t('cancellationOfReservationCompletion.notify1')}</div>
          </>
        )}
        {state.values.display == 2 && (
          <>
            <div>{t('cancellationOfReservationCompletion.notify2')}</div>
          </>
        )}
        <div className="white-space"></div>
        <div className="bg-dancer text-center p-5">
          <Button
            onClick={() => {
              navigate(
                `/planDetail?planId=${state?.values?.planId}&planBranchNum=${state?.values?.planBranchNum}`,
                {
                  replace: true
                }
              );
            }}
            className="btn-return"
          >
            {t('administratorConfirm.returnToPlanDetails')}
          </Button>
        </div>
      </div>
      <FooterComponent />
    </>
  );
}
export default CancellationOfReservationCompletion;
