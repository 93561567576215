import React, { useEffect } from 'react';
import Header from '../components/Header';
import '../i18n';
import { Row, Col, Breadcrumb, Form, Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';
import FooterComponent from '../components/FooterComponent';
import UseOptionComponent from '../components/UseOptionComponent';
import axios from 'axios';

function OptionChangeConfirm() {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { state } = useLocation();

  useEffect(() => {
    if (!state?.values?.planId) {
      navigate('/', { replace: true });
      return;
    }
  }, []);
  const updatePlanOption = () => {
    const url = '/api/updatePlanOption';
    axios
      .post(url, {
        ...state.values
      })
      .then(() => {
        navigate('/optionChangeCompletion', { replace: true, state: state });
      });
  };

  return (
    <>
      <Header />
      <div className="white-space"></div>
      <div className="container-custom-primary option-change-confirm">
        <Row className="title mb-primary">
          <Col md={{ span: 4 }} className="page-title">
            {t('planDetail.optionChange')}
            {' / '}
            {t('share.confirm')}
          </Col>
          <Col md={{ span: 20 }} className="text-right">
            <Breadcrumb separator=">">
              <Breadcrumb.Item>{t('share.input')}</Breadcrumb.Item>
              <Breadcrumb.Item className="bold current">
                {t('share.confirm')}
              </Breadcrumb.Item>
              <Breadcrumb.Item>{t('share.completion')}</Breadcrumb.Item>
            </Breadcrumb>
          </Col>
        </Row>
        <Form
          name="frmPlanNew"
          form={form}
          autoComplete="off"
          onFinish={() => {
            updatePlanOption();
          }}
        >
          <div className="usage-plan mb-primary">
            <Col md={{ span: 4 }} className="title-sub color-primary">
              {t('share.usagePlan')}
            </Col>
            <Col md={{ span: 10 }} className="border-default ml-15">
              <Row className="border-bottom-default">
                <Col className="bg-primary border-right-default pl-5 col-left-lbl">
                  {t('share.planId')}
                </Col>
                <Col>
                  <Form.Item name="planDetail.planId" className="mb-0 pl-15">
                    {state?.values?.planId}
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col className="bg-primary border-right-default pl-5 col-left-lbl">
                  {t('planDetail.planUsageStartDate')}
                </Col>
                <Col>
                  <Form.Item className="mb-0 pl-15">
                    {state?.values?.useStartDate}
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </div>
          <div className="option">
            <Col md={{ span: 4 }} className="title-sub color-primary">
              {t('share.option')}
            </Col>
            <UseOptionComponent
              data={state.values}
              type="optionChangeConfirm"
            />
          </div>
          <div className="bg-dancer p-5">
            <Row>
              <Col md={{ span: 12 }} className="text-right">
                <button type="submit" className="btn-submit cursor-pointer">
                  {t('cancellationOfReservationInput.execution')}
                </button>
              </Col>
              <Col md={{ span: 12 }} className="text-right">
                <Button
                  onClick={() => {
                    navigate(
                      `/planDetail?planId=${state?.values?.planId}&planBranchNum=${state?.values?.planBranchNum}`,
                      {
                        replace: true
                      }
                    );
                  }}
                  className="btn-cancel"
                >
                  {t('share.cancel')}
                </Button>
              </Col>
            </Row>
          </div>
          <div className="line-primary"></div>
          <Button
            onClick={() => {
              navigate(`/optionChangeInput`, {
                state
              });
            }}
            className="btn-return"
          >
            {t('share.return')}
          </Button>
        </Form>
      </div>
      <FooterComponent />
    </>
  );
}
export default OptionChangeConfirm;
