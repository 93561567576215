import React, { useEffect, useState } from 'react';
import Header from '../components/Header';
import '../i18n';
import {
  Row,
  Col,
  Breadcrumb,
  Form,
  Button,
  Radio,
  Space,
  Checkbox
} from 'antd';
import { useTranslation } from 'react-i18next';
import FooterComponent from '../components/FooterComponent';
import { useNavigate, useLocation } from 'react-router-dom';
import { useForceUpdate } from '../utils/utils';
import { STATUS_PLAN_DATA } from '../constant';
import axios from 'axios';

function BackupSelect() {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { state } = useLocation();
  const forceUpdate = useForceUpdate();
  const [listBackup, setListBackup] = useState([]);
  const [errorMsg, setErrorMsg] = useState('');

  useEffect(() => {
    if (!state?.values?.planId) {
      navigate('/', { replace: true });
      return;
    }

    if (state?.values) {
      axios
        .get('/api/getBackupList', {
          params: {
            planId: state?.values?.planId
          }
        })
        .then((res) => {
          const { data } = res;
          setListBackup([...data.bk_list]);
          setErrorMsg(data?.err_msg);
        });

      form.setFieldsValue(state.values);
      forceUpdate();
    }
  }, []);
  return (
    <>
      <Header />
      <div className="white-space"></div>
      <div className="box-plan container-custom-primary option-change-input">
        <Row className="title mb-primary">
          <Col md={{ span: 12 }} className="page-title">
            {t('backup.link')}
          </Col>
          <Col md={{ span: 12 }} className="text-right">
            <Breadcrumb separator=">">
              <Breadcrumb.Item className="bold current">
                {t('backup.select')}
              </Breadcrumb.Item>
              <Breadcrumb.Item>{t('share.confirm')}</Breadcrumb.Item>
              <Breadcrumb.Item>{t('share.completion')}</Breadcrumb.Item>
            </Breadcrumb>
          </Col>
        </Row>
        <Form
          name="frmOptionChange"
          form={form}
          autoComplete="off"
          onFinish={(values) => {
            navigate('/backupConfirm', {
              state: {
                ...state,
                values: {
                  ...state?.values,
                  ...values,
                  backupData: listBackup?.[values.backupIdx]
                },
                prevData: state?.prevData || {
                  ...state?.values
                }
              }
            });
          }}
        >
          <div className="mb-primary">
            <Col md={{ span: 10 }} className="title-sub color-primary">
              {t('share.usagePlan')}
            </Col>
            <Col md={{ span: 10 }}>
              <div className="border-default ml-15">
                <Row className="border-bottom-default">
                  <Col className="bg-primary border-right-primary pl-5 col-left-lbl">
                    {t('share.planId')}
                  </Col>
                  <Col>
                    <Form.Item name="planDetail.planId" className="mb-0 pl-15">
                      {state?.values?.planId}
                    </Form.Item>
                  </Col>
                </Row>
                <Row className="border-bottom-default">
                  <Col className="bg-primary border-right-primary pl-5 col-left-lbl">
                    {t('plan.version')}
                  </Col>
                  <Col>
                    <Form.Item className="mb-0 pl-15">
                      {state?.values?.planBranchNum}
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col className="bg-primary border-right-primary pl-5 col-left-lbl">
                    {t('planDetail.status')}
                  </Col>
                  <Col>
                    <Form.Item className="mb-0 pl-15">
                      {STATUS_PLAN_DATA[state?.values?.status]}
                    </Form.Item>
                  </Col>
                </Row>
              </div>
            </Col>
          </div>
          <div className="mb-primary">
            <Col md={{ span: 10 }}>
              <div className="title-sub color-primary">{t('backup.list')}</div>
              <div className="ml-15 mb-secondary">{t('backup.note5')}</div>
              <div className="ml-15 border-default">
                <Row className="row-item">
                  <Col
                    md={{ span: 20 }}
                    className="bg-primary col-label border-right-primary"
                  >
                    <label>{t('backup.listTitle')} </label>
                  </Col>
                  <Col md={{ span: 4 }} className="bg-primary p-5">
                    <label>{t('backup.restoreSelectionTitle')} </label>
                  </Col>
                </Row>
                {(listBackup?.length || 0) == 0 && (
                  <Row className="border-bottom-defaul">
                    <Col
                      md={{ span: 20 }}
                      className="border-right-primary pl-15"
                    >
                      {''}
                    </Col>
                    <Col md={{ span: 4 }} className="text-center">
                      {''}
                    </Col>
                  </Row>
                )}
                <Form.Item
                  name={'backupIdx'}
                  className="mb-0 rdo-group-backup-item"
                  rules={[{ required: true, message: '' }]}
                >
                  <Radio.Group className="rdo-group-backup">
                    {listBackup?.map((item, idx) => (
                      <Row className="border-bottom-defaul">
                        <Col
                          md={{ span: 20 }}
                          className="border-right-primary pl-15"
                        >
                          {item.create_time}
                        </Col>
                        <Col md={{ span: 4 }} className="text-center">
                          <Radio value={idx}></Radio>
                        </Col>
                      </Row>
                    ))}
                  </Radio.Group>
                </Form.Item>
              </div>
            </Col>
            {(!listBackup || errorMsg) && (
              <div className="bg-gray ml-15 p-5" direction="vertical">
                {(!listBackup || state?.values?.status != 3) && (
                  <div>{t('backup.nodata')}</div>
                )}
                {errorMsg && <div>{errorMsg}</div>}
              </div>
            )}
          </div>
          <div className="mb-primary ml-15">
            <div>{t('OptionChange.note')}</div>
            <div className="pl-15">
              <div>{t('backup.note1')}</div>
              <div>{t('backup.note2')}</div>
            </div>
          </div>
          <div className="bg-dancer text-center p-5">
            <Button
              htmlType="submit"
              disabled={!listBackup || state?.values?.status != 3}
              className="btn-return cursor-pointer"
            >
              {t('share.confirm')}
            </Button>
          </div>
          <div className="line-primary"></div>
          <Button
            onClick={() => {
              navigate('/otherProceduresStopOrCancel', {
                state
              });
            }}
            className="btn-return"
          >
            {t('share.return')}
          </Button>
        </Form>
      </div>
      <FooterComponent />
    </>
  );
}
export default BackupSelect;
