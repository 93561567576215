import React from 'react';
import '../i18n';
import { Row, Col, Form } from 'antd';
import { useTranslation } from 'react-i18next';
import { STATUS_PLAN_DATA } from '../constant';
function PlanInfoArea(props) {
  const { t } = useTranslation();
  if (!props.data?.planId) return;
  return (
    <div className="mb-primary box-plan-info">
      <Col md={{ span: 9 }} className="border-default">
        <Row className={props.isOnlyShowPlanId ? '' : 'border-bottom-default'}>
          <Col className="bg-primary border-right-default pl-5 col-left-lbl">
            {t('share.planId')}
          </Col>
          <Col>
            <Form.Item name="planDetail.planId" className="mb-0 pl-15">
              {props.data?.planId}
            </Form.Item>
          </Col>
        </Row>
        {!props.isOnlyShowPlanId && (
          <>
            <Row className="border-bottom-default">
              <Col className="bg-primary border-right-default pl-5 col-left-lbl">
                {t('plan.version')}
              </Col>
              <Col>
                <Form.Item className="mb-0 pl-15">
                  {props.data?.planBranchNum}
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col className="bg-primary border-right-default pl-5 col-left-lbl">
                {t('planDetail.status')}
              </Col>
              <Col>
                <Form.Item className="mb-0 pl-15">
                  {STATUS_PLAN_DATA[props.data?.status]}
                </Form.Item>
              </Col>
            </Row>
          </>
        )}
      </Col>
    </div>
  );
}
export default PlanInfoArea;
