import React from 'react';
import '../i18n';
import { Row, Col, Typography } from 'antd';
import { useTranslation } from 'react-i18next';

function PriceComponent(props) {
  const { t } = useTranslation();
  const { Title } = Typography;
  return (
    <>
      <Row>
        <Col md={{ span: 10 }}>
          <Row>
            <Col md={{ span: 15 }} className="number-primary text-right">
              <Col md={{ span: 18 }} push={6} className="bg-alert pr-15">
                {props.simulation?.annualTotal}
              </Col>
            </Col>
            <Col md={{ span: 9 }} className="vertical-align-center">
              <span className="f20 ml-5">{t('freeSimulation.yen')}</span>
              <span className="mt-15">{t('freeSimulation.taxExcluded')}</span>
            </Col>
          </Row>
        </Col>
      </Row>
      <Col md={{ span: 7 }} className="text-right pr-15">
        {`（${t('freeSimulation.monthlyAverage')}${
          props.simulation?.monthlyAverage
        }${t('freeSimulation.yen')}）`}
      </Col>
      <div className="border-bottom-primary mb-secondary"></div>

      <Row>
        <Col md={{ span: 10 }}>
          <Title level={5}>
            {t('freeSimulation.campaignApplicationAmount')}
          </Title>
          <Row className="mb-secondary">
            <Col md={{ span: 15 }}>
              <div className="number-primary text-right">
                <Col md={{ span: 18 }} push={6} className="bg-alert pr-15">
                  {props.simulation?.discountAmount != 0 && <span>-</span>}
                  {props.simulation?.discountAmount}
                </Col>
              </div>
            </Col>
            <Col md={{ span: 9 }}>
              <span className="f20 ml-5">{t('freeSimulation.yen')}</span>
            </Col>
          </Row>
          <Row>
            <Title level={5}>
              {t('freeSimulation.annualUsageFeeAfterApplyingTheCampaign')}
            </Title>
            <Col md={{ span: 15 }} className="number-primary text-right">
              <Col md={{ span: 18 }} push={6} className="bg-alert pr-15">
                <span>{props.simulation?.amountAfterDiscount}</span>
              </Col>
            </Col>
            <Col md={{ span: 9 }} className="vertical-align-center">
              <span className="f20 ml-5">{t('freeSimulation.yen')}</span>
              <span className="mt-15">{t('freeSimulation.taxExcluded')}</span>
            </Col>
          </Row>
        </Col>

        <Col md={{ span: 14 }}>
          <div className="title-secondary mb-primary">
            {t('freeSimulation.campaignApplicationDetails')}
          </div>
          <Row className="row-heading mb-secondary">
            <Col md={{ span: 13 }}>
              <strong>{t('share.campaignName')}</strong>
            </Col>
            <Col md={{ span: 6 }}>
              <strong>{t('share.applicablePeriod')}</strong>
            </Col>
            <Col md={{ span: 5 }}>
              <strong>{t('share.benefits')}</strong>
            </Col>
          </Row>
          {props.simulation?.campaignList?.map((item) => (
            <Row className="mb-secondary">
              <Col md={{ span: 13 }}>{item.campaignName}</Col>
              <Col md={{ span: 6 }}>{item.campaignPeriod}</Col>
              <Col md={{ span: 5 }}>{item.campaignBenefits}</Col>
            </Row>
          ))}
        </Col>
      </Row>
    </>
  );
}
export default PriceComponent;
