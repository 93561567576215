import React, { useEffect } from 'react';
import Header from '../components/Header';
import '../i18n';
import { Row, Col, Breadcrumb, Form, Space, Button } from 'antd';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import FooterComponent from '../components/FooterComponent';
import FormItem from 'antd/lib/form/FormItem';
import ServerListComponent from '../components/ServerListComponent';
import logoGeminiot from '../assets/images/logo-geminiot.png';
import logoPasterios from '../assets/images/logo-pasterios.png';
import axios from 'axios';

function ChangeApplicationConfirm() {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { state } = useLocation();

  useEffect(() => {
    if (!state?.values?.planId) {
      navigate('/', { replace: true });
    }
  }, []);

  const updatePlanApplication = () => {
    const url = '/api/updatePlanApplication';
    axios
      .post(url, {
        ...state.values
      })
      .then(() => {
        navigate('/changeApplicationCompletion', { state });
      });
  };

  return (
    <>
      <Header />
      <div className="white-space"></div>
      <div className="container-custom-primary change-application-confirm">
        <Row className="title mb-primary">
          <Col md={{ span: 4 }} className="page-title">
            {t('share.changeApplication')}
            {' / '}
            {t('share.confirm')}
          </Col>
          <Col md={{ span: 20 }} className="text-right">
            <Breadcrumb separator=">">
              <Breadcrumb.Item>
                {t('paymentInformationRegistration.useStartDateSetting')}
              </Breadcrumb.Item>
              <Breadcrumb.Item className="bold current">
                {t('share.confirm')}
              </Breadcrumb.Item>
              <Breadcrumb.Item>{t('share.completion')}</Breadcrumb.Item>
            </Breadcrumb>
          </Col>
        </Row>
        <Form
          name="frmPlanNew"
          form={form}
          autoComplete="off"
          onFinish={() => {
            updatePlanApplication();
          }}
        >
          <Col md={{ span: 12 }}>
            <div className="border-default mb-primary ml-15">
              <Row className="border-bottom-default">
                <Col
                  md={{ span: 8 }}
                  className="bg-primary border-right-default pl-5"
                >
                  {t('share.planId')}
                </Col>
                <Col md={{ span: 16 }} className="pl-5">
                  <FormItem className="mb-0">{state?.values?.planId}</FormItem>
                </Col>
              </Row>
              <Row>
                <Col
                  md={{ span: 8 }}
                  className="bg-primary border-right-default pl-5"
                >
                  {t('plan.version')}
                </Col>
                <Col md={{ span: 16 }} className="pl-5">
                  {state?.values?.planBranchNum}
                </Col>
              </Row>
            </div>
          </Col>
          <div className="use-start-date mb-primary">
            <Col md={{ span: 4 }} className="title-sub color-primary">
              {t('share.useStartDate')}
            </Col>
            <Col md={{ span: 12 }}>
              <div className="border-default ml-15">
                <Row className="row-title">
                  <Col
                    md={{ span: 8 }}
                    className="bg-primary border-right-default pl-5"
                  >
                    {t(
                      'changeApplicationSettingStartDate.changeUsageStartDate'
                    )}
                  </Col>
                  <Col md={{ span: 16 }} className="pl-5">
                    <Space size={20}>
                      <div>{t('share.specifyDate')}</div>
                      <div> {state?.values?.startDate}</div>
                    </Space>
                  </Col>
                </Row>
              </div>
            </Col>
          </div>
          <div className="applicable-campaign-code">
            <div className="usage-plan">
              <Col md={{ span: 4 }} className="title-sub color-primary">
                {t('changeApplicationConfirm.usagePlan')}
              </Col>
              <div className="ml-15">
                <Col md={{ span: 12 }}>
                  <Row className="border-default bb-0">
                    <Col
                      md={{ span: 10 }}
                      className="bg-primary vertical-align-center border-right-default pl-5"
                    >
                      {t('share.product')}
                    </Col>
                    <Col md={{ span: 14 }}>
                      <img
                        src={
                          state.values.component === 1
                            ? logoGeminiot
                            : logoPasterios
                        }
                        className="img-logo"
                      />
                    </Col>
                  </Row>
                </Col>
                <div className="border-default p-10">
                  <div className="title-secondary mb-secondary ml-15">
                    {t('share.basicComposition')}
                  </div>
                  <ServerListComponent
                    type="applicationCompletion"
                    itemSetting={state.values?.itemSetting}
                  />
                </div>
              </div>
              <div className="line-primary"></div>
              <div className="text-center bg-dancer mb-primary p-5">
                <button className="btn-default btn-submit" type="submit">
                  {t('share.Application')}
                </button>
              </div>
            </div>
          </div>
        </Form>
        <Button
          onClick={() => {
            navigate(`/changeApplicationSettingStartDate`, {
              state
            });
          }}
          className="btn-return"
        >
          {t('share.return')}
        </Button>
      </div>
      <FooterComponent />
    </>
  );
}
export default ChangeApplicationConfirm;
