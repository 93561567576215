import React, { useEffect } from 'react';
import Header from '../components/Header';
import { Row, Col, Breadcrumb, Form, Button } from 'antd';
import { useTranslation } from 'react-i18next';
import FooterComponent from '../components/FooterComponent';
import { useNavigate, useLocation } from 'react-router-dom';
import { useForceUpdate } from '../utils/utils';
import CampaignCodeInput from '../components/CampaignCodeInput';

function CampaignCodeAddedInput() {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { state } = useLocation();
  const forceUpdate = useForceUpdate();

  useEffect(() => {
    if (!state?.values?.planId) {
      navigate('/', { replace: true });
      return;
    }

    if (!state.values.campaignCodesExists) {
      state.values.campaignCodesExists = [
        ...(state.values.campaignCodes || [])
      ];
      state.values.campaignCodes = [''];
    }

    form.setFieldsValue({ ...state.values });
    forceUpdate();
  }, []);

  return (
    <>
      <Header />
      <div className="white-space"></div>
      <div className="container-custom-primary campaign-code-added-input">
        <Row className="title mb-primary">
          <Col md={{ span: 6 }} className="page-title">
            {t('share.campaignCodeAdd')}
          </Col>
          <Col md={{ span: 18 }} className="text-right">
            <Breadcrumb separator=">">
              <Breadcrumb.Item className="current bold">
                {t('share.input')}
              </Breadcrumb.Item>
              <Breadcrumb.Item>{t('share.confirm')}</Breadcrumb.Item>
              <Breadcrumb.Item>{t('share.completion')}</Breadcrumb.Item>
            </Breadcrumb>
          </Col>
        </Row>
        <Form
          name="frmCampaignCodeAddedInput"
          form={form}
          autoComplete="off"
          onFinish={(values) => {
            navigate('/campaignCodeAddedConfirm', {
              state: { ...state, values: { ...state?.values, ...values } }
            });
          }}
        >
          <div className="usage-plan mb-primary">
            <Col className="title-sub color-primary">
              {t('share.usagePlan')}
            </Col>
            <Col md={{ span: 10 }} className="border-default mb-primary ml-15">
              <Row className="border-bottom-default">
                <Col className="bg-primary border-right-default pl-5 col-left-lbl">
                  {t('share.planId')}
                </Col>
                <Col>
                  <Form.Item name="planDetail.planId" className="mb-0 pl-15">
                    {state?.values?.planId}
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col className="bg-primary border-right-default pl-5 col-left-lbl">
                  {t('planDetail.planUsageStartDate')}
                </Col>
                <Col>
                  <Form.Item name="" className="mb-0 pl-15">
                    {state?.values?.useStartDate}
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </div>

          <CampaignCodeInput form={form} isModeUse />
          <div className="white-space"></div>
          <div className="bg-dancer text-center p-5">
            <button type="submit" className="btn-submit cursor-pointer">
              {t('share.confirm')}
            </button>
          </div>
        </Form>
        <div className="line-primary"></div>
        <Button
          onClick={() => {
            navigate(
              `/planDetail?planId=${state?.values?.planId}&planBranchNum=${state?.values?.planBranchNum}`,
              {
                replace: true
              }
            );
          }}
          className="btn-return"
        >
          {t('share.return')}
        </Button>
      </div>
      <FooterComponent />
    </>
  );
}
export default CampaignCodeAddedInput;
