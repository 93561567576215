import React, { useEffect } from 'react';
import Header from '../components/Header';
import '../i18n';
import { Row, Col, Breadcrumb, Form, Button } from 'antd';
import { useTranslation } from 'react-i18next';
import FooterComponent from '../components/FooterComponent';
import { useNavigate, useLocation } from 'react-router-dom';
import { STATUS_PLAN_DATA } from '../constant';
import axios from 'axios';

function BackupConfirm() {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { state } = useLocation();

  useEffect(() => {
    if (!state?.values?.backupData) {
      navigate('/', { replace: true });
      return;
    }
  }, []);

  const restore = () => {
    const url = '/api/restoreIntance';
    axios
      .post(url, {
        ...state.values.backupData,
        planId: state.values.planId
      })
      .then(() => {
        navigate('/backupCompletion', { state });
      });
  };
  return (
    <>
      <Header />
      <div className="white-space"></div>
      <div className="box-plan container-custom-primary option-change-input">
        <Row className="title mb-primary">
          <Col md={{ span: 12 }} className="page-title">
            {t('backup.link')} / {t('share.confirm')}
          </Col>
          <Col md={{ span: 12 }} className="text-right">
            <Breadcrumb separator=">">
              <Breadcrumb.Item>{t('backup.select')}</Breadcrumb.Item>
              <Breadcrumb.Item className="bold current">
                {t('share.confirm')}
              </Breadcrumb.Item>
              <Breadcrumb.Item>{t('share.completion')}</Breadcrumb.Item>
            </Breadcrumb>
          </Col>
        </Row>
        <Form
          name="frmOptionChange"
          form={form}
          autoComplete="off"
          onFinish={() => {
            restore();
          }}
        >
          <div className="mb-primary">
            <Col md={{ span: 10 }} className="title-sub color-primary">
              {t('share.usagePlan')}
            </Col>
            <Col md={{ span: 10 }}>
              <div className="border-default ml-15">
                <Row className="border-bottom-default">
                  <Col className="bg-primary border-right-primary pl-5 col-left-lbl">
                    {t('share.planId')}
                  </Col>
                  <Col>
                    <Form.Item name="planDetail.planId" className="mb-0 pl-15">
                      {state?.values?.planId}
                    </Form.Item>
                  </Col>
                </Row>
                <Row className="border-bottom-default">
                  <Col className="bg-primary border-right-primary pl-5 col-left-lbl">
                    {t('plan.version')}
                  </Col>
                  <Col>
                    <Form.Item className="mb-0 pl-15">
                      {state?.values?.planBranchNum}
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col className="bg-primary border-right-primary pl-5 col-left-lbl">
                    {t('planDetail.status')}
                  </Col>
                  <Col>
                    <Form.Item className="mb-0 pl-15">
                      {STATUS_PLAN_DATA[state?.values?.status]}
                    </Form.Item>
                  </Col>
                </Row>
              </div>
            </Col>
          </div>
          <div className="mb-primary">
            <Col md={{ span: 10 }}>
              <div className="title-sub color-primary">
                {t('backup.confirmTitle')}
              </div>
              <div className="ml-15 border-default">
                <div className="bg-primary col-label border-bottom-secondary">
                  <label>{t('backup.listTitle')} </label>
                </div>
                <div className="pl-15">
                  {' '}
                  {state?.values?.backupData?.create_time}
                </div>
              </div>
            </Col>
          </div>
          <div className="ml-15">{t('backup.note3')}</div>
          <div className="bg-dancer p-5 mb-primary">
            <Row>
              <Col md={{ span: 12 }} className="text-right">
                <button type="submit" className="btn-submit cursor-pointer">
                  {t('cancellationOfReservationInput.execution')}
                </button>
              </Col>
              <Col md={{ span: 12 }} className="text-right">
                <Button
                  onClick={() => {
                    navigate('/otherProceduresStopOrCancel', {
                      state
                    });
                  }}
                  className="btn-cancel"
                >
                  {t('share.cancel')}
                </Button>
              </Col>
            </Row>
          </div>

          <div className="line-primary"></div>
          <Button
            onClick={() => {
              navigate('/backupSelect', {
                state
              });
            }}
            className="btn-return"
          >
            {t('share.return')}
          </Button>
        </Form>
      </div>
      <FooterComponent />
    </>
  );
}
export default BackupConfirm;
