import Header from '../components/Header';
import { Row, Col, Form, Space, Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import axios from 'axios';
import React, { useState, useEffect } from 'react';

function Profile() {
  const { t, i18n } = useTranslation();
  const [userInfo, setUserInfo] = useState('');

  useEffect(() => {
    if (!i18n.language) return;
    axios({
      method: 'get',
      url: '/api/profile',
      params: {
        language: i18n.language
      }
    }).then((res) => {
      setUserInfo(res.data);
    });
  }, [i18n.language]);

  return (
    <div>
      <Header />
      <div className="box">
        <Form>
          <div className="box-register box-register-confirm">
            <div className="box-title title-profile f-medium">
              <Row>
                <Col md={{ span: 8 }} xs={{ span: 24 }}>
                  {t('profile.title')}
                </Col>
                <Col span={16} className="text-right">
                  <Space>
                    <Button
                      htmlType="submit"
                      className="btn-primary btn-link f-small"
                    >
                      <Link to="/passwordChange">
                        {t('profile.btnChangePassword')}
                      </Link>
                    </Button>
                    <Button
                      htmlType="submit"
                      className="btn-primary btn-link f-small"
                    >
                      <Link to="/profileChange">
                        {t('profile.btnProfileChange')}
                      </Link>
                    </Button>
                    <Button
                      htmlType="submit"
                      className="btn-primary btn-link f-small"
                    >
                      <Link to="/emailChange">
                        {t('profile.btnEmailAddressChange')}
                      </Link>
                    </Button>
                  </Space>
                </Col>
              </Row>
            </div>
            <div className="box-body">
              <div className="frame">
                <Form.Item>
                  <Row>
                    <Col md={{ span: 8 }} xs={{ span: 12 }}>
                      E-mail：
                    </Col>
                    <Col smd={{ span: 16 }} xs={{ span: 12 }}>
                      {userInfo.mail}
                    </Col>
                  </Row>
                </Form.Item>
                <Form.Item>
                  <Row>
                    <Col md={{ span: 8 }} xs={{ span: 12 }}>
                      {t('share.surnameName')}：
                    </Col>
                    <Col md={{ span: 16 }} xs={{ span: 12 }}>
                      {userInfo.firstName}
                    </Col>
                  </Row>
                </Form.Item>
                <Form.Item>
                  <Row>
                    <Col md={{ span: 8 }} xs={{ span: 12 }}>
                      {t('share.Name')}：
                    </Col>
                    <Col md={{ span: 16 }} xs={{ span: 12 }}>
                      {userInfo.lastName}
                    </Col>
                  </Row>
                </Form.Item>
                <Form.Item>
                  <Row>
                    <Col md={{ span: 8 }} xs={{ span: 12 }}>
                      {t('share.kanaSurname')}
                    </Col>
                    <Col md={{ span: 16 }} xs={{ span: 12 }}>
                      {userInfo.kanaFirstName}
                    </Col>
                  </Row>
                </Form.Item>
                <Form.Item>
                  <Row>
                    <Col md={{ span: 8 }} xs={{ span: 12 }}>
                      {t('share.kanaName')}
                    </Col>
                    <Col md={{ span: 16 }} xs={{ span: 12 }}>
                      {userInfo.kanaLastName}
                    </Col>
                  </Row>
                </Form.Item>
                <Form.Item>
                  <Row>
                    <Col md={{ span: 8 }} xs={{ span: 12 }}>
                      {' '}
                      {t('share.companyName')}：
                    </Col>
                    <Col md={{ span: 16 }} xs={{ span: 12 }}>
                      {userInfo.companyName}
                    </Col>
                  </Row>
                </Form.Item>
                <Form.Item>
                  <Row>
                    <Col md={{ span: 8 }} xs={{ span: 12 }}>
                      {' '}
                      {t('share.divisionName')}：
                    </Col>
                    <Col md={{ span: 16 }} xs={{ span: 12 }}>
                      {userInfo.divisionName}
                    </Col>
                  </Row>
                </Form.Item>
                {userInfo.countryFlag === 1 && (
                  <>
                    <Form.Item>
                      <Row>
                        <Col md={{ span: 8 }} xs={{ span: 12 }}>
                          {t('share.country')}：
                        </Col>
                        <Col md={{ span: 16 }} xs={{ span: 12 }}>
                          {userInfo.countryCodeName?.countryCodeName}
                        </Col>
                      </Row>
                    </Form.Item>
                    <Form.Item>
                      <Row>
                        <Col md={{ span: 8 }} xs={{ span: 12 }}>
                          {t('share.postalCode')}：
                        </Col>
                        <Col md={{ span: 16 }} xs={{ span: 12 }}>
                          {userInfo.postalCode}
                        </Col>
                      </Row>
                    </Form.Item>
                    <Form.Item>
                      <Row>
                        <Col md={{ span: 8 }} xs={{ span: 12 }}>
                          {t('share.prefectures')}：
                        </Col>
                        <Col md={{ span: 16 }} xs={{ span: 12 }}>
                          {userInfo.prefectures}
                        </Col>
                      </Row>
                    </Form.Item>
                    <Form.Item>
                      <Row>
                        <Col md={{ span: 8 }} xs={{ span: 12 }}>
                          {t('share.municipalities')}：
                        </Col>
                        <Col md={{ span: 16 }} xs={{ span: 12 }}>
                          {userInfo.municipalities}
                        </Col>
                      </Row>
                    </Form.Item>
                    <Form.Item>
                      <Row>
                        <Col md={{ span: 8 }} xs={{ span: 12 }}>
                          {t('share.address1')}：
                        </Col>
                        <Col md={{ span: 16 }} xs={{ span: 12 }}>
                          {userInfo.address1}
                        </Col>
                      </Row>
                    </Form.Item>
                    <Form.Item>
                      <Row>
                        <Col md={{ span: 8 }} xs={{ span: 12 }}>
                          {t('share.address2')}：
                        </Col>
                        <Col md={{ span: 16 }} xs={{ span: 12 }}>
                          {userInfo.address2}
                        </Col>
                      </Row>
                    </Form.Item>
                  </>
                )}
                {userInfo.countryFlag === 2 && (
                  <>
                    <Form.Item>
                      <Row>
                        <Col md={{ span: 8 }} xs={{ span: 12 }}>
                          {t('share.address1')}：
                        </Col>
                        <Col md={{ span: 16 }} xs={{ span: 12 }}>
                          {userInfo.address1}
                        </Col>
                      </Row>
                    </Form.Item>
                    <Form.Item>
                      <Row>
                        <Col md={{ span: 8 }} xs={{ span: 12 }}>
                          {t('share.address2Option')}
                        </Col>
                        <Col md={{ span: 16 }} xs={{ span: 12 }}>
                          {userInfo.address2}
                        </Col>
                      </Row>
                    </Form.Item>
                    <Form.Item>
                      <Row>
                        <Col md={{ span: 8 }} xs={{ span: 12 }}>
                          {t('share.city')}：
                        </Col>
                        <Col md={{ span: 16 }} xs={{ span: 12 }}>
                          {userInfo.city}
                        </Col>
                      </Row>
                    </Form.Item>
                    <Form.Item>
                      <Row>
                        <Col md={{ span: 8 }} xs={{ span: 12 }}>
                          {t('share.region')}：
                        </Col>
                        <Col md={{ span: 16 }} xs={{ span: 12 }}>
                          {userInfo.region}
                        </Col>
                      </Row>
                    </Form.Item>
                    <Form.Item>
                      <Row>
                        <Col md={{ span: 8 }} xs={{ span: 12 }}>
                          {t('share.postalCode')}：
                        </Col>
                        <Col md={{ span: 16 }} xs={{ span: 12 }}>
                          {userInfo.postalCode}
                        </Col>
                      </Row>
                    </Form.Item>
                    <Form.Item>
                      <Row>
                        <Col md={{ span: 8 }} xs={{ span: 12 }}>
                          {t('share.country')}：
                        </Col>
                        <Col md={{ span: 16 }} xs={{ span: 12 }}>
                          {userInfo.countryCodeName?.countryCodeName}
                        </Col>
                      </Row>
                    </Form.Item>
                  </>
                )}
                <Form.Item>
                  <Row>
                    <Col md={{ span: 8 }} xs={{ span: 12 }}>
                      {t('share.phoneNumber')}：
                    </Col>
                    <Col md={{ span: 16 }} xs={{ span: 12 }}>
                      {userInfo.phoneNumber}
                    </Col>
                  </Row>
                </Form.Item>
              </div>
            </div>
          </div>
        </Form>
      </div>
    </div>
  );
}
export default Profile;
