import React, { useEffect, useState } from 'react';
import { Breadcrumb, Button, Col, Form, Row } from 'antd';
import { useNavigate } from 'react-router-dom';
import Header from '../components/Header';
import { useTranslation } from 'react-i18next';
import logoGeminiot from '../assets/images/logo-geminiot.png';
import logoPasterios from '../assets/images/logo-pasterios.png';
import FooterComponent from '../components/FooterComponent';
import { useForceUpdate } from '../utils/utils';
import axios from 'axios';
import SecuritysettingsConfigView from '../components/SecuritysettingsConfigView';

function SecuritysettingsInput() {
  const { t } = useTranslation();
  const [data, setData] = useState([]);
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const forceUpdate = useForceUpdate();

  useEffect(() => {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const planId = params.get('planId');
    if (!planId) {
      navigate('/');
      return;
    }

    axios
      .get('/api/getSecuritySetting', {
        params: {
          planId
        }
      })
      .then((res) => {
        const { data } = res;
        setData(data);
        form.setFieldsValue({
          lstIpAddress: [...(data.lstIpAddress || [])]
        });
        forceUpdate();
      });
  }, []);

  return (
    <>
      <Header />
      <div className="white-space"></div>
      <div className="container-custom-primary">
        <Row className="title mb-primary">
          <Col md={{ span: 6 }} className="page-title">
            {t('Securitysettings')}
          </Col>
          <Col md={{ span: 18 }} className="text-right">
            <Breadcrumb separator=">">
              <Breadcrumb.Item className="bold current">
                {t('share.input')}
              </Breadcrumb.Item>
              <Breadcrumb.Item>{t('share.confirm')}</Breadcrumb.Item>
              <Breadcrumb.Item>{t('share.completion')}</Breadcrumb.Item>
            </Breadcrumb>
          </Col>
        </Row>
        <Col md={{ span: 4 }} className="title-sub color-primary">
          {t('share.basicInformation')}
        </Col>
        <div className="border-default mb-primary">
          <Row className="border-bottom-default">
            <Col
              md={{ span: 6 }}
              className="bg-primary border-right-default pl-5"
            >
              {t('share.planId')}
            </Col>
            <Col md={{ span: 18 }} className="pl-5">
              {data?.planId}
            </Col>
          </Row>
          <Row>
            <Col
              md={{ span: 6 }}
              className="bg-primary border-right-default pl-5"
            >
              {t('plan.component')}
            </Col>
            <Col md={{ span: 18 }} className="pl-5">
              <img
                src={data?.component === 1 ? logoGeminiot : logoPasterios}
                className="img-logo pt-5 pb-5"
              />
            </Col>
          </Row>
        </div>
        <SecuritysettingsConfigView data={data} />
        <div class="line-primary"></div>

        <Button
          className="bg-info btn-info border-default"
          onClick={() => {
            navigate('/', { replace: true });
          }}
        >
          {t('share.return')}
        </Button>
      </div>
      <FooterComponent />
    </>
  );
}

export default SecuritysettingsInput;
