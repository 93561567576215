import React, { useEffect, useState } from 'react';
import '../i18n';
import { Row, Col, Form, InputNumber, Space, Collapse, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import logoGeminiot from '../assets/images/logo-geminiot.png';
import logoPasterios from '../assets/images/logo-pasterios.png';
import axios from 'axios';
import styled from 'styled-components';
import { CaretRightOutlined } from '@ant-design/icons';
import {
  DATE_FORMAT,
  LIST_SPEC_SETTING,
  PRODUCT_ITEM,
  USE_TIME
} from '../constant';
import { useForceUpdate, formatServerType } from '../utils/utils';
import { useLocation } from 'react-router-dom';
import isEqual from 'lodash/isEqual';
import moment from 'moment';

function ItemSettingInput(props) {
  const { t } = useTranslation();
  const [itemSelected, setItemSelected] = useState();
  const [listconfigTemplate, setListConfigTemplate] = useState([]);
  const forceUpdate = useForceUpdate();
  const { form } = props;
  const { Panel } = Collapse;
  const { state } = useLocation();
  const isPlanChange = state?.isNewPlanBranch ?? false;

  useEffect(() => {
    axios({
      method: 'get',
      url: '/api/getSystemConfigTemplate'
    }).then((res) => {
      setListConfigTemplate(res.data);
    });

    if (isPlanChange && !state.prevData) {
      state.prevData = { ...state.values };
    }
  }, []);

  useEffect(() => {
    if (listconfigTemplate.length == 0) return;

    if (form.getFieldValue('component') && form.getFieldValue('systemConfig')) {
      console.log(form.getFieldValue('component'));
      setItemSelected({
        component: form.getFieldValue('component'),
        type: form.getFieldValue('systemConfig')
      });
    } else {
      setSelectedItem(1, 2);
    }
  }, [listconfigTemplate]);

  const setSelectedItem = (component, type) => {
    if (!checkComponentChange(component)) {
      forceUpdate();
      return;
    }

    form.setFields([
      {
        name: 'componentSelectError',
        value: undefined
      }
    ]);
    setItemSelected({
      component,
      type
    });
    form.setFields([
      {
        name: 'component',
        value: component
      },
      {
        name: 'systemConfig',
        value: type
      }
    ]);

    if (listconfigTemplate.length > 0) {
      const setting = listconfigTemplate.filter(
        (item) =>
          component === item.component && type === item.systemConfiguration
      )?.[0]?.itemSetting;

      form.setFields([
        {
          name: 'itemSetting',
          value: setting ? JSON.parse(setting) : {}
        }
      ]);
    }
    if (type !== 4) {
      form.setFields([
        {
          name: ['systemUtilizationTimes', 5, 'usagePattern'],
          value: USE_TIME.NotUse
        },
        {
          name: ['systemUtilizationTimes', 6, 'usagePattern'],
          value: USE_TIME.NotUse
        }
      ]);
    } else {
      form.setFields([
        {
          name: ['systemUtilizationTimes', 5, 'usagePattern'],
          value: USE_TIME.AllDay
        },
        {
          name: ['systemUtilizationTimes', 6, 'usagePattern'],
          value: USE_TIME.AllDay
        }
      ]);
    }
    forceUpdate();
  };

  const selectedSpec = (key) => {
    const idx = LIST_SPEC_SETTING.findIndex(
      (item) =>
        item[0].setting.cpu ==
          form.getFieldValue(['itemSetting', key, 'server_cpu']) &&
        item[0].setting.mem ==
          form.getFieldValue(['itemSetting', key, 'server_mem'])
    );

    if (idx === -1) {
      form.setFields([
        {
          name: ['itemSetting', key, 'server_type'],
          value: null
        },
        {
          name: ['itemSetting', key, 'server_cpu'],
          value: null
        },
        {
          name: ['itemSetting', key, 'server_mem'],
          value: null
        }
      ]);
      return undefined;
    }
    return idx;
  };

  const addClassForItemSelected = (component, type) => {
    if (itemSelected?.component === component && itemSelected?.type === type)
      return 'product-item-selected';
    return '';
  };

  const StyledDiv = styled.div`
    display: table;
    .tbcell {
      display: table-cell;
    }
    .padLeft {
      position: relative;
      padding-left: 20px;
    }
    .padRight {
      position: relative;
      padding-right: 20px;
    }
    .insLeft_ap::before,
    .insLeft_etl::before {
      position: absolute;
      left: 10px;
      content: '(';
    }
    .insRight_ap::after,
    .insRight_etl::after {
      position: absolute;
      right: 0;
      content: ')';
    }
  `;

  const numberParser = (val) => {
    return Math.abs(Number.parseInt(val));
  };

  const checkComponentChange = (component) => {
    if (state?.prevData?.component === 2 && component === 1) {
      form.setFields([
        {
          name: 'componentSelectError',
          value: t('ItemSettingInput.error1')
        }
      ]);
      return false;
    }
    if (state?.prevData?.component === 1 && component === 2) {
      const startDate = moment(form.getFieldValue('startDate'), DATE_FORMAT);
      if (startDate.date() !== 1) {
        form.setFields([
          {
            name: 'componentSelectError',
            value: t('ItemSettingInput.error2')
          }
        ]);
        return false;
      }
    }

    return true;
  };
  return (
    <>
      <div className="usage-plan-body border-default ml-15">
        <Row>
          <Col md={{ span: 4 }} className="bg-primary col-label">
            {' '}
            <label className="lbl-required">
              {t('planNew.productSelection')}
            </label>
          </Col>
          <Col md={{ span: 16 }} className="col-list-product">
            <div className="list-geminiot">
              <div className="mb-primary">
                <img src={logoGeminiot} className="img-logo"></img>
              </div>
              <Space className="ml-30">
                {PRODUCT_ITEM.geminiot.map((item) => (
                  <div
                    className={`product-item border-default ${addClassForItemSelected(
                      item.component,
                      item.systemConfig
                    )}`}
                    onClick={() =>
                      setSelectedItem(item.component, item.systemConfig)
                    }
                  >
                    {item.childComponent}
                  </div>
                ))}
              </Space>
            </div>
            <div className="list-pasterios">
              <div className="mb-primary">
                <img src={logoPasterios} className="img-logo"></img>
              </div>
              <Space className="ml-30">
                {PRODUCT_ITEM.pasteriot.map((item) => (
                  <div
                    className={`product-item border-default ${addClassForItemSelected(
                      item.component,
                      item.systemConfig
                    )}`}
                    onClick={() =>
                      setSelectedItem(item.component, item.systemConfig)
                    }
                  >
                    {item.childComponent}
                  </div>
                ))}
              </Space>
            </div>
            <div className="mt-15 ant-form-item-explain-error">
              {form.getFieldValue('componentSelectError')}
            </div>
            <Form.Item hidden name="component" />
            <Form.Item hidden name="systemConfig" />
          </Col>
        </Row>
      </div>
      {props.type === 'planInput' && (
        <div className="mb-primary ml-15">{t('planNewProductNote')}</div>
      )}
      <div className="ml-15">
        <Form.Item
          name="itemSetting"
          hidden
          rules={[
            () => ({
              validator(_, value) {
                let isPass = true;
                let messagePlanChange = undefined;
                let isDataChanged = false;

                isPass = checkComponentChange(form.getFieldValue('component'));

                if (isPass) {
                  Object.keys(value).map((key) => {
                    delete value[key].message;

                    if (key !== 'data') {
                      // ストレージが選択されているが、スペックに入力がない場合
                      if (
                        value[key].server_cpu === null ||
                        value[key].server_mem === null
                      ) {
                        value[key].message = t('ItemSettingInput.error3');
                        isPass = false;
                      } else if (value[key].server_unit === null) {
                        value[key].message = t('ItemSettingInput.error4');
                        isPass = false;
                      } else if (value[key].server_unit === 0) {
                        value[key].message = t('ItemSettingInput.error5');
                        isPass = false;
                      } else if (value[key].server_vol === null) {
                        value[key].message = t('ItemSettingInput.error6');
                        isPass = false;
                      } else if (value[key].server_vol < 50) {
                        value[key].message = t('ItemSettingInput.error7');
                        isPass = false;
                      } else if (value[key].server_vol > 10000) {
                        value[key].message = t('ItemSettingInput.error8');
                        isPass = false;
                      } else if (
                        isPlanChange &&
                        (state.prevData?.itemSetting?.[key]?.server_vol >
                          value[key].server_vol ||
                          state.prevData?.itemSetting?.[key]?.server_unit >
                            value[key].server_unit)
                      ) {
                        isPass = false;
                        messagePlanChange = t('ItemSettingInput.error9');
                      }
                      if (
                        isPlanChange &&
                        !isDataChanged &&
                        !isEqual(state.prevData?.itemSetting?.[key], value[key])
                      ) {
                        isDataChanged = true;
                      }
                    } else if (
                      isPlanChange &&
                      !isDataChanged &&
                      !isEqual(state.prevData?.itemSetting?.[key], value[key])
                    ) {
                      isPass = false;
                      isDataChanged = true;
                      messagePlanChange = t('ItemSettingInput.error10');
                    }

                    if (key === 'data' && value[key]?.data_traffic > 10000) {
                      value[key].message = t('ItemSettingInput.error11');
                      isPass = false;
                    }
                  });

                  if (isPlanChange && !isDataChanged && !messagePlanChange) {
                    isPass = false;
                    messagePlanChange = t('ItemSettingInput.error12');
                  }
                }

                form.setFields([
                  {
                    name: 'itemSetting',
                    value
                  },
                  {
                    name: 'messagePlanChange',
                    value: messagePlanChange
                  }
                ]);
                forceUpdate();
                if (isPass) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error());
              }
            })
          ]}
        />
        <Collapse
          defaultActiveKey={['1']}
          bordered={false}
          expandIcon={({ isActive }) => (
            <CaretRightOutlined rotate={isActive ? 90 : 0} />
          )}
          className="site-collapse-custom-collapse mb-primary"
        >
          <Panel header={t('planNew.detailSetting')} key="1">
            <div className="list-row-server border-default ml-15">
              <table className="full-width">
                {Object.keys(form.getFieldValue('itemSetting') || {}).map(
                  (key) => (
                    <tr className="row-item">
                      <td md={{ span: 4 }} className="bg-primary col-label">
                        {t(`share.${key}Server`)}
                      </td>
                      <td md={{ span: 20 }}>
                        {key !== 'data' && (
                          <StyledDiv>
                            <div className={`tbcell padLeft insLeft_${key}`}>
                              {t('share.spec')}
                            </div>
                            <div className="tbcell">
                              <Form.Item style={{ marginLeft: '10px' }}>
                                <Select
                                  value={selectedSpec(key)}
                                  options={LIST_SPEC_SETTING.map(
                                    (item, idx) => ({
                                      value: idx,
                                      label: item[0].label
                                    })
                                  )}
                                  onChange={(value) => {
                                    const ret = LIST_SPEC_SETTING[value][0];
                                    form.setFields([
                                      {
                                        name: [
                                          'itemSetting',
                                          key,
                                          'server_type'
                                        ],
                                        value: formatServerType(
                                          ret.setting.cpu,
                                          ret.setting.mem
                                        )
                                      },
                                      {
                                        name: [
                                          'itemSetting',
                                          key,
                                          'server_cpu'
                                        ],
                                        value: ret.setting.cpu
                                      },
                                      {
                                        name: [
                                          'itemSetting',
                                          key,
                                          'server_mem'
                                        ],
                                        value: ret.setting.mem
                                      }
                                    ]);
                                    forceUpdate();
                                  }}
                                />
                              </Form.Item>
                            </div>
                            <div className="tbcell padLeft padRight">+</div>
                            <div className="tbcell">
                              <Form.Item
                                label={t('share.store')}
                                md={{ span: 10 }}
                                name={['itemSetting', key, 'server_vol']}
                                className="store"
                              >
                                <InputNumber
                                  controls={false}
                                  parser={numberParser}
                                />
                              </Form.Item>
                            </div>
                            <div className={`tbcell padRight insRight_${key}`}>
                              GB
                            </div>
                            {(key === 'ap' || key === 'etl') && (
                              <>
                                <div className="tbcell padLeft">x</div>
                                <div className="tbcell padLeft">
                                  <Form.Item
                                    label={t('share.numberOfServer')}
                                    md={{ span: 10 }}
                                    name={['itemSetting', key, 'server_unit']}
                                    className="number-server"
                                  >
                                    <InputNumber
                                      controls={false}
                                      parser={numberParser}
                                    />
                                  </Form.Item>
                                </div>
                                <div className="tbcell">台</div>
                              </>
                            )}
                            {key !== 'ap' && key !== 'etl' && (
                              <Form.Item
                                label={t('share.numberOfServer')}
                                md={{ span: 10 }}
                                name={['itemSetting', key, 'server_unit']}
                                className="number-server"
                                hidden
                              />
                            )}
                            <Form.Item
                              hidden
                              name={['itemSetting', key, 'server_type']}
                            />
                            <Form.Item
                              hidden
                              name={['itemSetting', key, 'server_cpu']}
                            />
                            <Form.Item
                              hidden
                              name={['itemSetting', key, 'server_mem']}
                            />
                          </StyledDiv>
                        )}
                        {key === 'data' && (
                          <StyledDiv>
                            <div className="tbcell padLeft">
                              {t('planNew.traffic')}
                            </div>
                            <div className="tbcell">
                              <Form.Item
                                name={['itemSetting', key, 'data_traffic']}
                                className="traffic"
                                style={{ marginLeft: '25px' }}
                              >
                                <InputNumber
                                  controls={false}
                                  parser={numberParser}
                                />
                              </Form.Item>
                            </div>
                            <div className="tbcell pl-5">GB</div>
                            <div className="tbcell padLeft">
                              {t('share.perMonth')}
                            </div>
                          </StyledDiv>
                        )}
                        <div className="ml-15 ant-form-item-explain-error">
                          {form.getFieldValue(['itemSetting', key, 'message'])}
                        </div>
                      </td>
                    </tr>
                  )
                )}
              </table>
            </div>

            <div className="ml-15" style={{ color: 'red' }}>
              {form.getFieldValue('messagePlanChange')}
            </div>
            <div className="ml-15">{t('planNew.detailSettingNote')}</div>
          </Panel>
        </Collapse>
      </div>
    </>
  );
}
export default ItemSettingInput;
