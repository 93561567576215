import React, { useEffect, useState } from 'react';
import '../i18n';
import { Space, Form, Row, Col, Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { LIST_SPEC_SETTING } from '../constant';
import { useLocation } from 'react-router-dom';
import isEqual from 'lodash/isEqual';
import axios from 'axios';
import { notification } from 'antd';
import { useConfirmDialog } from './UseConfirmDialog';
import { ReloadOutlined } from '@ant-design/icons';

function ConfigurationComponent(props) {
  const { state } = useLocation();
  const isCheckChanged = state?.prevData ?? false;

  const { ConfirmDialog, openConfirmDialog } = useConfirmDialog();

  const [config, setConfig] = useState({});
  const [insStatus, setInsStatus] = useState('-');
  useEffect(() => {
    if (props.type == 'planDetail') {
      setConfig({
        leftBracket: '',
        rightBracket: '',
        plus: '',
        multiply: '',
        perMonth: ''
      });
    } else {
      setConfig({
        leftBracket: '(',
        rightBracket: ')',
        plus: '+',
        multiply: 'x',
        perMonth: '（ひと月あたり）'
      });
    }
  }, []);

  const { t } = useTranslation();

  const getInstanceStatus = () => {
    axios
      .post('/api/getInstanceStatus', {
        planId: props?.planId,
        cloud: props?.cloud
      })
      .then((res) => {
        const data = res.data;
        if (data.status == 'RUNNING' || data.status == 'running') {
          setInsStatus('share.running');
        } else if (data.status == 'STOPPED' || data.status == 'stopped') {
          setInsStatus('share.stopped');
        } else if (data.status == 'STARTING' || data.status == 'pending') {
          setInsStatus('share.starting');
        } else if (data.status == 'STOPPING' || data.status == 'stopping') {
          setInsStatus('share.stopping');
        } else {
          setInsStatus('-');
        }
      });
  };

  const startStopRestartInstance = async (url) => {
    axios
      .post(url, {
        planId: props?.planId,
        cloud: props?.cloud
      })
      .then((res) => {
        notification['info']({
          message: '',
          description: t(res.data.message),
          placement: 'bottomRight',
          duration: 8
        });
      });
  };

  const startInstance = async () => {
    const result = await openConfirmDialog(t('startInstance.confirm'));
    if (result) {
      startStopRestartInstance('/api/startInstance');
    }
  };

  const stopInstance = async () => {
    const result = await openConfirmDialog(t('stopInstance.confirm'));
    if (result) {
      startStopRestartInstance('/api/stopInstance');
    }
  };

  const restartInstance = async () => {
    const result = await openConfirmDialog(t('restartInstance.confirm'));
    if (result) {
      startStopRestartInstance('/api/restartInstance');
    }
  };

  return (
    <>
      <ConfirmDialog />
      <div className="list-row-server border-default mb-primary">
        {Object.keys(props.itemSetting || {}).map((key) => (
          <>
            <Row className="row-item">
              <Col className="bg-primary col-label">
                {t(`share.${key}Server`)}
              </Col>
              <Col flex={1}>
                <Row>
                  <Col flex="65%">
                    {key !== 'data' && (
                      <Row>
                        <Col flex="20px" className="col-leftBracket">
                          {(key === 'ap' || key === 'etl') && (
                            <span>{config.leftBracket}</span>
                          )}
                        </Col>
                        <Col
                          flex="25%"
                          className={`td-spec ${
                            isCheckChanged &&
                            !isEqual(
                              {
                                cpu: state.prevData.itemSetting?.[key]
                                  ?.server_cpu,
                                mem: state.prevData.itemSetting?.[key]
                                  ?.server_mem
                              },
                              {
                                cpu: state.values.itemSetting[key].server_cpu,
                                mem: state.values.itemSetting[key].server_mem
                              }
                            )
                              ? 'content-changed'
                              : ''
                          }`}
                        >
                          <Form.Item
                            label={t('share.spec')}
                            name="webServerSpec"
                          >
                            {
                              LIST_SPEC_SETTING.find(
                                (item) =>
                                  item[0].setting.cpu ==
                                    props.itemSetting[key].server_cpu &&
                                  item[0].setting.mem ==
                                    props.itemSetting[key].server_mem
                              )?.[0].label
                            }
                          </Form.Item>
                        </Col>
                        <Col flex="5%">
                          <span>{config.plus}&nbsp;&nbsp;</span>
                        </Col>
                        <Col
                          flex="23%"
                          className={`td-store ${
                            isCheckChanged &&
                            !isEqual(
                              state.prevData.itemSetting?.[key]?.server_vol,
                              state.values.itemSetting[key].server_vol
                            )
                              ? 'content-changed'
                              : ''
                          }`}
                        >
                          <Space>
                            <Form.Item
                              label={t('share.store')}
                              name={['itemSetting', key, 'server_vol']}
                              className="store"
                            >
                              {props.itemSetting[key].server_vol}
                            </Form.Item>
                            <span>GB</span>
                          </Space>
                        </Col>
                        <Col flex="40%">
                          <Row>
                            {(key === 'ap' || key === 'etl') && (
                              <>
                                {/* <Col flex="90%">
                                <Row>
                                  <Col>サーバー台数 2 台</Col>
                                </Row>
                              </Col> */}
                                <Col flex="20px"> {config.rightBracket}</Col>
                                <Col flex="20px"> {config.multiply}</Col>

                                <Col flex="40%">
                                  <Form.Item
                                    label={t('share.numberOfServer')}
                                    name={['itemSetting', key, 'server_unit']}
                                    className="number-server"
                                  >
                                    {props.itemSetting[key].server_unit}
                                  </Form.Item>
                                </Col>
                                <Col flex="10px">{t('freeSimulation.set')}</Col>
                              </>
                            )}
                          </Row>
                        </Col>
                      </Row>
                    )}
                    {key === 'data' && (
                      <Row>
                        <Col flex="20px" className="col-leftBracket"></Col>
                        <Col>
                          <Space size={15}>
                            <Form.Item
                              label={t('planNew.traffic')}
                              name={['itemSetting', key, 'data_traffic']}
                              className={` ${
                                isCheckChanged &&
                                !isEqual(
                                  state.prevData.itemSetting?.[key]
                                    ?.data_traffic,
                                  state.values.itemSetting[key].data_traffic
                                )
                                  ? 'content-changed'
                                  : ''
                              }`}
                            >
                              {props.itemSetting[key].data_traffic}
                            </Form.Item>
                            <span>GB</span>
                            <span>{config.perMonth}</span>
                          </Space>
                        </Col>
                      </Row>
                    )}
                  </Col>
                  {props?.isInUse && props.type == 'planDetail' && (
                    <Col flex="35%">
                      {key !== 'data' && (
                        <Row>
                          <Col flex="55%">
                            <Row>
                              <Col flex="80%">
                                <Space>
                                  <Form.Item
                                    label={t('share.insStatus')}
                                    name={['itemSetting', key, 'server_vol']}
                                    className="server-status"
                                  >
                                    {t(insStatus)}
                                  </Form.Item>
                                </Space>
                              </Col>
                              <Col flex="20%">
                                <Button
                                  className="btn-action-oci btn-action-upd-status"
                                  type="primary"
                                  size="small"
                                  icon={<ReloadOutlined />}
                                  onClick={getInstanceStatus}
                                />
                              </Col>
                            </Row>
                          </Col>
                          <Col flex="45%">
                            <Row gutter={3}>
                              <Col flex="20%">
                                <Button
                                  className="btn-action-oci btn-action-oci-stop"
                                  type="primary"
                                  size="small"
                                  onClick={stopInstance}
                                >
                                  {t('stopInstance')}
                                </Button>
                              </Col>
                              <Col flex="20%">
                                <Button
                                  className="btn-action-oci btn-action-oci-start"
                                  type="primary"
                                  size="small"
                                  onClick={startInstance}
                                >
                                  {t('startInstance')}
                                </Button>
                              </Col>
                              <Col flex="20%">
                                <Button
                                  className="btn-action-oci btn-action-oci-restart"
                                  type="primary"
                                  size="small"
                                  onClick={restartInstance}
                                >
                                  {t('restartInstance')}
                                </Button>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      )}
                    </Col>
                  )}
                </Row>

                <Form.Item hidden name={['itemSetting', key, 'server_type']} />
                <Form.Item hidden name={['itemSetting', key, 'server_cpu']} />
                <Form.Item hidden name={['itemSetting', key, 'server_mem']} />
              </Col>
            </Row>
          </>
        ))}
      </div>
    </>
  );
}
export default ConfigurationComponent;
