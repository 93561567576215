import React, { useState } from 'react';
import '../i18n';
import { Row, Col } from 'antd';
import { useTranslation } from 'react-i18next';

function VpnsettingsConfigView(props) {
  const { t } = useTranslation();
  const [subInfo, setSubInfo] = useState({});

  return (
    <>
      <Col md={{ span: 4 }} className="title-sub color-primary">
        {t('Configuration')}
      </Col>
      <div className="border-default mb-primary">
        <Row className="border-bottom-default">
          <Col
            md={{ span: 6 }}
            className="bg-primary border-right-default pl-5"
          >
            {t('VPNSetting.UseVPN')}
          </Col>
          <Col md={{ span: 18 }} className="p-10">
            {props.data?.vpnInfo?.ope === 'DISABLE'
              ? t('VPNSetting.DisableVPN')
              : t('VPNSetting.EnableVPN')}
          </Col>
        </Row>
        <Row>
          <Col
            md={{ span: 6 }}
            className="bg-primary border-right-default pl-5"
          >
            {t('Securitysettings.VPNSettings')}
          </Col>
          <Col md={{ span: 18 }} className="p-10">
            <div className="box-primary mb-primary">
              <div className="bg-primary mb-primary">
                {t('Securitysettings.Server(VPN)Information')}
              </div>
              {!props.data?.vpnInfo && <div>{t('VPNSetting.NoSettings')}</div>}
              {props.data?.vpnInfo && (
                <table className="table-secondary border-default position-relative ml-15 mb-primary">
                  <tr>
                    <td
                      style={{ width: '30%' }}
                      className="col-title pl-5 border-right-primary"
                    >
                      {t('Securitysettings.VirtualIPAddress')}
                    </td>
                    <td className="col-input">
                      <Row className="mb-secondary">
                        <Col md={{ span: 12 }}>{props.data?.vpnInfo?.vip}</Col>
                        <Col md={{ span: 12 }}>
                          {props.data?.vpnInfo?.vipRemarks}
                        </Col>
                      </Row>
                    </td>
                  </tr>
                  <tr>
                    <td className="pl-5 border-right-primary">
                      {t('Securitysettings.UDPPortNumber')}
                    </td>
                    <td className="col-input">
                      <Row className="mb-secondary">
                        <Col md={{ span: 12 }}>
                          {props.data?.vpnInfo?.udpPort}
                        </Col>
                        <Col md={{ span: 12 }}>
                          {props.data?.vpnInfo?.udpPortRemarks}
                        </Col>
                      </Row>
                    </td>
                  </tr>
                </table>
              )}
            </div>
            <div className="box-primary mb-primary client-information">
              <div className="bg-primary mb-primary">
                <Row>
                  <Col span={22}>
                    {' '}
                    {t('Securitysettings.ClientInformation')}
                  </Col>
                  <Col span={2} className="text-center"></Col>
                </Row>
              </div>
              {!props.data?.lstVpnClInfo && (
                <div>{t('VPNSetting.NoSettings')}</div>
              )}
              {props.data?.lstVpnClInfo?.map((item, idx) => (
                <Row>
                  <Col span={22}>
                    <div className="mb-primary pl-15">
                      <table className="full-width border-default">
                        <tr className="bg-primary">
                          <td
                            style={{ width: '30%' }}
                            colspan="2"
                            className="pl-5"
                          >
                            {t('share.base')}
                          </td>
                        </tr>
                        <tr>
                          <td
                            style={{ width: '30%' }}
                            className="border-right-primary pl-5"
                          >
                            {t('Securitysettings.BaseName')}
                          </td>
                          <td className="p-10">{item.name}</td>
                        </tr>
                        <tr>
                          <td
                            style={{ width: '30%' }}
                            className="border-right-primary pl-5"
                          >
                            {t('Base.VPNConnectionVirtualIPAddress')}
                          </td>
                          <td className="p-10">
                            <Row className="mb-secondary">
                              <Col md={{ span: 12 }}>{item.vip}</Col>
                              <Col md={{ span: 12 }}>{item.vipRemarks}</Col>
                            </Row>
                          </td>
                        </tr>
                        <tr>
                          <td
                            style={{ width: '30%' }}
                            className="border-right-primary pl-5"
                          >
                            {t('Base.VPNConnectionUDPPortNumber')}
                          </td>
                          <td className="p-10">
                            <Row className="mb-secondary">
                              <Col md={{ span: 12 }}>{item.udpPort}</Col>
                              <Col md={{ span: 12 }}>{item.udpPortRemarks}</Col>
                            </Row>
                          </td>
                        </tr>
                        <tr>
                          <td
                            style={{ width: '30%' }}
                            className="border-right-primary pl-5"
                          >
                            {t('Base.MTU')}
                          </td>
                          <td className="p-10">
                            <Row className="mb-secondary">{item.mtu}</Row>
                          </td>
                        </tr>
                        <tr>
                          <td
                            style={{ width: '30%' }}
                            className="border-right-primary pl-5"
                          >
                            {t('Base.NetworkDeviceName')}
                          </td>
                          <td className="p-10">
                            <Row className="mb-secondary">
                              {item.deviceName}
                            </Row>
                          </td>
                        </tr>
                        <tr>
                          <td
                            style={{ width: '30%' }}
                            className="border-right-primary pl-5"
                          >
                            {t('Base.LANIPAddress/NumberOfBits')}
                          </td>
                          <td className="p-10">
                            <Row className="mb-secondary">
                              <Col md={{ span: 12 }}>{item.lan}</Col>
                              <Col md={{ span: 12 }}>{item.lanRemarks}</Col>
                            </Row>
                          </td>
                        </tr>
                        <tr>
                          <td
                            style={{ width: '30%' }}
                            className="border-right-primary pl-5"
                          >
                            {t('Base.KeepAliveSseconds')}
                          </td>
                          <td className="p-10">
                            <Row className="mb-secondary">{item.keepAlive}</Row>
                          </td>
                        </tr>
                      </table>
                      {props.referMode && (
                        <div
                          className="border-default"
                          style={{ borderTop: '0px' }}
                        >
                          <div
                            className="bg-primary"
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                              setSubInfo({
                                [idx]: !subInfo[idx]
                              });
                            }}
                          >
                            {subInfo[idx] ? <>&#9660;</> : <>&#9654;</>}
                            {t('VPNSetting.WireGuard')}
                          </div>
                          {subInfo[idx] && (
                            <div
                              className="p-10"
                              style={{ whiteSpace: 'break-spaces' }}
                            >
                              {item.wgConf}
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  </Col>
                  <Col span={2} className="text-center"></Col>
                </Row>
              ))}
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default VpnsettingsConfigView;
