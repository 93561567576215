import React from 'react';
import Header from '../components/Header';
import { Row, Col, Form, Button, Collapse, Checkbox } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { CaretRightOutlined } from '@ant-design/icons';
import { RECOMMENDATION, NEWS } from '../constant';

function ProfileChangeConfirm() {
  const { state } = useLocation();
  const navigate = useNavigate();
  const countryFlag = state.values['countryFlag'];
  const { t } = useTranslation();
  const { Panel } = Collapse;
  const accObject = state.values;
  const { prevProfile } = state;

  //会社住所が「日本」の場合、「日本」が選択された状態で変更不可にする。
  if (countryFlag === 1) {
    state.values['country'] = 'JP';
    //都道府県 + 市区町村 + 番地1 + 番地2
    state.values['address'] =
      state.values['prefectures'] +
      ' ' +
      state.values['municipalities'] +
      ' ' +
      state.values['address1'] +
      state.values['address2'];
  } else {
    //番地1 + 番地2（オプション）＋市＋地域/州
    state.values['address'] =
      state.values['address1'] +
      ' ' +
      state.values['address2'] +
      ' ' +
      state.values['city'] +
      ' ' +
      state.values['region'];
  }
  state.values['accountName'] =
    state.values['firstName'] + ' ' + state.values['lastName'];
  state.values['kana'] =
    state.values['kanaFirstName'] + ' ' + state.values['kanaLastName'];

  return (
    <div>
      <Header />
      <div className="box profile-change-confirm">
        <Form
          onFinish={() => {
            axios({
              method: 'post',
              url: '/api/updateAccount',
              data: state.values
            })
              .then(() => {
                //新規登録完了画面に遷移する。
                navigate('/profileChangeComplete');
              })
              .catch(() => {
                navigate('/invalidUrl');
              });
          }}
        >
          <div className="box-register box-register-confirm">
            <div className="box-title">{t('profileChangeConfirm.title')}</div>
            <div className="box-body">
              <div className="frame">
                <Form.Item>
                  <Row>
                    <Col md={{ span: 8 }} xs={{ span: 11 }}>
                      {t('share.surnameName')}：
                    </Col>
                    <Col
                      md={{ span: 16 }}
                      xs={{ span: 13 }}
                      className={`${
                        prevProfile.firstName !== state.values['firstName']
                          ? 'color-red'
                          : ''
                      }`}
                    >
                      {state.values['firstName']}
                    </Col>
                  </Row>
                </Form.Item>
                <Form.Item>
                  <Row>
                    <Col md={{ span: 8 }} xs={{ span: 11 }}>
                      {t('share.Name')}：
                    </Col>
                    <Col
                      md={{ span: 16 }}
                      xs={{ span: 13 }}
                      className={`${
                        prevProfile.lastName !== state.values['lastName']
                          ? 'color-red'
                          : ''
                      }`}
                    >
                      {state.values['lastName']}
                    </Col>
                  </Row>
                </Form.Item>
                <Form.Item>
                  <Row>
                    <Col md={{ span: 8 }} xs={{ span: 11 }}>
                      {t('share.kanaSurname')}
                    </Col>
                    <Col
                      md={{ span: 16 }}
                      xs={{ span: 13 }}
                      className={`${
                        prevProfile.kanaFirstName !==
                        state.values['kanaFirstName']
                          ? 'color-red'
                          : ''
                      }`}
                    >
                      {state.values['kanaFirstName']}
                    </Col>
                  </Row>
                </Form.Item>
                <Form.Item>
                  <Row>
                    <Col md={{ span: 8 }} xs={{ span: 11 }}>
                      {t('share.kanaName')}
                    </Col>
                    <Col
                      md={{ span: 16 }}
                      xs={{ span: 13 }}
                      className={`${
                        prevProfile.kanaLastName !==
                        state.values['kanaLastName']
                          ? 'color-red'
                          : ''
                      }`}
                    >
                      {state.values['kanaLastName']}
                    </Col>
                  </Row>
                </Form.Item>
                <Form.Item>
                  <Row>
                    <Col md={{ span: 8 }} xs={{ span: 11 }}>
                      {t('share.companyName')}：
                    </Col>
                    <Col
                      md={{ span: 16 }}
                      xs={{ span: 13 }}
                      className={`${
                        prevProfile.companyName !== state.values['companyName']
                          ? 'color-red'
                          : ''
                      }`}
                    >
                      {state.values['companyName']}
                    </Col>
                  </Row>
                </Form.Item>
                <Form.Item>
                  <Row>
                    <Col md={{ span: 8 }} xs={{ span: 11 }}>
                      {t('share.divisionName')}：
                    </Col>
                    <Col
                      md={{ span: 16 }}
                      xs={{ span: 13 }}
                      className={`${
                        prevProfile.divisionName !==
                        state.values['divisionName']
                          ? 'color-red'
                          : ''
                      }`}
                    >
                      {state.values['divisionName']}
                    </Col>
                  </Row>
                </Form.Item>

                {countryFlag === 1 && (
                  <>
                    <Form.Item>
                      <Row>
                        <Col md={{ span: 8 }} xs={{ span: 11 }}>
                          {t('share.country')}：
                        </Col>
                        <Col
                          md={{ span: 16 }}
                          xs={{ span: 13 }}
                          className={`${
                            prevProfile.countryObj?.countryName !==
                            state.values['countryObj']['countryName']
                              ? 'color-red'
                              : ''
                          }`}
                        >
                          {state.values['countryObj']['countryName']}
                        </Col>
                      </Row>
                    </Form.Item>
                    <Form.Item>
                      <Row>
                        <Col md={{ span: 8 }} xs={{ span: 11 }}>
                          {t('share.postalCode')}：
                        </Col>
                        <Col
                          md={{ span: 16 }}
                          xs={{ span: 13 }}
                          className={`${
                            prevProfile.postalCode !==
                            state.values['postalCode']
                              ? 'color-red'
                              : ''
                          }`}
                        >
                          {state.values['postalCode']}
                        </Col>
                      </Row>
                    </Form.Item>
                    <Form.Item>
                      <Row>
                        <Col md={{ span: 8 }} xs={{ span: 11 }}>
                          {t('share.prefectures')}：
                        </Col>
                        <Col
                          md={{ span: 16 }}
                          xs={{ span: 13 }}
                          className={`${
                            prevProfile.prefectures !==
                            state.values['prefectures']
                              ? 'color-red'
                              : ''
                          }`}
                        >
                          {state.values['prefectures']}
                        </Col>
                      </Row>
                    </Form.Item>
                    <Form.Item>
                      <Row>
                        <Col md={{ span: 8 }} xs={{ span: 11 }}>
                          {t('share.municipalities')}：
                        </Col>
                        <Col
                          md={{ span: 16 }}
                          xs={{ span: 13 }}
                          className={`${
                            prevProfile.municipalities !==
                            state.values['municipalities']
                              ? 'color-red'
                              : ''
                          }`}
                        >
                          {state.values['municipalities']}
                        </Col>
                      </Row>
                    </Form.Item>
                    <Form.Item>
                      <Row>
                        <Col md={{ span: 8 }} xs={{ span: 11 }}>
                          {t('share.address1')}：
                        </Col>
                        <Col
                          md={{ span: 16 }}
                          xs={{ span: 13 }}
                          className={`${
                            prevProfile.address1 !== state.values['address1']
                              ? 'color-red'
                              : ''
                          }`}
                        >
                          {state.values['address1']}
                        </Col>
                      </Row>
                    </Form.Item>
                    <Form.Item>
                      <Row>
                        <Col md={{ span: 8 }} xs={{ span: 11 }}>
                          {t('share.address2')}：
                        </Col>
                        <Col
                          md={{ span: 16 }}
                          xs={{ span: 13 }}
                          className={`${
                            prevProfile.address2 !== state.values['address2']
                              ? 'color-red'
                              : ''
                          }`}
                        >
                          {state.values['address2']}
                        </Col>
                      </Row>
                    </Form.Item>
                  </>
                )}

                {countryFlag === 2 && (
                  <>
                    <Form.Item>
                      <Row>
                        <Col md={{ span: 8 }} xs={{ span: 11 }}>
                          {t('share.address1')}：
                        </Col>
                        <Col
                          md={{ span: 16 }}
                          xs={{ span: 13 }}
                          className={`${
                            prevProfile.address1 !== state.values['address1']
                              ? 'color-red'
                              : ''
                          }`}
                        >
                          {state.values['address1']}
                        </Col>
                      </Row>
                    </Form.Item>
                    <Form.Item>
                      <Row>
                        <Col md={{ span: 8 }} xs={{ span: 11 }}>
                          {t('share.address2Option')}
                        </Col>
                        <Col
                          md={{ span: 16 }}
                          xs={{ span: 13 }}
                          className={`${
                            prevProfile.address2 !== state.values['address2']
                              ? 'color-red'
                              : ''
                          }`}
                        >
                          {state.values['address2']}
                        </Col>
                      </Row>
                    </Form.Item>
                    <Form.Item>
                      <Row>
                        <Col md={{ span: 8 }} xs={{ span: 11 }}>
                          {t('share.city')}：
                        </Col>
                        <Col
                          md={{ span: 16 }}
                          xs={{ span: 13 }}
                          className={`${
                            prevProfile.city !== state.values['city']
                              ? 'color-red'
                              : ''
                          }`}
                        >
                          {state.values['city']}
                        </Col>
                      </Row>
                    </Form.Item>
                    <Form.Item>
                      <Row>
                        <Col md={{ span: 8 }} xs={{ span: 11 }}>
                          {t('share.region')}：
                        </Col>
                        <Col
                          md={{ span: 16 }}
                          xs={{ span: 13 }}
                          className={`${
                            prevProfile.region !== state.values['region']
                              ? 'color-red'
                              : ''
                          }`}
                        >
                          {state.values['region']}
                        </Col>
                      </Row>
                    </Form.Item>
                    <Form.Item>
                      <Row>
                        <Col md={{ span: 8 }} xs={{ span: 11 }}>
                          {t('share.postalCode')}：
                        </Col>
                        <Col
                          md={{ span: 16 }}
                          xs={{ span: 11 }}
                          className={`${
                            prevProfile.postalCode !==
                            state.values['postalCode']
                              ? 'color-red'
                              : ''
                          }`}
                        >
                          {state.values['postalCode']}
                        </Col>
                      </Row>
                    </Form.Item>
                    <Form.Item>
                      <Row>
                        <Col md={{ span: 8 }} xs={{ span: 11 }}>
                          {t('share.country')}：
                        </Col>
                        <Col
                          md={{ span: 16 }}
                          xs={{ span: 13 }}
                          className={`${
                            prevProfile.countryObj?.countryName !==
                            state.values['countryObj']['countryName']
                              ? 'color-red'
                              : ''
                          }`}
                        >
                          {state.values['countryObj']['countryName']}
                        </Col>
                      </Row>
                    </Form.Item>
                  </>
                )}

                <Form.Item>
                  <Row>
                    <Col md={{ span: 8 }} xs={{ span: 11 }}>
                      {t('share.phoneNumber')}：
                    </Col>
                    <Col
                      md={{ span: 16 }}
                      xs={{ span: 13 }}
                      className={`${
                        prevProfile.phoneNumber !== state.values['phoneNumber']
                          ? 'color-red'
                          : ''
                      }`}
                    >
                      {state.values['phoneNumber']}
                    </Col>
                  </Row>
                </Form.Item>
                <div className="panel-setting mb-primary">
                  <Collapse
                    bordered={false}
                    defaultActiveKey={['1']}
                    expandIcon={({ isActive }) => (
                      <CaretRightOutlined rotate={isActive ? 90 : 0} />
                    )}
                    className="site-collapse-custom-collapse"
                  >
                    <Panel
                      header={t(
                        'profileChange.emailNewsletterDeliverySettings'
                      )}
                      key="1"
                      className="site-collapse-custom-panel"
                    >
                      <Checkbox.Group
                        style={{ width: '100%' }}
                        value={accObject.newsletterDeliverySettings}
                        disabled
                      >
                        <Col offset={2}>
                          {t(
                            'profileChange.stopUnstopDeliveryOfEmailNewsletter'
                          )}
                        </Col>
                        <Col offset={4}>
                          <Checkbox value={RECOMMENDATION}>
                            {t('profileChange.recommendation')}
                          </Checkbox>
                        </Col>
                        <Col offset={4}>
                          <Checkbox value={NEWS}>
                            {t('profileChange.news')}
                          </Checkbox>
                        </Col>
                      </Checkbox.Group>
                    </Panel>
                  </Collapse>
                </div>
              </div>
            </div>
          </div>
          <Form.Item>
            <Row>
              <Col span={12}>
                <Button
                  className="btn-primary"
                  onClick={() => {
                    navigate('/profileChange', {
                      state: { ...state, confirm: true }
                    });
                  }}
                >
                  {t('share.correctInput')}
                </Button>
              </Col>
              <Col span={12} className="text-right">
                {' '}
                <button
                  type="submit"
                  style={{ margin: '0 8px' }}
                  className="btn-primary cursor-pointer"
                >
                  {t('profileChangeConfirm.change')}
                </button>
              </Col>
            </Row>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
}
export default ProfileChangeConfirm;
