import React, { useEffect, useState } from 'react';
import {
  Breadcrumb,
  Button,
  Col,
  Form,
  Input,
  InputNumber,
  Radio,
  Row,
  Space
} from 'antd';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Header from '../components/Header';
import { useAuth } from '../AuthContext';
import { useTranslation } from 'react-i18next';
import logoGeminiot from '../assets/images/logo-geminiot.png';
import logoPasterios from '../assets/images/logo-pasterios.png';
import FooterComponent from '../components/FooterComponent';
import { useForceUpdate } from '../utils/utils';
import { MaskedInput } from 'antd-mask-input';
import axios from 'axios';
import { v4 as v4Re } from 'cidr-regex';
import { isIPv4 } from 'is-ip';

function VPNSettingsInput() {
  const { t } = useTranslation();
  const [data, setData] = useState();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { state } = useLocation();
  const [disableForm, setDisableForm] = useState(false);
  const forceUpdate = useForceUpdate();
  const [isNotAllowStop, setNotAllowStop] = useState(false);

  useEffect(() => {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const planId = params.get('planId') || state?.values?.planId;

    if (!planId) {
      navigate('/');
      return;
    }

    if (state?.values?.planId) {
      setData(state.values);
      setDisableForm(state.values.useVpn === 'DISABLE');
      form.setFieldsValue(state.values);
      forceUpdate();
    } else {
      axios
        .get('/api/getVpnSetting', {
          params: {
            planId
          }
        })
        .then((res) => {
          setData(res.data);
          setDisableForm(res.data.vpnInfo?.ope === 'DISABLE');
          form.setFieldsValue({
            useVpn: res.data.vpnInfo?.ope !== 'DISABLE' ? 'ENABLE' : 'DISABLE',
            vpnInfo: { ...res.data.vpnInfo },
            lstVpnClInfo: [...(res.data.lstVpnClInfo ?? [])]
          });
          forceUpdate();
        });
    }
  }, []);

  const compareObject = (obj1, obj2) => {
    let isEqual = true;
    if (Array.isArray(obj1)) {
      if (obj1.length != obj2.length) {
        return false;
      }
      for (let i = 0; i < obj1.length; i++) {
        const keys = Object.keys(obj2[i]);
        keys.forEach((key) => {
          if (String(obj1[i][key]) !== String(obj2[i][key])) {
            isEqual = false;
            return;
          }
        });
      }
    } else {
      const keys = Object.keys(obj2);
      keys.forEach((key) => {
        if (String(obj1[key]) !== String(obj2[key])) {
          isEqual = false;
          return;
        }
      });
    }
    return isEqual;
  };

  return (
    <>
      <Header />
      <div className="white-space"></div>
      <div className="container-custom-primary">
        <Row className="title mb-primary">
          <Col md={{ span: 6 }} className="page-title">
            {t('VPNSetting.Title')}
          </Col>
          <Col md={{ span: 18 }} className="text-right">
            <Breadcrumb separator=">">
              <Breadcrumb.Item className="bold current">
                {t('share.input')}
              </Breadcrumb.Item>
              <Breadcrumb.Item>{t('share.confirm')}</Breadcrumb.Item>
              <Breadcrumb.Item>{t('share.completion')}</Breadcrumb.Item>
            </Breadcrumb>
          </Col>
        </Row>
        <Col md={{ span: 4 }} className="title-sub color-primary">
          {t('share.basicInformation')}
        </Col>
        <div className="border-default mb-primary">
          <Row className="border-bottom-default">
            <Col
              md={{ span: 6 }}
              className="bg-primary border-right-default pl-5"
            >
              {t('share.planId')}
            </Col>
            <Col md={{ span: 18 }} className="pl-5">
              {data?.planId}
            </Col>
          </Row>
          <Row>
            <Col
              md={{ span: 6 }}
              className="bg-primary border-right-default pl-5"
            >
              {t('plan.component')}
            </Col>
            <Col md={{ span: 18 }} className="pl-5">
              <img
                src={data?.component === 1 ? logoGeminiot : logoPasterios}
                className="img-logo pt-5 pb-5"
              />
            </Col>
          </Row>
        </div>
        <Form
          className="config-form"
          name="frmSecuritysettings"
          form={form}
          autoComplete="off"
          onFinish={(values) => {
            if (values.useVpn === 'DISABLE') {
              if (
                !compareObject(data.vpnInfo, values.vpnInfo) ||
                !compareObject(data.lstVpnClInfo, values.lstVpnClInfo)
              ) {
                setNotAllowStop(true);
                return;
              }
            }

            navigate('/vpnSettingsConfirm', {
              state: {
                ...state,
                values: {
                  planId: data.planId,
                  component: data.component,
                  ...values,
                  vpnInfo: {
                    ...values.vpnInfo,
                    ope: values.useVpn
                  }
                }
              }
            });
          }}
        >
          <Col md={{ span: 4 }} className="title-sub color-primary">
            {t('Configuration')}
          </Col>
          <div className="border-default mb-primary">
            <Row className="border-bottom-default">
              <Col
                md={{ span: 6 }}
                className="bg-primary border-right-default pl-5"
              >
                {t('VPNSetting.UseVPN')}
              </Col>
              <Col md={{ span: 18 }} className="p-10">
                <Form.Item
                  name="useVpn"
                  className="mb-0"
                  initialValue={t('Securitysettings.NoSetting')}
                >
                  <Radio.Group
                    onChange={(elm) => {
                      form
                        .validateFields()
                        .then(() => {
                          const value = elm.target.value;
                          setNotAllowStop(false);
                          setDisableForm(value === 'DISABLE');
                        })
                        .catch(() => {
                          form.setFieldsValue({
                            useVpn: 'ENABLE'
                          });
                        });
                    }}
                  >
                    <Space direction="vertical" size={1}>
                      <Radio value="ENABLE">{t('VPNSetting.EnableVPN')}</Radio>
                      <Radio value="DISABLE" disabled={data?.newSetting}>
                        {t('VPNSetting.DisableVPN')}
                      </Radio>
                    </Space>
                  </Radio.Group>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col
                md={{ span: 6 }}
                className="bg-primary border-right-default pl-5"
              >
                {t('Securitysettings.VPNSettings')}
              </Col>
              <Col md={{ span: 18 }} className="p-10">
                <div className="box-primary mb-primary">
                  <div className="bg-primary mb-primary">
                    {t('Securitysettings.Server(VPN)Information')}
                  </div>
                  {form.getFieldValue('vpnInfo') && (
                    <table className="table-secondary border-default position-relative ml-15 mb-primary">
                      <tr>
                        <td className="col-title pl-5 border-right-primary">
                          {t('Securitysettings.VirtualIPAddress')}
                        </td>
                        <td className="col-input">
                          <Row className="mb-secondary">
                            <Form.Item
                              className="mb-0"
                              name={['vpnInfo', 'vip']}
                              rules={[
                                { required: true, message: '' },
                                {
                                  validator: async (_, value) => {
                                    if (value && !isIPv4(value)) {
                                      return Promise.reject(new Error(t('')));
                                    }
                                  }
                                }
                                // {
                                //   validator: async (_, value) => {
                                //     if (
                                //       form.getFieldValue([
                                //         'vpnInfo',
                                //         'vip'
                                //       ]) === value
                                //     ) {
                                //       return Promise.reject(
                                //         <div className="show-message">
                                //           サーバーとクライアント間で同じ仮想IPアドレスを指定することはできません。
                                //         </div>
                                //       );
                                //     }
                                //     return Promise.resolve();
                                //   }
                                // }
                              ]}
                            >
                              <MaskedInput
                                className="input-mask"
                                disabled={disableForm}
                                mask={'0[0][0].0[0][0].0[0][0].0[0]'}
                              />
                            </Form.Item>
                            <Form.Item
                              className="mb-0 ml-5"
                              name={['vpnInfo', 'vipRemarks']}
                            >
                              <Input
                                disabled={disableForm}
                                placeholder={t(
                                  'VPNSetting.DescriptionOptional'
                                )}
                              />
                            </Form.Item>
                          </Row>
                        </td>
                      </tr>
                      <tr>
                        <td className="pl-5 border-right-primary">
                          {t('Securitysettings.UDPPortNumber')}
                        </td>
                        <td className="col-input">
                          <Row className="mb-secondary">
                            <Form.Item
                              className="mb-0"
                              name={['vpnInfo', 'udpPort']}
                              rules={[{ required: true, message: '' }]}
                            >
                              <InputNumber
                                min={0}
                                max={65535}
                                className="number-input"
                                style={{ width: '100%' }}
                                disabled={disableForm}
                                placeholder="51820"
                              />
                            </Form.Item>
                            <Form.Item
                              className="mb-0 ml-5"
                              name={['vpnInfo', 'udpPortRemarks']}
                            >
                              <Input
                                disabled={disableForm}
                                placeholder={t(
                                  'VPNSetting.DescriptionOptional'
                                )}
                              />
                            </Form.Item>
                          </Row>
                        </td>
                      </tr>
                    </table>
                  )}
                </div>
                <div className="box-primary mb-primary client-information">
                  <div className="bg-primary mb-primary">
                    <Row>
                      <Col span={22}>
                        {' '}
                        {t('Securitysettings.ClientInformation')}
                        <Form.Item
                          hidden
                          name={'customCheckClInfo'}
                          rules={[
                            {
                              validator: async () => {
                                if (
                                  form.getFieldValue('lstVpnClInfo').length <= 0
                                ) {
                                  return Promise.reject(new Error(t('')));
                                }
                              }
                            }
                          ]}
                        ></Form.Item>
                      </Col>
                      <Col span={2} className="text-center">
                        <Button
                          disabled={disableForm}
                          className="bg-info btn-info border-default"
                          onClick={() => {
                            const lst =
                              form.getFieldValue('lstVpnClInfo') || [];
                            form.setFieldsValue({
                              lstVpnClInfo: [...lst, { type: 1 }]
                            });
                            forceUpdate();
                          }}
                        >
                          {t('Securitysettings.add')}
                        </Button>
                      </Col>
                    </Row>
                  </div>
                  {form.getFieldValue('lstVpnClInfo')?.length <= 0 && (
                    <div className="ant-form-item-explain-error">
                      {t('VPNSetting.CLInfoRequired')}
                    </div>
                  )}
                  {form.getFieldValue('lstVpnClInfo')?.map((item, idx) => (
                    <Row>
                      <Col span={22}>
                        <div className="mb-primary pl-15">
                          <table className="full-width border-default">
                            <tr className="bg-primary">
                              <td colspan="2" className="pl-5">
                                {t('share.base')}
                              </td>
                            </tr>
                            <tr>
                              <td className="border-right-primary pl-5">
                                {t('Securitysettings.BaseName')}
                              </td>
                              <td className="p-10">
                                <Form.Item
                                  hidden
                                  name={['lstVpnClInfo', idx, 'dispSeq']}
                                />
                                <Form.Item
                                  name={['lstVpnClInfo', idx, 'name']}
                                  className="mb-secondary"
                                  rules={[{ required: true, message: '' }]}
                                >
                                  <Input
                                    disabled={disableForm}
                                    placeholder={t('Securitysettings.factory')}
                                  />
                                </Form.Item>
                              </td>
                            </tr>
                            <tr>
                              <td className="border-right-primary pl-5">
                                {t('Base.VPNConnectionVirtualIPAddress')}
                              </td>
                              <td className="p-10">
                                <Row className="mb-secondary">
                                  <Form.Item
                                    className="mb-0"
                                    name={['lstVpnClInfo', idx, 'vip']}
                                    rules={[
                                      { required: true, message: '' },
                                      {
                                        validator: async (_, value) => {
                                          if (value && !isIPv4(value)) {
                                            return Promise.reject(
                                              new Error(t(''))
                                            );
                                          }
                                          return Promise.resolve();
                                        }
                                      },
                                      {
                                        validator: async (_, value) => {
                                          if (
                                            form.getFieldValue([
                                              'vpnInfo',
                                              'vip'
                                            ]) === value
                                          ) {
                                            return Promise.reject(
                                              <div className="show-message">
                                                {t('VPNSetting.Check1')}
                                              </div>
                                            );
                                          }
                                          return Promise.resolve();
                                        }
                                      },
                                      {
                                        validator: async (_, value) => {
                                          if (
                                            form.getFieldValue([
                                              'vpnInfo',
                                              'vip'
                                            ]) !== value &&
                                            form
                                              .getFieldValue('lstVpnClInfo')
                                              ?.filter(
                                                (item) => item.vip === value
                                              ).length > 1
                                          ) {
                                            return Promise.reject(
                                              <div className="show-message">
                                                {t('VPNSetting.Check2')}
                                              </div>
                                            );
                                          }
                                          return Promise.resolve();
                                        }
                                      }
                                    ]}
                                  >
                                    <MaskedInput
                                      className="input-mask"
                                      disabled={disableForm}
                                      mask={'0[0][0].0[0][0].0[0][0].0[0][0]'}
                                      placeholder="10.173.2.10"
                                    />
                                  </Form.Item>
                                  <Form.Item
                                    className="mb-0 ml-5"
                                    name={['lstVpnClInfo', idx, 'vipRemarks']}
                                  >
                                    <Input
                                      disabled={disableForm}
                                      placeholder={t(
                                        'VPNSetting.DescriptionOptional'
                                      )}
                                    />
                                  </Form.Item>
                                </Row>
                              </td>
                            </tr>
                            <tr>
                              <td className="border-right-primary pl-5">
                                {t('Base.VPNConnectionUDPPortNumber')}
                              </td>
                              <td className="p-10">
                                <Row className="mb-secondary">
                                  <Form.Item
                                    className="mb-0"
                                    name={['lstVpnClInfo', idx, 'udpPort']}
                                    rules={[{ required: true, message: '' }]}
                                  >
                                    <InputNumber
                                      min={0}
                                      max={65535}
                                      className="number-input"
                                      style={{ width: '100%' }}
                                      disabled={disableForm}
                                      placeholder="51820"
                                    />
                                  </Form.Item>
                                  <Form.Item
                                    className="mb-0 ml-5"
                                    name={[
                                      'lstVpnClInfo',
                                      idx,
                                      'udpPortRemarks'
                                    ]}
                                  >
                                    <Input
                                      disabled={disableForm}
                                      placeholder={t(
                                        'VPNSetting.DescriptionOptional'
                                      )}
                                    />
                                  </Form.Item>
                                </Row>
                              </td>
                            </tr>
                            <tr>
                              <td className="border-right-primary pl-5">
                                {t('Base.MTU')}
                              </td>
                              <td className="p-10">
                                <Row className="mb-secondary">
                                  <Form.Item
                                    className="mb-0"
                                    name={['lstVpnClInfo', idx, 'mtu']}
                                    rules={[{ required: true, message: '' }]}
                                  >
                                    <InputNumber
                                      className="number-input"
                                      style={{ width: '100%' }}
                                      disabled={disableForm}
                                      placeholder="1380"
                                    />
                                  </Form.Item>
                                </Row>
                              </td>
                            </tr>
                            <tr>
                              <td className="border-right-primary pl-5">
                                {t('Base.NetworkDeviceName')}
                              </td>
                              <td className="p-10">
                                <Row className="mb-secondary">
                                  <Form.Item
                                    className="mb-0"
                                    name={['lstVpnClInfo', idx, 'deviceName']}
                                    rules={[{ required: true, message: '' }]}
                                  >
                                    <Input
                                      disabled={disableForm}
                                      placeholder="eth0"
                                    />
                                  </Form.Item>
                                </Row>
                              </td>
                            </tr>
                            <tr>
                              <td className="border-right-primary pl-5">
                                {t('Base.LANIPAddress/NumberOfBits')}
                              </td>
                              <td className="p-10">
                                <Row className="mb-secondary">
                                  <Form.Item
                                    className="mb-0"
                                    name={['lstVpnClInfo', idx, 'lan']}
                                    rules={[
                                      { required: true, message: '' },
                                      {
                                        validator: async (_, value) => {
                                          const re4 = v4Re({ exact: true });

                                          if (value && !re4.test(value)) {
                                            return Promise.reject(
                                              new Error(t(''))
                                            );
                                          }
                                        }
                                      },
                                      {
                                        validator: async (_, value) => {
                                          if (
                                            form
                                              .getFieldValue('lstVpnClInfo')
                                              ?.filter(
                                                (item) =>
                                                  item.lan.split('/')[0] ===
                                                  value.split('/')[0]
                                              ).length > 1
                                          ) {
                                            return Promise.reject(
                                              <div className="show-message">
                                                {t('VPNSetting.Check3')}
                                              </div>
                                            );
                                          }
                                          return Promise.resolve();
                                        }
                                      }
                                    ]}
                                  >
                                    <MaskedInput
                                      className="input-mask"
                                      disabled={disableForm}
                                      mask={
                                        '0[0][0].0[0][0].0[0][0].0[0][0]/0[0]'
                                      }
                                      placeholder="192.168.1.0/24"
                                    />
                                  </Form.Item>
                                  <Form.Item
                                    className="mb-0 ml-5"
                                    name={['lstVpnClInfo', idx, 'lanRemarks']}
                                  >
                                    <Input
                                      disabled={disableForm}
                                      placeholder={t(
                                        'VPNSetting.DescriptionOptional'
                                      )}
                                    />
                                  </Form.Item>
                                </Row>
                              </td>
                            </tr>
                            <tr>
                              <td className="border-right-primary pl-5">
                                {t('Base.KeepAliveSseconds')}
                              </td>
                              <td className="p-10">
                                <Row className="mb-secondary">
                                  <Form.Item
                                    className="mb-0"
                                    name={['lstVpnClInfo', idx, 'keepAlive']}
                                    rules={[{ required: true, message: '' }]}
                                  >
                                    <InputNumber
                                      className="number-input"
                                      style={{ width: '100%' }}
                                      min={1}
                                      disabled={disableForm}
                                      placeholder="15"
                                    />
                                  </Form.Item>
                                </Row>
                              </td>
                            </tr>
                          </table>
                        </div>
                      </Col>
                      <Col span={2} className="text-center">
                        <Button
                          disabled={disableForm}
                          className="bg-info btn-info border-default"
                          onClick={() => {
                            const lst =
                              form.getFieldValue('lstVpnClInfo') || [];
                            lst.splice(idx, 1);
                            form.setFieldsValue({ lstVpnClInfo: [...lst] });
                            forceUpdate();
                          }}
                        >
                          {t('planNew.delete')}
                        </Button>
                      </Col>
                    </Row>
                  ))}
                  {isNotAllowStop && (
                    <div className="ant-form-item-explain-error">
                      {t('VPNSetting.NotAllowStop')}
                    </div>
                  )}
                </div>
              </Col>
            </Row>
          </div>
          <div class="line-primary"></div>
          <div className="text-center bg-dancer mb-primary p-5">
            <button type="submit" className="btn-submit cursor-pointer">
              {t('share.confirm')}
            </button>
          </div>
          <Button
            className="bg-info btn-info border-default"
            onClick={() => {
              navigate('/', { replace: true });
            }}
          >
            {t('share.return')}
          </Button>
        </Form>
      </div>
      <FooterComponent />
    </>
  );
}

export default VPNSettingsInput;
