import React, { useEffect, useState } from 'react';
import '../i18n';
import Header from '../components/Header';
import { useTranslation } from 'react-i18next';
import { Row, Col, Typography, Button } from 'antd';
import logoGeminiot from '../assets/images/logo-geminiot.png';
import logoPasterios from '../assets/images/logo-pasterios.png';
import UserTimeComponent from '../components/UserTimeComponent';
import ServerListComponent from '../components/ServerListComponent';
import PriceComponent from '../components/PriceComponent';
import { useLocation } from 'react-router-dom';
import moment from 'moment';
import { DATE_FORMAT, USE_NOTUSE } from '../constant';
import axios from 'axios';
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import $ from 'jquery';
import { useNavigate } from 'react-router-dom';

function QuoteMake() {
  const { Title } = Typography;
  const { t } = useTranslation();
  const { state } = useLocation();
  const [priceSimultion, setPriceSimultion] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    getSimulatorPrice();
  }, []);

  const getSimulatorPrice = () => {
    const url = '/api/getPriceSimultion';
    axios
      .post(url, {
        ...state.values
      })
      .then((res) => {
        setPriceSimultion(res.data);
      });
  };

  const handleDownloadPdf = async () => {
    const canvas = await html2canvas($('.area-outputpdf')[0], {
      ignoreElements: (e) => {
        if ($(e).hasClass('ignore-pdf')) {
          return true;
        }
      },
      scale: 4
    });

    const data = canvas.toDataURL('image/png');

    const pdf = new jsPDF({
      format: 'a4',
      unit: 'px'
    });

    pdf.addImage(
      data,
      'PNG',
      10,
      10,
      pdf.internal.pageSize.getWidth() - 20,
      pdf.internal.pageSize.getHeight() - 20,
      '',
      'FAST'
    );
    pdf.save('御見積書.pdf');
  };

  return (
    <>
      <Header />
      <div className="white-space"></div>
      <div className="area-outputpdf container-custom-secondary quocte-make">
        <div className="text-right ignore-pdf">
          <Button className="btn-submit" onClick={handleDownloadPdf}>
            PDF
          </Button>
          <br />

          <Button
            style={{ marginTop: '5px' }}
            onClick={() => {
              navigate(
                `/planDetail?planId=${state.values.planId}&planBranchNum=${state.values.planBranchNum}`,
                {
                  replace: true
                }
              );
            }}
            className="bg-info btn-info border-default"
          >
            {t('share.return')}
          </Button>
        </div>
        <div className="text-center bold report-title">{t('quote.title')}</div>
        <div className="line-primary mt-10"></div>
        <Row className="mb-primary">
          <Col md={{ span: 16 }}>
            <h2 className="text-underline">
              <span>{state?.values?.companyName}</span>
              <span>御中</span>
            </h2>
          </Col>
          <Col md={{ span: 8 }}>
            <div className="mb-primary pl-15">
              <Row>
                <Col md={{ span: 8 }}>
                  <span>{t('quote.quoteNumber')}</span>
                </Col>
                <Col>
                  {state.values?.planId}-{state.values?.planBranchNum}
                </Col>
              </Row>
              <Row>
                <Col md={{ span: 8 }}>
                  <span>{t('quote.estimatedDate')}</span>
                </Col>
                <Col>
                  <span>{moment().format(DATE_FORMAT)}</span>
                </Col>
              </Row>
            </div>
            <div className="mb-primary">
              <div>{state.values?.agency?.companyName}</div>
              <div>{state.values?.agency?.divisionName}</div>
            </div>
            <div className="mb-primary">
              <div>〒{state.values?.agency?.postalCode}</div>
              <div>{state.values?.agency?.address}</div>
              <div>(TEL) {state.values?.agency?.phoneNumber}</div>
            </div>
          </Col>
        </Row>
        <Col md={{ span: 17 }}>
          <Row className="border-default mb-primary">
            <Col
              md={{ span: 5 }}
              className="bg-primary vertical-align-center border-right-default pl-5"
            >
              {t('share.product')}
            </Col>
            <Col md={{ span: 8 }} className="border-right-default pl-5">
              <img
                src={
                  state.values.component === 1 ? logoGeminiot : logoPasterios
                }
                className="img-logo"
              />
            </Col>
            <Col
              md={{ span: 5 }}
              className="bg-primary vertical-align-center border-right-default pl-5"
            >
              {t('freeSimulation.simulationStartDate')}
            </Col>
            <Col md={{ span: 6 }} className="align-center">
              {state.values.startDate}
            </Col>
          </Row>
        </Col>

        <div className="border-default box-simulation">
          <Title level={5}>{t('freeSimulation.annualUsageFee')}</Title>
          <PriceComponent type="freeSimulation" simulation={priceSimultion} />
          <div className="line-default"></div>
          <div className="title-secondary mb-secondary ml-15">
            {t('share.basicComposition')}
          </div>
          <ServerListComponent
            type="quoteMake"
            itemSetting={state.values?.itemSetting}
          />
          <div className="option">
            <div className="title-secondary mb-secondary ml-15">
              {t('share.option')}
            </div>
            {state.values.useBackup === USE_NOTUSE.Use && (
              <Row className="row-option">
                <Col md={{ span: 6 }}>{t('share.dataBackup')}</Col>
                <Col md={{ span: 6 }}>{state.values.backupGeneration}世代</Col>
              </Row>
            )}
          </div>
          <div className="user-time">
            <div className="title-secondary mb-primary ml-15">
              {t('freeSimulation.useTime')}
            </div>
            <UserTimeComponent
              type="quoteMake"
              systemUtilizationTimes={state.values?.systemUtilizationTimes}
            />
          </div>
        </div>
        <div>{t('freeSimulation.note1')}</div>
        <div>{t('freeSimulation.note2')}</div>
        <div className="line-primary mb-primary"></div>
      </div>
    </>
  );
}

export default QuoteMake;
