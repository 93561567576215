import React, { useState } from 'react';
import { Modal } from 'antd';

const _ConfirmDialog = ({ open, onClose, message }) => (
  <Modal
    visible={open}
    onOk={() => onClose(true)}
    onCancel={() => onClose(false)}
  >
    <p>{message}</p>
  </Modal>
);

export const useConfirmDialog = () => {
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [resolve, setResolve] = useState();

  const openConfirmDialog = (_message) => {
    setMessage(_message);
    setOpen(true);
    return new Promise((resolve) => {
      setResolve(() => resolve);
    });
  };

  const onClose = (result) => {
    setOpen(false);
    if (resolve) {
      resolve(result);
    }
  };

  const ConfirmDialog = () => (
    <_ConfirmDialog open={open} onClose={onClose} message={message} />
  );

  return {
    ConfirmDialog,
    openConfirmDialog
  };
};
