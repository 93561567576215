import React, { useEffect, useState } from 'react';
import {
  Breadcrumb,
  Button,
  Col,
  Form,
  Input,
  Modal,
  Radio,
  Row,
  Space
} from 'antd';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Header from '../components/Header';
import { useTranslation } from 'react-i18next';
import logoGeminiot from '../assets/images/logo-geminiot.png';
import logoPasterios from '../assets/images/logo-pasterios.png';
import FooterComponent from '../components/FooterComponent';
import { useForceUpdate } from '../utils/utils';
import axios from 'axios';
import VpnsettingsConfigView from '../components/VpnsettingsConfigView';

function VPNSettingsConfirm() {
  const { t } = useTranslation();
  const [data, setData] = useState();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { state } = useLocation();
  const forceUpdate = useForceUpdate();
  const [isModalVisible, setIsModalVisible] = useState(false);

  useEffect(() => {
    const planId = state?.values?.planId;
    if (!planId) {
      navigate('/');
      return;
    }

    if (state?.values) {
      setData({ ...state?.values });
      form.setFieldsValue(state.values);
      forceUpdate();
    }
  }, []);

  const saveVpnSetting = () => {
    if (state.values?.planId) {
      const url = '/api/updateVpnSetting';
      axios
        .post(url, {
          ...state.values
        })
        .then((res) => {
          if (res.data?.isError) {
            navigate('/vpnSettingsComplete', {
              state: { ...state, isError: true }
            });
            return;
          }
          navigate('/vpnSettingsComplete', { state });
        });
    } else {
      navigate('/', { replace: true });
    }
  };

  return (
    <>
      <Header />
      <div className="white-space"></div>
      <div className="container-custom-primary">
        <Row className="title mb-primary">
          <Col md={{ span: 6 }} className="page-title">
            {t('VPNSetting.Title')}/{t('share.confirm')}
          </Col>
          <Col md={{ span: 18 }} className="text-right">
            <Breadcrumb separator=">">
              <Breadcrumb.Item>{t('share.input')}</Breadcrumb.Item>
              <Breadcrumb.Item className="bold current">
                {t('share.confirm')}
              </Breadcrumb.Item>
              <Breadcrumb.Item>{t('share.completion')}</Breadcrumb.Item>
            </Breadcrumb>
          </Col>
        </Row>
        <Col md={{ span: 4 }} className="title-sub color-primary">
          {t('share.basicInformation')}
        </Col>
        <div className="border-default mb-primary">
          <Row className="border-bottom-default">
            <Col
              md={{ span: 6 }}
              className="bg-primary border-right-default pl-5"
            >
              {t('share.planId')}
            </Col>
            <Col md={{ span: 18 }} className="pl-5">
              {data?.planId}
            </Col>
          </Row>
          <Row>
            <Col
              md={{ span: 6 }}
              className="bg-primary border-right-default pl-5"
            >
              {t('plan.component')}
            </Col>
            <Col md={{ span: 18 }} className="pl-5">
              <img
                src={data?.component === 1 ? logoGeminiot : logoPasterios}
                className="img-logo pt-5 pb-5"
              />
            </Col>
          </Row>
        </div>
        <Form name="frmSecuritysettings" form={form} autoComplete="off">
          <VpnsettingsConfigView data={state?.values} />
          <div class="line-primary"></div>
          <div className="text-center bg-dancer mb-primary p-5">
            <Row>
              <Col md={{ span: 12 }} className="text-right">
                <button
                  type="submit"
                  onClick={() => {
                    setIsModalVisible(true);
                  }}
                  className="btn-submit cursor-pointer"
                >
                  {t('freeSimulation.save')}
                </button>
              </Col>
              <Col md={{ span: 12 }} className="text-right">
                <Button
                  onClick={() => {
                    navigate('/', { replace: true });
                  }}
                  className="btn-cancel"
                >
                  {t('share.cancel')}
                </Button>
              </Col>
            </Row>
          </div>
          <Button
            className="bg-info btn-info border-default"
            onClick={() => {
              navigate(`/vpnsettingsInput`, {
                state
              });
            }}
          >
            {t('share.return')}
          </Button>
        </Form>
      </div>
      <FooterComponent />
      <Modal
        visible={isModalVisible}
        onOk={() => {
          setIsModalVisible(false);
          saveVpnSetting();
        }}
        onCancel={() => {
          setIsModalVisible(false);
        }}
        closable={false}
        okText={t('share.ok')}
        cancelText={t('share.cancel')}
        className="modal-delete"
      >
        <p className="des" style={{ whiteSpace: 'break-spaces' }}>
          {t('VPNSetting.ConfirmSave')}
        </p>
      </Modal>
    </>
  );
}

export default VPNSettingsConfirm;
