import React from 'react';
import '../i18n';
import { Row, Input, Col, Form, Space, Button } from 'antd';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { useForceUpdate } from '../utils/utils';

function CampaignCodeInput(props) {
  const { t } = useTranslation();
  const forceUpdate = useForceUpdate();
  const { form } = props;

  const checkCampaignCode = async (
    useStartDate,
    projectCode,
    campaignCodes
  ) => {
    return await axios.post('/api/checkCampaignCode', {
      campaignCodes,
      projectCode,
      startDate: useStartDate,
      modeUse: props?.isModeUse
    });
  };

  const checkProjectCode = async (projectCode) => {
    return await axios.post('/api/checkProjectCode', {
      projectCode
    });
  };

  return (
    <div className="campaign">
      <Col className="title-sub color-primary mb-0">
        {t('share.campaignCode')}
      </Col>
      <div className="mb-primary ml-15"> {t('planNew.campaignCodeNote')}</div>
      <div className="campaign-body border-default ml-15">
        <Form.Item
          name="campaignCodes"
          hidden
          rules={[
            () => ({
              async validator(_, value) {
                const useStartDate = form.getFieldValue('startDate');
                const projectCode = form.getFieldValue('projectCode');
                const campaignCodesExists = form.getFieldValue(
                  'campaignCodesExists'
                );
                const customValidateMessage = {};
                let isError = false;

                // キャンペーンコード
                // キャンペーンコードで重複がある場合、『同一のキャンペーンコードが入力されています』
                if (
                  [...(value || []), ...(campaignCodesExists || [])].length > 1
                ) {
                  value?.forEach((item) => {
                    customValidateMessage[item] = {};
                    customValidateMessage['required'] = {};
                    if (!item || item === '') {
                      customValidateMessage['required'].message = t(
                        'CampaignCodeInput.error1'
                      );
                      isError = true;
                    } else if (
                      [...(value || []), ...(campaignCodesExists || [])].filter(
                        (i) => i !== '' && i === item
                      ).length > 1
                    ) {
                      customValidateMessage[item] = {};
                      customValidateMessage[item].message = t(
                        'CampaignCodeInput.error2'
                      );
                      isError = true;
                    }
                  });
                }

                if (props?.isCheckProjectCode) {
                  customValidateMessage.projectCodeMessage = undefined;
                  //「代理店案件情報」 に案件登録コードがない場合、『入力された案件登録コードは存在しません』
                  if (projectCode && projectCode !== '') {
                    const resultCheckProjectCode = await checkProjectCode(
                      projectCode
                    );
                    if (resultCheckProjectCode.data === '') {
                      customValidateMessage.projectCodeMessage = t(
                        'CampaignCodeInput.error3'
                      );
                      isError = true;
                    }
                  }
                }

                if ((value?.[0] || '') !== '' && !isError && useStartDate) {
                  // キャンペーンマスタにキャンペーンコードが存在しない場合、『入力されたキャンペーンコードは存在しません』
                  const result = await checkCampaignCode(
                    useStartDate,
                    projectCode,
                    value
                  );
                  result.data?.forEach((item) => {
                    customValidateMessage[item] = {};
                    customValidateMessage[item].message = t(
                      'CampaignCodeInput.error4'
                    );
                    isError = true;
                  });
                }

                form.setFields([
                  {
                    name: 'customValidateMessage',
                    value: customValidateMessage
                  }
                ]);
                props?.forceUpdate ? props?.forceUpdate() : forceUpdate();
                if (!isError) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error());
              }
            })
          ]}
        />
        <Row>
          <Col md={{ span: 4 }} className="bg-primary col-label">
            {t('share.campaignCode')}
          </Col>
          <Col md={{ span: 20 }} className="col-control">
            {form.getFieldValue('campaignCodesExists')?.map((item) => (
              <div className="mb-secondary">
                <Space align="start">
                  <Input
                    value={item}
                    disabled
                    className="txtCampaignCode"
                    placeholder={t('planNew.campaignCodePlaceholder')}
                  />
                </Space>
              </div>
            ))}
            <Space align="start">
              <Form.Item
                name={['campaignCodes', 0]}
                className="mb-secondary"
                initialValue=""
              >
                <Input
                  className="txtCampaignCode"
                  placeholder={t('planNew.campaignCodePlaceholder')}
                />
              </Form.Item>
              <button
                type="button"
                onClick={() => {
                  form.setFields([
                    {
                      name: 'campaignCodes',
                      value: [
                        ...((form.getFieldValue('campaignCodes')?.length ||
                          0) === 0
                          ? ['']
                          : form.getFieldValue('campaignCodes')),
                        ''
                      ]
                    }
                  ]);
                  forceUpdate();
                }}
                className="btn-secondary bg-info cursor-pointer"
              >
                {t('planNew.add')}
              </button>
            </Space>
            <div className="ant-form-item-explain-error">
              {
                form.getFieldValue('customValidateMessage')?.[
                  (form.getFieldValue(['campaignCodes', 0]) || '') === ''
                    ? 'required'
                    : form.getFieldValue(['campaignCodes', 0])
                ]?.message
              }
            </div>
            {form
              .getFieldValue('campaignCodes')
              ?.slice(1)
              ?.map((_, idx) => (
                <div className="mb-secondary">
                  <Space>
                    <Form.Item
                      name={['campaignCodes', idx + 1]}
                      className="mb-0"
                    >
                      <Input
                        className="txt-campain height20"
                        placeholder={t('planNew.campaignCodePlaceholder')}
                      />
                    </Form.Item>
                    <button
                      type="button"
                      onClick={() => {
                        form.getFieldValue('campaignCodes').splice(idx + 1, 1);
                        forceUpdate();
                      }}
                      className="btn-secondary bg-info cursor-pointer"
                    >
                      {t('planNew.delete')}
                    </button>
                  </Space>
                  <div className="ant-form-item-explain-error">
                    {
                      form.getFieldValue('customValidateMessage')?.[
                        (form.getFieldValue(['campaignCodes', idx + 1]) ||
                          '') === ''
                          ? 'required'
                          : form.getFieldValue(['campaignCodes', idx + 1])
                      ]?.message
                    }
                  </div>
                </div>
              ))}
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default CampaignCodeInput;
