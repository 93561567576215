import React from 'react';
import '../i18n';
import { Row, Col } from 'antd';
import { useTranslation } from 'react-i18next';
import { PROTOCOL } from '../constant';

function SecuritysettingsConfigView(props) {
  const { t } = useTranslation();

  return (
    <>
      <Col md={{ span: 4 }} className="title-sub color-primary">
        {t('Settings')}
      </Col>
      <div className="border-default mb-primary">
        <Row>
          <Col
            md={{ span: 6 }}
            className="bg-primary border-right-default pl-5"
          >
            {t('Securitysettings.IPAddressRestrictions')}
          </Col>
          <Col md={{ span: 18 }} className="p-10">
            <div className="bg-primary mb-primary">
              <Row>
                <Col span={21}> {t('Securitysettings.Accessible')}</Col>
                <Col span={3} className="text-center"></Col>
              </Row>
            </div>
            {(props?.data?.lstIpAddress?.length || 0) === 0 && (
              <div>{t('Securitysettings.NoSettings')}</div>
            )}

            {props?.data?.lstIpAddress?.map((item) => (
              <Row>
                <Col span={21}>
                  <div className="border-default p-10 mb-secondary">
                    <Row gutter={[8, 8]} className="mb-primary">
                      <Col span={7}>
                        {t('Securitysettings.SourceCIDR')}
                        <div className="border-default security-item">
                          {item.ipAddr}
                        </div>
                      </Col>
                      <Col span={7}>
                        {t('Securitysettings.IPProtocol')}
                        <div className="border-default security-item">
                          {PROTOCOL[item.protocol]}
                        </div>
                      </Col>
                      <Col span={7}></Col>
                    </Row>
                    <Row gutter={[8, 8]} className="mb-primary">
                      <Col span={6}>
                        {t('Securitysettings.destinationPortNumber')}
                        <div className="border-default security-item">
                          {item.minPort}
                        </div>
                      </Col>
                      <Col span={18}>
                        <Col span={21}>
                          <div>&nbsp;</div>
                          <Row>
                            <Col span={1} className="center">
                              ～
                            </Col>
                            <Col span={11} style={{ paddingLeft: '8px' }}>
                              <div className="border-default security-item">
                                {item.maxPort}
                              </div>
                            </Col>
                          </Row>
                        </Col>
                      </Col>
                    </Row>

                    <Row>
                      <Col span={21}>
                        {t('Securitysettings.explanation')}
                        <div className="border-default security-item">
                          {item.description}
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Col>
                <Col span={3} className="text-center"></Col>
              </Row>
            ))}
          </Col>
        </Row>
      </div>
    </>
  );
}

export default SecuritysettingsConfigView;
