import React, { useEffect, useState } from 'react';
import '../i18n';
import {
  Row,
  Col,
  Breadcrumb,
  Radio,
  Space,
  DatePicker,
  Form,
  Checkbox,
  Button
} from 'antd';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import FooterComponent from '../components/FooterComponent';
import Header from '../components/Header';
import FormItem from 'antd/lib/form/FormItem';
import { DATE_FORMAT } from '../constant';
import moment from 'moment';
import { useForceUpdate } from '../utils/utils';
import axios from 'axios';

function ChangeApplicationSettingStartDate() {
  const { t, i18n } = useTranslation();
  const [form] = Form.useForm();
  const { state } = useLocation();
  const navigate = useNavigate();
  const forceUpdate = useForceUpdate();
  const [termsOfService, setTermsOfService] = useState();
  const [showAlert, setShowAlert] = useState(false);

  useEffect(() => {
    if (!state?.values?.planId) {
      navigate('/', { replace: true });
      return;
    }

    form.setFieldsValue(state.values);
    forceUpdate();
  }, []);

  useEffect(() => {
    if (!i18n.language) return;
    getTermsOfService(i18n.language);
  }, [i18n.language]);

  const getTermsOfService = (language) => {
    axios({
      method: 'get',
      url: '/api/getTermsOfService',
      params: {
        language: language
      }
    }).then((res) => {
      setTermsOfService(res.data);
    });
  };
  return (
    <div className="block-setting-start-date">
      <Header />
      <div className="white-space"></div>
      <div className="container-custom-primary">
        <Row className="title mb-primary">
          <Col md={{ span: 6 }} className="page-title">
            {t('share.changeApplication')}
            {' / '}
            {t('paymentInformationRegistration.useStartDateSetting')}
          </Col>
          <Col md={{ span: 18 }} className="text-right">
            <Breadcrumb separator=">">
              <Breadcrumb.Item className="bold current">
                {t('paymentInformationRegistration.useStartDateSetting')}
              </Breadcrumb.Item>
              <Breadcrumb.Item>{t('share.confirm')}</Breadcrumb.Item>
              <Breadcrumb.Item>{t('share.completion')}</Breadcrumb.Item>
            </Breadcrumb>
          </Col>
        </Row>
        <div className="mb-primary">
          <div>{t('changeApplicationSettingStartDate.note1')}</div>
          <div>{t('changeApplicationSettingStartDate.note2')}</div>
        </div>
        <Form
          name="frmPlanNew"
          form={form}
          autoComplete="off"
          onFinish={(values) => {
            if (!values.agreeCheck) {
              setShowAlert(true);
              return;
            }

            navigate('/changeApplicationConfirm', {
              state: { ...state, values: { ...state?.values, ...values } }
            });
          }}
        >
          <Col md={{ span: 12 }}>
            <div className="border-default mb-primary">
              <Row className="border-bottom-default">
                <Col
                  md={{ span: 6 }}
                  className="bg-primary border-right-default pl-5"
                >
                  {t('share.planId')}
                </Col>
                <Col md={{ span: 18 }} className="pl-5">
                  <FormItem className="mb-0">{state?.values?.planId}</FormItem>
                </Col>
              </Row>
              <Row>
                <Col
                  md={{ span: 6 }}
                  className="bg-primary border-right-default pl-5"
                >
                  {t('plan.version')}
                </Col>
                <Col md={{ span: 18 }} className="pl-5">
                  {state?.values?.planBranchNum}
                </Col>
              </Row>
            </div>
          </Col>
          <Col md={{ span: 12 }}>
            <div className="border-default mb-primary">
              <Row>
                <Col
                  md={{ span: 6 }}
                  className="bg-primary border-right-default p-10"
                >
                  {t('changeApplicationSettingStartDate.changeUsageStartDate')}
                </Col>
                <Col md={{ span: 18 }} className="p-10">
                  <Space>
                    <Radio.Group defaultValue={1}>
                      <Radio value={1}>{t('share.specifyDate')}</Radio>
                    </Radio.Group>
                    <FormItem
                      className="mb-0"
                      name="startDate"
                      rules={
                        form.getFieldValue('startType') !== 0
                          ? [
                              {
                                required: form.getFieldValue('startType') !== 0,
                                whitespace: true,
                                message:
                                  t(
                                    'changeApplicationSettingStartDate.changeUsageStartDate'
                                  ) + t('share.error.notEntered')
                              },
                              () => ({
                                validator(_, value) {
                                  if (
                                    !value ||
                                    moment(value, DATE_FORMAT).isAfter(
                                      moment().format(DATE_FORMAT)
                                    )
                                  ) {
                                    return Promise.resolve();
                                  }

                                  return Promise.reject(
                                    new Error(
                                      t(
                                        'changeApplicationSettingStartDate.error1'
                                      )
                                    )
                                  );
                                }
                              })
                            ]
                          : undefined
                      }
                    >
                      <div></div>
                      <DatePicker
                        disabled={form.getFieldValue('startType') === 0}
                        value={
                          form.getFieldValue('startDate')
                            ? moment(
                                form.getFieldValue('startDate'),
                                DATE_FORMAT
                              )
                            : undefined
                        }
                        format={DATE_FORMAT}
                        onChange={(date) => {
                          form.setFields([
                            {
                              name: 'startDate',
                              value: date.format(DATE_FORMAT)
                            }
                          ]);
                          forceUpdate();
                          form.validateFields(['startDate']);
                        }}
                      />
                    </FormItem>
                  </Space>
                </Col>
              </Row>
            </div>
          </Col>
          <div className="mb-primary">
            <div>{t('changeApplicationSettingStartDate.note5')}</div>
          </div>
          <div className="pl-15">
            <strong>{t('share.termOfService')}</strong>
            <Form.Item className="mb-0">
              <div
                className="terms-of-service border-default"
                style={{ whiteSpace: 'break-spaces' }}
              >
                {termsOfService?.messageText}
              </div>
            </Form.Item>
          </div>
          <Form.Item
            name="agreeCheck"
            className="mb-0 text-center"
            valuePropName="checked"
            initialValue={false}
          >
            <Checkbox>{t('settingStartDate.agree')}</Checkbox>
          </Form.Item>
          {showAlert && (
            <div className="text-center error alert-terms">
              {t('settingStartDate.alert')}
            </div>
          )}
          <div className="white-space"></div>
          <div className="bg-dancer p-5">
            <Row>
              <Col md={{ span: 12 }} className="text-right">
                <button type="submit" className="btn-submit cursor-pointer">
                  {t('share.confirm')}
                </button>
              </Col>
              <Col md={{ span: 12 }} className="text-right">
                <Button
                  onClick={() => {
                    navigate(
                      `/planDetail?planId=${state?.values?.planId}&planBranchNum=${state?.values?.planBranchNum}`,
                      {
                        replace: true
                      }
                    );
                  }}
                  className="btn-cancel"
                >
                  {t('share.cancel')}
                </Button>
              </Col>
            </Row>
          </div>
        </Form>
      </div>
      <FooterComponent />
    </div>
  );
}
export default ChangeApplicationSettingStartDate;
