import React, { useEffect } from 'react';
import Header from '../components/Header';
import '../i18n';
import { Row, Col, Breadcrumb, Form, DatePicker, Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { DATE_FORMAT } from '../constant';
import moment from 'moment';
import ItemSettingInput from '../components/ItemSettingInput';
import PlanInfoArea from '../components/PlanInfoArea';
import { useNavigate, useLocation } from 'react-router-dom';
import { useForceUpdate } from '../utils/utils';

function PlanChangeApplicationInput() {
  const { state } = useLocation();
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const forceUpdate = useForceUpdate();
  const navigate = useNavigate();

  useEffect(() => {
    if (state?.values) {
      form.setFieldsValue(state.values);
      forceUpdate();
    }
  }, []);
  return (
    <>
      <Header />
      <div className="white-space"></div>
      <div className="container-custom-primary box-plan">
        <Row className="title">
          <Col md={{ span: 4 }} className="page-title">
            {t('share.planChangeRegistration')}
          </Col>
          <Col md={{ span: 20 }} className="text-right">
            <Breadcrumb separator=">">
              <Breadcrumb.Item className="bold current">
                {t('share.input')}
              </Breadcrumb.Item>
              <Breadcrumb.Item>{t('share.confirm')}</Breadcrumb.Item>
              <Breadcrumb.Item>{t('share.changeSimulation')}</Breadcrumb.Item>
            </Breadcrumb>
          </Col>
        </Row>
        <Form
          name="frmPlanNew"
          form={form}
          autoComplete="off"
          onFinish={(values) => {
            let isPass = true;

            if (!isPass) {
              form.setFieldsValue(values);
              forceUpdate();
              return;
            }
            navigate('/planChangeApplicationConfirm', {
              state: {
                ...state,
                values: {
                  ...state?.values,
                  ...values
                },
                prevData: state?.prevData || {
                  ...state.values
                }
              }
            });
          }}
        >
          <div className="text-right required"> {t('planNew.required')}</div>
          <div className="usage-plan mb-primary">
            <Col md={{ span: 4 }} className="title-sub color-primary">
              {t('share.usagePlan')}
            </Col>
            <div className="ml-15">
              <PlanInfoArea
                data={state?.values}
                isOnlyShowPlanId={state?.isNewPlanBranch}
              />
            </div>
          </div>

          <div className="plan-change mb-primary">
            <Col md={{ span: 4 }} className="title-sub color-primary">
              {t('share.planChange')}
            </Col>
            <div className="base-infomation border-default mb-primary ml-15">
              <Form.Item
                shouldUpdate
                label={t('share.scheduledPlanChangeDate')}
                name="startDate"
                className="isRequired"
                rules={[
                  {
                    required: true,
                    whitespace: true,
                    message:
                      t('share.scheduledPlanChangeDate') +
                      t('share.error.notEntered')
                  },
                  () => ({
                    validator(_, value) {
                      if (
                        !value ||
                        moment(value, DATE_FORMAT).isAfter(
                          moment().format(DATE_FORMAT)
                        )
                      ) {
                        return Promise.resolve();
                      }

                      return Promise.reject(
                        new Error(t('PlanChangeApplicationInput.error1'))
                      );
                    }
                  })
                ]}
              >
                <div>
                  <DatePicker
                    value={
                      form.getFieldValue('startDate')
                        ? moment(form.getFieldValue('startDate'), DATE_FORMAT)
                        : undefined
                    }
                    format={DATE_FORMAT}
                    onChange={(date) => {
                      form.setFields([
                        {
                          name: 'startDate',
                          value: date.format(DATE_FORMAT)
                        }
                      ]);
                      forceUpdate();
                      form.validateFields(['startDate']);
                    }}
                  />
                </div>
              </Form.Item>
            </div>
          </div>
          <div className="usage-plan">
            <ItemSettingInput form={form} type="planChangeApplicationInput" />
          </div>

          <div className="line-primary"></div>
          <div className="text-center bg-dancer mb-primary p-5">
            <button type="submit" className="btn-submit">
              {t('planNewBtnConfirm')}
            </button>
          </div>
          <Button
            onClick={() => {
              navigate(
                `/planDetail?planId=${state?.values?.planId}&planBranchNum=${state?.values?.planBranchNum}`,
                {
                  replace: true
                }
              );
            }}
            className="btn-return"
          >
            {t('share.return')}
          </Button>
        </Form>
      </div>
    </>
  );
}
export default PlanChangeApplicationInput;
