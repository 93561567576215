import React, { useEffect } from 'react';
import Header from '../components/Header';
import { useTranslation } from 'react-i18next';
import { Row, Col, Breadcrumb, Form, Checkbox, Button, Input } from 'antd';
import { useNavigate, useLocation } from 'react-router-dom';
import FooterComponent from '../components/FooterComponent';
import { useForceUpdate } from '../utils/utils';
import axios from 'axios';

function AdministratorAdd() {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { state } = useLocation();
  const forceUpdate = useForceUpdate();

  useEffect(() => {
    if (!state?.values?.planId) {
      navigate('/', { replace: true });
      return;
    }
    form.setFieldsValue(state.values);
    forceUpdate();
  }, []);

  const getAccountInfoByListEmail = async (accountAdd) => {
    return await axios.post('/api/getAccountInfoByListEmail', [...accountAdd]);
  };

  return (
    <>
      <Header />
      <div className="white-space"></div>
      <div className="container-custom-primary administrator-add">
        <Row className="title mb-primary">
          <Col md={{ span: 4 }} className="page-title">
            {t('share.addOrDelete')}
          </Col>
          <Col md={{ span: 20 }} className="text-right">
            <Breadcrumb separator=">">
              <Breadcrumb.Item className="bold current">
                {t('share.input')}
              </Breadcrumb.Item>
              <Breadcrumb.Item>{t('share.confirm')}</Breadcrumb.Item>
              <Breadcrumb.Item>{t('share.completion')}</Breadcrumb.Item>
            </Breadcrumb>
          </Col>
        </Row>
        <Form
          name="frmAdministratorAdd"
          form={form}
          autoComplete="off"
          onFinish={async (values) => {
            let isPass = true;
            const deleteAccountIds = values.deleteAccountIds.filter(
              (i) => i && i !== ''
            );
            // 管理者が全て削除となる場合、『管理者を全アカウント削除することはできません』
            if (values.deleteAccountIds.length === deleteAccountIds.length) {
              values.deleteAccountMessage = t('AdministratorAdd.error1');
              isPass = false;
            }

            // すでに存在する管理者と、追加された管理者情報で 重複がある場合
            values.accountAdd?.forEach((i) => {
              i.message = '';
              if (!i.mail || i.mail === '') {
                i.message = t('AdministratorAdd.error2');
                isPass = false;
              }
              if (
                state.values.accountAdminInfoList?.filter(
                  (a) => a.mail === i.mail
                ).length >= 1 ||
                values.accountAdd?.filter(
                  (a) => (a.mail || '') !== '' && a.mail === i.mail
                ).length > 1
              ) {
                i.message = t('AdministratorAdd.error3');
                isPass = false;
              }
            });

            if (isPass && values.accountAdd?.length > 0) {
              // 追加のアカウント（メールアドレス）が アカウントDBに存在しない場合
              const resultData = await getAccountInfoByListEmail(
                values.accountAdd
              );
              resultData.data?.forEach((item, idx) => {
                const add = values.accountAdd[idx];
                add.message = '';
                if (!item.accountId || item.accountId === '') {
                  values.accountAdd[idx].message = t('AdministratorAdd.error4');
                  isPass = false;
                } else {
                  add.accountId = item.accountId;
                  add.accountName = item.accountName;
                }
              });
            }

            if (!isPass) {
              form.setFieldsValue(values);
              forceUpdate();
              return;
            }
            navigate('/administratorConfirm', {
              state: { ...state, values: { ...state?.values, ...values } }
            });
          }}
        >
          <Col className="title-sub color-primary">{t('share.usagePlan')}</Col>
          <Col md={{ span: 10 }} className="border-default mb-primary ml-15">
            <Row className="border-bottom-default">
              <Col
                md={{ span: 8 }}
                className="bg-primary border-right-default pl-5"
              >
                {t('share.planId')}
              </Col>
              <Col>
                <Form.Item name="planDetail.planId" className="mb-0 pl-15">
                  {state?.values?.planId}
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col
                md={{ span: 8 }}
                className="bg-primary border-right-default pl-5"
              >
                {t('planDetail.planUsageStartDate')}
              </Col>
              <Col>
                <Form.Item name="" className="mb-0 pl-15">
                  {state?.values?.useStartDate}
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col className="title-sub color-primary mb-primary">
            {t('share.managerInformation')}
          </Col>
          <div className="mb-primary ml-15">
            <div className="mb-secondary">
              <span className="bold border-bottom-default">
                {t('share.delete')}
              </span>
            </div>
            <Col md={{ span: 20 }}>
              <table className="table-account">
                <thead className="bg-primary">
                  <td className="td-account">{t('share.account')}</td>
                  <td>{t('share.accountName')}</td>
                  <td className="text-center col-select">
                    {t('administratorAdd.titleSelectRow')}
                  </td>
                </thead>
                <tbody>
                  {state?.values?.accountAdminInfoList?.map((item, idx) => (
                    <tr>
                      <td>{item.mail}</td>
                      <td>{item.accountName}</td>
                      <td className="text-center">
                        <Form.Item
                          hidden
                          name={['deleteAccountIds', idx]}
                        ></Form.Item>
                        <Checkbox
                          checked={
                            form.getFieldValue(['deleteAccountIds', idx]) ===
                            item.accountId
                          }
                          onChange={(e) => {
                            form.setFields([
                              {
                                name: ['deleteAccountIds', idx],
                                value: e.target.checked
                                  ? item.accountId
                                  : undefined
                              }
                            ]);
                            forceUpdate();
                          }}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </Col>
            <div
              className="ant-form-item-explain-error"
              style={{ textAlign: 'right' }}
            >
              {form.getFieldValue('deleteAccountMessage')}
            </div>
          </div>
          <div className="mb-primary ml-15">
            <div className="mb-secondary">
              <span className="bold border-bottom-default">
                {t('planNew.add')}
              </span>
            </div>
            <table className="table-delete border-default">
              <thead className="bg-primary border-bottom-secondary">
                <td className="text-left">{t('administratorAdd.account')}</td>
                <td className="text-right">
                  <button
                    type="button"
                    onClick={() => {
                      form.setFields([
                        {
                          name: 'accountAdd',
                          value: [
                            ...(form.getFieldValue('accountAdd') || []),
                            []
                          ]
                        }
                      ]);
                      forceUpdate();
                    }}
                    className="btn-secondary bg-info cursor-pointer"
                  >
                    {t('planNew.add')}
                  </button>
                </td>
              </thead>
              <tbody>
                {form.getFieldValue('accountAdd')?.map((field, idx) => (
                  <tr className="border-bottom-secondary">
                    <td className="col-input">
                      <Form.Item
                        name={['accountAdd', idx, 'mail']}
                        className="mb-0"
                      >
                        <Input placeholder="new@dts.co.jp" />
                      </Form.Item>
                      <div className="ant-form-item-explain-error">
                        {form.getFieldValue(['accountAdd', idx, 'message'])}
                      </div>
                    </td>
                    <td className="text-right">
                      <button
                        type="button"
                        onClick={() => {
                          form.getFieldValue('accountAdd').splice(idx, 1);
                          forceUpdate();
                        }}
                        className="btn-secondary bg-info cursor-pointer"
                      >
                        {t('planNew.delete')}
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="mb-primary ml-15">
            <div>{t('OptionChange.note')}</div>
            <div className="pl-15">
              <div>{t('administratorAdd.note1')}</div>
              <div>{t('administratorAdd.note2')}</div>
            </div>
          </div>
          <div className="bg-dancer text-center p-5">
            <button type="submit" className="btn-submit cursor-pointer">
              {t('share.confirm')}
            </button>
          </div>
          <div className="line-primary"></div>
          <Button
            onClick={() => {
              navigate(
                `/planDetail?planId=${state?.values?.planId}&planBranchNum=${state?.values?.planBranchNum}`,
                {
                  replace: true
                }
              );
            }}
            className="btn-return"
          >
            {t('share.return')}
          </Button>
        </Form>
        <FooterComponent />
      </div>
    </>
  );
}
export default AdministratorAdd;
