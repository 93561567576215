import iconGeminiot from './assets/images/icon-geminiot.png';
import iconPasterios from './assets/images/icon-pasterios.png';

export const BOOL_FALSE_DISPLAY = 'False';
export const RECOMMENDATION = 'Recommendation';
export const NEWS = 'News';

export const DATE_FORMAT = 'YYYY/MM/DD';
export const USE_NOTUSE = {
  Use: 1,
  NotUse: 0
};

export const USE_TIME = {
  AllDay: 1,
  NotUse: 2,
  Time: 3
};

export const PRIORITY_FLAG = {
  Calendar: 1,
  DayOfWeek: 2
};

export const PRODUCT_ITEM = {
  geminiot: [
    {
      component: 1,
      systemConfig: 2,
      childComponent: (
        <>
          <div className="pro-title text-center border-bottom-default bg-gradient1">
            light
          </div>
          <div className="p-5">
            <img src={iconGeminiot}></img>
            <span>BIライト</span>
          </div>
        </>
      )
    },
    {
      component: 1,
      systemConfig: 1,
      childComponent: (
        <>
          <div className="pro-title text-center border-bottom-default bg-gradient2">
            standard
          </div>
          <div className="p-5">
            <img src={iconGeminiot}></img>
            <span>BIスタンダード</span>
          </div>
        </>
      )
    },
    {
      component: 1,
      systemConfig: 3,
      childComponent: (
        <>
          <div className="pro-title text-center border-bottom-default bg-gradient3">
            high spec
          </div>
          <div className="p-5">
            <img src={iconGeminiot}></img>
            <span>BIハイスペック</span>
          </div>
        </>
      )
    },
    {
      component: 1,
      systemConfig: 4,
      childComponent: (
        <>
          <div className="pro-title text-center border-bottom-default bg-gradient4">
            heavy use
          </div>
          <div className="p-5">
            <img src={iconGeminiot}></img>
            <span>BIヘビーユース</span>
          </div>
        </>
      )
    }
  ],
  pasteriot: [
    {
      component: 2,
      systemConfig: 2,
      childComponent: (
        <>
          <div className="pro-title text-center border-bottom-default bg-gradient1">
            light
          </div>
          <div className="p-5">
            <img src={iconPasterios}></img>
            <span>IoTライト</span>
          </div>
        </>
      )
    },
    {
      component: 2,
      systemConfig: 1,
      childComponent: (
        <>
          <div className="pro-title text-center border-bottom-default bg-gradient2">
            standard
          </div>
          <div className="p-5">
            <img src={iconPasterios}></img>
            <span>IoTスタンダード</span>
          </div>
        </>
      )
    },
    {
      component: 2,
      systemConfig: 3,
      childComponent: (
        <>
          <div className="pro-title text-center border-bottom-default bg-gradient3">
            high spec
          </div>
          <div className="p-5">
            <img src={iconPasterios}></img>
            <span>IoTハイスペック</span>
          </div>
        </>
      )
    },
    {
      component: 2,
      systemConfig: 4,
      childComponent: (
        <>
          <div className="pro-title text-center border-bottom-default bg-gradient4">
            heavy use
          </div>
          <div className="p-5">
            <img src={iconPasterios}></img>
            <span>IoTヘビーユース</span>
          </div>
        </>
      )
    }
  ]
};

export const LIST_SPEC_SETTING = [
  [
    {
      setting: {
        cpu: 2,
        mem: 8
      },
      label: '2 core, 8 GB'
    }
  ],
  [
    {
      setting: {
        cpu: 4,
        mem: 8
      },
      label: '4 core, 8 GB'
    }
  ],
  [
    {
      setting: {
        cpu: 4,
        mem: 16
      },
      label: '4 core, 16 GB'
    }
  ],
  [
    {
      setting: {
        cpu: 8,
        mem: 16
      },
      label: '8 core, 16 GB'
    }
  ],
  [
    {
      setting: {
        cpu: 8,
        mem: 32
      },
      label: '8 core, 32 GB'
    }
  ],
  [
    {
      setting: {
        cpu: 8,
        mem: 32
      },
      label: '8 core, 64 GB'
    }
  ],
  [
    {
      setting: {
        cpu: 16,
        mem: 32
      },
      label: '16 core, 32 GB'
    }
  ],
  [
    {
      setting: {
        cpu: 16,
        mem: 128
      },
      label: '16 core, 128 GB'
    }
  ]
];

export const PLAN_DISPLAY_MODE = {
  NotIncludeHistory: 0,
  IncludeHistory: 1
};

export const COMPONENT_PLAN_DATA = {
  1: 'Geminiot',
  2: 'Pasteriot.mi'
};

export const PLAN_STATUS = {
  1: 1,
  2: 2,
  3: 3,
  4: 4,
  5: 5,
  6: 6
};

export const STATUS_PLAN_DATA = {
  [PLAN_STATUS[1]]: 'ドラフト',
  [PLAN_STATUS[2]]: '申込中',
  [PLAN_STATUS[3]]: '利用中',
  [PLAN_STATUS[4]]: '停止中',
  [PLAN_STATUS[5]]: '変更済',
  [PLAN_STATUS[6]]: '解約済'
};

export const BACKUP_REGION = [
  [1, '東京'],
  [2, '大阪']
].map((o) => ({ value: o[0], label: o[1] }));

export const BACKUP_CYCLE = [[1, '1日毎']].map((o) => ({
  value: o[0],
  label: o[1]
}));

export const CHOICE_TYPE_STOP_DATE = {
  0: '即日',
  1: '予約'
};

export const PAYMENT_METHOD = {
  0: 'createReceipt.paymentMethod.notSetting', //未設定
  1: 'createReceipt.paymentMethod.creditPayment', //クレジットカード決済
  2: 'createReceipt.paymentMethod.invoicePayment', //請求書払い
  3: 'createReceipt.paymentMethod.dealerSettlement' //販売店決済
};

export const TIME_FEE = [
  [1, '2023年01月'],
  [2, '2023年02月']
].map((o) => ({ value: o[0], label: o[1] }));

export const paygentSendDataInfo = {
  merchant_name: '株式会社ＤＴＳ',
  copy_right: '(C) DTS CORPORATION All Rights Reserved.',
  use_card_conf_number: 1,
  use_cardholder_name: 1,
  valid_check_flg: 1,
  use_card_list_view: 0,
  use_update: 0,
  use_delete: 0,
  appendix: 0,
  isbtob: 0
};

export const PROTOCOL = {
  1: 'TCP',
  2: 'UDP'
};
