import React, { useEffect } from 'react';
import Header from '../components/Header';
import { Row, Col, Breadcrumb } from 'antd';
import { useTranslation } from 'react-i18next';
import FooterComponent from '../components/FooterComponent';
import { useNavigate, useLocation } from 'react-router-dom';

function CannotCreditCardChange() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { state } = useLocation();

  useEffect(() => {
    if (!state?.planId) {
      navigate('/', { replace: true });
    }
  }, []);

  return (
    <>
      <Header />
      <div className="white-space"></div>
      <div className="container-custom-primary campaign-code-added-input">
        <Row className="title mb-primary">
          <Col md={{ span: 6 }} className="page-title">
            {t('cannotCreditCardChange.title')}
          </Col>
          <Col md={{ span: 18 }} className="text-right">
            <Breadcrumb separator=">">
              <Breadcrumb.Item>
                {t('cannotCreditCardChange.title')}
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                {t('paymentRegistration.externalSite')}
              </Breadcrumb.Item>
              <Breadcrumb.Item className="current bold">
                {t('share.completion')}
              </Breadcrumb.Item>
            </Breadcrumb>
          </Col>
        </Row>
        <div className="white-space"></div>
        <div className="mb-primary">{t('cannotCreditCardChange.des1')}</div>
        <div>{t('cannotCreditCardChange.des2')}</div>
        <div className="white-space"></div>
        <div className="bg-dancer text-center p-5">
          <button
            className="btn-submit cursor-pointer"
            onClick={() => {
              navigate(
                `/planDetail?planId=${state?.planId}&planBranchNum=${state?.planBranchNum}`,
                {
                  replace: true
                }
              );
            }}
          >
            {t('optionChangeCompletion.returnToPlanDetails')}
          </button>
        </div>
      </div>
      <FooterComponent />
    </>
  );
}
export default CannotCreditCardChange;
