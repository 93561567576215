import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Row, Col } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import Header from '../components/Header';
import { useForm } from 'antd/lib/form/Form';
import { useAuth } from '../AuthContext';
import { useTranslation } from 'react-i18next';
function Login() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { form } = useForm();
  const auth = useAuth();
  const [error, setError] = useState('');

  useEffect(() => {
    auth.signout();
  }, []);
  return (
    <>
      <Header />
      <div className="container-custom modal-primary" gutter={16}>
        <div className="box-title">{t('login.title')}</div>
        <div className="box-body">
          <div className="message-error mb-primary">{error}</div>
          <Form
            form={form}
            name="nest-messages"
            onFinish={(values) => {
              auth.signin(values.mail, values.password, (data) => {
                if (data.error) {
                  setError(t('login.wrongPassword'));
                } else {
                  navigate('/', { replace: true });
                }
              });
            }}
          >
            <Row>
              <Col md={{ span: 7 }} xs={{ span: 24 }}>
                {t('login.acc')}:
              </Col>
              <Col md={{ span: 17 }} xs={{ span: 24 }}>
                <Form.Item
                  name="mail"
                  rules={[
                    {
                      required: true,
                      message: t('login.acc') + t('share.error.notEntered')
                    }
                  ]}
                >
                  <Input
                    className="txt-primary txt-username"
                    placeholder="mailaddress@example.com"
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <Col md={{ span: 7 }} xs={{ span: 24 }}>
                {t('login.pass')}:
              </Col>
              <Col md={{ span: 17 }} xs={{ span: 24 }}>
                <Form.Item
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: t('login.pass') + t('share.error.notEntered')
                    }
                  ]}
                >
                  <Input.Password className="txt-primary" />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item>
              <Col offset={11}>
                <Button htmlType="submit" className="btn-primary">
                  {t('login.submit')}
                </Button>
              </Col>
            </Form.Item>
            <Col md={{ offset: 16 }} xs={{ offset: 11 }}>
              <Link to="/emailRegister" className="link">
                {t('login.linkEmailRegister')}
              </Link>
            </Col>
            <Col md={{ offset: 16 }} xs={{ offset: 11 }} className="mb-primary">
              <Link to="/passwordReissue">{t('login.linkRePass')}</Link>
            </Col>
          </Form>
        </div>
      </div>
    </>
  );
}
export default Login;
