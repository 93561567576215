import React, { useEffect, useState } from 'react';
import '../i18n';
import { Row, Col, Space, Form } from 'antd';
import { useTranslation } from 'react-i18next';
import { LIST_SPEC_SETTING } from '../constant';
import { CaretRightOutlined } from '@ant-design/icons';

function ServerListComponent(props) {
  const { t } = useTranslation();
  const [data, setData] = useState({});

  return (
    <>
      <div className="list-server">
        {Object.keys(props.itemSetting || {}).map((key) => (
          <>
            {key !== 'data' && (
              <Row>
                <Col md={{ span: 6 }}>
                  <CaretRightOutlined />
                  {t(`share.${key}Server`)}
                </Col>
                <Col md={{ span: 5 }}>
                  <Form.Item
                    label={t('freeSimulation.server')}
                    name="dbServerIotSpec"
                    className="label-after-hidden"
                  >
                    {props.itemSetting[key].server_unit}
                    <span>{t('freeSimulation.set')}</span>
                  </Form.Item>
                </Col>
                <Col md={{ span: 7 }}>
                  <Form.Item
                    label={t('share.spec')}
                    name="dbServerIotStore"
                    className="store label-after-hidden"
                  >
                    {
                      LIST_SPEC_SETTING.find(
                        (item) =>
                          item[0].setting.cpu ==
                            props.itemSetting[key].server_cpu &&
                          item[0].setting.mem ==
                            props.itemSetting[key].server_mem
                      )?.[0].label
                    }
                  </Form.Item>
                </Col>
                <Col md={{ span: 6 }}>
                  <Form.Item
                    label={t('share.storage')}
                    name="dbServerIotStore"
                    className="store label-after-hidden"
                  >
                    {props.itemSetting[key].server_vol} GB
                  </Form.Item>
                </Col>
              </Row>
            )}
            {key === 'data' && (
              <Row>
                <Col md={{ span: 6 }}>
                  <CaretRightOutlined />
                  {t('share.dataServer')}
                </Col>
                <Col md={{ span: 6 }}>
                  <Form.Item
                    label={t('freeSimulation.server')}
                    name="dbServerIotSpec"
                    className="label-after-hidden"
                  >
                    {props.itemSetting[key].data_traffic} GB
                  </Form.Item>
                </Col>
                <Col md={{ span: 6 }}>{t('share.perMonth')}</Col>
              </Row>
            )}
          </>
        ))}
      </div>
    </>
  );
}
export default ServerListComponent;
