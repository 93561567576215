import { routes } from './views/routes';
import React, { useState } from 'react';
import './App.css';
import './i18n';
import './service/interceptor';
import { Routes, Route, Navigate, BrowserRouter } from 'react-router-dom';
import AuthProvider, { RequireAuth } from './AuthContext';

function App() {
  const basename = window.location.pathname.substring(
    0,
    window.location.pathname.indexOf('/', 2)
  );

  const [loading, setLoading] = useState(false);

  const addLoading = () => {
    setLoading(true);
  };
  App.addLoading = addLoading;

  const removeLoading = () => {
    setLoading(false);
  };
  App.removeLoading = removeLoading;
  return (
    <AuthProvider>
      <BrowserRouter basename={basename}>
        <div className="App">
          <Routes>
            {routes.map(({ path, Component, isLogin, props = {} }) => (
              <Route
                exact={false}
                path={path}
                element={
                  isLogin ? (
                    <RequireAuth>
                      <Component {...props} />
                    </RequireAuth>
                  ) : (
                    <Component {...props} />
                  )
                }
              ></Route>
            ))}

            <Route path="/logout" element={<Navigate to="/login" replace />} />
          </Routes>
          {loading && (
            <div>
              <div
                className="ant-modal-mask"
                style={{ zIndex: 99999999999999 }}
              >
                <div className="loading"></div>
              </div>
            </div>
          )}
        </div>
      </BrowserRouter>
    </AuthProvider>
  );
}

export default App;
