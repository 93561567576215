import Header from '../components/Header';
import { Row, Col, Breadcrumb, Button } from 'antd';
import { useTranslation } from 'react-i18next';
import FooterComponent from '../components/FooterComponent';
import { useNavigate, useLocation } from 'react-router-dom';

function ChangeSystemUsageTimeCompletion() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { state } = useLocation();
  return (
    <>
      <Header />
      <div className="white-space"></div>
      <div className="container-custom-primary">
        <Row className="title mb-primary">
          <Col md={{ span: 6 }} className="page-title">
            {t('changeSystemUsageTime.title')}
            {' / '}
            {t('share.completion')}
          </Col>
          <Col md={{ span: 18 }} className="text-right">
            <Breadcrumb separator=">">
              <Breadcrumb.Item>{t('share.input')}</Breadcrumb.Item>
              <Breadcrumb.Item>{t('share.confirm')}</Breadcrumb.Item>
              <Breadcrumb.Item className="bold current">
                {t('share.completion')}
              </Breadcrumb.Item>
            </Breadcrumb>
          </Col>
        </Row>
        <div>{t('changeSystemUsageTimeCompletion.note')}</div>
        <div className="white-space"></div>
        <div className="bg-dancer text-center p-5">
          <Button
            onClick={() => {
              navigate(
                `/planDetail?planId=${state.values.planId}&planBranchNum=${state.values.planBranchNum}`,
                {
                  replace: true
                }
              );
            }}
            className="btn-submit"
          >
            {t('administratorConfirm.returnToPlanDetails')}
          </Button>
        </div>
        <div className="white-space"></div>
        <FooterComponent />
      </div>
    </>
  );
}
export default ChangeSystemUsageTimeCompletion;
