/**
 * (C) 2022 DTS CORPORATION
 */
import React from 'react';
import '../i18n';
import { Row, Col, Space } from 'antd';
import { useTranslation } from 'react-i18next';
import { USE_TIME } from '../constant';
import { formatTimeDisplay } from '../utils/utils';
/**
 * User time component
 */
function UserTimeComponent(props) {
  const { t } = useTranslation();

  const getUseTimeType = (type) => {
    switch (type) {
      case USE_TIME.AllDay:
        return t('share.useAllDay');
      case USE_TIME.NotUse:
        return t('share.notAvailableAllDay');
      case USE_TIME.Time:
        return t('share.specifyTime');
    }
  };
  return (
    <>
      <div className="user-time-body">
        <div className="box-week">
          {props.type === 'planDetail'
            ? t('share.systemUsageTimePriority')
            : ''}

          <Row className="row-title">
            <Col md={{ span: 13 }}>{t('share.dayOfWeek')}</Col>
            <Col md={{ span: 11 }}>
              <Space size={30}>
                <span>{t('share.start')}</span>
                <span>{t('share.stop')}</span>
              </Space>
            </Col>
          </Row>
          {[
            t('share.monday'),
            t('share.tuesday'),
            t('share.wednesday'),
            t('share.thursday'),
            t('share.friday'),
            t('share.saturday'),
            t('share.sunday')
          ].map((x, i) => (
            <Row justify="center">
              <Col md={{ span: 7 }}>
                <Space size={30}>
                  <span className="day-of-week">{x}</span>
                  <span>
                    {getUseTimeType(
                      props.systemUtilizationTimes?.[i]?.usagePattern
                    )}
                  </span>
                </Space>
              </Col>
              <Col md={{ span: 5 }}>
                {props.systemUtilizationTimes?.[i].usagePattern ===
                  USE_TIME.Time && (
                  <Space size={15}>
                    <span>
                      {formatTimeDisplay(
                        props.systemUtilizationTimes?.[i]?.startTime
                      )}
                    </span>
                    <span>～</span>
                    <span>
                      {formatTimeDisplay(
                        props.systemUtilizationTimes?.[i]?.stopTime
                      )}
                    </span>
                  </Space>
                )}
              </Col>
            </Row>
          ))}
        </div>
        <div className="box-design">
          <div>
            {props.type === 'planDetail' ? t('share.calendarDesignation') : ''}
          </div>
          <Row className="row-title">
            <Col md={{ span: 13 }}>
              {}
              {props.type === 'planDetail'
                ? ''
                : t('freeSimulation.calendarDesignation')}
            </Col>
            <Col md={{ span: 11 }}>
              <Space size={30}>
                <span>{t('share.start')}</span>
                <span>{t('share.stop')}</span>
              </Space>
            </Col>
          </Row>
          {props.systemUtilizationTimes
            ?.filter((item, i) => i > 6)
            .map((item) => (
              <Row justify="center">
                <Col md={{ span: 7 }}>
                  <Space size={30}>
                    <span>{item.designatedDate || 'ddd'}</span>
                    <span>{getUseTimeType(item.usagePattern)}</span>
                  </Space>
                </Col>
                <Col md={{ span: 5 }}>
                  {item.usagePattern === USE_TIME.Time && (
                    <Space size={15}>
                      <span>{formatTimeDisplay(item.startTime)}</span>
                      <span>～</span>
                      <span>{formatTimeDisplay(item.stopTime)}</span>
                    </Space>
                  )}
                </Col>
              </Row>
            ))}
        </div>
      </div>
    </>
  );
}
export default UserTimeComponent;
