import React from 'react';
import '../i18n';

import { Row, Col, Form, Space, Radio } from 'antd';
import { useTranslation } from 'react-i18next';
import { USE_TIME } from '../constant';
import { formatTimeDisplay } from '../utils/utils';
import { useLocation } from 'react-router-dom';
import isEqual from 'lodash/isEqual';

function TimeComponentConfirm(props) {
  const { t } = useTranslation();
  const { form } = props;
  const { state } = useLocation();

  return (
    <div className="system-usage-time">
      <Col md={{ span: 4 }} className="title-sub color-primary">
        {t('share.systemUsageTime')}
      </Col>
      <div className="system-usage-time-body border-default ml-15">
        <Row>
          <Col md={{ span: 4 }} className="bg-primary col-label">
            <label>{t('share.systemUsageTime')}</label>
          </Col>
          <Col md={{ span: 20 }} className="col-control">
            <div className="mb-secondary">
              {t('share.systemUsageTimePriority')}
            </div>
            <Row>
              <Col md={{ span: 20 }} className="bg-primary mb-primary">
                {t('share.dayOfWeek')}
              </Col>
            </Row>
            <div className="mb-primary box-dayofweek scr-confirm mt-10">
              <table>
                {[
                  t('share.monday'),
                  t('share.tuesday'),
                  t('share.wednesday'),
                  t('share.thursday'),
                  t('share.friday'),
                  t('share.saturday'),
                  t('share.sunday')
                ].map((x, i) => (
                  <>
                    <tr className="mb-secondary">
                      <td className="day-of-week">{x}</td>
                      <td>
                        <Space>
                          <span>:</span>
                          <Form.Item
                            name={['systemUtilizationTimes', i, 'usagePattern']}
                            initialValue={USE_TIME.AllDay}
                          >
                            <Radio.Group disabled>
                              <Radio value={USE_TIME.AllDay}>
                                {t('share.useAllDay')}
                              </Radio>
                              <Radio value={USE_TIME.NotUse}>
                                {t('share.notAvailableAllDay')}
                              </Radio>
                              <Radio value={USE_TIME.Time}>
                                {t('share.specifyTime')}
                              </Radio>
                            </Radio.Group>
                          </Form.Item>
                          <div className="start-time">
                            {i === 0 && (
                              <div className="text-center col-title">
                                {t('share.start')}
                              </div>
                            )}
                            <Form.Item
                              name={['systemUtilizationTimes', i, 'startTime']}
                              className="mb-0 text-center"
                            >
                              {formatTimeDisplay(
                                form.getFieldValue([
                                  'systemUtilizationTimes',
                                  i,
                                  'startTime'
                                ])
                              )}
                            </Form.Item>
                          </div>

                          <span>~</span>
                          <div className="stop-time">
                            {i === 0 && (
                              <div className="text-center col-title">
                                {t('share.stop')}
                              </div>
                            )}
                            <Form.Item
                              name={['systemUtilizationTimes', i, 'stopTime']}
                              className="mb-0 text-center"
                            >
                              {formatTimeDisplay(
                                form.getFieldValue([
                                  'systemUtilizationTimes',
                                  i,
                                  'stopTime'
                                ])
                              )}
                            </Form.Item>
                          </div>
                        </Space>
                      </td>

                      {state?.prevData && (
                        <td
                          style={{
                            width: '30px',
                            textAlign: 'right',
                            color: 'red'
                          }}
                        >
                          {isEqual(
                            state.prevData[i],
                            state.values.systemUtilizationTimes[i]
                          )
                            ? ''
                            : '*'}
                        </td>
                      )}
                    </tr>
                  </>
                ))}
              </table>
            </div>
            <Form.List name="systemUtilizationTimes">
              {(fields) => (
                <>
                  <Row className="mb-primary">
                    <Col md={{ span: 20 }} className="bg-primary">
                      {t('planNew.calendarDesign')}
                    </Col>
                  </Row>
                  <div className="list-row-new pt-10">
                    {fields
                      .filter((field) => field.name > 6)
                      .map((field, idx) => (
                        <>
                          <Row className="row-item-design">
                            <Col md={{ span: 20 }}>
                              <Space>
                                <Form.Item
                                  name={[field.name, 'designatedDate']}
                                  className="mb-0 col-date-value"
                                >
                                  {form.getFieldValue([
                                    'systemUtilizationTimes',
                                    field.name,
                                    'designatedDate'
                                  ])}
                                </Form.Item>

                                <Form.Item
                                  name={[field.name, 'usagePattern']}
                                  className="mb-0"
                                  initialValue={USE_TIME.AllDay}
                                >
                                  <Radio.Group disabled>
                                    <Radio value={USE_TIME.AllDay}>
                                      {t('share.useAllDay')}
                                    </Radio>
                                    <Radio value={USE_TIME.NotUse}>
                                      {t('share.notAvailableAllDay')}
                                    </Radio>
                                    <Radio value={USE_TIME.Time}>
                                      {t('share.specifyTime')}
                                    </Radio>
                                  </Radio.Group>
                                </Form.Item>
                                <div className="start-time">
                                  <div
                                    className="text-center col-title"
                                    hidden={idx > 0}
                                  >
                                    {t('share.start')}
                                  </div>
                                  <Form.Item
                                    name={[field.name, 'startTime']}
                                    className="mb-0 text-center"
                                  >
                                    {formatTimeDisplay(
                                      form.getFieldValue([
                                        'systemUtilizationTimes',
                                        field.name,
                                        'startTime'
                                      ])
                                    )}
                                  </Form.Item>
                                </div>
                                <span>~</span>
                                <div className="stop-time">
                                  <div
                                    className="text-center col-title"
                                    hidden={idx > 0}
                                  >
                                    {t('share.stop')}
                                  </div>
                                  <Form.Item
                                    name={[field.name, 'stopTime']}
                                    className="mb-0 text-center"
                                  >
                                    {formatTimeDisplay(
                                      form.getFieldValue([
                                        'systemUtilizationTimes',
                                        field.name,
                                        'stopTime'
                                      ])
                                    )}
                                  </Form.Item>
                                </div>
                                {state?.prevData && (
                                  <div
                                    style={{
                                      width: '20px',
                                      textAlign: 'right',
                                      color: 'red'
                                    }}
                                  >
                                    {isEqual(
                                      state.prevData[field.name],
                                      state.values.systemUtilizationTimes[
                                        field.name
                                      ]
                                    )
                                      ? ''
                                      : '*'}
                                  </div>
                                )}
                              </Space>
                            </Col>
                          </Row>
                        </>
                      ))}
                  </div>
                </>
              )}
            </Form.List>
          </Col>
        </Row>
      </div>
    </div>
  );
}
export default TimeComponentConfirm;
