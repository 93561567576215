import axios from 'axios';

export const authenticateService = {
  login,
  logout,
  currentUser
};
function login(mail, password, callback) {
  const url = '/api/login';
  axios
    .post(url, { mail: mail, password: password })
    .then((res) => {
      callback(res.data);
    })
    .catch((error) => {
      callback({ error: { status: error.response.status } });
    });
}
function logout() {
  const url = 'api/logout';
  axios.post(url, {});
  localStorage.removeItem('accessToken');
}
function currentUser() {
  return localStorage.getItem('user') || '';
}
