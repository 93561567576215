import Header from '../components/Header';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Row,
  Form,
  Col,
  Button,
  Breadcrumb,
  Radio,
  Space,
  DatePicker
} from 'antd';
import FooterComponent from '../components/FooterComponent';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import { STATUS_PLAN_DATA, DATE_FORMAT, PLAN_STATUS } from '../constant';
import { useForceUpdate } from '../utils/utils';
import moment from 'moment';
function TemporarySuspensionOrCancellationSuspensionInput() {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { state } = useLocation();
  const [data, setData] = useState();
  const [display, setDisplay] = useState();
  const [messageValidate, setMessageValidate] = useState();
  const forceUpdate = useForceUpdate();

  useEffect(() => {
    const params = state.values;
    const planId = params.planId;
    const planBranchNum = params.planBranchNum;
    if (!planId || !planBranchNum) {
      navigate('/');
      return;
    }
    form.setFields([
      {
        name: 'choiceTypeStopDate',
        value: 0
      }
    ]);
    form.setFields([
      {
        name: 'designationDate',
        value: moment(new Date()).format(DATE_FORMAT)
      }
    ]);
    setMessageValidate('');
    axios
      .post('/api/getOtherProcedureInfo', {
        planId,
        planBranchNum
      })
      .then((res) => {
        const { data } = res;
        setData(data);
        if (data) {
          form.setFieldsValue(data);
          forceUpdate();
        }
        // set display
        // set display
        if (data?.status == PLAN_STATUS[2]) {
          setDisplay(5);
        } else if (data?.stopDate == null && data?.stopReserveDate == null) {
          setDisplay(1);
        } else if (
          data?.stopDate != null &&
          !(
            (data?.restrictionStatus == 1 && data?.restrictionValidFlag == 1) ||
            (data?.restrictionStatus == 4 && data?.restrictionValidFlag == 1)
          )
        ) {
          setDisplay(2);
        } else if (data?.stopReserveDate != null) {
          setDisplay(3);
        } else {
          setDisplay(4);
        }
      });
    if (state?.values) {
      form.setFieldsValue(state.values);
      forceUpdate();
    }
  }, []);

  const onChangeType = (e) => {
    setMessageValidate('');
    form.setFields([
      {
        name: 'choiceTypeStopDate',
        value: e.target.value
      }
    ]);
    if (e.target.value == 0) {
      form.setFields([
        {
          name: 'designationDate',
          value: moment(new Date()).format(DATE_FORMAT)
        }
      ]);
    }
    forceUpdate();
  };

  return (
    <>
      <Header />
      <div className="white-space"></div>
      <div className="container-custom-primary suspension-input">
        <Row className="title mb-primary">
          <Col md={{ span: 12 }} className="page-title">
            {t('OtherProceduresStopOrCancel.link1')}
          </Col>
          <Col md={{ span: 12 }} className="text-right">
            <Breadcrumb separator=">">
              <Breadcrumb.Item className="bold current">
                {t('share.input')}
              </Breadcrumb.Item>
              <Breadcrumb.Item>{t('share.confirm')}</Breadcrumb.Item>
              <Breadcrumb.Item>{t('share.completion')}</Breadcrumb.Item>
            </Breadcrumb>
          </Col>
        </Row>
        <Form
          name="frmTemporarySuspensionOrCancellationSuspension"
          form={form}
          autoComplete="off"
          onFinish={(values) => {
            let isPass = true;
            setMessageValidate('');
            if (values.choiceTypeStopDate == 1) {
              if (values.designationDate == null) {
                setMessageValidate('日付指定を入力してください');
                isPass = false;
              } else {
                const currentDate = moment().format(DATE_FORMAT);
                const checkAfterDate = moment(currentDate).isAfter(
                  values.designationDate
                );
                if (checkAfterDate) {
                  setMessageValidate('日付指定は、本日以降をご指定ください');
                  isPass = false;
                }
              }
            }
            if (isPass) {
              state.values.display = display;
              navigate('/temporarySuspensionOrCancellationSuspensionConfirm', {
                state: { ...state, values: { ...state?.values, ...values } }
              });
            }
          }}
        >
          <Col className="title-sub color-primary">{t('share.usagePlan')}</Col>
          <Col
            md={{ span: 10 }}
            className="border-default ml-15 mb-primary"
            style={{ borderBottom: '0px' }}
          >
            <Row className="border-bottom-default">
              <Col
                md={{ span: 8 }}
                className="bg-primary border-right-default pl-5"
              >
                {t('share.planId')}
              </Col>
              <Col>
                <Form.Item className="mb-0 pl-15">{data?.planId}</Form.Item>
              </Col>
            </Row>
            <Row className="border-bottom-default">
              <Col
                md={{ span: 8 }}
                className="bg-primary border-right-default pl-5"
              >
                {t('plan.version')}
              </Col>
              <Col>
                <Form.Item className="mb-0 pl-15">
                  {data?.planBranchNum}
                </Form.Item>
              </Col>
            </Row>
            <Row className="border-bottom-default">
              <Col
                md={{ span: 8 }}
                className="bg-primary border-right-default pl-5"
              >
                {t('planDetail.status')}
              </Col>
              <Col>
                <Form.Item className="mb-0 pl-15">
                  {STATUS_PLAN_DATA[data?.status]}
                </Form.Item>
              </Col>
            </Row>
            <Row className="border-bottom-default">
              <Col
                md={{ span: 8 }}
                className="bg-primary border-right-default pl-5"
              >
                {t('planDetail.planUsageStartDate')}
              </Col>
              <Col>
                <Form.Item className="mb-0 pl-15">
                  {data?.useStartDate}
                </Form.Item>
              </Col>
            </Row>
            {data?.stopReserveDate && (
              <Row className="border-bottom-default">
                <Col
                  md={{ span: 8 }}
                  className="bg-primary border-right-default pl-5"
                >
                  {t(
                    'TemporarySuspensionOrCancellationSuspension.planSuspensionDate'
                  )}
                </Col>
                <Col>
                  <Form.Item className="mb-0 pl-15">
                    {data.stopReserveDate}
                  </Form.Item>
                </Col>
              </Row>
            )}
            {data?.stopDate && (
              <Row className="border-bottom-default">
                <Col
                  md={{ span: 8 }}
                  className="bg-primary border-right-default pl-5"
                >
                  {t('share.planStopDate')}
                </Col>
                <Col>
                  <Form.Item className="mb-0 pl-15">{data.stopDate}</Form.Item>
                </Col>
              </Row>
            )}
            {data?.cancelReserveDate && (
              <Row className="border-bottom-default">
                <Col
                  md={{ span: 8 }}
                  className="bg-primary border-right-default pl-5"
                >
                  {t('share.scheduledCancellationDate')}
                </Col>
                <Col>
                  <Form.Item className="mb-0 pl-15">
                    {data.cancelReserveDate}
                  </Form.Item>
                </Col>
              </Row>
            )}
            {data?.cancelDate && (
              <Row className="border-bottom-default">
                <Col
                  md={{ span: 8 }}
                  className="bg-primary border-right-default pl-5"
                >
                  {t('share.planCancellationDate')}
                </Col>
                <Col>
                  <Form.Item className="mb-0 pl-15">
                    {data.cancelDate}
                  </Form.Item>
                </Col>
              </Row>
            )}
          </Col>
          <Col className="title-sub color-primary">
            {t('TemporarySuspensionOrCancellationSuspension.stop')}
          </Col>
          <div className="ml-15">
            <Col md={{ span: 20 }}>
              <table className="table-stop mb-primary">
                {display == 1 && (
                  <>
                    <tr>
                      <td className="bg-primary border-right-default col-label-lv1">
                        {t(
                          'TemporarySuspensionOrCancellationSuspension.stopLabel1'
                        )}
                      </td>
                      <td>
                        <Space direction="horizontal" align="start">
                          <Form.Item name="choiceTypeStopDate" className="mb-0">
                            <Radio.Group onChange={onChangeType}>
                              <Radio value={0}>
                                {t('settingStartDate.immediately')}
                              </Radio>
                              <Radio value={1} className="radio-specify-date">
                                {t('share.specifyDate')}
                              </Radio>
                            </Radio.Group>
                          </Form.Item>
                          <Space direction="vertical" align="start" size={0}>
                            <Form.Item
                              name="designationDate"
                              className="mb-0 date"
                            >
                              <div></div>
                              <DatePicker
                                value={
                                  form.getFieldValue('designationDate')
                                    ? moment(
                                        form.getFieldValue('designationDate'),
                                        DATE_FORMAT
                                      )
                                    : undefined
                                }
                                format={DATE_FORMAT}
                                onChange={(date) => {
                                  form.setFields([
                                    {
                                      name: 'designationDate',
                                      value: date.format(DATE_FORMAT)
                                    }
                                  ]);
                                  forceUpdate();
                                }}
                                disabled={
                                  form.getFieldValue(['choiceTypeStopDate']) ==
                                  0
                                }
                              />
                            </Form.Item>
                            <div className="error">{messageValidate}</div>
                          </Space>
                        </Space>
                      </td>
                      <td className="text-right">
                        <Button
                          htmlType="submit"
                          className="btn-dancer size-standard"
                        >
                          {t(
                            'TemporarySuspensionOrCancellationSuspension.stopLabel1'
                          )}
                        </Button>
                      </td>
                    </tr>
                  </>
                )}
                {display == 2 && (
                  <>
                    <tr>
                      <td className="bg-primary border-right-default col-label-lv1">
                        {t(
                          'TemporarySuspensionOrCancellationSuspension.stopLabel2'
                        )}
                      </td>
                      <td>
                        <Form.Item name="choiceTypeStopDate" className="mb-0">
                          <Radio.Group>
                            <Radio value={0}>
                              {t('settingStartDate.immediately')}
                            </Radio>
                          </Radio.Group>
                        </Form.Item>
                      </td>
                      <td className="text-right">
                        <Button
                          htmlType="submit"
                          className="btn-dancer size-standard"
                        >
                          {t(
                            'TemporarySuspensionOrCancellationSuspension.stopRelease'
                          )}
                        </Button>
                      </td>
                    </tr>
                  </>
                )}
                {display == 3 && (
                  <>
                    <tr className="border-none tr-notify">
                      <td>
                        {t(
                          'TemporarySuspensionOrCancellationSuspension.notify1'
                        )}
                      </td>
                      <td></td>
                      <td></td>
                    </tr>
                  </>
                )}
                {display == 4 && (
                  <tr className="border-none tr-notify">
                    <td>
                      {t('TemporarySuspensionOrCancellationSuspension.notify2')}
                    </td>
                    <td></td>
                    <td></td>
                  </tr>
                )}
                {display == 5 && (
                  <tr className="border-none tr-notify">
                    <td>
                      {t('TemporarySuspensionOrCancellationSuspension.notify3')}
                    </td>
                    <td></td>
                    <td></td>
                  </tr>
                )}
              </table>
            </Col>
            <div>{t('TemporarySuspensionOrCancellationSuspension.note')}</div>
            <div className="white-space"></div>
            <div>{t('OptionChange.note')}</div>
            <div className="ml-15">
              <div>
                {t('TemporarySuspensionOrCancellationSuspension.note1')}
              </div>
              <div>
                {t('TemporarySuspensionOrCancellationSuspension.note2')}
              </div>
              <div>
                {t('TemporarySuspensionOrCancellationSuspension.note3')}
              </div>
            </div>
          </div>
        </Form>
        <div className="line-primary"></div>
        <Button
          onClick={() => {
            navigate('/otherProceduresStopOrCancel', {
              state
            });
          }}
          className="btn-return"
        >
          {t('share.return')}
        </Button>
      </div>
      <FooterComponent />
    </>
  );
}
export default TemporarySuspensionOrCancellationSuspensionInput;
