import React from 'react';
import '../i18n';

import { Row, Col, Form, DatePicker, Space, Select, Radio } from 'antd';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { DATE_FORMAT, PRIORITY_FLAG, USE_TIME } from '../constant';
import { formatTimeDisplay, useForceUpdate } from '../utils/utils';

function TimeComponentInput(props) {
  const forceUpdate = useForceUpdate();
  const { t } = useTranslation();
  const { form } = props;
  const hours = [...Array(25).keys()].map((o) => ({
    value: o.toString(),
    label: formatTimeDisplay(o)
  }));

  return (
    <div className="system-usage-time-body border-default ml-15">
      <Row>
        <Col md={{ span: 4 }} className="bg-primary col-label">
          <label className="lbl-required">{t('share.systemUsageTime')}</label>
        </Col>
        <Col md={{ span: 20 }} className="col-control">
          <div className="mb-secondary">
            {t('share.systemUsageTimePriority')}
          </div>
          <Row>
            <Col md={{ span: 20 }} className="bg-primary mb-primary">
              {t('share.dayOfWeek')}
            </Col>
          </Row>
          <Form.Item
            name="systemUtilizationTimes"
            hidden
            rules={[
              () => ({
                validator(_, value) {
                  let isPass = true;
                  value.forEach((item) => {
                    delete item.message;
                    if (item.priorityFlag === PRIORITY_FLAG.Calendar) {
                      // 画面入力チェック：日付入力がない場合
                      if (!item.designatedDate || item.designatedDate === '') {
                        item.message = t('timeComponentInput.error1');
                        isPass = false;
                      } else if (
                        value.filter(
                          (ite) =>
                            ite.priorityFlag === PRIORITY_FLAG.Calendar &&
                            ite.designatedDate === item.designatedDate
                        ).length > 1
                      ) {
                        item.message = t('timeComponentInput.error2');
                        isPass = false;
                      }
                    }
                    if (!item.message && item.usagePattern === USE_TIME.Time) {
                      if (
                        (item.startTime?.toString() || '') === '' ||
                        (item.stopTime?.toString() || '') === ''
                      ) {
                        item.message = t('timeComponentInput.error3');
                        isPass = false;
                      } else if (
                        parseInt(item.startTime) === 0 &&
                        parseInt(item.stopTime) === 24
                      ) {
                        item.message = t('timeComponentInput.error4');
                        isPass = false;
                      } else if (item.startTime === item.stopTime) {
                        item.message = t('timeComponentInput.error5');
                        isPass = false;
                      } else if (
                        parseInt(item.startTime) > parseInt(item.stopTime)
                      ) {
                        item.message = t('timeComponentInput.error6');
                        isPass = false;
                      }
                    }
                  });

                  form.setFields([
                    {
                      name: 'systemUtilizationTimes',
                      value
                    }
                  ]);
                  forceUpdate();
                  if (isPass) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error());
                }
              })
            ]}
          />
          <div className="mb-primary box-dayofweek mt-10">
            <table>
              {[
                t('share.monday'),
                t('share.tuesday'),
                t('share.wednesday'),
                t('share.thursday'),
                t('share.friday'),
                t('share.saturday'),
                t('share.sunday')
              ].map((x, i) => (
                <>
                  <tr className="mb-secondary">
                    <td className="day-of-week">{x}</td>
                    <td>
                      <Space>
                        <span>:</span>
                        <Form.Item
                          name={['systemUtilizationTimes', i, 'usagePattern']}
                          className="mb-0"
                          initialValue={USE_TIME.AllDay}
                        >
                          <Radio.Group
                            onChange={() => {
                              if (
                                form.getFieldValue([
                                  'systemUtilizationTimes',
                                  i,
                                  'usagePattern'
                                ]) !== USE_TIME.Time
                              ) {
                                form.setFields([
                                  {
                                    name: [
                                      'systemUtilizationTimes',
                                      i,
                                      'startTime'
                                    ],
                                    value: undefined
                                  },
                                  {
                                    name: [
                                      'systemUtilizationTimes',
                                      i,
                                      'stopTime'
                                    ],
                                    value: undefined
                                  }
                                ]);
                              }
                              forceUpdate();
                            }}
                          >
                            <Radio value={USE_TIME.AllDay}>
                              {t('share.useAllDay')}
                            </Radio>
                            <Radio value={USE_TIME.NotUse}>
                              {t('share.notAvailableAllDay')}
                            </Radio>
                            <Radio value={USE_TIME.Time}>
                              {t('share.specifyTime')}
                            </Radio>
                          </Radio.Group>
                        </Form.Item>
                        <div className="start-time">
                          <div className="text-center col-title" hidden={i > 0}>
                            {t('share.start')}
                          </div>
                          <Form.Item
                            name={['systemUtilizationTimes', i, 'startTime']}
                            className="mb-0"
                          >
                            <Select
                              style={{ width: '80px' }}
                              disabled={
                                form.getFieldValue([
                                  'systemUtilizationTimes',
                                  i,
                                  'usagePattern'
                                ]) !== USE_TIME.Time
                              }
                              options={hours}
                            />
                          </Form.Item>
                        </div>

                        <span>~</span>
                        <div className="stop-time">
                          <div className="text-center col-title" hidden={i > 0}>
                            {t('share.stop')}
                          </div>
                          <Form.Item
                            name={['systemUtilizationTimes', i, 'stopTime']}
                            className="mb-0"
                          >
                            <Select
                              style={{ width: '80px' }}
                              disabled={
                                form.getFieldValue([
                                  'systemUtilizationTimes',
                                  i,
                                  'usagePattern'
                                ]) !== USE_TIME.Time
                              }
                              options={hours}
                            />
                          </Form.Item>
                        </div>
                        <Form.Item
                          hidden
                          name={['systemUtilizationTimes', i, 'priorityFlag']}
                          initialValue={PRIORITY_FLAG.DayOfWeek}
                        />
                        <Form.Item
                          hidden
                          name={['systemUtilizationTimes', i, 'dayOfWeekId']}
                          initialValue={i + 1}
                        />
                      </Space>
                    </td>
                  </tr>
                  <tr className="ant-form-item-explain-error">
                    {form.getFieldValue([
                      'systemUtilizationTimes',
                      i,
                      'message'
                    ])}
                  </tr>
                </>
              ))}
            </table>
          </div>
          <Form.List name="systemUtilizationTimes">
            {(fields, { add, remove }) => (
              <>
                <Row className="mb-primary">
                  <Col md={{ span: 20 }} className="bg-primary">
                    {t('planNew.calendarDesign')}
                  </Col>
                  <Col md={{ span: 4 }} className="text-center">
                    <button
                      type="button"
                      onClick={() => add(null, fields.length + 7)}
                      className="btn-secondary bg-info cursor-pointer"
                    >
                      {t('planNew.add')}
                    </button>
                  </Col>
                </Row>
                <div className="list-row-new  pt-10">
                  {fields
                    .filter((field) => field.name > 6)
                    .map((field, idx) => (
                      <>
                        <Row className="row-item-design">
                          <Col md={{ span: 20 }}>
                            <Space size={10}>
                              <Form.Item
                                name={[field.name, 'designatedDate']}
                                className="mb-0"
                              >
                                <div></div>
                                <DatePicker
                                  value={
                                    form.getFieldValue([
                                      'systemUtilizationTimes',
                                      field.name,
                                      'designatedDate'
                                    ])
                                      ? moment(
                                          form.getFieldValue([
                                            'systemUtilizationTimes',
                                            field.name,
                                            'designatedDate'
                                          ]),
                                          DATE_FORMAT
                                        )
                                      : undefined
                                  }
                                  format={DATE_FORMAT}
                                  onChange={(date) => {
                                    form.setFields([
                                      {
                                        name: [
                                          'systemUtilizationTimes',
                                          field.name,
                                          'designatedDate'
                                        ],
                                        value: date.format(DATE_FORMAT)
                                      }
                                    ]);
                                    forceUpdate();
                                  }}
                                />
                              </Form.Item>

                              <Form.Item
                                name={[field.name, 'usagePattern']}
                                className="mb-0"
                                initialValue={USE_TIME.AllDay}
                              >
                                <Radio.Group
                                  onChange={() => {
                                    if (
                                      form.getFieldValue([
                                        'systemUtilizationTimes',
                                        field.name,
                                        'usagePattern'
                                      ]) !== USE_TIME.Time
                                    ) {
                                      form.setFields([
                                        {
                                          name: [
                                            'systemUtilizationTimes',
                                            field.name,
                                            'startTime'
                                          ],
                                          value: undefined
                                        },
                                        {
                                          name: [
                                            'systemUtilizationTimes',
                                            field.name,
                                            'stopTime'
                                          ],
                                          value: undefined
                                        }
                                      ]);
                                    }
                                    forceUpdate();
                                  }}
                                >
                                  <Radio value={USE_TIME.AllDay}>
                                    {t('share.useAllDay')}
                                  </Radio>
                                  <Radio value={USE_TIME.NotUse}>
                                    {t('share.notAvailableAllDay')}
                                  </Radio>
                                  <Radio value={USE_TIME.Time}>
                                    {t('share.specifyTime')}
                                  </Radio>
                                </Radio.Group>
                              </Form.Item>
                              <div className="start-time">
                                <div
                                  className="text-center col-title"
                                  hidden={idx > 0}
                                >
                                  {t('share.start')}
                                </div>
                                <Form.Item
                                  name={[field.name, 'startTime']}
                                  className="mb-0"
                                >
                                  <Select
                                    disabled={
                                      form.getFieldValue([
                                        'systemUtilizationTimes',
                                        field.name,
                                        'usagePattern'
                                      ]) !== USE_TIME.Time
                                    }
                                    style={{ width: '80px' }}
                                    options={hours}
                                  />
                                </Form.Item>
                              </div>
                              <span>~</span>
                              <div className="stop-time">
                                <div
                                  className="text-center col-title"
                                  hidden={idx > 0}
                                >
                                  {t('share.stop')}
                                </div>
                                <Form.Item
                                  name={[field.name, 'stopTime']}
                                  className="mb-0"
                                >
                                  <Select
                                    disabled={
                                      form.getFieldValue([
                                        'systemUtilizationTimes',
                                        field.name,
                                        'usagePattern'
                                      ]) !== USE_TIME.Time
                                    }
                                    style={{ width: '80px' }}
                                    options={hours}
                                  />
                                </Form.Item>
                              </div>
                              <Form.Item
                                hidden
                                name={[field.name, 'priorityFlag']}
                                initialValue={PRIORITY_FLAG.Calendar}
                              />
                            </Space>
                          </Col>
                          <Col md={{ span: 4 }} className="text-center">
                            <button
                              onClick={() => remove(field.name)}
                              className="btn-secondary bg-info cursor-pointer"
                            >
                              {t('planNew.delete')}
                            </button>
                          </Col>
                        </Row>
                        <div className="ant-form-item-explain-error">
                          {form.getFieldValue([
                            'systemUtilizationTimes',
                            field.name,
                            'message'
                          ])}
                        </div>
                      </>
                    ))}
                </div>
              </>
            )}
          </Form.List>
        </Col>
      </Row>
    </div>
  );
}
export default TimeComponentInput;
