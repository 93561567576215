import Header from '../components/Header';
import { Row, Col, Form, Button, Input } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useTranslation } from 'react-i18next';

function RegisterConfirm() {
  const { state } = useLocation();
  const navigate = useNavigate();
  const countryFlag = state.values['countryFlag'];
  const { t } = useTranslation();

  const accObject = state.values;

  //会社住所が「日本」の場合、「日本」が選択された状態で変更不可にする。
  if (countryFlag === 1) {
    state.values['country'] = 'JP';
    //都道府県 + 市区町村 + 番地1 + 番地2
    state.values['address'] =
      state.values['prefectures'] +
      ' ' +
      state.values['municipalities'] +
      ' ' +
      state.values['address1'] +
      state.values['address2'];
  } else {
    //番地1 + 番地2（オプション）＋市＋地域/州
    state.values['address'] =
      state.values['address1'] +
      ' ' +
      state.values['address2'] +
      ' ' +
      state.values['city'] +
      ' ' +
      state.values['region'];
  }
  state.values['accountName'] =
    state.values['firstName'] + ' ' + state.values['lastName'];
  state.values['kana'] =
    state.values['kanaFirstName'] + ' ' + state.values['kanaLastName'];
  return (
    <div>
      <Header />
      <div className="box register-confirm">
        <Form
          onFinish={() => {
            axios({
              method: 'post',
              url: '/api/registerAccount?token=' + state.values['token'],
              data: state.values
            }).then(() => {
              //新規登録完了画面に遷移する。
              navigate('/registerCompletion');
            });
          }}
        >
          <div className="box-register box-register-confirm">
            <div className="box-title">{t('registerConfirm.title')}</div>
            <div className="box-body">
              <div className="frame">
                <Form.Item>
                  <Row>
                    <Col md={{ span: 8 }} xs={{ span: 11 }}>
                      E-mail：
                    </Col>
                    <Col md={{ span: 16 }} xs={{ span: 13 }}>
                      {state.values['mail']}
                    </Col>
                  </Row>
                </Form.Item>
                <Form.Item>
                  <Row>
                    <Col md={{ span: 8 }} xs={{ span: 11 }}>
                      {t('share.surnameName')}：
                    </Col>
                    <Col md={{ span: 16 }} xs={{ span: 13 }}>
                      {state.values['firstName']}
                    </Col>
                  </Row>
                </Form.Item>
                <Form.Item>
                  <Row>
                    <Col md={{ span: 8 }} xs={{ span: 11 }}>
                      {t('share.Name')}：
                    </Col>
                    <Col md={{ span: 16 }} xs={{ span: 13 }}>
                      {state.values['lastName']}
                    </Col>
                  </Row>
                </Form.Item>
                <Form.Item>
                  <Row>
                    <Col md={{ span: 8 }} xs={{ span: 11 }}>
                      {t('share.kanaSurname')}
                    </Col>
                    <Col md={{ span: 16 }} xs={{ span: 13 }}>
                      {state.values['kanaFirstName']}
                    </Col>
                  </Row>
                </Form.Item>
                <Form.Item>
                  <Row>
                    <Col md={{ span: 8 }} xs={{ span: 11 }}>
                      {t('share.kanaName')}
                    </Col>
                    <Col md={{ span: 16 }} xs={{ span: 13 }}>
                      {state.values['kanaLastName']}
                    </Col>
                  </Row>
                </Form.Item>
                <Form.Item>
                  <Row>
                    <Col md={{ span: 8 }} xs={{ span: 11 }}>
                      {t('share.companyName')}：
                    </Col>
                    <Col md={{ span: 16 }} xs={{ span: 13 }}>
                      {state.values['companyName']}
                    </Col>
                  </Row>
                </Form.Item>
                <Form.Item>
                  <Row>
                    <Col md={{ span: 8 }} xs={{ span: 11 }}>
                      {t('share.divisionName')}：
                    </Col>
                    <Col md={{ span: 16 }} xs={{ span: 13 }}>
                      {state.values['divisionName']}
                    </Col>
                  </Row>
                </Form.Item>

                {countryFlag === 2 && (
                  <>
                    <Form.Item>
                      <Row>
                        <Col md={{ span: 8 }} xs={{ span: 11 }}>
                          {t('share.address1')}：
                        </Col>
                        <Col md={{ span: 16 }} xs={{ span: 13 }}>
                          {state.values['address1']}
                        </Col>
                      </Row>
                    </Form.Item>
                    <Form.Item>
                      <Row>
                        <Col md={{ span: 8 }} xs={{ span: 11 }}>
                          {t('share.address2Option')}
                        </Col>
                        <Col md={{ span: 16 }} xs={{ span: 13 }}>
                          {state.values['address2']}
                        </Col>
                      </Row>
                    </Form.Item>
                    <Form.Item>
                      <Row>
                        <Col md={{ span: 8 }} xs={{ span: 11 }}>
                          {t('share.city')}：
                        </Col>
                        <Col md={{ span: 16 }} xs={{ span: 13 }}>
                          {state.values['city']}
                        </Col>
                      </Row>
                    </Form.Item>

                    <Form.Item>
                      <Row>
                        <Col md={{ span: 8 }} xs={{ span: 11 }}>
                          {t('share.region')}：
                        </Col>
                        <Col md={{ span: 16 }} xs={{ span: 13 }}>
                          {state.values['region']}
                        </Col>
                      </Row>
                    </Form.Item>

                    <Form.Item>
                      <Row>
                        <Col md={{ span: 8 }} xs={{ span: 11 }}>
                          {t('share.postalCode')}：
                        </Col>
                        <Col md={{ span: 16 }} xs={{ span: 13 }}>
                          {state.values['postalCode']}
                        </Col>
                      </Row>
                    </Form.Item>

                    <Form.Item>
                      <Row>
                        <Col md={{ span: 8 }} xs={{ span: 11 }}>
                          {t('share.country')}：
                        </Col>
                        <Col md={{ span: 16 }} xs={{ span: 13 }}>
                          {state.values['countryDisplayValue']}
                        </Col>
                      </Row>
                    </Form.Item>
                  </>
                )}

                {countryFlag === 1 && (
                  <>
                    <Form.Item>
                      <Row>
                        <Col md={{ span: 8 }} xs={{ span: 11 }}>
                          {t('share.country')}：
                        </Col>
                        <Col md={{ span: 16 }} xs={{ span: 13 }}>
                          {state.values['countryDisplayValue']}
                        </Col>
                      </Row>
                    </Form.Item>
                    <Form.Item>
                      <Row>
                        <Col md={{ span: 8 }} xs={{ span: 11 }}>
                          {t('share.postalCode')}：
                        </Col>
                        <Col md={{ span: 16 }} xs={{ span: 13 }}>
                          {state.values['postalCode']}
                        </Col>
                      </Row>
                    </Form.Item>
                    <Form.Item>
                      <Row>
                        <Col md={{ span: 8 }} xs={{ span: 11 }}>
                          {t('share.prefectures')}：
                        </Col>
                        <Col md={{ span: 16 }} xs={{ span: 13 }}>
                          {state.values['prefectures']}
                        </Col>
                      </Row>
                    </Form.Item>
                    <Form.Item>
                      <Row>
                        <Col md={{ span: 8 }} xs={{ span: 11 }}>
                          {t('share.municipalities')}：
                        </Col>
                        <Col md={{ span: 16 }} xs={{ span: 13 }}>
                          {state.values['municipalities']}
                        </Col>
                      </Row>
                    </Form.Item>
                    <Form.Item>
                      <Row>
                        <Col md={{ span: 8 }} xs={{ span: 11 }}>
                          {t('share.address1')}：
                        </Col>
                        <Col md={{ span: 16 }} xs={{ span: 13 }}>
                          {state.values['address1']}
                        </Col>
                      </Row>
                    </Form.Item>
                    <Form.Item>
                      <Row>
                        <Col md={{ span: 8 }} xs={{ span: 11 }}>
                          {t('share.address2')}：
                        </Col>
                        <Col md={{ span: 16 }} xs={{ span: 13 }}>
                          {state.values['address2']}
                        </Col>
                      </Row>
                    </Form.Item>
                  </>
                )}

                <Form.Item>
                  <Row>
                    <Col md={{ span: 8 }} xs={{ span: 11 }}>
                      {t('share.phoneNumber')}：
                    </Col>
                    <Col md={{ span: 16 }} xs={{ span: 13 }}>
                      {state.values['phoneNumber']}
                    </Col>
                  </Row>
                </Form.Item>
                <Form.Item>
                  <Row>
                    <Col md={{ span: 8 }} xs={{ span: 11 }}>
                      {t('register.password')}：
                    </Col>
                    <Col md={{ span: 16 }} xs={{ span: 13 }}>
                      <Input.Password
                        defaultValue={state.values['password']}
                        readOnly
                        bordered={false}
                        addonAfter={''}
                        className="txtShowPass"
                      />
                    </Col>
                  </Row>
                </Form.Item>
              </div>
            </div>
          </div>
          <Form.Item>
            <Row>
              <Col span={12}>
                <Button
                  className="btn-primary"
                  onClick={() => {
                    navigate('/register', { state: { accObject } });
                  }}
                >
                  {t('registerConfirm.correctInput')}
                </Button>
              </Col>
              <Col span={12} className="text-right">
                {' '}
                <button
                  type="submit"
                  style={{ margin: '0 8px' }}
                  className="btn-primary cursor-pointer"
                >
                  {t('registerConfirm.register')}
                </button>
              </Col>
            </Row>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
}
export default RegisterConfirm;
