import React from 'react';
import { Col } from 'antd';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Header from '../components/Header';
import t from '../translate';

function RegisterEmailCompletion() {
  const location = useLocation();
  let navigate = useNavigate();

  if (
    location.state === undefined ||
    location.state === null ||
    location.state === ''
  ) {
    navigate('/invalidUrl');
  }

  return (
    <React.Fragment>
      <Header />
      <div
        className="container-custom modal-primary box-register-completion"
        gutter={16}
      >
        <div className="box-title">{t('registerEmailCompletion.title')}</div>
        <div className="box-body">
          <div className="ant-form-item description">
            {t('registerEmailCompletion.description')}
          </div>
        </div>
        <Col offset={10}>
          <Link to="/login" className="link mb-primary">
            {t('returnToLogin')}
          </Link>
        </Col>
      </div>
    </React.Fragment>
  );
}

export default RegisterEmailCompletion;
