/**
 * (C) 2022 DTS CORPORATION
 */
import React, { useEffect, useState } from 'react';
import '../../i18n';
import { Row, Col, Form, Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import Header from '../../components/Header';
import FooterComponent from '../../components/FooterComponent';
import UsageSituationComponent from '../../components/usageInquiryComponents/UsageSituationComponent';
import axios from 'axios';
import moment from 'moment';

const DATE_FORMAT_CUSTOM = 'YYYY/MM';

/**
 * 利用状況
 */
function UsageSituationPast() {
  const { t } = useTranslation();
  const { state } = useLocation();
  const [data, setData] = useState();

  const date = moment(state.values.preUsage).format(DATE_FORMAT_CUSTOM);

  useEffect(() => {
    axios
      .post('/api/getPreviousUsage', {
        planId: state.values.planId,
        previousDate: state.values.preUsage
      })
      .then((res) => {
        const { data } = res;
        setData(data);
      });
  }, []);

  return (
    <>
      <Header />
      <div className="white-space"></div>
      <div className="container-custom-primary usage-inquiry">
        <Row className="title mb-primary">
          <Col md={{ span: 4 }} className="page-title">
            {t('usageInquiry.title')}
          </Col>
        </Row>
        <div className="usage-plan mb-primary">
          <Col className="title-sub color-primary">
            {t('usageInquiry.usagePlan')}
          </Col>
          <Col md={{ span: 9 }} className="border-default ml-15">
            <Row>
              <Col className="bg-primary border-right-default pl-5 col-left-lbl">
                {t('share.planId')}
              </Col>
              <Col>
                <Form.Item name="planDetail.planId" className="mb-0 pl-15">
                  {state.values.planId}
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </div>
        <UsageSituationComponent
          date={date}
          data={data}
          componentName="UsageSituationPast"
        />
        <div className="line-primary"></div>
        <Link
          to={{
            pathname: '/usageInquiry'
          }}
          state={{ values: state.values }}
        >
          <Button className="btn-return">{t('share.return')}</Button>
        </Link>
        <FooterComponent />
      </div>
    </>
  );
}
export default UsageSituationPast;
