/**
 * (C) 2022 DTS CORPORATION
 */
import React, { useEffect, useState } from 'react';
import '../../i18n';
import { Row, Col, Form, Space, Button } from 'antd';
import Header from '../../components/Header';
import FooterComponent from '../../components/FooterComponent';
import UsageFeeComponent from '../../components/usageInquiryComponents/UsageFeeComponent';
import { Link, useLocation } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment';
import t from '../../translate';

const DATE_FORMAT_CUSTOM = 'YYYY/MM';

/**
 * 利用料金
 */
function UsageFeePast() {
  const { state } = useLocation();
  const [data, setData] = useState();
  const date = moment(state.values.preUsage).format(DATE_FORMAT_CUSTOM);

  useEffect(() => {
    axios
      .post('/api/getUsageFee', {
        planId: state.values.planId,
        previousDate: state.values.preUsage
      })
      .then((res) => {
        const { data } = res;
        setData(data);
      });
  }, []);

  return (
    <>
      <Header />
      <div className="white-space"></div>
      <div className="container-custom-primary usage-inquiry">
        <Row className="title mb-primary">
          <Col md={{ span: 4 }} className="page-title">
            {t('usageInquiry.title')}
          </Col>
        </Row>
        <div className="usage-plan mb-primary">
          <Col className="title-sub color-primary">
            {t('usageInquiry.usagePlan')}
          </Col>
          <Col md={{ span: 9 }} className="border-default ml-15">
            <Row>
              <Col className="bg-primary border-right-default pl-5 col-left-lbl">
                {t('share.planId')}
              </Col>
              <Col>
                <Form.Item name="planDetail.planId" className="mb-0 pl-15">
                  {state.values.planId}
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </div>

        <div>
          <Row>
            <Col
              md={{ span: 19 }}
              className="title-sub color-primary mb-primary"
            >
              {t('usageInquiry.usageFee')}
            </Col>
            <Col md={{ span: 5 }}>
              <Link
                to={{
                  pathname: '/createReceipt'
                }}
                state={{ values: { ...state.values, usageFee: data } }}
              >
                <Button className="btn-dancer">
                  {t('usageInquiry.createReceipt')}
                </Button>
              </Link>
            </Col>
          </Row>
          <Space className="mb-primary ml-15">
            <div className="bold">{date}</div>
            <div className="bold">{t('usageInquiry.usageFeeOf')}</div>
          </Space>
        </div>
        <UsageFeeComponent
          date={date}
          data={data}
          componentName="UsageFeePast"
        />
        <div className="white-space"></div>
        <div className="line-primary"></div>
        <Link
          to={{
            pathname: '/usageInquiry'
          }}
          state={{ values: state.values }}
        >
          <Button className="btn-return">{t('share.return')}</Button>
        </Link>
        <FooterComponent />
      </div>
    </>
  );
}
export default UsageFeePast;
