/**
 * (C) 2022 DTS CORPORATION
 */
import EmailRegister from '../pages/EmailRegister';
import Home from '../pages/Home';
import Login from '../pages/Login';
import Register from '../pages/Register';
import RegisterEmailCompletion from '../pages/RegisterEmailCompletion';
import RegisterConfirm from '../pages/RegisterConfirm';
import InvalidUrl from '../pages/InvalidUrl';
import RegisterCompletion from '../pages/RegisterCompletion';
import PasswordReissue from '../pages/PasswordReissue';
import PasswordReissueCompleted from '../pages/PasswordReissueCompleted';
import PasswordChange from '../pages/PasswordChange';
import PasswordChangeComplete from '../pages/PasswordChangeComplete';
import Profile from '../pages/Profile';
import ProfileChangeComplete from '../pages/ProfileChangeComplete';
import EmailChange from '../pages/EmailChange';
import EmailChangeComplete from '../pages/EmailChangeComplete';
import EmailAddressChangeReception from '../pages/EmailAddressChangeReception';
import ProfileChange from '../pages/ProfileChange';
import ProfileChangeConfirm from '../pages/ProfileChangeConfirm';
import PlanInput from '../pages/PlanInput';
import PlanConfirm from '../pages/PlanConfirm';
import FreeSimulation from '../pages/FreeSimulation';
import PlanDetail from '../pages/PlanDetail';
import QuoteMake from '../pages/QuoteMake';
import PaymentInformationRegistration from '../pages/PaymentInformationRegistration';
import PlanChangeApplicationInput from '../pages/PlanChangeApplicationInput';
import PlanChangeApplicationConfirm from '../pages/PlanChangeApplicationConfirm';
import PlanChangeApplicationFeeSimulation from '../pages/PlanChangeApplicationFeeSimulation';
import ApplicationSettingStartDate from '../pages/ApplicationSettingStartDate';
import ChangeApplicationSettingStartDate from '../pages/ChangeApplicationSettingStartDate';
import ChangeApplicationConfirm from '../pages/ChangeApplicationConfirm';
import ChangeApplicationCompletion from '../pages/ChangeApplicationCompletion';
import ApplicationConfirm from '../pages/ApplicationConfirm';
import ApplicationCompletion from '../pages/ApplicationCompletion';
import OptionChangeInput from '../pages/OptionChangeInput';
import OptionChangeConfirm from '../pages/OptionChangeConfirm';
import OptionChangeCompletion from '../pages/OptionChangeCompletion';
import ChangeSystemUsageTime from '../pages/ChangeSystemUsageTime';
import AdministratorAdd from '../pages/AdministratorAdd';
import AdministratorConfirm from '../pages/AdministratorConfirm';
import AdministratorCompletion from '../pages/AdministratorCompletion';
import ChangeSystemUsageTimeConfirm from '../pages/ChangeSystemUsageTimeConfirm';
import OtherProceduresStopOrCancel from '../pages/OtherProceduresStopOrCancel';
import TemporarySuspensionOrCancellationSuspensionInput from '../pages/TemporarySuspensionOrCancellationSuspensionInput';
import TemporarySuspensionOrCancellationSuspensionConfirm from '../pages/TemporarySuspensionOrCancellationSuspensionConfirm';
import TemporarySuspensionOrCancellationSuspensionCompletion from '../pages/TemporarySuspensionOrCancellationSuspensionCompletion';
import CancellationOfReservationInput from '../pages/CancellationOfReservationInput';
import CancellationOfReservationConfirm from '../pages/CancellationOfReservationConfirm';
import CancellationOfReservationCompletion from '../pages/CancellationOfReservationCompletion';
import ChangeSystemUsageTimeCompletion from '../pages/ChangeSystemUsageTimeCompletion';
import CampaignCodeAddedInput from '../pages/CampaignCodeAddedInput';
import CampaignCodeAddedConfirm from '../pages/CampaignCodeAddedConfirm';
import CampaignCodeAddedCompletion from '../pages/CampaignCodeAddedCompletion';
import UsageInquiry from '../pages/usageInquiry/UsageInquiry';
import UsageFeePast from '../pages/usageInquiry/UsageFeePast';
import UsageSituationPast from '../pages/usageInquiry/UsageSituationPast';
import CreateReceipt from '../pages/usageInquiry/CreateReceipt';
import PaymentRegistration from '../pages/PaymentRegistration';
import CannotCreditCardChange from '../pages/CannotCreditCardChange';
import CreditCardChangeComplete from '../pages/CreditCardChangeComplete';
import CreditCardInformationInquiry from '../pages/CreditCardInformationInquiry';
import CreditCardChange from '../pages/CreditCardChange';
import CardChangeComplete from '../pages/CardChangeComplete';
import SecuritysettingsReference from '../pages/SecuritysettingsReference';
import SecuritysettingsInput from '../pages/SecuritysettingsInput';
import SecuritysettingsConfirm from '../pages/SecuritysettingsConfirm';
import SecuritySettingsComplete from '../pages/SecuritySettingsComplete';
import Training from '../pages/Training';
import TrainingList from '../pages/TrainingList';
import BackupSelect from '../pages/BackupSelect';
import BackupConfirm from '../pages/BackupConfirm';
import BackupCompletion from '../pages/BackupCompletion';
import VPNSettingsInput from '../pages/VPNSettingsInput';
import VPNSettingsConfirm from '../pages/VPNSettingsConfirm';
import VpnSettingsComplete from '../pages/VpnSettingsComplete';
import VpnSettingsReference from '../pages/VPNSettingsReference';

export const routes = [
  {
    //My page画面
    path: '/',
    Component: Home,
    isLogin: true
  },
  {
    //メールアドレス登録画面
    path: '/emailRegister',
    Component: EmailRegister
  },
  {
    //メールアドレス登録完了画面
    path: '/registerEmailCompletion',
    Component: RegisterEmailCompletion
  },
  {
    //ログイン画面
    path: '/login',
    Component: Login
  },
  {
    //新規登録画面
    path: '/register',
    Component: Register
  },
  {
    path: '/registerConfirm',
    Component: RegisterConfirm
  },
  {
    //URL無効画面
    path: '/invalidUrl',
    Component: InvalidUrl
  },
  {
    //新規登録完了画面
    path: '/registerCompletion',
    Component: RegisterCompletion
  },
  {
    //パスワード再発行画面
    path: '/passwordReissue',
    Component: PasswordReissue
  },
  {
    //パスワード再発行完了画面
    path: '/passwordReissueCompleted',
    Component: PasswordReissueCompleted
  },
  {
    //パスワード変更画面
    path: '/passwordChange',
    isLogin: true,
    Component: PasswordChange
  },
  {
    //パスワード変更完了画面
    path: '/passwordChangeComplete',
    isLogin: true,
    Component: PasswordChangeComplete
  },
  {
    //プロフィール画面
    path: '/profile',
    isLogin: true,
    Component: Profile
  },
  {
    //プロフィール変更画面
    path: '/profileChange',
    isLogin: true,
    Component: ProfileChange
  },
  {
    //プロフィール変更内容確認画面
    path: '/profileChangeConfirm',
    isLogin: true,
    Component: ProfileChangeConfirm
  },
  {
    //プロフィール変更完了画面
    path: '/profileChangeComplete',
    isLogin: true,
    Component: ProfileChangeComplete
  },
  {
    //メールアドレス変更画面
    path: '/emailChange',
    isLogin: true,
    Component: EmailChange
  },
  {
    //メールアドレス変更完了画面
    path: '/emailChangeComplete',
    Component: EmailChangeComplete
  },
  {
    //メールアドレス変更受付画面
    path: '/emailAddressChangeReception',
    isLogin: true,
    Component: EmailAddressChangeReception
  },
  {
    //新規プラン登録（入力）
    path: '/planInput',
    isLogin: true,
    Component: PlanInput
  },
  {
    //新規申込 (確認)
    path: '/planConfirm',
    isLogin: true,
    Component: PlanConfirm
  },
  {
    //新規申込 (料金シミュレーション)
    path: '/freeSimulation',
    isLogin: true,
    Component: FreeSimulation
  },
  {
    //プラン詳細
    path: '/planDetail',
    isLogin: true,
    Component: PlanDetail
  },
  {
    //見積書作成
    path: '/quoteMake',
    isLogin: true,
    Component: QuoteMake
  },
  {
    //申込（決済情報登録）
    path: '/paymentInformationRegistration',
    isLogin: true,
    Component: PaymentInformationRegistration
  },
  {
    //申込（利用開始日設定）
    path: '/applicationSettingStartDate',
    isLogin: true,
    Component: ApplicationSettingStartDate
  },
  {
    //変更申込（利用開始日設定）
    path: '/changeApplicationSettingStartDate',
    isLogin: true,
    Component: ChangeApplicationSettingStartDate
  },
  {
    //申込（確認）
    path: '/applicationConfirm',
    isLogin: true,
    Component: ApplicationConfirm
  },
  {
    //申込（完了）
    path: '/applicationCompletion',
    isLogin: true,
    Component: ApplicationCompletion
  },
  {
    //変更申込（確認）
    path: '/changeApplicationConfirm',
    isLogin: true,
    Component: ChangeApplicationConfirm
  },
  {
    //変更申込（完了）
    path: '/changeApplicationCompletion',
    isLogin: true,
    Component: ChangeApplicationCompletion
  },
  {
    //プラン変更申込（入力） // update plan
    path: '/planChangeApplicationInput',
    isLogin: true,
    Component: PlanChangeApplicationInput
  },
  {
    //プラン変更申込 (確認)
    path: '/planChangeApplicationConfirm',
    isLogin: true,
    Component: PlanChangeApplicationConfirm
  },
  {
    //プラン変更申込 (料金シミュレーション)
    path: '/planChangeApplicationFeeSimulation',
    isLogin: true,
    Component: PlanChangeApplicationFeeSimulation
  },
  {
    //オプション変更
    path: '/optionChangeInput',
    isLogin: true,
    Component: OptionChangeInput
  },
  {
    //オプション変更
    path: '/optionChangeConfirm',
    isLogin: true,
    Component: OptionChangeConfirm
  },
  {
    //オプション完了
    path: '/optionChangeCompletion',
    isLogin: true,
    Component: OptionChangeCompletion
  },
  {
    //システム利用時間変更 (入力)
    path: '/changeSystemUsageTime',
    isLogin: true,
    Component: ChangeSystemUsageTime
  },
  {
    //システム利用時間変更 (確認)
    path: '/changeSystemUsageTimeConfirm',
    isLogin: true,
    Component: ChangeSystemUsageTimeConfirm
  },
  {
    //システム利用時間変更
    path: '/changeSystemUsageTimeCompletion',
    isLogin: true,
    Component: ChangeSystemUsageTimeCompletion
  },
  {
    //管理者追加・削除
    path: '/administratorAdd',
    isLogin: true,
    Component: AdministratorAdd
  },
  {
    //管理者追加・削除
    path: '/administratorConfirm',
    isLogin: true,
    Component: AdministratorConfirm
  },
  {
    //管理者追加・削除
    path: '/administratorCompletion',
    isLogin: true,
    Component: AdministratorCompletion
  },
  {
    //その他お手続き①停止・解除
    path: '/otherProceduresStopOrCancel',
    isLogin: true,
    Component: OtherProceduresStopOrCancel
  },
  {
    //[一時利用停止／停止解除のお手続き]
    path: '/temporarySuspensionOrCancellationSuspensionInput',
    isLogin: true,
    Component: TemporarySuspensionOrCancellationSuspensionInput
  },
  {
    //[一時利用停止／停止解除のお手続き]
    path: '/temporarySuspensionOrCancellationSuspensionConfirm',
    isLogin: true,
    Component: TemporarySuspensionOrCancellationSuspensionConfirm
  },
  {
    //[一時利用停止／停止解除のお手続き]
    path: '/temporarySuspensionOrCancellationSuspensionCompletion',
    isLogin: true,
    Component: TemporarySuspensionOrCancellationSuspensionCompletion
  },
  {
    //その他お手続き②予約キャンセル
    path: '/cancellationOfReservationInput',
    isLogin: true,
    Component: CancellationOfReservationInput
  },
  {
    //その他お手続き②予約キャンセル
    path: '/cancellationOfReservationConfirm',
    isLogin: true,
    Component: CancellationOfReservationConfirm
  },
  {
    //その他お手続き②予約キャンセル
    path: '/cancellationOfReservationCompletion',
    isLogin: true,
    Component: CancellationOfReservationCompletion
  },
  {
    //キャンペーンコード追加
    path: '/campaignCodeAddedInput',
    isLogin: true,
    Component: CampaignCodeAddedInput
  },
  {
    //キャンペーンコード追加
    path: '/campaignCodeAddedConfirm',
    isLogin: true,
    Component: CampaignCodeAddedConfirm
  },
  {
    //キャンペーンコード追加
    path: '/campaignCodeAddedCompletion',
    isLogin: true,
    Component: CampaignCodeAddedCompletion
  },
  {
    //利用状況照会
    path: '/usageInquiry',
    isLogin: true,
    Component: UsageInquiry
  },
  {
    //利用料金
    path: '/usageFeePast',
    isLogin: true,
    Component: UsageFeePast
  },
  {
    //利用状況
    path: '/usageSituationPast',
    isLogin: true,
    Component: UsageSituationPast
  },
  {
    //領収書作成
    path: '/createReceipt',
    isLogin: true,
    Component: CreateReceipt
  },
  {
    //決済情報_登録・変更照会・削除
    path: '/paymentRegistration',
    isLogin: true,
    Component: PaymentRegistration
  },
  {
    //クレジットカード変更
    path: '/cannotCreditCardChange',
    isLogin: true,
    Component: CannotCreditCardChange
  },
  {
    //
    path: '/creditCardChangeComplete',
    isLogin: true,
    Component: CreditCardChangeComplete
  },
  {
    path: '/creditCardInformationInquiry',
    isLogin: true,
    Component: CreditCardInformationInquiry
  },
  {
    path: '/creditCardChange',
    isLogin: true,
    Component: CreditCardChange
  },
  {
    path: '/cardChangeComplete',
    isLogin: true,
    Component: CardChangeComplete
  },
  //[セキュリティ設定（照会）]
  {
    path: '/securitysettingsReference',
    isLogin: true,
    Component: SecuritysettingsReference
  },
  //[セキュリティ設定（入力）]
  {
    path: '/securitysettingsInput',
    isLogin: true,
    Component: SecuritysettingsInput
  },
  //[セキュリティ設定（確認）]
  {
    path: '/securitysettingsConfirm',
    isLogin: true,
    Component: SecuritysettingsConfirm
  },
  //[セキュリティ設定（完了）]
  {
    path: '/securitySettingsComplete',
    isLogin: true,
    Component: SecuritySettingsComplete
  },
  //セルフトレーニング コンテンツ編集画面
  {
    path: '/training',
    isLogin: false,
    Component: Training
  },
  {
    path: '/trainingList',
    isLogin: false,
    Component: TrainingList
  },
  {
    path: '/backupSelect',
    isLogin: true,
    Component: BackupSelect
  },
  {
    path: '/backupConfirm',
    isLogin: true,
    Component: BackupConfirm
  },
  {
    path: '/backupCompletion',
    isLogin: true,
    Component: BackupCompletion
  },
  {
    path: '/vpnSettingsInput',
    isLogin: true,
    Component: VPNSettingsInput
  },
  {
    path: '/vpnSettingsConfirm',
    isLogin: true,
    Component: VPNSettingsConfirm
  },
  {
    path: '/vpnSettingsComplete',
    isLogin: true,
    Component: VpnSettingsComplete
  },
  {
    path: '/vpnSettingsReference',
    isLogin: true,
    Component: VpnSettingsReference
  }
];
