/**
 * (C) 2022 DTS CORPORATION
 */
import React from 'react';
import '../../i18n';
import { Row, Col } from 'antd';
import t from '../../translate';

function UsageFeeComponent(props) {
  return (
    <>
      <Row className="mb-primary">
        <Col md={{ span: 10 }} className="col-number">
          <Row className="mb-primary">
            <Col md={{ span: 15 }} className="number-primary text-right">
              <Col md={{ span: 24 }} className="bg-alert pr-15">
                {props?.data?.billingAmount}
              </Col>
            </Col>
            <Col md={{ span: 9 }} className="vertical-align-center">
              <span className="f20 ml-5">{t('freeSimulation.yen')}</span>
              <span className="mt-15">{t('freeSimulation.taxExcluded')}</span>
            </Col>
          </Row>
          <div className="bold">{t('usageInquiry.usageFeeNotCampaign')}</div>
          <Row className="mb-secondary">
            <Col md={{ span: 15 }}>
              <div className="number-secondary text-right">
                <Col md={{ span: 18 }} push={6} className="bg-alert pr-15">
                  {props?.data?.originalAmount}
                </Col>
              </div>
            </Col>
            <Col md={{ span: 9 }}>
              <span className="ml-5">{t('freeSimulation.yen')}</span>
            </Col>
          </Row>
          <Row>
            <div className="full-width bold">
              {t('freeSimulation.campaignApplicationAmount')}
            </div>
            <Col md={{ span: 15 }} className="number-secondary text-right">
              <Col md={{ span: 18 }} push={6} className="bg-alert pr-15">
                <span>{props?.data?.applicableAmount}</span>
              </Col>
            </Col>
            <Col md={{ span: 9 }} className="vertical-align-center">
              <span className="ml-5">{t('freeSimulation.yen')}</span>
              <span className="mt-15">{t('freeSimulation.taxExcluded')}</span>
            </Col>
          </Row>
        </Col>

        <Col md={{ span: 14 }}>
          {props?.componentName === 'UsageInquiry' && (
            <div className="note mb-primary">
              <div>{t('usageInquiry.note1')}</div>
              <div>{t('usageInquiry.note2')}</div>
            </div>
          )}

          <div className="title-secondary mb-primary">
            {t('freeSimulation.campaignApplicationDetails')}
          </div>
          <Row className="row-heading mb-secondary">
            <Col md={{ span: 12 }}>
              <strong>{t('share.campaignName')}</strong>
            </Col>
            <Col md={{ span: 6 }}>
              <strong>{t('share.applicablePeriod')}</strong>
            </Col>
            <Col md={{ span: 6 }}>
              <strong>{t('share.benefits')}</strong>
            </Col>
          </Row>
          {props?.data?.campaigns?.map((item) => (
            <Row className="mb-secondary">
              <Col md={{ span: 12 }}>{item.campaignName}</Col>
              <Col md={{ span: 6 }}>{item.campaignPeriod}</Col>
              <Col md={{ span: 6 }}>{item.campaignBenefits}</Col>
            </Row>
          ))}
        </Col>
      </Row>
    </>
  );
}
export default UsageFeeComponent;
