import React, { useEffect } from 'react';
import Header from '../components/Header';
import { useTranslation } from 'react-i18next';
import { Row, Col, Button } from 'antd';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import FooterComponent from '../components/FooterComponent';
function OtherProceduresStopOrCancel() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { state } = useLocation();

  useEffect(() => {
    const params = state.values;
    const planId = params.planId;
    const planBranchNum = params.planBranchNum;
    if (!planId || !planBranchNum) {
      navigate('/');
      return;
    }
  });
  return (
    <>
      <Header />
      <div className="white-space"></div>
      <div className="container-custom-primary">
        <div className="title-primary color-secondary">
          {t('share.otherProcedures')}
        </div>
        <div className="white-space"></div>
        <div className="ml-15">
          <Row className="mb-primary">
            <Col md={{ span: 8 }}>
              <Link
                to="/temporarySuspensionOrCancellationSuspensionInput"
                state={{ values: state.values }}
                className="text-underline bold"
              >
                {t('OtherProceduresStopOrCancel.link1')}
              </Link>
            </Col>
            <Col md={{ span: 16 }}>
              {t('OtherProceduresStopOrCancel.note1')} <br />
              {t('OtherProceduresStopOrCancel.note2')}
            </Col>
          </Row>
          <Row className="mb-primary">
            <Col md={{ span: 8 }}>
              <Link
                to="/cancellationOfReservationInput"
                state={{ values: state.values }}
                className="text-underline bold"
              >
                {t('OtherProceduresStopOrCancel.link2')}
              </Link>
            </Col>
            <Col md={{ span: 16 }}>
              {t('OtherProceduresStopOrCancel.note3')} <br />
              {t('OtherProceduresStopOrCancel.note4')}
            </Col>
          </Row>
          <Row className="mb-primary">
            <Col md={{ span: 8 }}>
              <Link
                to="/backupSelect"
                className="text-underline bold"
                state={{ values: state.values }}
              >
                {t('backup.link')}
              </Link>
            </Col>
            <Col md={{ span: 16 }}>
              {t('backup.des')} <br />
            </Col>
          </Row>
          <div className="bold">{t('OtherProceduresStopOrCancel.note5')}</div>
        </div>
        <div className="white-space"></div>
        <div className="line-primary"></div>
        <Button
          onClick={() => {
            navigate(
              `/planDetail?planId=${state.values.planId}&planBranchNum=${state.values.planBranchNum}`,
              {
                replace: true
              }
            );
          }}
          className="btn-return"
        >
          {t('share.return')}
        </Button>
      </div>
      <FooterComponent />
    </>
  );
}
export default OtherProceduresStopOrCancel;
