import React, { useEffect, useState } from 'react';
import '../i18n';
import {
  Row,
  Col,
  Breadcrumb,
  Radio,
  Space,
  DatePicker,
  Form,
  Checkbox,
  Button
} from 'antd';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import FooterComponent from '../components/FooterComponent';
import Header from '../components/Header';
import FormItem from 'antd/lib/form/FormItem';
import { DATE_FORMAT } from '../constant';
import moment from 'moment';
import { useForceUpdate } from '../utils/utils';
import axios from 'axios';

function ApplicationSettingStartDate() {
  const { t, i18n } = useTranslation();
  const [form] = Form.useForm();
  const { state } = useLocation();
  const navigate = useNavigate();
  const forceUpdate = useForceUpdate();
  const [termsOfService, setTermsOfService] = useState();
  const [values, setValues] = useState();
  const [showAlert, setShowAlert] = useState(false);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const planId = params.get('customer_id');
    const customerCardId = params.get('customer_card_id');
    const planBranchNum = params.get('planBranchNum');

    if (!state?.values?.planId && !planId) {
      navigate('/', { replace: true });
      return;
    }

    if (state) {
      setValues(state.values);
      form.setFieldsValue(state.values);
      forceUpdate();
    } else if (customerCardId) {
      resgiterCardAndGetData(planId, planBranchNum, customerCardId);
    }
  }, []);

  useEffect(() => {
    if (!i18n.language) return;
    getTermsOfService(i18n.language);
  }, [i18n.language]);

  const getTermsOfService = (language) => {
    axios({
      method: 'get',
      url: '/api/getTermsOfService',
      params: {
        language: language
      }
    }).then((res) => {
      setTermsOfService(res.data);
    });
  };

  const resgiterCardAndGetData = (planId, planBranchNum, customerCardId) => {
    axios({
      method: 'post',
      url: '/api/regCardAndGetData',
      data: {
        planId,
        planBranchNum,
        customerCardId
      }
    }).then((res) => {
      const { data } = res;
      data.itemSetting = JSON.parse(data.itemSetting);
      setValues(data);
      form.setFieldsValue(data);
      forceUpdate();
    });
  };

  return (
    <div className="block-setting-start-date">
      <Header />
      <div className="white-space"></div>
      <div className="container-custom-primary">
        <Row className="title mb-primary">
          <Col md={{ span: 12 }} className="page-title">
            {t('share.Application')}
            {' / '}
            {t('paymentInformationRegistration.title')}
          </Col>
          <Col md={{ span: 12 }} className="text-right">
            <Breadcrumb separator=">">
              <Breadcrumb.Item>
                {t('paymentInformationRegistration.title')}
              </Breadcrumb.Item>
              <Breadcrumb.Item className="bold current">
                {t('paymentInformationRegistration.useStartDateSetting')}
              </Breadcrumb.Item>
              <Breadcrumb.Item>{t('share.confirm')}</Breadcrumb.Item>
              <Breadcrumb.Item>{t('share.completion')}</Breadcrumb.Item>
            </Breadcrumb>
          </Col>
        </Row>
        <div className="mb-primary">
          <div>{t('settingStartDate.note1')}</div>
          <div>{t('settingStartDate.note2')}</div>
        </div>
        <Form
          name="frmApplicationSettingStartDate"
          form={form}
          autoComplete="off"
          onFinish={(data) => {
            if (!data.agreeCheck) {
              setShowAlert(true);
              return;
            }

            navigate('/applicationConfirm', {
              state: { ...state, values: { ...values, ...data } }
            });
          }}
        >
          <Col md={{ span: 10 }}>
            <div className="border-default mb-primary">
              <Row className="border-bottom-default">
                <Col className="bg-primary border-right-default pl-5 col-left-lbl">
                  {t('share.planId')}
                </Col>
                <Col className="pl-5">
                  <FormItem className="mb-0">{values?.planId}</FormItem>
                </Col>
              </Row>
              <Row>
                <Col className="bg-primary border-right-default pl-5 col-left-lbl">
                  {t('plan.version')}
                </Col>
                <Col className="pl-5">
                  <FormItem className="mb-0">{values?.planBranchNum}</FormItem>
                </Col>
              </Row>
            </div>
          </Col>
          <Col md={{ span: 12 }}>
            <div className="border-default mb-primary">
              <Row>
                <Col
                  md={{ span: 4 }}
                  className="bg-primary border-right-default pl-5 pt-10"
                >
                  {t('share.useStartDate')}
                </Col>
                <Col md={{ span: 20 }} className="p-10">
                  <Row>
                    <Col md={{ span: 6 }}>
                      <Form.Item
                        name="startType"
                        className="mb-0"
                        initialValue={1}
                      >
                        <Radio.Group onChange={() => forceUpdate()}>
                          <Space direction="vertical" size={1}>
                            <Radio value={0}>
                              {t('settingStartDate.immediately')}
                            </Radio>
                            <Radio value={1}>{t('share.specifyDate')}</Radio>
                          </Space>
                        </Radio.Group>
                      </Form.Item>
                    </Col>
                    <Col md={{ span: 12 }} className="col-date">
                      <Form.Item
                        name="startDate"
                        className="mb-0"
                        rules={
                          form.getFieldValue('startType') !== 0
                            ? [
                                {
                                  required:
                                    form.getFieldValue('startType') !== 0,
                                  whitespace: true,
                                  message:
                                    t('share.useStartDate') +
                                    t('share.error.notEntered')
                                },
                                () => ({
                                  validator(_, value) {
                                    if (
                                      !value ||
                                      !moment(value, DATE_FORMAT).isBefore(
                                        moment().format(DATE_FORMAT)
                                      )
                                    ) {
                                      return Promise.resolve();
                                    }

                                    return Promise.reject(
                                      new Error(
                                        t(
                                          'ご利用開始予定日は、本日以降をご指定ください。'
                                        )
                                      )
                                    );
                                  }
                                })
                              ]
                            : undefined
                        }
                      >
                        <div></div>
                        <DatePicker
                          disabled={form.getFieldValue('startType') === 0}
                          value={
                            form.getFieldValue('startDate')
                              ? moment(
                                  form.getFieldValue('startDate'),
                                  DATE_FORMAT
                                )
                              : undefined
                          }
                          format={DATE_FORMAT}
                          onChange={(date) => {
                            form.setFields([
                              {
                                name: 'startDate',
                                value: date.format(DATE_FORMAT)
                              }
                            ]);
                            forceUpdate();
                            form.validateFields(['startDate']);
                          }}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          </Col>
          <div className="mb-primary">
            <div>{t('settingStartDate.note3')}</div>
            <div>{t('settingStartDate.note4')}</div>
            <div>{t('settingStartDate.note5')}</div>
            <div>{t('settingStartDate.note6')}</div>
          </div>
          <div className="ml-15">
            <strong>{t('share.termOfService')}</strong>
            <Form.Item className="mb-0">
              <div
                className="terms-of-service border-default"
                style={{ whiteSpace: 'break-spaces' }}
              >
                {termsOfService?.messageText}
              </div>
            </Form.Item>
          </div>
          <Form.Item
            name="agreeCheck"
            className="mb-0 text-center"
            valuePropName="checked"
            initialValue={false}
          >
            <Checkbox>{t('settingStartDate.agree')}</Checkbox>
          </Form.Item>
          {showAlert && (
            <div className="text-center error alert-terms">
              {t('settingStartDate.alert')}
            </div>
          )}
          <div className="white-space"></div>
          <div className="bg-dancer p-5">
            <Row>
              <Col md={{ span: 12 }} className="text-right">
                <button type="submit" className="btn-submit cursor-pointer">
                  {t('share.confirm')}
                </button>
              </Col>
              <Col md={{ span: 12 }} className="text-right">
                <Button
                  onClick={() => {
                    navigate(
                      `/planDetail?planId=${values?.planId}&planBranchNum=${values?.planBranchNum}`,
                      {
                        replace: true
                      }
                    );
                  }}
                  className="btn-cancel"
                >
                  {t('share.cancel')}
                </Button>
              </Col>
            </Row>
          </div>
        </Form>
      </div>
      <FooterComponent />
    </div>
  );
}
export default ApplicationSettingStartDate;
