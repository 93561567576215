import React, { useEffect } from 'react';
import { Breadcrumb, Button, Col, Form, Row } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
import Header from '../components/Header';
import { useTranslation } from 'react-i18next';
import logoGeminiot from '../assets/images/logo-geminiot.png';
import logoPasterios from '../assets/images/logo-pasterios.png';
import FooterComponent from '../components/FooterComponent';
import { useForceUpdate } from '../utils/utils';
import axios from 'axios';
import SecuritysettingsConfigView from '../components/SecuritysettingsConfigView';

function SecuritysettingsInput() {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { state } = useLocation();
  const forceUpdate = useForceUpdate();

  useEffect(() => {
    const planId = state?.values?.planId;
    if (!planId) {
      navigate('/');
      return;
    }

    if (state?.values) {
      form.setFieldsValue(state.values);
      forceUpdate();
    }
  }, []);

  const saveSecuritySetting = () => {
    if (state.values?.planId) {
      const url = '/api/updateSecuritySetting';
      axios.post(url, state.values).then(() => {
        navigate('/securitySettingsComplete', { state });
      });
    } else {
      navigate('/securitySettingsComplete', { state });
    }
  };

  return (
    <>
      <Header />
      <div className="white-space"></div>
      <div className="container-custom-primary">
        <Row className="title mb-primary">
          <Col md={{ span: 6 }} className="page-title">
            {t('Securitysettings')}/{t('share.confirm')}
          </Col>
          <Col md={{ span: 18 }} className="text-right">
            <Breadcrumb separator=">">
              <Breadcrumb.Item>{t('share.input')}</Breadcrumb.Item>
              <Breadcrumb.Item className="bold current">
                {t('share.confirm')}
              </Breadcrumb.Item>
              <Breadcrumb.Item>{t('share.completion')}</Breadcrumb.Item>
            </Breadcrumb>
          </Col>
        </Row>
        <Col md={{ span: 4 }} className="title-sub color-primary">
          {t('share.basicInformation')}
        </Col>
        <div className="border-default mb-primary">
          <Row className="border-bottom-default">
            <Col
              md={{ span: 6 }}
              className="bg-primary border-right-default pl-5"
            >
              {t('share.planId')}
            </Col>
            <Col md={{ span: 18 }} className="pl-5">
              {state?.values?.planId}
            </Col>
          </Row>
          <Row>
            <Col
              md={{ span: 6 }}
              className="bg-primary border-right-default pl-5"
            >
              {t('plan.component')}
            </Col>
            <Col md={{ span: 18 }} className="pl-5">
              <img
                src={
                  state?.values?.component === 1 ? logoGeminiot : logoPasterios
                }
                className="img-logo pt-5 pb-5"
              />
            </Col>
          </Row>
        </div>
        <Form
          name="frmSecuritysettings"
          form={form}
          autoComplete="off"
          onFinish={() => {
            saveSecuritySetting();
          }}
        >
          <SecuritysettingsConfigView data={state?.values} />
          <div className="bg-dancer p-5">
            <Row>
              <Col md={{ span: 12 }} className="text-right">
                <button type="submit" className="btn-submit cursor-pointer">
                  {t('freeSimulation.save')}
                </button>
              </Col>
              <Col md={{ span: 12 }} className="text-right">
                <Button
                  onClick={() => {
                    navigate('/', { replace: true });
                  }}
                  className="btn-cancel"
                >
                  {t('share.cancel')}
                </Button>
              </Col>
            </Row>
          </div>
          <div class="line-primary"></div>
          <Button
            className="bg-info btn-info border-default"
            onClick={() => {
              navigate(`/securitysettingsInput`, {
                state
              });
            }}
          >
            {t('share.return')}
          </Button>
        </Form>
      </div>
      <FooterComponent />
    </>
  );
}

export default SecuritysettingsInput;
