import React, { useEffect } from 'react';
import Header from '../components/Header';
import { useTranslation } from 'react-i18next';
import { Row, Col, Breadcrumb, Form, Button } from 'antd';
import { useNavigate, useLocation } from 'react-router-dom';
import FooterComponent from '../components/FooterComponent';
import axios from 'axios';

function AdministratorConfirm() {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { state } = useLocation();

  useEffect(() => {
    if (!state?.values?.planId) {
      navigate('/', { replace: true });
    }
  }, []);

  const updateManager = () => {
    const url = '/api/updateManager';
    axios
      .post(url, {
        planId: state.values.planId,
        deleteAccountIds: state.values.deleteAccountIds?.filter((item) => item),
        addAccountIds: state.values.accountAdd?.map((item) => item.accountId)
      })
      .then(() => {
        navigate('/administratorCompletion', { state });
      });
  };

  return (
    <>
      <Header />
      <div className="white-space"></div>
      <div className="container-custom-primary administrator-confirm">
        <Row className="title mb-primary">
          <Col md={{ span: 12 }} className="page-title">
            {t('share.addOrDelete')}
            {' / '}
            {t('share.confirm')}
          </Col>
          <Col md={{ span: 12 }} className="text-right">
            <Breadcrumb separator=">">
              <Breadcrumb.Item>{t('share.input')}</Breadcrumb.Item>
              <Breadcrumb.Item className="bold current">
                {t('share.confirm')}
              </Breadcrumb.Item>
              <Breadcrumb.Item>{t('share.completion')}</Breadcrumb.Item>
            </Breadcrumb>
          </Col>
        </Row>
        <Form
          name="frmAdministratorConfirm"
          form={form}
          autoComplete="off"
          onFinish={() => {
            updateManager();
          }}
        >
          <Col className="title-sub color-primary">{t('share.usagePlan')}</Col>
          <Col md={{ span: 8 }} className="border-default mb-primary ml-15">
            <Row className="border-bottom-default">
              <Col
                md={{ span: 10 }}
                className="bg-primary border-right-default pl-5"
              >
                {t('share.planId')}
              </Col>
              <Col>
                <Form.Item name="planDetail.planId" className="mb-0 pl-15">
                  {state?.values?.planId}
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col
                md={{ span: 10 }}
                className="bg-primary border-right-default pl-5"
              >
                {t('planDetail.planUsageStartDate')}
              </Col>
              <Col>
                <Form.Item name="" className="mb-0 pl-15">
                  {state?.values?.useStartDate}
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col className="title-sub color-primary mb-primary">
            {t('share.managerInformation')}
          </Col>
          <div className="ml-15">
            <div className="mb-primary">
              <div className="mb-secondary">
                <span className="bold border-bottom-default">
                  {t('share.delete')}
                </span>
              </div>
              <Col md={{ span: 16 }}>
                <table className="table-account">
                  <thead className="bg-primary">
                    <td className="td-delete-account">{t('share.account')}</td>
                    <td>{t('share.accountName')}</td>
                    <td className="text-center td-remove">
                      {t('administratorAdd.titleSelectRow')}
                    </td>
                  </thead>
                  {state?.values?.accountAdminInfoList?.map((item, idx) => (
                    <tr>
                      <td>{item.mail}</td>
                      <td>{item.accountName}</td>
                      <td className="text-center">
                        {state.values.deleteAccountIds[idx] ? '削除' : ''}
                      </td>
                    </tr>
                  ))}
                </table>
              </Col>
            </div>
            <div className="mb-primary">
              <div className="mb-secondary">
                <span className="bold border-bottom-default">
                  {t('planNew.add')}
                </span>
              </div>
              <Col md={{ span: 14 }}>
                <table className="full-width table-confirm-add border-default">
                  <thead className="bg-primary border-bottom-default">
                    <td className="add-account border-style1">
                      {t('administratorAdd.account')}
                    </td>
                    <td className="border-style1">{t('share.accountName')}</td>
                  </thead>
                  <tbody>
                    {state?.values?.accountAdd?.map((item) => (
                      <tr>
                        <td className="border-style1">{item.mail}</td>
                        <td className="border-style1">{item.accountName}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </Col>
            </div>
          </div>

          <div className="bg-dancer p-5">
            <Row>
              <Col md={{ span: 12 }} className="text-right">
                <button type="submit" className="btn-submit cursor-pointer">
                  {t('cancellationOfReservationInput.execution')}
                </button>
              </Col>
              <Col md={{ span: 12 }} className="text-right">
                <Button
                  onClick={() => {
                    navigate(
                      `/planDetail?planId=${state?.values?.planId}&planBranchNum=${state?.values?.planBranchNum}`,
                      {
                        replace: true
                      }
                    );
                  }}
                  className="btn-cancel"
                >
                  {t('share.cancel')}
                </Button>
              </Col>
            </Row>
          </div>
          <div className="line-primary"></div>
          <Button
            onClick={() => {
              navigate(`/administratorAdd`, {
                state
              });
            }}
            className="btn-return"
          >
            {t('share.return')}
          </Button>
        </Form>
        <FooterComponent />
      </div>
    </>
  );
}
export default AdministratorConfirm;
