import React, { useEffect, useState } from 'react';
import '../i18n';
import { Row, Col, Breadcrumb, Space, Button } from 'antd';
import { useTranslation } from 'react-i18next';
import Header from '../components/Header';
import FooterComponent from '../components/FooterComponent';
import { useLocation, useNavigate } from 'react-router-dom';
import { paygentSendDataInfo, PAYMENT_METHOD } from '../constant';
import { getContextPathUrl, postDataToPaygent, SHA256 } from '../utils/utils';
import axios from 'axios';

function CreditCardInformationInquiry() {
  const { t } = useTranslation();
  const { state } = useLocation();
  const navigate = useNavigate();
  const [paygentDataInfo, setPaygentDataInfo] = useState();

  const params = new URLSearchParams(window.location.search);
  const planId =
    params.get('customer_id') || params.get('planId') || state?.planId;
  const planBranchNum = params.get('planBranchNum') || state?.planBranchNum;
  const customerCardId =
    params.get('customer_card_id') || state?.customerCardId;

  useEffect(() => {
    if (!planId || !planBranchNum || !customerCardId) {
      navigate('/', { replace: true });
    }
    getPaygentInfo();
  }, []);

  const getPaygentInfo = () => {
    axios.post('/api/getPaygentDataInfo').then((res) => {
      setPaygentDataInfo(res.data);
    });
  };

  const backtoDetailScreen = () => {
    navigate(`/planDetail?planId=${planId}&planBranchNum=${planBranchNum}`, {
      replace: true
    });
  };
  return (
    <>
      <Header />
      <div className="white-space"></div>
      <div className="container-custom-primary">
        <Row className="title">
          <Col md={{ span: 11 }} className="page-title">
            {t('share.Application')}
            {' / '}
            {t('creditCardInformationInquiry.title')}
          </Col>
          <Col md={{ span: 13 }} className="text-right">
            <Breadcrumb separator=">">
              <Breadcrumb.Item className="current bold">
                {t('creditCardInformationInquiry.title')}
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                {t('paymentRegistration.externalSite')}
              </Breadcrumb.Item>
              <Breadcrumb.Item>{t('share.completion')}</Breadcrumb.Item>
            </Breadcrumb>
          </Col>
        </Row>
        <div className="white-space"></div>
        <div className="text-center mb-primary">
          <div>{t('creditCardInformationInquiry.des1')}</div>
          <div>{t('creditCardInformationInquiry.des2')}</div>
          <div>{t('creditCardInformationInquiry.des3')}</div>
        </div>
        <div className="white-space"></div>

        <div className="border-default mb-primary">
          <Row>
            <Col
              md={{ span: 4 }}
              className="bg-primary border-right-default pl-5"
            >
              {t('share.paymentMethod')}
            </Col>
            <Col md={{ span: 20 }} className="pl-5">
              {t(PAYMENT_METHOD[1])}
            </Col>
          </Row>
        </div>
        <div className="white-space"></div>
        <div className="text-center">
          {t('paymentInformationRegistration.note1')}
        </div>
        <div className="text-center">
          {t('paymentInformationRegistration.note2')}
        </div>
        <div className="white-space"></div>
        <div className="bg-dancer text-center p-5 mb-primary">
          <Space size={50}>
            <button
              onClick={() => {
                const data = {
                  ...paygentSendDataInfo,
                  return_url: `${getContextPathUrl()}/creditCardChangeComplete?planId=${planId}&planBranchNum=${planBranchNum}`,
                  stop_return_url: `${getContextPathUrl()}/creditCardInformationInquiry?planId=${planId}&planBranchNum=${planBranchNum}&customer_card_id=${customerCardId}`,
                  hc: SHA256(
                    paygentDataInfo.seq_merchant_id +
                      paygentSendDataInfo.valid_check_flg +
                      planId +
                      paygentDataInfo.hcKey
                  ),
                  seq_merchant_id: paygentDataInfo.seq_merchant_id,
                  customer_id: planId,
                  customer_card_id: customerCardId,
                  operation_type: '02',
                  inform_url: `${getContextPathUrl()}/creditCardInformationInquiry?planId=${planId}&planBranchNum=${planBranchNum}`
                };
                postDataToPaygent(data, paygentDataInfo.url);
              }}
              className="btn-return cursor-pointer"
            >
              {t('share.move')}
            </button>
            <Button onClick={backtoDetailScreen} className="btn-return">
              {t('share.return')}
            </Button>
          </Space>
        </div>
        <div>{t('creditCardInformationInquiry.note1')}</div>
        <div>{t('creditCardInformationInquiry.note2')}</div>
      </div>
      <FooterComponent className="mb-0" />
    </>
  );
}
export default CreditCardInformationInquiry;
