import React from 'react';
import '../i18n';
import { Row, Col, Space, Form } from 'antd';
import { useTranslation } from 'react-i18next';
import { BACKUP_CYCLE, BACKUP_REGION, USE_NOTUSE } from '../constant';
import { useLocation } from 'react-router-dom';
import isEqual from 'lodash/isEqual';

function UseOptionComponent(props) {
  const { t } = useTranslation();
  const { state } = useLocation();
  const isCheckChanged = state?.prevData ?? false;
  return (
    <>
      <div className="option ml-15">
        <div className="option-body">
          <Row className="row-item">
            <Col
              md={{ span: 4 }}
              className="bg-primary col-label col-label-lv1"
            >
              <label className="lbl-required">
                {t('planNew.vpn')} <br />
                {props?.type === 'planDetail' ? '' : t('planNew.free')}
              </label>
            </Col>
            <Col md={{ span: 20 }} className="col-setting">
              <Space size={40}>
                <Form.Item
                  name="useVpn"
                  className={`mb-0 ${
                    isCheckChanged &&
                    !isEqual(state.prevData.useVpn, state.values.useVpn)
                      ? 'content-changed'
                      : ''
                  }`}
                >
                  {props?.data?.useVpn === USE_NOTUSE.Use
                    ? t('share.use')
                    : t('share.doNotUse')}
                </Form.Item>
              </Space>
            </Col>
          </Row>
          <Row className="row-item">
            <Col
              md={{ span: 4 }}
              className="bg-primary col-label col-label-lv1"
            >
              <label className="lbl-required">{t('share.dataBackup')}</label>
            </Col>
            <Col md={{ span: 20 }} className="col-setting">
              <Space size={40}>
                <Form.Item
                  name="useBackup"
                  className={`${
                    isCheckChanged &&
                    !isEqual(state.prevData.useBackup, state.values.useBackup)
                      ? 'content-changed'
                      : ''
                  }`}
                >
                  {props?.data?.useBackup === USE_NOTUSE.Use
                    ? t('share.use')
                    : t('share.doNotUse')}
                </Form.Item>
                {props?.data?.useBackup === USE_NOTUSE.Use && (
                  <>
                    <Form.Item
                      label={t('share.base')}
                      name="backupRegion"
                      className={`${
                        isCheckChanged &&
                        !isEqual(
                          state.prevData.backupRegion,
                          state.values.backupRegion
                        )
                          ? 'content-changed'
                          : ''
                      }`}
                    >
                      {
                        BACKUP_REGION.find(
                          (item) => item.value === props.data.backupRegion
                        )?.label
                      }
                    </Form.Item>
                    <Form.Item
                      label={t('planNew.frequency')}
                      name="backupCycle"
                      className={`${
                        isCheckChanged &&
                        !isEqual(
                          state.prevData.backupCycle,
                          state.values.backupCycle
                        )
                          ? 'content-changed'
                          : ''
                      }`}
                    >
                      {
                        BACKUP_CYCLE.find(
                          (item) => item.value === props.data.backupCycle
                        )?.label
                      }
                    </Form.Item>
                    <Form.Item
                      label={t('planNew.generation')}
                      name="backupGeneration"
                      className={`${
                        isCheckChanged &&
                        !isEqual(
                          state.prevData.backupGeneration,
                          state.values.backupGeneration
                        )
                          ? 'content-changed'
                          : ''
                      }`}
                    >
                      {props.data.backupGeneration}
                    </Form.Item>
                  </>
                )}
              </Space>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
}
export default UseOptionComponent;
