/**
 * (C) 2022 DTS CORPORATION
 */
import React from 'react';
import '../../i18n';
import { Row, Col, Space } from 'antd';
import { useTranslation } from 'react-i18next';

/**
 * Usage Situation Component
 */
function UsageSituationComponent(props) {
  const { t } = useTranslation();
  /**
   * convert amout traffic (byte to gigabyte)
   */
  const amountTraffic = () => {
    let traffic = 0;
    let byteToGB = 1073741824; // conversion rate byte to gigabyte
    props?.data?.forEach((element) => {
      traffic += element.traffic;
    });
    return Math.round((traffic / byteToGB) * 1000) / 1000;
  };

  const truncate = (value, precision) => {
    var step = Math.pow(10, precision || 0);
    var temp = Math.trunc(step * value);

    return temp / step;
  };

  return (
    <>
      <div className="usage-situation mb-primary">
        <Col md={{ span: 3 }} className="title-sub color-primary mb-primary">
          {t('usageInquiry.usageSituation')}
        </Col>
        <Space className="ml-15 mb-secondary">
          <span className="bold">{props?.date}</span>
          <span className="bold">{t('usageInquiry.usageOf')}</span>
        </Space>
        <table className="table-config full-width">
          <tr>
            <td rowSpan={3} className="vertical-align-top bg-primary">
              {t('freeSimulation.server')}
            </td>
            <td rowSpan={3} className="vertical-align-top bg-primary">
              {t('usageInquiry.unit')}
            </td>
            <td className="text-center bg-primary">{t('usageInquiry.spec')}</td>
            <td colSpan={2} className="text-center bg-primary">
              CPU（%）
            </td>
            <td colSpan={2} className="text-center bg-primary">
              {t('usageInquiry.memory')}（%）
            </td>
            <td colSpan={2} className="text-center bg-primary">
              {t('usageInquiry.store')}
            </td>
          </tr>
          <tr>
            <td
              rowSpan={2}
              className="text-center vertical-align-top bg-primary"
            >
              {t('usageInquiry.plan')}
            </td>
            <td colSpan={4} className="text-center bg-primary">
              {t('usageInquiry.currentUsageStatus')}
            </td>
            <td
              rowSpan={2}
              className="text-center vertical-align-top bg-primary"
            >
              {t('usageInquiry.plan')} <br />
              （GB）
            </td>
            <td
              rowSpan={2}
              className="text-center vertical-align-top bg-primary"
            >
              {t('usageInquiry.currentUsage')} <br />
              （％）
            </td>
          </tr>
          <tr>
            <td className="text-center bg-primary">
              {t('usageInquiry.average')}
            </td>
            <td className="text-center bg-primary">
              {t('usageInquiry.maximum')}
            </td>
            <td className="text-center bg-primary">
              {t('usageInquiry.average')}
            </td>
            <td className="text-center bg-primary">
              {t('usageInquiry.maximum')}
            </td>
          </tr>
          {props?.data?.map((item) => (
            <tr>
              <td>{t('share.' + item.server + 'Server')}</td>
              <td>{item.serverUnit}</td>
              <td>
                {item.serverCpu} core, {item.serverMem} GB
              </td>
              <td className="text-center">{truncate(item.cpuAvg, 1)}</td>
              <td className="text-center">{truncate(item.cpuMax, 1)}</td>
              <td className="text-center">{truncate(item.memoryAvg, 1)}</td>
              <td className="text-center">{truncate(item.memoryMax, 1)}</td>
              <td className="text-center">{item.serverVol}</td>
              <td className="text-center">
                {truncate(item.diskUtilization, 1)}
              </td>
            </tr>
          ))}
        </table>
      </div>
      <Row className="block-note mb-primary">
        <Col md={{ span: 8 }}>
          <table className="table-note full-width">
            <tr>
              <td className="bg-primary">{t('usageInquiry.dataTraffic')}</td>
              <td className="bg-primary">{t('usageInquiry.amount')} (GB)</td>
            </tr>
            <tr>
              <td>{t('usageInquiry.dataVolume')}</td>
              <td>{amountTraffic()}</td>
            </tr>
          </table>
        </Col>
        {props?.componentName === 'UsageInquiry' && (
          <Col md={{ span: 16 }} className="col-note">
            <div>{t('usageInquiry.note4')}</div>
            <div>{t('usageInquiry.note5')}</div>
          </Col>
        )}
        {props?.componentName === 'UsageSituationPast' && (
          <Col md={{ span: 16 }} className="col-note">
            <div>{t('usageSituationComponent.note')}</div>
          </Col>
        )}
      </Row>
    </>
  );
}
export default UsageSituationComponent;
