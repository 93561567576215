/**
 * (C) 2022 DTS CORPORATION
 */
import '../../i18n';
import Header from '../../components/Header';
import { useTranslation } from 'react-i18next';
import { Row, Col, Space, Button } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
import moment from 'moment';
import { jsPDF } from 'jspdf';
import { DATE_FORMAT } from '../../constant';
import html2canvas from 'html2canvas';
import $ from 'jquery';

const DATE_FORMAT_CUSTOM = 'YYYY/MM';

function CreateReceipt() {
  const { t } = useTranslation();
  const { state } = useLocation();
  const navigate = useNavigate();
  const date = moment(state.values.preUsage).format('yyyyMM');

  const handleDownloadPdf = async () => {
    const canvas = await html2canvas($('.area-outputpdf')[0], {
      ignoreElements: (e) => {
        if ($(e).hasClass('ignore-pdf')) {
          return true;
        }
      },
      scale: 4
    });
    let wid;
    let hgt;

    const data = canvas.toDataURL(
      'image/png',
      (wid = canvas.width),
      (hgt = canvas.height)
    );

    const pdfWidth = 300;
    const pdfHeight = (hgt * pdfWidth) / wid;

    const pdf = new jsPDF('p', 'mm', [pdfWidth, pdfHeight]);

    const fullWidth = pdf.internal.pageSize.getWidth();
    const fullHeight = pdf.internal.pageSize.getHeight();
    const widthRatio = fullWidth / canvas.width;
    const heightRatio = fullHeight / canvas.height;

    const ratio = widthRatio > heightRatio ? heightRatio : widthRatio;

    pdf.addImage(
      data,
      'PNG',
      10,
      10,
      canvas.width * ratio - 20,
      canvas.height * ratio - 20,
      '',
      'FAST'
    );
    pdf.save('明細書.pdf');
  };

  return (
    <>
      <Header />
      <div className="white-space"></div>
      <div className="area-outputpdf container-custom-secondary">
        <div className="text-right ignore-pdf">
          {' '}
          <Button className="btn-submit ignore-pdf" onClick={handleDownloadPdf}>
            PDF
          </Button>
          <br />
          <Button
            style={{ marginTop: '5px' }}
            onClick={() => {
              navigate('/usageFeePast', {
                state: state
              });
            }}
            className="bg-info btn-info border-default"
          >
            {t('share.return')}
          </Button>
        </div>

        <div className="text-center bold report-title">
          {t('createReceipt.title')}
        </div>
        <div className="line-primary"></div>
        <Row className="mb-primary">
          <Col md={{ span: 16 }}>
            <div className="white-space"></div>
            <div className="text-underline">
              <span>{state?.values?.companyName}</span>
              <span> </span>
              <span>{t('createReceipt.your')}</span>
            </div>
          </Col>
          <Col md={{ span: 8 }}>
            <div className="mb-primary pl-15">
              <table>
                <tbody>
                  <tr>
                    <td className="pr-15">
                      {t('createReceipt.receiptNumber')}
                    </td>
                    <td>
                      {state.values?.planId}-{date}
                    </td>
                  </tr>
                  <tr>
                    <td>{t('createReceipt.dateOfIssue')}</td>
                    <td>{moment().format(DATE_FORMAT)}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="mb-primary">
              <div>{state.values?.agency?.companyName}</div>
              <div>{state.values?.agency?.divisionName}</div>
            </div>
            <div className="mb-primary">
              <div>〒{state.values?.agency?.postalCode}</div>
              <div>{state.values?.agency?.address}</div>
              <div>(TEL) {state.values?.agency?.phoneNumber}</div>
              <div>
                {t('createReceipt.registrationNumber')}
                {state.values?.agency?.registrationNumber}
              </div>
              <div>&nbsp;</div>
              <div>&nbsp;</div>
            </div>
          </Col>
        </Row>
        <div className="border-default p-10">
          <div className="border-default table-inner">
            <Row>
              <Col md={{ span: 12 }} className="pr-15">
                <div className="border-bottom-default mb-primary">
                  {t('createReceipt.productName')}
                </div>
              </Col>
              <Col md={{ span: 12 }}>
                <div className="border-bottom-default mb-primary">
                  {t('createReceipt.amountOfMoney')}
                </div>
              </Col>
            </Row>
            <Row className="mb-secondary">
              <Col md={{ span: 12 }}>
                <Space>
                  <span>
                    {moment(state.values?.preUsage).format(DATE_FORMAT_CUSTOM)}
                  </span>
                  <span>{t('createReceipt.usage')}</span>
                </Space>
              </Col>
              <Col md={{ span: 12 }}>
                <Col md={{ span: 8 }} className="text-right col-value">
                  <Row>
                    <div className="div-value">
                      <span>{state.values?.usageFee?.billingAmount}</span>
                    </div>
                    <div className="div-unit pl-5">
                      <span>{t('freeSimulation.yen')}</span>
                    </div>
                  </Row>
                </Col>
              </Col>
            </Row>
            <Row className="mb-secondary">
              <Col md={{ span: 12 }}>
                <span>{t('createReceipt.saleTax')}（10%）</span>
              </Col>
              <Col md={{ span: 12 }}>
                <Col md={{ span: 8 }} className="text-right col-value">
                  <Row>
                    <div className="div-value">
                      <span>{state.values?.usageFee?.tax}</span>
                    </div>
                    <div className="div-unit pl-5">
                      <span>{t('freeSimulation.yen')}</span>
                    </div>
                  </Row>
                </Col>
              </Col>
            </Row>
            <Row className="mb-secondary bg-alert pt-5 pb-5">
              <Col md={{ span: 12 }} className="pl-5">
                <span className="bold">
                  {t('createReceipt.totalAmountBilled')}
                </span>
              </Col>
              <Col md={{ span: 12 }}>
                <Col md={{ span: 8 }} className="text-right col-value">
                  <Row>
                    <div className="div-value">
                      <span>{state.values?.usageFee?.total}</span>
                    </div>
                    <div className="div-unit pl-5">
                      <span>{t('freeSimulation.yen')}</span>
                    </div>
                  </Row>
                </Col>
              </Col>
            </Row>
          </div>
          <div className="white-space"></div>
          <Col md={{ span: 15 }}>
            <div className="title-secondary mb-primary">
              {t('freeSimulation.campaignApplicationDetails')}
            </div>
            <Row className="row-heading mb-secondary">
              <Col md={{ span: 12 }}>
                <strong>{t('share.campaignName')}</strong>
              </Col>
              <Col md={{ span: 6 }}>
                <strong>{t('share.applicablePeriod')}</strong>
              </Col>
              <Col md={{ span: 6 }}>
                <strong>{t('share.benefits')}</strong>
              </Col>
            </Row>
            {state.values?.usageFee?.campaigns?.map((item) => (
              <Row className="mb-secondary">
                <Col md={{ span: 12 }}>{item.campaignName}</Col>
                <Col md={{ span: 6 }}>{item.campaignPeriod}</Col>
                <Col md={{ span: 6 }}>{item.campaignBenefits}</Col>
              </Row>
            ))}
          </Col>
          <div className="white-space"></div>
          <Col md={{ span: 15 }} className="border-default mb-primary">
            <Row>
              <Col className="bg-primary border-right-default pl-5 col-left-lbl pr-15">
                {t('share.paymentMethod')}
              </Col>
              <Col className="pl-5">
                {state.values.paymentMethod == 1
                  ? t('createReceipt.paymentMethod.creditPayment')
                  : state.values.paymentMethod == 2
                  ? t('createReceipt.paymentMethod.invoicePayment')
                  : state.values.paymentMethod == 3
                  ? t('createReceipt.paymentMethod.dealerSettlement')
                  : t('createReceipt.paymentMethod.notSetting')}
              </Col>
            </Row>
          </Col>
          <div>{t('createReceipt.note1')}</div>
          <div>{t('createReceipt.note2')}</div>
          <div className="white-space"></div>
        </div>
      </div>
    </>
  );
}

export default CreateReceipt;
