import i18n from 'i18next';
import XHR from 'i18next-xhr-backend';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  .use(XHR)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'ja', //when specified language translations not present
    detection: {
      order: ['cookie', 'htmlTag', 'localStorage'],
      caches: ['cookie']
    },
    //then fallbacklang translations loaded.
    // debug: true,
    backend: {
      /* translation file path */
      loadPath: './assets/locales/{{lng}}/translations.json'
    },
    /* can have multiple namespace, in case you want to divide a huge translation into smaller pieces and load them on demand */
    ns: ['translations'],
    defaultNS: 'translations',
    keySeparator: false,
    interpolation: {
      escapeValue: false,
      formatSeparator: ','
    },
    react: {
      useSuspense: false
    }
  });

export default i18n;
