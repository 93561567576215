/**
 * (C) 2022 DTS CORPORATION
 */
import React, { useEffect, useState } from 'react';
import Header from '../components/Header';
import '../i18n';
import { Row, Col, Breadcrumb, Form, Space, Button } from 'antd';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import FooterComponent from '../components/FooterComponent';
import FormItem from 'antd/lib/form/FormItem';
import ServerListComponent from '../components/ServerListComponent';
import logoGeminiot from '../assets/images/logo-geminiot.png';
import logoPasterios from '../assets/images/logo-pasterios.png';
import UserTimeComponent from '../components/UserTimeComponent';
import { DATE_FORMAT, PAYMENT_METHOD, USE_NOTUSE } from '../constant';
import moment from 'moment';
import axios from 'axios';

/**
 * Application confirm page
 */
function ApplicationConfirm() {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { state } = useLocation();
  const [campaingData, setCampaignData] = useState();

  useEffect(() => {
    if (!state?.values?.planId) {
      navigate('/', { replace: true });
    }

    if (!state.values?.prevCampaignCodes) {
      state.values.prevCampaignCodes = [...(state.values?.campaignCodes || [])];
    }
    if (
      state.values?.prevCampaignCodes &&
      state.values.prevCampaignCodes.length > 0
    ) {
      axios
        .post('api/getCampaignInfoByListCampaignCode', {
          campaignCodes: state.values.prevCampaignCodes,
          projectCode: state.values.projectCode,
          startDate: getStartDate()
        })
        .then((res) => {
          setCampaignData(res.data);
          state.values.campaignCodes = res.data?.map((i) => i.campaignCode);
        });
    }
  }, []);

  const getStartDate = () => {
    return state?.values?.startType === 0
      ? moment().format(DATE_FORMAT)
      : state?.values?.startDate;
  };

  const addPlanApplication = () => {
    const url = '/api/addPlanApplication';
    axios
      .post(url, {
        ...state.values,
        startDate: getStartDate()
      })
      .then(() => {
        navigate('/applicationCompletion', { state });
      });
  };

  return (
    <>
      <Header />
      <div className="white-space"></div>
      <div className="container-custom-primary application-confirm">
        <Row className="title mb-primary">
          <Col md={{ span: 4 }} className="page-title">
            {t('share.Application')}
            {' / '}
            {t('share.confirm')}
          </Col>
          <Col md={{ span: 20 }} className="text-right">
            <Breadcrumb separator=">">
              <Breadcrumb.Item>
                {t('paymentInformationRegistration.title')}
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                {t('paymentInformationRegistration.useStartDateSetting')}
              </Breadcrumb.Item>
              <Breadcrumb.Item className="bold current">
                {t('share.confirm')}
              </Breadcrumb.Item>
              <Breadcrumb.Item>{t('share.completion')}</Breadcrumb.Item>
            </Breadcrumb>
          </Col>
        </Row>
        <Form
          name="frmApplicationConfirm"
          form={form}
          autoComplete="off"
          onFinish={() => {
            addPlanApplication();
          }}
        >
          <Col md={{ span: 12 }}>
            <div className="border-default mb-primary ml-15">
              <Row className="border-bottom-default">
                <Col className="bg-primary border-right-default pl-5 col-left-lbl">
                  {t('share.planId')}
                </Col>
                <Col className="pl-5">
                  <FormItem className="mb-0">{state?.values?.planId}</FormItem>
                </Col>
              </Row>
              <Row>
                <Col className="bg-primary border-right-default pl-5 col-left-lbl">
                  {t('plan.version')}
                </Col>
                <Col className="pl-5"> {state?.values?.planBranchNum}</Col>
              </Row>
            </div>
          </Col>
          <div className="payment mb-primary">
            <Row className="row-title">
              <Col md={{ span: 4 }} className="title-sub color-primary">
                {t('share.paymentInformation')}
              </Col>
            </Row>
            <div className="border-default ml-15">
              <Row>
                <Col
                  md={{ span: 4 }}
                  className="bg-primary border-right-default pl-5"
                >
                  {t('share.paymentMethod')}
                </Col>
                <Col md={{ span: 20 }} className="pl-5">
                  {t(PAYMENT_METHOD[state?.values?.paymentMethod])}
                </Col>
              </Row>
            </div>
          </div>
          <div className="use-start-date mb-primary">
            <Col md={{ span: 4 }} className="title-sub color-primary">
              {t('share.useStartDate')}
            </Col>
            <Col md={{ span: 12 }}>
              <div className="border-default ml-15">
                <Row className="row-title">
                  <Col className="bg-primary border-right-default pl-5 col-left-lbl">
                    {t('share.useStartDate')}
                  </Col>
                  <Col className="pl-5">
                    <Space size={20}>
                      <div>{t('share.specifyDate')}</div>
                      <div> {getStartDate()}</div>
                    </Space>
                  </Col>
                </Row>
              </div>
            </Col>
          </div>
          <div className="applicable-campaign-code">
            <Col md={{ span: 4 }} className="title-sub color-primary">
              {t('applicationCompletion.applicableCampaignCode')}
            </Col>
            <Row className="border-default mb-primary ml-15">
              <Col
                md={{ span: 4 }}
                className="bg-primary border-right-default pl-5"
              >
                {t('CampaignCodeAddedConfirm.campaign')}
                <div className="pl-5">{t('share.applicable')}</div>
              </Col>
              <Col md={{ span: 20 }}>
                <Row className="bg-primary  border-bottom-default">
                  <Col md={{ span: 3 }} className="pl-15">
                    {' '}
                    {t('share.code')}
                  </Col>
                  <Col md={{ span: 10 }}>{t('share.campaignName')}</Col>
                  <Col md={{ span: 6 }}>{t('share.applicablePeriod')}</Col>
                  <Col md={{ span: 5 }}>{t('share.benefits')}</Col>
                </Row>
                {campaingData?.map((item) => (
                  <Row>
                    <Col md={{ span: 3 }} className="pl-15">
                      {item.campaignCode}
                    </Col>
                    <Col md={{ span: 10 }}>{item.campaignName}</Col>
                    <Col md={{ span: 6 }}>{item.campaignPeriod}</Col>
                    <Col md={{ span: 5 }}>{item.campaignBenefits}</Col>
                  </Row>
                ))}
              </Col>
            </Row>
            <div className="dealer-information">
              <Col md={{ span: 4 }} className="title-sub color-primary">
                {t('share.dealerInformation')}
              </Col>
              <div className="border-default mb-primary ml-15">
                <Row>
                  <Col
                    md={{ span: 4 }}
                    className="bg-secondary border-right-default pl-5"
                  >
                    {t('share.regisCode')}
                  </Col>
                  <Col md={{ span: 4 }} className="pl-15">
                    {state?.values?.projectCode}
                  </Col>
                  <Col
                    md={{ span: 4 }}
                    className="bg-secondary border-right-default pl-5"
                  >
                    {t('share.salesOutlet')}
                  </Col>
                  <Col md={{ span: 4 }} className="pl-15">
                    {state?.values?.projectCode
                      ? state?.values?.agency?.companyName
                      : ''}
                  </Col>
                </Row>
              </div>
            </div>
            <div className="usage-plan">
              <Col md={{ span: 4 }} className="title-sub color-primary">
                {t('share.usagePlan')}
              </Col>
              <div className="ml-15">
                <Col md={{ span: 12 }}>
                  <Row className="border-default bb-0">
                    <Col
                      md={{ span: 10 }}
                      className="bg-primary vertical-align-center border-right-default pl-5 col-left-lbl"
                    >
                      {t('share.product')}
                    </Col>
                    <Col md={{ span: 14 }}>
                      <img
                        src={
                          state.values.component === 1
                            ? logoGeminiot
                            : logoPasterios
                        }
                        className="img-logo"
                      />
                    </Col>
                  </Row>
                </Col>
                <div className="border-default p-10">
                  <div className="title-secondary mb-secondary ml-15">
                    {t('share.basicComposition')}
                  </div>
                  <ServerListComponent
                    type="applicationCompletion"
                    itemSetting={state.values?.itemSetting}
                  />
                  <div className="option">
                    <div className="title-secondary mb-secondary ml-15">
                      {t('share.option')}
                    </div>
                    {state.values.useBackup === USE_NOTUSE.Use && (
                      <Row className="row-option">
                        <Col md={{ span: 6 }}>{t('share.dataBackup')}</Col>
                        <Col md={{ span: 6 }}>
                          {state.values.backupGeneration}世代
                        </Col>
                      </Row>
                    )}
                  </div>
                  <div className="user-time">
                    <div className="title-secondary mb-primary ml-15">
                      {t('freeSimulation.useTime')}
                    </div>
                    <UserTimeComponent
                      type="freeSimulation"
                      systemUtilizationTimes={
                        state.values?.systemUtilizationTimes
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="line-primary"></div>
              <div className="text-center bg-dancer mb-primary p-5">
                <button
                  className="btn-default btn-submit cursor-pointer"
                  type="submit"
                >
                  {t('share.Application')}
                </button>
              </div>
            </div>
          </div>
        </Form>
        <Button
          onClick={() => {
            navigate(`/applicationSettingStartDate`, {
              state
            });
          }}
          className="btn-return"
        >
          {t('share.return')}
        </Button>
      </div>
      <FooterComponent />
    </>
  );
}
export default ApplicationConfirm;
