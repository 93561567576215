/**
 * (C) 2022 DTS CORPORATION
 */
import React from 'react';
import { Row, Col, Form, Select, Button } from 'antd';
import '../../i18n';
import t from '../../translate';

function PreviousUsageSelector(props) {
  const isPreviousFree = props.isPreviousFree;
  let options = [];
  let buttonDisable = false;
  if (isPreviousFree) {
    options = props.options?.previousUsageFee;
    buttonDisable = options?.length < 1;
  } else {
    options = props.options?.previousUsage;
    buttonDisable = options?.length < 1;
  }
  return (
    <>
      <div className="ml-15">
        <div className="bold mb-secondary">
          {isPreviousFree
            ? t('usageInquiry.previousUsageFee')
            : t('usageInquiry.preUsage')}
        </div>
        <Row>
          <Col md={{ span: 9 }} className="border-default ml-15">
            <Row>
              <Col md={{ span: 12 }} className="pl-15 pt-5 pb-5">
                <Form.Item
                  className="mb-0 label-after-hidden"
                  style={{ width: '150px' }}
                >
                  <Select
                    className="time-fee"
                    disabled={options?.length < 1}
                    options={options?.map((item) => ({
                      label: item,
                      value: item
                    }))}
                    value={props.value}
                    onChange={(value, option) => props.onChange(option)}
                  />
                </Form.Item>
              </Col>
              <Col
                md={{ span: 12 }}
                className="vertical-align-center horizontal-center"
              >
                <Button
                  className="btn-submit cursor-pointer"
                  disabled={buttonDisable}
                  onClick={props.onClick}
                >
                  {t('mypage.display')}
                </Button>
              </Col>
            </Row>
          </Col>
          <Col md={{ span: 13 }} className="vertical-align-center ml-30">
            {props.note}
          </Col>
        </Row>
      </div>
    </>
  );
}
export default PreviousUsageSelector;
