import React from 'react';
import 'antd/dist/antd.css';
import { Row, Col, Menu, Dropdown, Space, List, Avatar, Select } from 'antd';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import logoHeader from '../assets/images/logo-header.png';
import userThumb from '../assets/images/user.png';
import { useAuth } from '../AuthContext';

const { Option } = Select;

const menu = (user, signout, t) => (
  <Menu
    className="drop-user"
    onClick={({ key }) => {
      if (key === 'logout') {
        signout(() => {
          window.location.href = './logout';
        });
      }
    }}
  >
    <Menu.Item key="0">
      <Link to="/profile">
        <List>
          <List.Item>
            <List.Item.Meta
              avatar={<Avatar src={userThumb} />}
              title={user?.account?.accountName || ''}
              description={user?.account?.accountMail || ''}
            />
          </List.Item>
        </List>
      </Link>
    </Menu.Item>
    <Menu.Divider />
    <Menu.Item key="1">
      <Link to="/passwordChange">{t('header.changePassword')}</Link>
    </Menu.Item>
    <Menu.Item key="2">
      <Link to="/profileChange">{t('header.profileChange')}</Link>
    </Menu.Item>
    <Menu.Item key="3">
      <Link to="/emailChange">{t('header.emailAddressChange')}</Link>
    </Menu.Item>
    <Menu.Divider />
    <Menu.Item key="logout">{t('header.logout')}</Menu.Item>
  </Menu>
);

function Header() {
  const { user, signout } = useAuth();
  const { t, i18n } = useTranslation();
  return (
    <React.Fragment>
      <div className="header f13-xs">
        <Row gutter={16} align="middle">
          <Col className="gutter-row" md={12} xs={9}>
            <Space>
              <Link to="/">
                <img src={logoHeader} className="img-logo"></img>
              </Link>
            </Space>
          </Col>
          <Col className="gutter-row" md={12} xs={15} align="right">
            <Space size={10}>
              <span>{t('header.language')}:</span>
              <Select
                value={i18n.language}
                onChange={(value) => {
                  i18next.changeLanguage(value);
                }}
              >
                <Option value="ja">{t('header.ja')}</Option>{' '}
                <Option value="en">{t('header.en')}</Option>
              </Select>
              {user && (
                <Dropdown overlay={menu(user, signout, t)} trigger={['hover']}>
                  <img src={userThumb} className="img-user-thumbail" />
                </Dropdown>
              )}
            </Space>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
}

export default Header;
