import React, { useEffect } from 'react';
import Header from '../components/Header';
import { Row, Form, Col, Button, Breadcrumb, Space } from 'antd';
import FooterComponent from '../components/FooterComponent';
import { useForceUpdate } from '../utils/utils';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import { STATUS_PLAN_DATA, CHOICE_TYPE_STOP_DATE } from '../constant';

function TemporarySuspensionOrCancellationSuspensionConfirm() {
  const { state } = useLocation();
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const forceUpdate = useForceUpdate();

  useEffect(() => {
    forceUpdate();
  }, []);

  return (
    <>
      <Header />
      <div className="white-space"></div>
      <div className="container-custom-primary suspension-confirm">
        <Row className="title mb-primary">
          <Col md={{ span: 12 }} className="page-title">
            {t('OtherProceduresStopOrCancel.link1')}
            {' / '}
            {t('share.confirm')}
          </Col>
          <Col md={{ span: 12 }} className="text-right">
            <Breadcrumb separator=">">
              <Breadcrumb.Item>{t('share.input')}</Breadcrumb.Item>
              <Breadcrumb.Item className="bold current">
                {t('share.confirm')}
              </Breadcrumb.Item>
              <Breadcrumb.Item>{t('share.completion')}</Breadcrumb.Item>
            </Breadcrumb>
          </Col>
        </Row>
        <Form
          name="frmTemporarySuspensionOrCancellationSuspension"
          form={form}
          autoComplete="off"
          onFinish={() => {
            if (state.values.display == 1) {
              state.values.stopReserveDate = state.values.designationDate;
              state.values.stopReserveDateOrStopDateFlag = true;
            }

            if (state.values.display == 2) {
              state.values.stopDate = null;
              state.values.cancelStopDateFlag = true;
            }
            axios
              .post('/api/updateProcedureOfPLan', {
                ...state.values
              })
              .then(() => {
                navigate(
                  '/temporarySuspensionOrCancellationSuspensionCompletion',
                  {
                    state
                  }
                );
              });
          }}
        >
          <Col className="title-sub color-primary">{t('share.usagePlan')}</Col>
          <Col
            md={{ span: 10 }}
            className="border-default ml-15 mb-primary"
            style={{ borderBottom: '0px' }}
          >
            <Row className="border-bottom-default">
              <Col className="bg-primary border-right-default pl-5 col-left-lbl">
                {t('share.planId')}
              </Col>
              <Col>
                <Form.Item className="mb-0 pl-15">
                  {state.values.planId}
                </Form.Item>
              </Col>
            </Row>
            <Row className="border-bottom-default">
              <Col className="bg-primary border-right-default pl-5 col-left-lbl">
                {t('plan.version')}
              </Col>
              <Col>
                <Form.Item className="mb-0 pl-15">
                  {state.values.planBranchNum}
                </Form.Item>
              </Col>
            </Row>
            <Row className="border-bottom-default">
              <Col className="bg-primary border-right-default pl-5 col-left-lbl">
                {t('planDetail.status')}
              </Col>
              <Col>
                <Form.Item className="mb-0 pl-15">
                  {STATUS_PLAN_DATA[state.values.status]}
                </Form.Item>
              </Col>
            </Row>
            <Row className="border-bottom-default">
              <Col className="bg-primary border-right-default pl-5 col-left-lbl">
                {t('planDetail.planUsageStartDate')}
              </Col>
              <Col>
                <Form.Item className="mb-0 pl-15">
                  {state.values.useStartDate}
                </Form.Item>
              </Col>
            </Row>
            {state.values?.stopReserveDate && (
              <Row className="border-bottom-default">
                <Col className="bg-primary border-right-default pl-5 col-left-lbl">
                  {t(
                    'TemporarySuspensionOrCancellationSuspension.planSuspensionDate'
                  )}
                </Col>
                <Col>
                  <Form.Item className="mb-0 pl-15">
                    {state.values.stopReserveDate}
                  </Form.Item>
                </Col>
              </Row>
            )}
            {state.values?.stopDate && (
              <Row className="border-bottom-default">
                <Col className="bg-primary border-right-default pl-5 col-left-lbl">
                  {t('share.planStopDate')}
                </Col>
                <Col>
                  <Form.Item className="mb-0 pl-15">
                    {state.values.stopDate}
                  </Form.Item>
                </Col>
              </Row>
            )}
            {state.values?.cancelReserveDate && (
              <Row className="border-bottom-default">
                <Col className="bg-primary border-right-default pl-5 col-left-lbl">
                  {t('share.scheduledCancellationDate')}
                </Col>
                <Col>
                  <Form.Item className="mb-0 pl-15">
                    {state.values.cancelReserveDate}
                  </Form.Item>
                </Col>
              </Row>
            )}
            {state.values?.cancelDate && (
              <Row className="border-bottom-default">
                <Col className="bg-primary border-right-default pl-5 col-left-lbl">
                  {t('share.planCancellationDate')}
                </Col>
                <Col>
                  <Form.Item className="mb-0 pl-15">
                    {state.values.cancelDate}
                  </Form.Item>
                </Col>
              </Row>
            )}
          </Col>
          <Col className="title-sub color-primary">
            {t('TemporarySuspensionOrCancellationSuspension.stop')}
          </Col>
          <Col md={{ span: 18 }} className="ml-15">
            <table className="table-stop">
              {state.values.display == 1 && (
                <>
                  <tr>
                    <td className="bg-primary border-right-default col-label-lv1">
                      {t(
                        'TemporarySuspensionOrCancellationSuspension.stopLabel1'
                      )}
                    </td>
                    <td className="text-center">
                      <Space>
                        <span>
                          {
                            CHOICE_TYPE_STOP_DATE[
                              state.values.choiceTypeStopDate
                            ]
                          }
                        </span>
                        <span>{state.values.designationDate}</span>
                        <span>（23:00 に停止）</span>
                      </Space>
                    </td>
                  </tr>
                </>
              )}
              {state.values.display == 2 && (
                <>
                  <tr>
                    <td className="bg-primary border-right-default col-label-lv1">
                      {t(
                        'TemporarySuspensionOrCancellationSuspension.stopLabel2'
                      )}
                    </td>
                    <td>{t('settingStartDate.immediately')}</td>
                  </tr>
                </>
              )}
            </table>
          </Col>

          <div className="white-space"></div>
          <div className="bg-dancer p-5">
            <Row>
              <Col md={{ span: 12 }} className="text-right">
                <button type="submit" className="btn-submit cursor-pointer">
                  {t('share.confirm')}
                </button>
              </Col>
              <Col md={{ span: 12 }} className="text-right">
                <Button
                  onClick={() => {
                    navigate('/otherProceduresStopOrCancel', {
                      state
                    });
                  }}
                  className="btn-cancel"
                >
                  {t('share.cancel')}
                </Button>
              </Col>
            </Row>
          </div>
          <div className="line-primary"></div>
          <Button
            onClick={() => {
              navigate('/temporarySuspensionOrCancellationSuspensionInput', {
                state
              });
            }}
            className="btn-return"
          >
            {t('share.return')}
          </Button>
        </Form>
      </div>
      <FooterComponent />
    </>
  );
}
export default TemporarySuspensionOrCancellationSuspensionConfirm;
