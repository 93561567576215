import React from 'react';
import { Col } from 'antd';
import { Link } from 'react-router-dom';
import Header from '../components/Header';
import { useAuth } from '../AuthContext';
import { useTranslation } from 'react-i18next';

function InvalidUrl() {
  const { t } = useTranslation();
  const { user } = useAuth();
  return (
    <React.Fragment>
      <Header />
      <div
        className="container-custom modal-primary box-register-completion"
        gutter={16}
      >
        <div className="box-title">{t('invalidUrl.title')}</div>
        <div className="box-body">
          <div className="ant-form-item description">
            {t('invalidUrl.description')}
          </div>
        </div>
        <Col offset={10}>
          {user ? (
            <Link to="/" className="link mb-primary">
              {t('invalidUrl.button.myPage')}
            </Link>
          ) : (
            <Link to="/login" className="link mb-primary">
              {t('returnToLogin')}
            </Link>
          )}
        </Col>
      </div>
    </React.Fragment>
  );
}

export default InvalidUrl;
