/**
 * (C) 2022 DTS CORPORATION
 */
import React, { useEffect, useState } from 'react';
import '../../i18n';
import { Row, Col, Form, Space, Button } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
import Header from '../../components/Header';
import FooterComponent from '../../components/FooterComponent';
import UsageSituationComponent from '../../components/usageInquiryComponents/UsageSituationComponent';
import UsageFeeComponent from '../../components/usageInquiryComponents/UsageFeeComponent';
import axios from 'axios';
import moment from 'moment';
import t from '../../translate';
import PreviousUsageSelector from '../../components/usageInquiryComponents/PreviousUsageSelector';

const DATE_FORMAT_CUSTOM = 'YYYY/MM';
/**
 * Usage Inquiry component
 */
function UsageInquiry() {
  const { state } = useLocation();
  const navigate = useNavigate();
  const [dataSituation, setDataSituation] = useState();
  const [dataFee, setDataFee] = useState();
  const [preUsageFee, setPreUsageFee] = useState();
  const [preUsage, setPreUsage] = useState();
  const [options, setOptions] = useState();

  const dateCurrent = moment(new Date()).format(DATE_FORMAT_CUSTOM);

  const fectOptions = (data) => {
    const dateCurrent = moment(new Date());
    if (dateCurrent.date() > 2) {
      setOptions(data);
      setPreUsageFee(data?.previousUsageFee[0]);
      setPreUsage(data?.previousUsage[0]);
      return;
    }
    if (
      data?.previousUsageFee[0] ==
      dateCurrent.subtract(1, 'M').format(DATE_FORMAT_CUSTOM)
    ) {
      data?.previousUsageFee?.splice(0, 1);
    }
    setOptions(data);
    setPreUsageFee(data?.previousUsageFee[0]);
    setPreUsage(data?.previousUsage[0]);
  };

  const changePreUsage = (e) => {
    setPreUsage(e.value);
  };

  const changePreUsageFee = (e) => {
    setPreUsageFee(e.value);
  };

  useEffect(() => {
    axios
      .post('/api/getUsageInquiry', {
        planId: state.values.planId
      })
      .then((res) => {
        const { data } = res;
        if (data) {
          const { listUsageSituation, preUsage, usageFree } = data;
          setDataSituation(listUsageSituation);
          setDataFee(usageFree);
          fectOptions(preUsage);
        }
      });
  }, []);

  return (
    <>
      <Header />
      <div className="white-space" />
      <div className="container-custom-primary usage-inquiry">
        <Row className="title mb-primary">
          <Col md={{ span: 4 }} className="page-title">
            {t('usageInquiry.title')}
          </Col>
        </Row>
        <div className="usage-plan mb-primary">
          <Col className="title-sub color-primary">
            {t('usageInquiry.usagePlan')}
          </Col>
          <Col md={{ span: 9 }} className="border-default ml-15">
            <Row>
              <Col className="bg-primary border-right-default pl-5 col-left-lbl">
                {t('share.planId')}
              </Col>
              <Col>
                <Form.Item name="usageSituation.planId" className="mb-0 pl-15">
                  {state.values.planId}
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </div>

        <div>
          <Col md={{ span: 3 }} className="title-sub color-primary mb-primary">
            {t('usageInquiry.usageFee')}
          </Col>
          <Space className="mb-primary ml-15">
            <div className="bold">{dateCurrent}</div>
            <div className="bold">{t('usageInquiry.usageFeeOf')}</div>
          </Space>
        </div>
        <UsageFeeComponent
          date={dateCurrent}
          data={dataFee}
          componentName="UsageInquiry"
        />
        <PreviousUsageSelector
          options={options}
          value={preUsageFee}
          isPreviousFree={true}
          onChange={changePreUsageFee}
          onClick={() => {
            navigate(`/usageFeePast`, {
              state: {
                values: {
                  ...state?.values,
                  preUsage: preUsageFee + '/01'
                }
              }
            });
          }}
          note={t('usageInquiry.note3')}
        />
        <div className="usage-situation mb-primary">
          <UsageSituationComponent
            date={dateCurrent}
            data={dataSituation}
            componentName="UsageInquiry"
          />
        </div>
        <PreviousUsageSelector
          options={options}
          isPreviousFree={false}
          value={preUsage}
          onChange={changePreUsage}
          onClick={() => {
            navigate(`/usageSituationPast`, {
              state: {
                values: { ...state?.values, preUsage: preUsage + '/01' }
              }
            });
          }}
          note=""
        />
        <div className="white-space"></div>
        <div className="line-primary"></div>
        <Button
          className="btn-return"
          onClick={() => {
            navigate(
              `/planDetail?planId=${state?.values?.planId}&planBranchNum=${state?.values?.planBranchNum}`,
              {
                replace: true
              }
            );
          }}
        >
          {t('share.return')}
        </Button>
        <FooterComponent />
      </div>
    </>
  );
}
export default UsageInquiry;
