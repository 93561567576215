import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import Header from '../components/Header';
import { Row, Col, Form, Radio, Button, Checkbox, Input, Select } from 'antd';
import axios from 'axios';
import { useTranslation } from 'react-i18next';

function Register() {
  const [form] = Form.useForm();
  const { state } = useLocation();
  const { t, i18n } = useTranslation();

  const [countryFlag, setCountryFlag] = useState();
  const [mailReg, setMailReg] = useState();
  const [countryDisplayValue, setCountryDisplayValue] = useState();
  const [listCountry, setListCountry] = useState([]);
  const [errorIsPolicy, SetErrorIsPolicy] = useState();
  const [token, setToken] = useState();
  const navigate = useNavigate();

  //半角英数8~20文字、英大文字・英小文字・数字を最低1文字ずつ含む
  const regExp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,20}/;

  const onCountryFlagChange = (event) => {
    setCountryFlag(event.target.value);
    setDefaultCountry();
  };

  const onChangePostalCode = () => {
    form.setFieldsValue({
      ...form.getFieldsValue(),
      prefectures: null,
      municipalities: null,
      address1_japan: null
    });
  };

  const onClickBtnAddressSearch = () => {
    const postalCode = form.getFieldValue('postalCode');
    if (isNaN(postalCode) || postalCode.length !== 7) {
      //入力された郵便番号が数字7桁ではない場合、検索を行わない。
      return;
    }
    axios({
      method: 'get',
      url: '/api/postalCode?postalCode=' + postalCode
    }).then((res) => {
      form.setFieldsValue({
        //入力された郵便番号で検索を行い、「都道府県」「市区町村」「番地1」に自動入力する。
        prefectures: res.data.state,
        municipalities: res.data.city,
        address1_japan: res.data.address
      });
    });
  };

  const setDefaultCountry = () => {
    form.setFieldsValue({
      country: 'JP'
    });
    setCountryDisplayValue('日本');
  };

  useEffect(() => {
    if (!state?.accObject) {
      const search = window.location.search;
      const params = new URLSearchParams(search);
      const token = params.get('token');

      setToken(token);

      form.setFieldsValue({
        countryFlag: 1
      });
      setCountryFlag(1);
      setDefaultCountry();

      axios({
        method: 'get',
        url: '/api/getEmailByToken?token=' + token
      }).then((res) => {
        setMailReg(res.data);
      });
    } else {
      form.setFieldsValue({
        ...state?.accObject,
        ...(state?.accObject.countryFlag === 1
          ? { address1_japan: state?.accObject.address1 }
          : { address1_overseas: state?.accObject.address1 })
      });

      setCountryDisplayValue(state.accObject.countryDisplayValue);
      setToken(state.accObject.token);
      setMailReg(state.accObject.mail);
      if (state?.accObject?.countryFlag) {
        setCountryFlag(state?.accObject?.countryFlag);
      }
    }
  }, []);

  useEffect(() => {
    if (!i18n.language) return;
    axios({
      method: 'get',
      url: '/api/country',
      params: {
        language: i18n.language
      }
    }).then((res) => {
      setListCountry(res.data);
    });
  }, [i18n.language]);

  return (
    <div>
      <Header />
      <div className="box">
        <Form
          form={form}
          onFinish={(values) => {
            if (values.policy !== true) {
              //プライバシーポリシーに同意するにチェックが入っていない場合、
              //エラーメッセージ欄に「プライバシーポリシーに同意いただけない場合、登録いただけません。」を表示し、画面遷移しない。
              SetErrorIsPolicy(t('register.error.policyNotChecked'));
              return;
            }
            values.mail = mailReg;
            values.token = token;
            values.countryDisplayValue = countryDisplayValue;
            switch (countryFlag) {
              case 1:
                values.address1 = values.address1_japan;
                values.region = null;
                values.city = null;
                delete values.address1_japan;
                break;
              case 2:
                values.address1 = values.address1_overseas;
                values.municipalities = null;
                values.prefectures = null;
                delete values.address1_overseas;
                break;
              default:
                break;
            }
            navigate('/registerConfirm', {
              state: { values: values }
            });
          }}
        >
          <div className="box-register mb-primary">
            <div className="box-title">{t('register.title')}</div>
            <div className="box-body">
              <div className="required text-right">{t('share.required')}</div>
              <div className="frame">
                <Row>
                  <Col md={{ span: 8 }} xs={{ span: 24 }}>
                    E-mail：
                  </Col>
                  <Col md={{ span: 16 }} xs={{ span: 24 }}>
                    <Form.Item name="mail">{mailReg}</Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col md={{ span: 8 }} xs={{ span: 24 }}>
                    {t('share.surnameName')}
                    <span className="required">*</span>：
                  </Col>
                  <Col md={{ span: 16 }} xs={{ span: 24 }}>
                    <Form.Item
                      name="firstName"
                      rules={[
                        {
                          required: true,
                          whitespace: true,
                          message:
                            t('share.surnameName') + t('share.error.notEntered')
                        }
                      ]}
                    >
                      <Input
                        placeholder={t('register.surnamePlacehoder')}
                        className="txt-primary"
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col md={{ span: 8 }} xs={{ span: 24 }}>
                    {t('share.Name')}
                    <span className="required">*</span>：
                  </Col>
                  <Col md={{ span: 16 }} xs={{ span: 24 }}>
                    <Form.Item
                      name="lastName"
                      rules={[
                        {
                          required: true,
                          whitespace: true,
                          message: t('share.Name') + t('share.error.notEntered')
                        }
                      ]}
                    >
                      <Input
                        placeholder={t('register.namePlacehoder')}
                        className="txt-primary"
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col md={{ span: 8 }} xs={{ span: 24 }}>
                    {t('share.kanaSurname')}
                  </Col>
                  <Col md={{ span: 16 }} xs={{ span: 24 }}>
                    <Form.Item name="kanaFirstName">
                      <Input
                        placeholder={t('register.kanaSurnamePlacehoder')}
                        className="txt-primary"
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col md={{ span: 8 }} xs={{ span: 24 }}>
                    {t('share.kanaName')}
                  </Col>
                  <Col md={{ span: 16 }} xs={{ span: 24 }}>
                    <Form.Item name="kanaLastName">
                      <Input
                        placeholder={t('register.kanaNamePlacehoder')}
                        className="txt-primary"
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col md={{ span: 8 }} xs={{ span: 24 }}>
                    {t('share.companyName')}
                    <span className="required">*</span>：
                  </Col>
                  <Col md={{ span: 16 }} xs={{ span: 24 }}>
                    <Form.Item
                      name="companyName"
                      rules={[
                        {
                          required: true,
                          whitespace: true,
                          message:
                            t('share.companyName') + t('share.error.notEntered')
                        }
                      ]}
                    >
                      <Input
                        placeholder={t('register.companyNamePlacehoder')}
                        className="txt-primary"
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col md={{ span: 8 }} xs={{ span: 24 }}>
                    {t('share.divisionName')}
                    <span className="required">*</span>：
                  </Col>
                  <Col md={{ span: 16 }} xs={{ span: 24 }}>
                    <Form.Item
                      name="divisionName"
                      rules={[
                        {
                          required: true,
                          whitespace: true,
                          message:
                            t('share.divisionName') +
                            t('share.error.notEntered')
                        }
                      ]}
                    >
                      <Input
                        placeholder={t('register.divisionNamePlacehoder')}
                        className="txt-primary"
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col md={{ span: 8 }} xs={{ span: 24 }}>
                    {t('share.companyAddress')}
                    <span className="required">*</span>：
                  </Col>
                  <Col md={{ span: 16 }} xs={{ span: 24 }}>
                    <Form.Item name="countryFlag">
                      <Radio.Group onChange={onCountryFlagChange}>
                        <Radio value={1}>
                          {t('share.companyAddressJapan')}
                        </Radio>
                        <Radio value={2}>
                          {t('share.companyAddressOverseas')}
                        </Radio>
                      </Radio.Group>
                    </Form.Item>
                  </Col>
                </Row>
                {countryFlag === 1 && (
                  <>
                    <Row>
                      <Col md={{ span: 8 }} xs={{ span: 24 }}>
                        {t('share.countryRegion')}
                        <span className="required">*</span>：
                      </Col>
                      <Col md={{ span: 16 }} xs={{ span: 24 }}>
                        <Form.Item name="country" className="slbCountry">
                          <Select
                            defaultValue="JP"
                            disabled
                            options={listCountry.map((item) => ({
                              label: item.countryCodeName,
                              value: item.countryCode
                            }))}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="f13-xs" md={{ span: 8 }} xs={{ span: 7 }}>
                        {t('share.postalCode')}
                        <span className="required">*</span>：
                      </Col>
                      <Col
                        className="f13-xs"
                        md={{ span: 13 }}
                        xs={{ span: 7 }}
                      >
                        <Form.Item
                          name="postalCode"
                          rules={[
                            {
                              required: true,
                              whitespace: true,
                              message:
                                t('share.postalCode') +
                                t('share.error.notEntered')
                            },
                            {
                              validator: async (_, value) => {
                                if (value && value.length !== 7) {
                                  return Promise.reject(
                                    new Error(t('share.error.postCode7Number'))
                                  );
                                }
                              }
                            }
                          ]}
                        >
                          <Input
                            placeholder={t('register.postalCodePlacehoder')}
                            className="txt-primary"
                            onChange={() => onChangePostalCode()}
                          />
                        </Form.Item>
                      </Col>
                      <Col className="f13-xs" md={{ span: 3 }} xs={{ span: 4 }}>
                        <Button
                          className="btn-primary btn-add-search"
                          onClick={() => onClickBtnAddressSearch()}
                        >
                          {t('share.addressSearch')}
                        </Button>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={{ span: 8 }} xs={{ span: 24 }}>
                        {t('share.prefectures')}
                        <span className="required">*</span>：
                      </Col>
                      <Col md={{ span: 16 }} xs={{ span: 24 }}>
                        <Form.Item
                          name="prefectures"
                          rules={[
                            {
                              required: true,
                              whitespace: true,
                              message:
                                t('share.prefectures') +
                                t('share.error.notEntered')
                            }
                          ]}
                        >
                          <Input
                            disabled
                            placeholder={t('register.prefecturesPlacehoder')}
                            className="txt-primary"
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={{ span: 8 }} xs={{ span: 24 }}>
                        {t('share.municipalities')}
                        <span className="required">*</span>：
                      </Col>
                      <Col md={{ span: 16 }} xs={{ span: 24 }}>
                        <Form.Item
                          name="municipalities"
                          rules={[
                            {
                              required: true,
                              whitespace: true,
                              message:
                                t('share.municipalities') +
                                t('share.error.notEntered')
                            }
                          ]}
                        >
                          <Input
                            disabled
                            placeholder={t('register.municipalitiesPlacehoder')}
                            className="txt-primary"
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={{ span: 8 }} xs={{ span: 24 }}>
                        {t('share.address1')}
                        <span className="required">*</span>：
                      </Col>
                      <Col md={{ span: 16 }} xs={{ span: 24 }}>
                        <Form.Item
                          name="address1_japan"
                          rules={[
                            {
                              required: true,
                              whitespace: true,
                              message:
                                t('share.address1') +
                                t('share.error.notEntered')
                            }
                          ]}
                        >
                          <Input
                            disabled
                            placeholder={t('register.address1Placehoder')}
                            className="txt-primary"
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={{ span: 8 }} xs={{ span: 24 }}>
                        {t('share.address2')}
                        <span className="required">*</span>：
                      </Col>
                      <Col md={{ span: 16 }} xs={{ span: 24 }}>
                        <Form.Item
                          name="address2"
                          rules={[
                            {
                              required: true,
                              whitespace: true,
                              message:
                                t('share.address2') +
                                t('share.error.notEntered')
                            }
                          ]}
                        >
                          <Input
                            placeholder={t('register.address2Placehoder')}
                            className="txt-primary"
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </>
                )}
                {countryFlag === 2 && (
                  <>
                    <Row>
                      <Col md={{ span: 8 }} xs={{ span: 24 }}>
                        {t('share.address1')}
                        <span className="required">*</span>：
                      </Col>
                      <Col md={{ span: 16 }} xs={{ span: 24 }}>
                        <Form.Item
                          name="address1_overseas"
                          rules={[
                            {
                              required: true,
                              whitespace: true,
                              message:
                                t('share.address1') +
                                t('share.error.notEntered')
                            }
                          ]}
                        >
                          <Input
                            placeholder={t('register.address1Placehoder')}
                            className="txt-primary"
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={{ span: 8 }} xs={{ span: 24 }}>
                        {t('share.address2Option')}
                      </Col>
                      <Col md={{ span: 16 }} xs={{ span: 24 }}>
                        <Form.Item name="address2">
                          <Input
                            placeholder={t('register.address2Placehoder')}
                            className="txt-primary"
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={{ span: 8 }} xs={{ span: 24 }}>
                        {t('share.city')}
                        <span className="required">*</span>：
                      </Col>
                      <Col md={{ span: 16 }} xs={{ span: 24 }}>
                        <Form.Item
                          name="city"
                          rules={[
                            {
                              required: true,
                              whitespace: true,
                              message:
                                t('share.city') + t('share.error.notEntered')
                            }
                          ]}
                        >
                          <Input
                            placeholder={t('register.cityPlacehoder')}
                            className="txt-primary"
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={{ span: 8 }} xs={{ span: 24 }}>
                        {t('share.region')}
                        <span className="required">*</span>：
                      </Col>
                      <Col md={{ span: 16 }} xs={{ span: 24 }}>
                        <Form.Item
                          name="region"
                          rules={[
                            {
                              required: true,
                              whitespace: true,
                              message:
                                t('share.region') + t('share.error.notEntered')
                            }
                          ]}
                        >
                          <Input
                            placeholder={t('register.regionPlacehoder')}
                            className="txt-primary"
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={{ span: 8 }} xs={{ span: 24 }}>
                        {t('share.postalCode')}
                        <span className="required">*</span>：
                      </Col>
                      <Col md={{ span: 16 }} xs={{ span: 24 }}>
                        <Form.Item
                          name="postalCode"
                          rules={[
                            {
                              required: true,
                              whitespace: true,
                              message:
                                t('share.postalCode') +
                                t('share.error.notEntered')
                            },
                            {
                              validator: async (_, value) => {
                                if (value && isNaN(value)) {
                                  return Promise.reject(
                                    new Error(t('share.error.postCodeIsNumber'))
                                  );
                                }
                              }
                            }
                          ]}
                        >
                          <Input
                            placeholder={t('register.postalCodePlacehoder')}
                            className="txt-primary"
                            onChange={() => onChangePostalCode()}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={{ span: 8 }} xs={{ span: 24 }}>
                        {t('share.country')}
                        <span className="required">*</span>：
                      </Col>
                      <Col md={{ span: 16 }} xs={{ span: 24 }}>
                        <Form.Item
                          name="country"
                          className="slbCountry"
                          rules={[
                            {
                              required: true,
                              whitespace: true,
                              message:
                                t('share.country') + t('share.error.notEntered')
                            }
                          ]}
                        >
                          <Select
                            defaultValue="JP"
                            onChange={(value, option) =>
                              setCountryDisplayValue(option.label)
                            }
                            options={listCountry.map((item) => ({
                              label: item.countryCodeName,
                              value: item.countryCode
                            }))}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </>
                )}
                <Row>
                  <Col md={{ span: 8 }} xs={{ span: 24 }}>
                    {t('share.phoneNumber')}
                    <span className="required">*</span>：
                  </Col>
                  <Col md={{ span: 16 }} xs={{ span: 24 }}>
                    <Form.Item
                      name="phoneNumber"
                      rules={[
                        {
                          required: true,
                          whitespace: true,
                          message:
                            t('share.phoneNumber') + t('share.error.notEntered')
                        },
                        {
                          validator: async (_, value) => {
                            if (value && isNaN(value)) {
                              return Promise.reject(
                                new Error(t('share.error.phoneNumberIsNumber'))
                              );
                            }
                          }
                        }
                      ]}
                    >
                      <Input
                        type={Number}
                        placeholder={t('register.phoneNumberPlacehoder')}
                        className="txt-primary"
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col md={{ span: 8 }} xs={{ span: 24 }}>
                    {t('register.password')}
                    <span className="required">*</span>：
                  </Col>
                  <Col md={{ span: 16 }} xs={{ span: 24 }}>
                    <Form.Item
                      name="password"
                      rules={[
                        {
                          required: true,
                          whitespace: true,
                          message:
                            t('register.password') + t('share.error.notEntered')
                        },
                        {
                          validator: async (_, value) => {
                            if (value && !regExp.test(value)) {
                              return Promise.reject(
                                new Error(t('register.error.passwordInvalid'))
                              );
                            }
                          }
                        }
                      ]}
                    >
                      <Input.Password
                        placeholder={t('register.passwordPlacehoder')}
                        className="txt-primary"
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col md={{ span: 8 }} xs={{ span: 24 }}>
                    {t('register.rePassword')}
                    <span className="required">*</span>：
                  </Col>
                  <Col md={{ span: 16 }} xs={{ span: 24 }}>
                    <Form.Item
                      name="confirmPassword"
                      rules={[
                        {
                          required: true,
                          message: t('register.passwordConfirmRequired')
                        },
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            if (!value || getFieldValue('password') === value) {
                              return Promise.resolve();
                            }

                            return Promise.reject(
                              new Error(
                                t(
                                  'register.error.passwordDifferentPasswordConfirm'
                                )
                              )
                            );
                          }
                        })
                      ]}
                    >
                      <Input.Password
                        placeholder={t('register.rePasswordPlacehoder')}
                        className="txt-primary"
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
          <Form.Item className="mb-0">
            <div>{t('register.policy1')}</div>
            <div>
              {t('register.policy2')}{' '}
              <a
                href="https://www.dts.co.jp/sustainability/governance/privacy_policy/"
                target={'_blank'}
              >
                <u style={{ color: '#e03a00' }}>{t('regiser.linkPolicy')}</u>
              </a>
              <span>{t('register.policy3')}</span>
            </div>
            <div>{t('register.policy4')}</div>
          </Form.Item>
          <Form.Item
            name="policy"
            valuePropName="checked"
            className="text-right"
            initialValue={true}
          >
            <Checkbox className="lbl-policy">{t('register.lbl')}</Checkbox>
          </Form.Item>
          <div className="message-error mb-primary text-right">
            {errorIsPolicy}
          </div>
          <Form.Item>
            <Row>
              <Col span={12}>
                <Link to="/login" className="link">
                  {t('register.linkLogin')}
                </Link>
              </Col>
              <Col span={12} className="text-right">
                <Button
                  htmlType="submit"
                  style={{ margin: '0 8px' }}
                  className="btn-primary"
                >
                  {t('register.submit')}
                </Button>
              </Col>
            </Row>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
}
export default Register;
