import React from 'react';
import { Breadcrumb, Col, Row } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
import Header from '../components/Header';
import { useTranslation } from 'react-i18next';
import FooterComponent from '../components/FooterComponent';

function VpnSettingsComplete() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { state } = useLocation();
  if (!state.values?.planId) {
    navigate('/');
    return;
  }

  return (
    <>
      <Header />
      <div class="white-space"></div>
      <div className="container-custom-primary">
        <Row className="title mb-primary">
          <Col md={{ span: 6 }} className="page-title">
            {t('VPNSetting.Title')}
            {' / '}
            {!state.isError ? t('VPNSetting.Complete') : t('VPNSetting.Fail')}
          </Col>
          <Col md={{ span: 18 }} className="text-right">
            <Breadcrumb separator=">">
              <Breadcrumb.Item>{t('share.input')}</Breadcrumb.Item>
              <Breadcrumb.Item>{t('share.confirm')}</Breadcrumb.Item>
              <Breadcrumb.Item className="bold current">
                {t('share.completion')}
              </Breadcrumb.Item>
            </Breadcrumb>
          </Col>
        </Row>
        {state.isError && (
          <p style={{ whiteSpace: 'break-spaces' }}>
            {t('VPNSetting.ErrorContent')}
          </p>
        )}
        {!state.isError && (
          <p style={{ whiteSpace: 'break-spaces' }}>
            {t('VPNSetting.CompleteContent')}
          </p>
        )}

        <div className="text-center bg-dancer mb-primary p-5">
          <button
            type="button"
            onClick={() => {
              navigate('/', { replace: true });
            }}
            className="btn-submit cursor-pointer"
          >
            {t('ReturnToTOPScreen')}
          </button>
        </div>
      </div>
      <FooterComponent class="footer-home" />
    </>
  );
}

export default VpnSettingsComplete;
