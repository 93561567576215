import React, { useEffect, useState } from 'react';
import '../i18n';
import Header from '../components/Header';
import { Row, Col, Select, Button, Modal, Space, Tag } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import {
  PLAN_DISPLAY_MODE,
  COMPONENT_PLAN_DATA,
  STATUS_PLAN_DATA,
  DATE_FORMAT,
  PLAN_STATUS
} from '../constant';
import moment from 'moment';
import { base64ToBlob } from '../utils/utils';
import mime from 'mime';

function Home() {
  const { t, i18n } = useTranslation();
  // 固定料金フラグ
  // このフラグが1の場合、新規プラン作成のボタンを非表示にする
  const [fixedPriceFlag, setFixedPriceFlag] = useState([]);
  const [planList, setPlanList] = useState([]);
  const [showTermsOfService, setShowTermsOfService] = useState(false);
  const [termsOfService, setTermsOfService] = useState();
  const [notice, setNotice] = useState();
  const [trainingContents, setTrainingContents] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    getAllPlan(PLAN_DISPLAY_MODE.NotIncludeHistory);
    getTrainingContents();
  }, []);

  useEffect(() => {
    if (!i18n.language) return;
    getTermsOfService(i18n.language);
  }, [i18n.language]);

  useEffect(() => {
    if (!i18n.language) return;
    getNotice(i18n.language);
  }, [i18n.language]);

  const getAllPlan = (mode) => {
    axios({
      method: 'get',
      url: '/api/getPlanList',
      params: {
        displayMode: mode
      }
    }).then((res) => {
      setFixedPriceFlag(res.data.fixedPriceFlag);
      setPlanList(res.data.planList);
    });
  };

  const changeHistoryInfo = (e) => {
    getAllPlan(e.value);
  };

  const getTermsOfService = (language) => {
    axios({
      method: 'get',
      url: '/api/getTermsOfService',
      params: {
        language: language
      }
    }).then((res) => {
      setTermsOfService(res.data);
    });
  };

  const pending = false;

  const getNotice = (language) => {
    axios({
      method: 'get',
      url: '/api/getNotice',
      params: {
        language: language
      }
    }).then((res) => {
      setNotice(res.data);
    });
  };

  const getTrainingContents = () => {
    axios({
      method: 'get',
      url: '/api/getTrainingContents'
    }).then((res) => {
      setTrainingContents(res.data);
    });
  };

  const getTrainingDocFile = (trainingContentId, fileName, videoType) => {
    const basename = window.location.pathname.substring(
      0,
      window.location.pathname.indexOf('/', 2)
    );

    let url = new URL(window.location.origin + basename + '/api/getFile');

    url.searchParams.set('trainingContentId', trainingContentId);
    url.searchParams.set('fileName', fileName);

    if (videoType) {
      window.open(url, 'mozillaWindow', 'popup');
    } else {
      window.open(url);
    }
  };

  const checkNew = (date) => {
    var dateMoment = moment(date, DATE_FORMAT);
    if (dateMoment.isSameOrAfter(moment(), 'date')) {
      return true;
    }

    return false;
  };

  return (
    <>
      <Header />
      {pending === true && (
        <>
          <div className="white-space"></div>
          <div className="ml-30">11月より新規申込の受付を予定しています。</div>
          <Modal
            centered
            title={t('share.termOfService')}
            visible={showTermsOfService}
            onCancel={() => {
              setShowTermsOfService(false);
            }}
            footer={[
              <Button
                className="bg-info btn-info border-default"
                key="back"
                onClick={() => {
                  setShowTermsOfService(false);
                }}
              >
                {t('share.return')}
              </Button>
            ]}
          >
            <p style={{ whiteSpace: 'break-spaces' }}>
              {termsOfService?.messageText}
            </p>
          </Modal>
          <div className="footer-home text-center">
            <div className="line-default"></div>
            <div className="mb-primary">
              <a
                className="hover-underline"
                onClick={() => {
                  setShowTermsOfService(true);
                }}
              >
                {t('share.termOfService')}
              </a>
            </div>
            <div>© DTS CORPORATION All Rights Reserved</div>
          </div>
        </>
      )}
      {pending === false && (
        <>
          <div className="white-space"></div>
          <div className="box-plan">
            <h2 className="bg-dancer pl-10">MyPage</h2>
            <Row className="title page-title">{t('notice.title')}</Row>
            <div style={{ whiteSpace: 'break-spaces' }}>
              {notice?.messageText}
            </div>

            <div className="white-space"></div>
            <Row className="title page-title">{t('mypage.planList')}</Row>
            <Row className="mb-primary">
              <Col md={{ span: 20 }}>
                {fixedPriceFlag !== 1 && (
                  <Link to="/planInput" className="link-newPlan">
                    {t('mypage.newPlanRegistration')}
                  </Link>
                )}
              </Col>
              <Col className="col-display">
                <div>{t('mypage.display')}</div>
                <div className="text-right">
                  <Select
                    defaultValue={PLAN_DISPLAY_MODE.NotIncludeHistory}
                    onChange={(value, option) => changeHistoryInfo(option)}
                  >
                    <Option value={PLAN_DISPLAY_MODE.NotIncludeHistory}>
                      {t('mypage.notHistory')}
                    </Option>
                    <Option value={PLAN_DISPLAY_MODE.IncludeHistory}>
                      {t('mypage.includingHistory')}
                    </Option>
                  </Select>
                </div>
              </Col>
            </Row>

            <table className="table-parent border-default">
              <thead className="bg-primary border-default">
                <td>{t('share.planId')}</td>
                <td>{t('plan.version')}</td>
                <td>{t('plan.component')}</td>
                <td>{t('plan.startDate')}</td>
                <td>{t('plan.usageStatus')}</td>
                <th></th>
              </thead>
              <tbody>
                {planList.map((row) => {
                  return (
                    <tr>
                      <td className="vertical-align-top">{row.planId}</td>
                      <td className="vertical-align-top bg-gray">
                        {row.planBranchNum}
                      </td>
                      <td className="vertical-align-top">
                        {COMPONENT_PLAN_DATA[row.component]}
                      </td>
                      <td className="vertical-align-top bg-gray">
                        {moment(row.startDate).format(DATE_FORMAT)}
                      </td>
                      <td>
                        <table className="table-child">
                          <tr>
                            <td>{STATUS_PLAN_DATA[row.status]}</td>
                            <td></td>
                            <td></td>
                          </tr>

                          {![PLAN_STATUS[5], PLAN_STATUS[6]].includes(
                            row.status
                          ) &&
                            row.stopReserveDate != '' &&
                            row.stopReserveDate != null && (
                              <tr>
                                <td>{t('plan.underSuspensionAcceptance')}</td>
                                <td>{t('plan.scheduledStopDate')}</td>
                                <td>
                                  {moment(row.stopReserveDate).format(
                                    DATE_FORMAT
                                  )}
                                </td>
                              </tr>
                            )}
                          {![PLAN_STATUS[5], PLAN_STATUS[6]].includes(
                            row.status
                          ) &&
                            row.cancelReserveDate != '' &&
                            row.cancelReserveDate != null && (
                              <tr>
                                <td>{t('plan.acceptingCancellation')}</td>
                                <td>{t('plan.scheduledCancellationDate')}</td>
                                <td>
                                  {moment(row.cancelReserveDate).format(
                                    DATE_FORMAT
                                  )}
                                </td>
                              </tr>
                            )}

                          {[PLAN_STATUS[4]].includes(row.status) &&
                            row.restrictionValidFlag == 1 &&
                            row.restrictionStatus == 8 && (
                              <tr>
                                <td>
                                  {t('plan.receivingCancellationOfSuspension')}
                                </td>
                                <td></td>
                                <td></td>
                              </tr>
                            )}
                        </table>
                      </td>
                      <td className="text-right">
                        {' '}
                        <Link
                          to={{
                            pathname: '/planDetail',
                            search: `?planId=${row.planId}&planBranchNum=${row.planBranchNum}`
                          }}
                          className="btn-detail"
                        >
                          {t('plan.detail')}
                        </Link>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            {false && (
              <>
                <div className="white-space"></div>
                <Space className="my-border-bottom-primary full-width mb-primary">
                  <div className="bold page-title">
                    {t('Securitysettings.title')}
                  </div>
                  <span>{t('Securitysettings.des')}</span>
                </Space>
                <Col md={8}>
                  <table className="table-parent border-default">
                    <thead className="bg-primary border-default">
                      <td className="border-right-default">
                        {t('share.planId')}
                      </td>
                      <td className="border-right-default">
                        {t('Securitysettings.IPAddressCol')}
                      </td>
                      <td>{t('VPNSetting.Title')}</td>
                    </thead>
                    <tbody>
                      {planList
                        .filter((item) =>
                          [PLAN_STATUS[3], PLAN_STATUS[4]].includes(item.status)
                        )
                        .map((row) => (
                          <>
                            <tr>
                              <td className="border-right-default">
                                {row.planId}
                              </td>
                              <td className="border-right-default p-5 text-center">
                                <Button
                                  className="btn-detail"
                                  onClick={() => {
                                    navigate(
                                      `/securitysettingsInput?planId=${row.planId}`
                                    );
                                  }}
                                >
                                  {t('Configuration')}
                                </Button>
                                <Button
                                  className="btn-detail ml-5"
                                  onClick={() => {
                                    navigate(
                                      `/securitysettingsReference?planId=${row.planId}`
                                    );
                                  }}
                                >
                                  {t('Inquiry')}
                                </Button>
                              </td>
                              <td className="p-5 text-center">
                                <Button
                                  disabled={!row.useVpn}
                                  className="btn-detail"
                                  onClick={() => {
                                    navigate(
                                      `/vpnSettingsInput?planId=${row.planId}`
                                    );
                                  }}
                                >
                                  {t('Configuration')}
                                </Button>
                                <Button
                                  disabled={!row.useVpn}
                                  className="btn-detail ml-5"
                                  onClick={() => {
                                    navigate(
                                      `/vpnSettingsReference?planId=${row.planId}`
                                    );
                                  }}
                                >
                                  {t('Inquiry')}
                                </Button>
                              </td>
                            </tr>
                          </>
                        ))}
                    </tbody>
                  </table>
                </Col>
              </>
            )}
            <div className="white-space"></div>
            <div className="title page-title mb-primary">
              {t('training.title')}
            </div>
            {trainingContents?.map((item) => {
              return (
                <>
                  <div className="d-flex align-items-baseline">
                    <span className="circle-icon"></span>
                    <div class="ant-col mb-primary ant-col-md-6 border-bottom-default bold">
                      {item.categoryName}
                    </div>
                  </div>
                  <Row
                    gutter={[15, 15]}
                    wrap={false}
                    className="overflow-x-auto"
                  >
                    {item.trainingContents?.map((ele) => {
                      return (
                        <Col className="customize-col">
                          <div className="article border-default card-1">
                            <div className="article-avatar">
                              {ele.imageFile && (
                                <img
                                  src={URL.createObjectURL(
                                    base64ToBlob(
                                      ele.imageFile.fileBase64,
                                      ele.imageFile.fileName
                                    )
                                  )}
                                  className="mb-secondary full-width"
                                />
                              )}
                            </div>
                            {checkNew(ele.trainingNewDate) ? (
                              <div className="text-right">
                                <Tag
                                  bordered="false"
                                  color="magenta"
                                  className="border-none f14 tag-new bold"
                                >
                                  {t('training.new')}
                                </Tag>
                              </div>
                            ) : (
                              <div className="tag-new"></div>
                            )}
                            <div className="p-10">
                              <div className="article-title">
                                {ele.trainingTitle}
                              </div>
                            </div>
                            <div className="text-center mb-primary">
                              <Button
                                onClick={() => {
                                  if (ele.docFile) {
                                    getTrainingDocFile(
                                      ele.trainingId,
                                      ele.docFile.fileName,
                                      ele.videoType
                                    );
                                  } else if (ele.url && ele.url != '') {
                                    window.open(ele.url);
                                  }
                                }}
                                className={
                                  ele.videoType
                                    ? 'btn-video-file '
                                    : 'btn-view-file'
                                }
                              >
                                {ele.videoType
                                  ? '動画を見る'
                                  : t('training.ViewMaterials')}
                              </Button>
                            </div>
                          </div>
                        </Col>
                      );
                    })}
                  </Row>
                  <div className="white-space"></div>
                </>
              );
            })}
            <div className="white-space"></div>
          </div>
          <Modal
            centered
            title={t('share.termOfService')}
            visible={showTermsOfService}
            onCancel={() => {
              setShowTermsOfService(false);
            }}
            footer={[
              <Button
                className="bg-info btn-info border-default"
                key="back"
                onClick={() => {
                  setShowTermsOfService(false);
                }}
              >
                {t('share.return')}
              </Button>
            ]}
          >
            <p style={{ whiteSpace: 'break-spaces' }}>
              {termsOfService?.messageText}
            </p>
          </Modal>
          <div className="footer-home text-center">
            <div className="line-default"></div>
            <div className="mb-primary">
              <a
                className="hover-underline"
                onClick={() => {
                  setShowTermsOfService(true);
                }}
              >
                {t('share.termOfService')}
              </a>
            </div>
            <div>© DTS CORPORATION All Rights Reserved</div>
          </div>
        </>
      )}
    </>
  );
}

export default Home;
