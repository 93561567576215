import React, { useEffect } from 'react';
import '../i18n';
import Header from '../components/Header';
import { Row, Col, Form, Breadcrumb, Space, Collapse, Button } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { CaretRightOutlined } from '@ant-design/icons';
import logoGeminiot from '../assets/images/logo-geminiot.png';
import logoPasterios from '../assets/images/logo-pasterios.png';
import ConfigurationComponent from '../components/ConfigurationComponent';
import UseOptionComponent from '../components/UseOptionComponent';
import { PRODUCT_ITEM } from '../constant';
import { useForceUpdate } from '../utils/utils';
import PlanInfoArea from '../components/PlanInfoArea';
import TimeComponentConfirm from '../components/TimeComponentConfirm';

function PlanConfirm() {
  const { t } = useTranslation();
  const { Panel } = Collapse;
  const { state } = useLocation();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const forceUpdate = useForceUpdate();
  useEffect(() => {
    form.setFieldsValue(state.values);
    forceUpdate();
  }, []);

  const addClassForItemSelected = (component, type) => {
    if (
      form.getFieldValue('component') === component &&
      form.getFieldValue('systemConfig') === type
    )
      return 'product-item-selected';
    return '';
  };

  return (
    <>
      <Header />
      <div className="white-space"></div>
      <div className="box-plan plan-confirm">
        <Row className="title mb-primary">
          <Col md={{ span: 12 }} className="page-title">
            {t('planNew.title')}
            {' / '}
            {t('planNewBtnConfirm')}
          </Col>
          <Col md={{ span: 12 }} className="text-right">
            <Breadcrumb separator=">">
              <Breadcrumb.Item>{t('share.input')}</Breadcrumb.Item>
              <Breadcrumb.Item className="current bold">
                {t('share.confirm')}
              </Breadcrumb.Item>
              <Breadcrumb.Item>{t('share.changeSimulation')}</Breadcrumb.Item>
            </Breadcrumb>
          </Col>
        </Row>
        <Form
          name="frmPlanNew"
          form={form}
          autoComplete="off"
          onFinish={() => {
            navigate('/freeSimulation', {
              state
            });
          }}
        >
          <div className="ml-15">
            <PlanInfoArea data={state?.values} />
          </div>
          <Col md={{ span: 4 }} className="title-sub color-primary">
            {t('share.basicInformation')}
          </Col>

          <div className="base-infomation border-default mb-primary ml-15">
            <Form.Item
              label={t('share.companyName')}
              name="companyName"
              className="isRequired"
            >
              {form.getFieldValue('companyName')}
            </Form.Item>
            <Form.Item
              label={t('planNew.scheduledStartDate')}
              name="startDate"
              className="isRequired"
            >
              {form.getFieldValue('startDate')}
            </Form.Item>
          </div>
          <div className="usage-plan">
            <Col md={{ span: 4 }} className="title-sub color-primary">
              {t('share.usagePlan')}
            </Col>
            <div className="usage-plan-body border-default ml-15">
              <Row>
                <Col md={{ span: 4 }} className="bg-primary col-label">
                  {' '}
                  <label className="lbl-required">
                    {t('planNew.productSelection')}
                  </label>
                </Col>
                <Col md={{ span: 16 }} className="col-list-product">
                  <div className="list-geminiot">
                    <div className="mb-primary">
                      <img src={logoGeminiot} className="img-logo"></img>
                    </div>
                    <Space className="ml-30">
                      {PRODUCT_ITEM.geminiot.map((item) => (
                        <div
                          className={`product-item border-default ${addClassForItemSelected(
                            item.component,
                            item.systemConfig
                          )}`}
                        >
                          {item.childComponent}
                        </div>
                      ))}
                    </Space>
                  </div>
                  <div className="list-pasterios">
                    <div className="mb-primary">
                      <img src={logoPasterios} className="img-logo"></img>
                    </div>
                    <Space className="ml-30">
                      {PRODUCT_ITEM.pasteriot.map((item) => (
                        <div
                          className={`product-item border-default ${addClassForItemSelected(
                            item.component,
                            item.systemConfig
                          )}`}
                        >
                          {item.childComponent}
                        </div>
                      ))}
                    </Space>
                  </div>
                </Col>
              </Row>
            </div>
            <div className="ml-15">
              <Collapse
                defaultActiveKey={['1']}
                bordered={false}
                expandIcon={({ isActive }) => (
                  <CaretRightOutlined rotate={isActive ? 90 : 0} />
                )}
                className="site-collapse-custom-collapse mb-primary"
              >
                <Panel header={t('planNew.detailSetting')} key="1">
                  <ConfigurationComponent
                    type="planConfirm"
                    itemSetting={form.getFieldValue('itemSetting')}
                  />
                </Panel>
              </Collapse>
            </div>
          </div>
          <Col md={{ span: 4 }} className="title-sub color-primary">
            {t('share.option')}
          </Col>
          <UseOptionComponent data={state?.values} type="planConfirm" />
          <TimeComponentConfirm form={form} />
          <div className="campaign">
            <Col md={{ span: 4 }} className="title-sub color-primary">
              {t('share.campaignCode')}
            </Col>
            <div className="campaign-body border-default ml-15">
              <Row>
                <Col md={{ span: 4 }} className="bg-primary col-label">
                  {t('share.campaignCode')}
                </Col>
                <Col md={{ span: 20 }} className="col-control">
                  <Form.List name="campaignCodes">
                    {(fields) => (
                      <>
                        {fields.map((field) => (
                          <div className="mb-secondary">
                            <Space>
                              <Form.Item name={field.name} className="mb-0">
                                {form.getFieldValue([
                                  'campaignCodes',
                                  field.name
                                ])}
                              </Form.Item>
                            </Space>
                          </div>
                        ))}
                      </>
                    )}
                  </Form.List>
                </Col>
              </Row>
            </div>
          </div>
          <div className="line-primary"></div>
          <div className="introduc-store">
            <Col md={{ span: 4 }} className="title-sub color-primary">
              {t('planNew.introducStore')}
            </Col>
            <div className="introduc-store-body border-default ml-15">
              <Row className="row-item">
                <Col md={{ span: 4 }} className="col-label bg-secondary">
                  {t('share.regisCode')}
                </Col>
                <Col md={{ span: 20 }} className="col-control">
                  <Form.Item name="projectCode" className="mb-0">
                    {form.getFieldValue('projectCode')}
                  </Form.Item>
                </Col>
              </Row>
            </div>
          </div>
          <div className="line-primary"></div>
          <div className="text-center bg-dancer mb-primary p-5">
            <Button
              htmlType="submit"
              style={{ margin: '0 8px' }}
              className="btn-submit"
            >
              {t('planConfirm.changeSimulation')}
            </Button>
          </div>
          <Button
            className="btn-info bg-info border-default"
            onClick={() => {
              navigate('/planInput', { state });
            }}
          >
            {t('share.return')}
          </Button>
        </Form>
      </div>
    </>
  );
}

export default PlanConfirm;
