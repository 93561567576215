import React, { useState } from 'react';
import { Button, Modal, Space } from 'antd';
import { useTranslation } from 'react-i18next';

function CommonModalComponent(props) {
  const { t } = useTranslation();
  var onModal = props.isOpenModal;

  return (
    <Modal
      visible={onModal}
      title={props.title}
      width={props.width ? props.width : 600}
      closable={true}
      maskClosable={false}
      onCancel={props.close}
      footer={[
        props.dataModal.map((item, i) => (
          <Button
            key={i}
            onClick={item.onClick}
            className="mp-btn-primary"
            icon={item.icon}
          >
            {item.modalTextButton}
          </Button>
        ))
      ]}
      className={`common-modal ${props.className}`}
    >
      {props.modalContent}
    </Modal>
  );
}
export default CommonModalComponent;
