import React from 'react';
import { Col } from 'antd';
import { Link } from 'react-router-dom';
import Header from '../components/Header';
import { useTranslation } from 'react-i18next';

function PasswordChangeComplete() {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <Header />
      <div
        className="container-custom modal-primary box-register-completion"
        gutter={16}
      >
        <div className="box-title">{t('passwordChangeComplete.title')}</div>
        <div className="box-body">
          <div className="ant-form-item description">
            {t('passwordChangeComplete.des')}
          </div>
        </div>
        <Col offset={10}>
          <Link to="/" className="link mb-primary">
            {t('share.myPage')}
          </Link>
        </Col>
      </div>
    </React.Fragment>
  );
}

export default PasswordChangeComplete;
