import React, { useEffect } from 'react';
import '../i18n';
import Header from '../components/Header';

import { Row, Col, Form, Breadcrumb, Input, DatePicker, Button } from 'antd';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../AuthContext';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import PlanInfoArea from '../components/PlanInfoArea';
import { DATE_FORMAT } from '../constant';
import { useForceUpdate } from '../utils/utils';
import ItemSettingInput from '../components/ItemSettingInput';
import OptionComponentInput from '../components/OptionComponentInput';
import TimeComponentInput from '../components/TimeComponentInput';
import CampaignCodeInput from '../components/CampaignCodeInput';

function PlanNew() {
  const { state } = useLocation();
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const { user } = useAuth();
  const navigate = useNavigate();
  const forceUpdate = useForceUpdate();

  useEffect(() => {
    if (state?.values) {
      form.setFieldsValue(state.values);
      forceUpdate();
    }
  }, []);

  return (
    <>
      <Header />
      <div className="white-space"></div>
      <div className="box-plan plan-input">
        <Row className="title">
          <Col md={{ span: 4 }} className="page-title">
            {t('planNew.title')}
          </Col>
          <Col md={{ span: 20 }} className="text-right">
            <Breadcrumb separator=">">
              <Breadcrumb.Item className="current bold">
                {t('share.input')}
              </Breadcrumb.Item>
              <Breadcrumb.Item>{t('share.confirm')}</Breadcrumb.Item>
              <Breadcrumb.Item>{t('share.changeSimulation')}</Breadcrumb.Item>
            </Breadcrumb>
          </Col>
        </Row>
        <div className="text-right required"> {t('planNew.required')}</div>
        <Form
          name="frmPlanNew"
          form={form}
          autoComplete="off"
          onFinish={(values) => {
            navigate('/planConfirm', {
              state: { ...state, values: { ...state?.values, ...values } }
            });
          }}
        >
          <div className="ml-15">
            <PlanInfoArea data={state?.values} />
          </div>
          <Col md={{ span: 4 }} className="title-sub color-primary">
            {t('share.basicInformation')}
          </Col>
          <div className="base-infomation border-default mb-primary ml-15">
            <Form.Item
              label={t('share.companyName')}
              name="companyName"
              initialValue={user?.account?.companyName || ''}
              rules={[
                {
                  required: true,
                  whitespace: true,
                  message: t('share.companyName') + t('share.error.notEntered')
                }
              ]}
              className="isRequired"
            >
              <Input
                placeholder={t('planNew.companyNamePlaceholder')}
                className="txt-company-name"
              />
            </Form.Item>
            <div className="d-flex" style={{ alignItems: 'center' }}>
              <Form.Item
                shouldUpdate
                label={t('planNew.scheduledStartDate')}
                name="startDate"
                className="isRequired border-none"
                rules={[
                  {
                    required: true,
                    whitespace: true,
                    message:
                      t('planNew.scheduledStartDate') +
                      t('share.error.notEntered')
                  },
                  () => ({
                    validator(_, value) {
                      if (
                        !value ||
                        !moment(value, DATE_FORMAT).isBefore(
                          moment().format(DATE_FORMAT)
                        )
                      ) {
                        return Promise.resolve();
                      }

                      return Promise.reject(
                        new Error(
                          t('ご利用開始予定日は、本日以降をご指定ください。')
                        )
                      );
                    }
                  })
                ]}
              >
                <div></div>
                <DatePicker
                  value={
                    form.getFieldValue('startDate')
                      ? moment(form.getFieldValue('startDate'), DATE_FORMAT)
                      : undefined
                  }
                  format={DATE_FORMAT}
                  onChange={(date) => {
                    form.setFields([
                      {
                        name: 'startDate',
                        value: date.format(DATE_FORMAT)
                      }
                    ]);
                    forceUpdate();
                    form.validateFields(['startDate']);
                  }}
                />
              </Form.Item>
              <span>{t('planInput.note')}</span>
            </div>
          </div>
          <div className="usage-plan">
            <Col md={{ span: 4 }} className="title-sub color-primary">
              {t('share.usagePlan')}
            </Col>
            <ItemSettingInput form={form} type="planInput" />
          </div>
          <div className="option">
            <Col md={{ span: 4 }} className="title-sub color-primary">
              {t('share.option')}
            </Col>
            <OptionComponentInput form={form} />
            <div className="ml-15">{t('planNewOptionNote')}</div>
          </div>
          <div className="system-usage-time">
            <Col md={{ span: 4 }} className="title-sub color-primary">
              {t('share.systemUsageTime')}
            </Col>
            <TimeComponentInput form={form} />
            <div className="ml-15">
              <div>{t('planNewNote1')}</div>
              <div>{t('planNewNote2')}</div>
              <div>{t('planNewNote3')}</div>
            </div>
          </div>
          <CampaignCodeInput
            forceUpdate={forceUpdate}
            form={form}
            isCheckProjectCode
          />
          <div className="line-primary"></div>
          <div className="introduc-store">
            <Col md={{ span: 4 }} className="title-sub color-primary">
              {t('planNew.introducStore')}
            </Col>
            <div className="introduc-store-body border-default ml-15">
              <Row className="row-item">
                <Col md={{ span: 4 }} className="col-label bg-secondary">
                  {t('share.regisCode')}
                </Col>
                <Col md={{ span: 20 }} className="col-control">
                  <Form.Item name="projectCode" className="mb-0">
                    <Input className="txtPrimary" />
                  </Form.Item>
                  <div className="ant-form-item-explain-error">
                    {
                      form.getFieldValue('customValidateMessage')?.[
                        'projectCodeMessage'
                      ]
                    }
                  </div>
                </Col>
              </Row>
            </div>
            <div className="ml-15">{t('planNewIntroducStoreNote')}</div>
          </div>
          <div className="line-primary"></div>
          <div className="text-center bg-dancer mb-primary p-5">
            <button type="submit" className="btn-submit cursor-pointer">
              {t('planNewBtnConfirm')}
            </button>
          </div>
          <Button
            onClick={() => {
              if (state?.values?.planId) {
                navigate(
                  `/planDetail?planId=${state.values.planId}&planBranchNum=${state.values.planBranchNum}`,
                  {
                    replace: true
                  }
                );
              } else {
                navigate('/', { replace: true });
              }
            }}
            className="bg-info btn-info border-default"
          >
            {t('share.return')}
          </Button>
        </Form>
      </div>
    </>
  );
}

export default PlanNew;
