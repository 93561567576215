import React, { useEffect } from 'react';
import Header from '../components/Header';
import '../i18n';
import { Row, Col, Breadcrumb, Form, Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';
import FooterComponent from '../components/FooterComponent';
import { useForceUpdate } from '../utils/utils';
import TimeComponentInput from '../components/TimeComponentInput';

function ChangeSystemUsageTime() {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { state } = useLocation();
  const forceUpdate = useForceUpdate();

  useEffect(() => {
    if (state?.values) {
      form.setFieldsValue(state.values);
      forceUpdate();
    }
  }, []);
  return (
    <>
      <Header />
      <div className="white-space"></div>
      <div className="box-plan container-custom-primary change-system-usage-time">
        <Row className="title mb-primary">
          <Col md={{ span: 4 }} className="page-title">
            {t('changeSystemUsageTime.title')}
          </Col>
          <Col md={{ span: 20 }} className="text-right">
            <Breadcrumb separator=">">
              <Breadcrumb.Item className="bold current">
                {t('share.input')}
              </Breadcrumb.Item>
              <Breadcrumb.Item>{t('share.confirm')}</Breadcrumb.Item>
              <Breadcrumb.Item>{t('share.completion')}</Breadcrumb.Item>
            </Breadcrumb>
          </Col>
        </Row>
        <Form
          name="frmPlanNew"
          form={form}
          autoComplete="off"
          onFinish={(values) => {
            navigate('/changeSystemUsageTimeConfirm', {
              state: {
                ...state,
                values: { ...state?.values, ...values },
                prevData: state?.prevData || {
                  ...state?.values.systemUtilizationTimes
                }
              }
            });
          }}
        >
          <div className="usage-plan mb-primary">
            <Col md={{ span: 10 }} className="title-sub color-primary">
              {t('share.usagePlan')}
            </Col>
            <Col md={{ span: 10 }} className="border-default ml-15">
              <Row className="border-bottom-default">
                <Col className="bg-primary border-right-default pl-5 col-left-lbl">
                  {t('share.planId')}
                </Col>
                <Col>
                  <Form.Item name="planDetail.planId" className="mb-0 pl-15">
                    {state?.values?.planId}
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col className="bg-primary border-right-default pl-5 col-left-lbl">
                  {t('planDetail.planUsageStartDate')}
                </Col>
                <Col>
                  <Form.Item name="" className="mb-0 pl-15">
                    {state?.values?.useStartDate}
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </div>
          <div className="system-usage-time">
            <Col md={{ span: 4 }} className="title-sub color-primary">
              {t('share.systemUsageTime')}
            </Col>
            <div className="mb-primary">
              <TimeComponentInput form={form} />
            </div>
            <div className="mb-primary ml-15">
              <div>{t('OptionChange.note')}</div>
              <div className="ml-15">
                <div>{t('planNewNote1')}</div>
                <div>{t('planNewNote2')}</div>
              </div>
            </div>
          </div>
          <div className="bg-dancer text-center p-5">
            <button type="submit" className="btn-submit cursor-pointer">
              {t('share.confirm')}
            </button>
          </div>
          <div className="line-primary"></div>
          <Button
            onClick={() => {
              navigate(
                `/planDetail?planId=${state.values.planId}&planBranchNum=${state.values.planBranchNum}`,
                {
                  replace: true
                }
              );
            }}
            className="btn-submit"
          >
            {t('share.return')}
          </Button>
        </Form>
      </div>
      <FooterComponent />
    </>
  );
}
export default ChangeSystemUsageTime;
