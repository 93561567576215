import React, { useEffect } from 'react';
import Header from '../components/Header';
import '../i18n';
import { Row, Col, Breadcrumb, Form, Space, Collapse, Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { CaretRightOutlined } from '@ant-design/icons';
import logoGeminiot from '../assets/images/logo-geminiot.png';
import logoPasterios from '../assets/images/logo-pasterios.png';
import { useLocation, useNavigate } from 'react-router-dom';
import ConfigurationComponent from '../components/ConfigurationComponent';
import { PRODUCT_ITEM } from '../constant';
import PlanInfoArea from '../components/PlanInfoArea';
import { useForceUpdate } from '../utils/utils';
import isEqual from 'lodash/isEqual';

function PlanChangeApplicationConfirm() {
  const forceUpdate = useForceUpdate();
  const { t } = useTranslation();
  const { Panel } = Collapse;
  const { state } = useLocation();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  useEffect(() => {
    form.setFieldsValue(state.values);
    forceUpdate();
  }, []);

  const addClassForItemSelected = (component, type) => {
    if (
      form.getFieldValue('component') === component &&
      form.getFieldValue('systemConfig') === type
    )
      return `product-item-selected  ${
        state?.prevData &&
        isEqual(
          {
            component: state.prevData.component,
            systemConfig: state.prevData.systemConfig
          },
          {
            component,
            systemConfig: type
          }
        )
          ? ''
          : 'product-item-selected-changed'
      } `;
    return '';
  };
  return (
    <>
      <Header />
      <div className="white-space"></div>
      <div className="container-custom-primary plan-change-application-confirm">
        <Row className="title">
          <Col md={{ span: 12 }} className="page-title">
            {t('share.planChangeRegistration')}
            {' / '}
            {t('share.confirm')}
          </Col>
          <Col md={{ span: 12 }} className="text-right">
            <Breadcrumb separator=">">
              <Breadcrumb.Item>{t('share.input')}</Breadcrumb.Item>
              <Breadcrumb.Item className="bold current">
                {t('share.confirm')}
              </Breadcrumb.Item>
              <Breadcrumb.Item>{t('share.changeSimulation')}</Breadcrumb.Item>
            </Breadcrumb>
          </Col>
        </Row>
        <div className="text-right required"> {t('planNew.required')}</div>
        <Form
          name="frmPlanNew"
          form={form}
          autoComplete="off"
          onFinish={() => {
            navigate('/planChangeApplicationFeeSimulation', {
              state
            });
          }}
        >
          <div className="usage-plan mb-primary">
            <Col md={{ span: 4 }} className="title-sub color-primary">
              {t('share.usagePlan')}
            </Col>
            <div className="ml-15">
              <PlanInfoArea
                data={state?.values}
                isOnlyShowPlanId={state?.isNewPlanBranch}
              />
            </div>
          </div>
          <div className="plan-change mb-primary">
            <Col md={{ span: 4 }} className="title-sub color-primary">
              {t('share.planChange')}
            </Col>
            <div className="base-infomation border-default mb-primary ml-15">
              <Form.Item
                label={t('share.scheduledPlanChangeDate')}
                name="startDate"
              >
                {form.getFieldValue('startDate')}
              </Form.Item>
            </div>
          </div>
          {state.values.planBranchNum === '001' && (
            <Col md={{ span: 4 }} className="title-sub color-primary">
              {t('changeApplicationConfirm.usagePlan')}
            </Col>
          )}
          <div className="usage-plan-body border-default ml-15">
            <Row>
              <Col md={{ span: 4 }} className="bg-primary col-label">
                {' '}
                {t('planNew.productSelection')}
              </Col>
              <Col md={{ span: 16 }} className="col-list-product">
                <div className="list-geminiot">
                  <div className="mb-primary">
                    <img src={logoGeminiot} className="img-logo"></img>
                  </div>
                  <Space>
                    {PRODUCT_ITEM.geminiot.map((item) => (
                      <div
                        className={`product-item border-default ${addClassForItemSelected(
                          item.component,
                          item.systemConfig
                        )}`}
                      >
                        {item.childComponent}
                      </div>
                    ))}
                  </Space>
                </div>
                <div className="list-pasterios">
                  <div className="mb-primary">
                    <img src={logoPasterios} className="img-logo"></img>
                  </div>
                  <Space>
                    {PRODUCT_ITEM.pasteriot.map((item) => (
                      <div
                        className={`product-item border-default ${addClassForItemSelected(
                          item.component,
                          item.systemConfig
                        )}`}
                      >
                        {item.childComponent}
                      </div>
                    ))}
                  </Space>
                </div>
              </Col>
            </Row>
          </div>
          <div className="usage-plan ml-15">
            <Collapse
              defaultActiveKey={['1']}
              bordered={false}
              expandIcon={({ isActive }) => (
                <CaretRightOutlined rotate={isActive ? 90 : 0} />
              )}
              className="site-collapse-custom-collapse mb-primary"
            >
              <Panel header={t('planNew.detailSetting')} key="1">
                <div className="ml-15">
                  <ConfigurationComponent
                    type="planConfirm"
                    itemSetting={form.getFieldValue('itemSetting')}
                  />
                </div>
              </Panel>
            </Collapse>
          </div>
          <div className="line-primary"></div>
          <div className="text-center bg-dancer mb-primary p-5">
            <Button htmlType="submit" className="btn-submit">
              {t('planConfirm.changeSimulation')}
            </Button>
          </div>
          <Button
            onClick={() => {
              navigate(`/planChangeApplicationInput`, {
                state
              });
            }}
            className="btn-return"
          >
            {t('share.return')}
          </Button>
        </Form>
      </div>
    </>
  );
}
export default PlanChangeApplicationConfirm;
