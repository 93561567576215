import React, { useEffect } from 'react';
import Header from '../components/Header';
import '../i18n';
import { Row, Col, Breadcrumb, Button } from 'antd';
import { useTranslation } from 'react-i18next';
import FooterComponent from '../components/FooterComponent';
import { useNavigate, useLocation, Link } from 'react-router-dom';

function ApplicationCompletion() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { state } = useLocation();

  useEffect(() => {
    if (!state?.values?.planId) {
      navigate('/', { replace: true });
    }
  }, []);

  return (
    <>
      <Header />
      <div className="white-space"></div>
      <div className="container-custom-primary">
        <Row className="title mb-primary">
          <Col md={{ span: 4 }} className="page-title">
            {t('share.Application')}
            {' / '}
            {t('share.completion')}
          </Col>
          <Col md={{ span: 20 }} className="text-right">
            <Breadcrumb separator=">">
              <Breadcrumb.Item>
                {t('paymentInformationRegistration.title')}
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                {t('paymentInformationRegistration.useStartDateSetting')}
              </Breadcrumb.Item>
              <Breadcrumb.Item>{t('share.confirm')}</Breadcrumb.Item>
              <Breadcrumb.Item className="bold current">
                {t('share.completion')}
              </Breadcrumb.Item>
            </Breadcrumb>
          </Col>
        </Row>
        <div>{t('applicationCompletion.NewApplicationCompleted')}</div>
        <div>{t('ChangeApplicationCompletion.note2')}</div>
        <div className="white-space"></div>
        <div className="text-center bg-dancer p-5">
          <Link to="/" className="btn-return">
            {t('ChangeApplicationCompletion.toThePlanList')}
          </Link>
        </div>
      </div>
      <FooterComponent />
    </>
  );
}
export default ApplicationCompletion;
