import React, { useEffect, useState } from 'react';
import '../i18n';
import Header from '../components/Header';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { Button, Col, Modal, Row, Tag } from 'antd';
import avatar from '../assets/images/avatar.jpg';
import { Link } from 'react-router-dom';

function TrainingList() {
  const { t, i18n } = useTranslation();
  const [showTermsOfService, setShowTermsOfService] = useState(false);
  const [termsOfService, setTermsOfService] = useState();

  useEffect(() => {
    if (!i18n.language) return;
    getTermsOfService(i18n.language);
  }, [i18n.language]);

  const getTermsOfService = (language) => {
    axios({
      method: 'get',
      url: '/api/getTermsOfService',
      params: {
        language: language
      }
    }).then((res) => {
      setTermsOfService(res.data);
    });
  };

  return (
    <>
      <Header />
      <div className="white-space"></div>
      <div className="box-plan">
        <h2 className="bg-dancer pl-10">MyPage</h2>
        <div className="title page-title mb-primary">{t('training.title')}</div>
        <div class="ant-col mb-primary ant-col-md-6 border-bottom-default bold">
          基本概念
        </div>
        <Row gutter={[15, 15]}>
          <Col md={6}>
            <div className="article border-default">
              <img src={avatar} className="mb-secondary" />
              <div className="text-right">
                <Tag bordered={false} color="magenta">
                  New
                </Tag>
              </div>
              <div className="mb-primary">
                <div className="article-title">
                  デジタルツイン／AI型第3世代BI
                </div>
                <div>『Geminiot』 基本概念</div>
              </div>
              <div className="text-center mb-primary">
                <Link to="#!" className="btn-view-file">
                  {t('training.ViewMaterials')}
                </Link>
              </div>
            </div>
          </Col>
          <Col md={6}>
            <div className="article border-default">
              <img src={avatar} className="mb-secondary" />
              <div className="text-right">
                <Tag bordered={false} color="magenta">
                  New
                </Tag>
              </div>
              <div className="mb-primary">
                <div className="article-title">
                  デジタルツイン／AI型第3世代BI
                </div>
                <div>『Geminiot』 基本概念</div>
              </div>
              <div className="text-center mb-primary">
                <Link to="#!" className="btn-view-file">
                  {t('training.ViewMaterials')}
                </Link>
              </div>
            </div>
          </Col>
          <Col md={6}>
            <div className="article border-default">
              <img src={avatar} className="mb-secondary" />
              <div className="text-right">
                <Tag bordered={false} color="magenta">
                  New
                </Tag>
              </div>
              <div className="mb-primary">
                <div className="article-title">
                  デジタルツイン／AI型第3世代BI
                </div>
                <div>『Geminiot』 基本概念</div>
              </div>
              <div className="text-center mb-primary">
                <Link to="#!" className="btn-view-file">
                  {t('training.ViewMaterials')}
                </Link>
              </div>
            </div>
          </Col>
          <Col md={6}>
            <div className="article border-default">
              <img src={avatar} className="mb-secondary" />
              <div className="text-right">
                <Tag bordered={false} color="magenta">
                  New
                </Tag>
              </div>
              <div className="mb-primary">
                <div className="article-title">
                  デジタルツイン／AI型第3世代BI
                </div>
                <div>『Geminiot』 基本概念</div>
              </div>
              <div className="text-center mb-primary">
                <Link to="#!" className="btn-view-file">
                  {t('training.ViewMaterials')}
                </Link>
              </div>
            </div>
          </Col>
          <Col md={6}>
            <div className="article border-default">
              <img src={avatar} className="mb-secondary" />
              <div className="text-right">
                <Tag bordered={false} color="magenta">
                  New
                </Tag>
              </div>
              <div className="mb-primary">
                <div className="article-title">
                  デジタルツイン／AI型第3世代BI
                </div>
                <div>『Geminiot』 基本概念</div>
              </div>
              <div className="text-center mb-primary">
                <Link to="#!" className="btn-view-file">
                  {t('training.ViewMaterials')}
                </Link>
              </div>
            </div>
          </Col>
          <Col md={6}>
            <div className="article border-default">
              <img src={avatar} className="mb-secondary" />
              <div className="text-right">
                <Tag bordered={false} color="magenta">
                  New
                </Tag>
              </div>
              <div className="mb-primary">
                <div className="article-title">
                  デジタルツイン／AI型第3世代BI
                </div>
                <div>『Geminiot』 基本概念</div>
              </div>
              <div className="text-center mb-primary">
                <Link to="#!" className="btn-view-file">
                  {t('training.ViewMaterials')}
                </Link>
              </div>
            </div>
          </Col>
        </Row>
      </div>
      <div className="footer-home text-center">
        <div className="line-default"></div>
        <div className="mb-primary">
          <a
            className="hover-underline"
            onClick={() => {
              setShowTermsOfService(true);
            }}
          >
            {t('share.termOfService')}
          </a>
        </div>
        <div>© DTS CORPORATION All Rights Reserved</div>
      </div>
      <Modal
        centered
        title={t('share.termOfService')}
        visible={showTermsOfService}
        onCancel={() => {
          setShowTermsOfService(false);
        }}
        footer={[
          <Button
            className="bg-info btn-info border-default"
            key="back"
            onClick={() => {
              setShowTermsOfService(false);
            }}
          >
            {t('share.return')}
          </Button>
        ]}
      >
        <p style={{ whiteSpace: 'break-spaces' }}>
          {termsOfService?.messageText}
        </p>
      </Modal>
    </>
  );
}

export default TrainingList;
