import React, { useEffect, useState } from 'react';
import '../i18n';
import Header from '../components/Header';
import { useTranslation } from 'react-i18next';
import { Row, Col, Breadcrumb, Typography, Button } from 'antd';
import logoGeminiot from '../assets/images/logo-geminiot.png';
import logoPasterios from '../assets/images/logo-pasterios.png';
import UserTimeComponent from '../components/UserTimeComponent';
import ServerListComponent from '../components/ServerListComponent';
import PriceComponent from '../components/PriceComponent';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment';
import { DATE_FORMAT, USE_NOTUSE } from '../constant';
import PlanInfoArea from '../components/PlanInfoArea';

function FreeSimulation() {
  const { Title } = Typography;
  const { t } = useTranslation();
  const { state } = useLocation();
  const [priceSimultion, setPriceSimultion] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    getSimulatorPrice();
  }, []);

  const getSimulatorPrice = () => {
    const url = '/api/getPriceSimultion';
    axios
      .post(url, {
        ...state.values
      })
      .then((res) => {
        setPriceSimultion(res.data);
      });
  };

  const savePlan = () => {
    const url = !state.values.planId ? '/api/addPlan' : '/api/updatePlan';
    axios
      .post(url, {
        ...state.values
      })
      .then(() => {
        if (state?.values?.planId) {
          navigate(
            `/planDetail?planId=${state.values.planId}&planBranchNum=${state.values.planBranchNum}`,
            {
              replace: true
            }
          );
        } else {
          navigate('/', { replace: true });
        }
      });
  };
  return (
    <>
      <Header />
      <div className="white-space"></div>
      <div className="container-custom-secondary free-simulation">
        <Row className="title mb-primary">
          <Col md={{ span: 12 }} className="page-title">
            {t('planNew.title')}
            {' / '}
            {t('planConfirm.changeSimulation')}
          </Col>
          <Col md={{ span: 12 }} className="text-right">
            <Breadcrumb separator=">">
              <Breadcrumb.Item>{t('share.input')}</Breadcrumb.Item>
              <Breadcrumb.Item>{t('share.confirm')}</Breadcrumb.Item>
              <Breadcrumb.Item className="current bold">
                {t('share.changeSimulation')}
              </Breadcrumb.Item>
            </Breadcrumb>
          </Col>
        </Row>
        <PlanInfoArea data={state?.values} />
        <Row className="border-default mb-primary">
          <Col
            md={{ span: 3 }}
            className="bg-primary vertical-align-center border-right-default pl-5"
          >
            {t('share.product')}
          </Col>
          <Col md={{ span: 5 }} className="border-right-default pl-5">
            <img
              src={state.values.component === 1 ? logoGeminiot : logoPasterios}
              className="img-logo"
            />
          </Col>
          <Col
            md={{ span: 5 }}
            className="bg-primary vertical-align-center border-right-default pl-5"
          >
            {t('freeSimulation.simulationStartDate')}
          </Col>
          <Col md={{ span: 3 }} className="align-center border-right-default">
            {state.values.startDate}
          </Col>
          <Col
            md={{ span: 5 }}
            className="bg-primary vertical-align-center border-right-default pl-5"
          >
            {t('freeSimulation.simulationDate')}
          </Col>
          <Col md={{ span: 3 }} className="align-center">
            {moment().format(DATE_FORMAT)}
          </Col>
        </Row>
        <div className="border-default box-simulation">
          <Title level={5}>{t('freeSimulation.annualUsageFee')}</Title>
          <PriceComponent type="freeSimulation" simulation={priceSimultion} />
          <div className="line-default"></div>
          <div className="title-secondary mb-secondary ml-15">
            {t('share.basicComposition')}
          </div>

          <ServerListComponent
            type="freeSimulation"
            itemSetting={state.values?.itemSetting}
          />
          <div className="option">
            <div className="title-secondary mb-secondary ml-15">
              {t('share.option')}
            </div>
            {state.values?.useBackup === USE_NOTUSE.Use && (
              <Row className="row-option">
                <Col md={{ span: 6 }}>{t('share.dataBackup')}</Col>
                <Col md={{ span: 6 }}>{state.values.backupGeneration}世代</Col>
              </Row>
            )}
          </div>
          <div className="user-time">
            <div className="title-secondary mb-primary ml-15">
              {t('freeSimulation.useTime')}
            </div>
            <UserTimeComponent
              type="freeSimulation"
              systemUtilizationTimes={state.values?.systemUtilizationTimes}
            />
          </div>
        </div>
        <div>{t('freeSimulation.note1')}</div>
        <div>{t('freeSimulation.note2')}</div>
        <div className="line-primary"></div>
        <div className="text-center bg-dancer mb-primary p-5">
          <button
            type="button"
            className="btn-submit cursor-pointer"
            onClick={() => {
              savePlan();
            }}
          >
            {t('freeSimulation.save')}
          </button>
        </div>
        <Button
          className="btn-return"
          onClick={() => {
            navigate('/planConfirm', { state });
          }}
        >
          {t('share.return')}
        </Button>
      </div>
    </>
  );
}

export default FreeSimulation;
