import React, { useState } from 'react';
import { Form, Input, Row, Col } from 'antd';
import axios from 'axios';
import Header from '../components/Header';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function EmailChange() {
  const [email, setEmail] = useState({ mail: '', error: '', isExist: false });
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const onfinish = (values) => {
    const url = '/api/changeMail';
    axios.post(url, { mail: values.email }).then(({ data }) => {
      if (data.exist) {
        setEmail({
          mail: data.mail,
          isExist: data.exist,
          error: 'emailRegister.emailExist'
        });
      } else {
        navigate('/emailAddressChangeReception');
      }
    });
  };
  return (
    <React.Fragment>
      <Header />
      <div
        className="container-custom-big modal-primary email-change"
        gutter={16}
      >
        <div className="box-title">{t('emailChange.title')}</div>
        <div className="box-body">
          <div className="ant-form-item description">
            {t('emailChange.des')}
            <br />
            {t('emailChange.des2')}
          </div>
          <div className="message-error">{t(email.error)}</div>
        </div>
        <Form
          form={form}
          onFinish={(values) => {
            onfinish(values);
          }}
          className="mb-primary ml-primary"
        >
          <Row gutter={16}>
            <Col
              className="gutter-row col-lbl"
              md={{ span: 3 }}
              xs={{ span: 5 }}
            >
              E-mail:
            </Col>
            <Col
              className="gutter-row center"
              md={{ span: 21 }}
              xs={{ span: 19 }}
            >
              <Form.Item
                name="email"
                rules={[
                  {
                    required: true,
                    message: t('emailRegister.error.emailBlank')
                  }
                ]}
              >
                <Input
                  placeholder="xxx.xxxx@xyz.com"
                  className="txt-primary txt-email"
                />
              </Form.Item>
              <Form.Item>
                <button
                  type="submit"
                  style={{ margin: '0 8px' }}
                  className="btn-primary cursor-pointer"
                >
                  {t('button.send')}
                </button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
    </React.Fragment>
  );
}
export default EmailChange;
