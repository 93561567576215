import React from 'react';
import { Col, Breadcrumb, Row, Button } from 'antd';
import Header from '../components/Header';
import { useTranslation } from 'react-i18next';
import { PAYMENT_METHOD } from '../constant';
import { CaretDownOutlined } from '@ant-design/icons';
import FooterComponent from '../components/FooterComponent';

function PaymentRegistration() {
  const { t } = useTranslation();

  return (
    <>
      <Header />
      <div className="white-space"></div>
      <div className="container-custom-primary">
        <Row className="title mb-primary">
          <Col md={{ span: 10 }} className="page-title">
            {t('paymentRegistration.title')}
          </Col>
          <Col md={{ span: 14 }} className="text-right">
            <Breadcrumb separator=">">
              <Breadcrumb.Item className="current bold">
                {t('paymentRegistration.title')}
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                {t('paymentRegistration.externalSite')}
              </Breadcrumb.Item>
              <Breadcrumb.Item>{t('share.completion')}</Breadcrumb.Item>
            </Breadcrumb>
          </Col>
        </Row>
        <div className="white-space"></div>
        <div className="text-center mb-primary">
          {t('paymentRegistration.note1')}
        </div>
        <div className="border-default mb-primary">
          <Row>
            <Col
              md={{ span: 4 }}
              className="bg-primary border-right-default pl-5"
            >
              {t('share.paymentMethod')}
            </Col>
            <Col md={{ span: 20 }} className="pl-5">
              {t(PAYMENT_METHOD[1])}
            </Col>
          </Row>
        </div>
        <div className="border-default mb-primary">
          <Row className="border-bottom-default">
            <Col
              md={{ span: 20 }}
              className="bg-primary border-right-default pl-5"
            >
              <div>
                <CaretDownOutlined />
                {t('paymentRegistration.note2')}
              </div>
              <div>
                <CaretDownOutlined />
                {t('paymentRegistration.note3')}
              </div>
            </Col>
            <Col md={{ span: 4 }} className="pl-5 vertical-align-center">
              <Button className="btn-submit m-auto">
                {t('paymentRegistration.ChangeOrInquire')}
              </Button>
            </Col>
          </Row>
          <Row>
            <Col
              md={{ span: 20 }}
              className="bg-primary border-right-default pl-5"
            >
              <div>
                <CaretDownOutlined />
                {t('paymentRegistration.note4')}
              </div>
              <div>{t('paymentRegistration.note5')}</div>
            </Col>
            <Col md={{ span: 4 }} className="pl-5 vertical-align-center">
              <button className="btn-submit m-auto cursor-pointer">
                {t('share.delete')}
              </button>
            </Col>
          </Row>
        </div>
        <div className="text-center">
          {t('paymentInformationRegistration.note1')}
          <div>{t('paymentRegistration.note7')}</div>
        </div>
        <div className="white-space"></div>
        <div className="text-center bg-dancer mb-primary p-5">
          <Button htmlType="submit" className="btn-submit">
            {t('share.return')}
          </Button>
        </div>
        <div>{t('paymentInformationRegistration.pleaseNote')}</div>
        <div className="mb-primary">{t('paymentRegistration.note8')}</div>
        <FooterComponent />
      </div>
    </>
  );
}

export default PaymentRegistration;
