import React, { useEffect, useState } from 'react';
import {
  Breadcrumb,
  Button,
  Col,
  Form,
  Input,
  InputNumber,
  Radio,
  Row,
  Select
} from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
import Header from '../components/Header';
import { useTranslation } from 'react-i18next';
import logoGeminiot from '../assets/images/logo-geminiot.png';
import logoPasterios from '../assets/images/logo-pasterios.png';
import FooterComponent from '../components/FooterComponent';
import { useForceUpdate } from '../utils/utils';
import { MaskedInput } from 'antd-mask-input';
import axios from 'axios';
import { PROTOCOL } from '../constant';
import { v4 as v4Re } from 'cidr-regex';

function SecuritysettingsInput() {
  const { t } = useTranslation();
  const [data, setData] = useState([]);
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { state } = useLocation();
  const forceUpdate = useForceUpdate();

  useEffect(() => {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const planId = params.get('planId') || state?.values?.planId;
    if (!planId) {
      navigate('/');
      return;
    }

    if (state?.values?.planId) {
      setData(state.values);
      form.setFieldsValue(state.values);
      forceUpdate();
    } else {
      axios
        .get('/api/getSecuritySetting', {
          params: {
            planId
          }
        })
        .then((res) => {
          const { data } = res;
          setData(data);
          form.setFieldsValue({
            lstIpAddress: [
              ...(data?.lstIpAddress?.map((item) => ({
                ...item,
                type: checkType(item.minPort, item.maxPort)
              })) || [])
            ]
          });
          forceUpdate();
        });
    }
  }, []);

  const checkType = (minPortValue, maxPortValue) => {
    const minPort = minPortValue ?? '';
    const maxPort = maxPortValue ?? '';
    if (minPort === '' && maxPort === '') {
      return 1;
    } else if (minPort !== '' && maxPort === '') {
      return 2;
    }
    return 3;
  };
  const checkDisablePortInput = (type, portType) => {
    if (type === 1) {
      return true;
    } else if (type === 2) {
      if (portType === 'maxPort') {
        return true;
      }
    }
    return false;
  };
  return (
    <>
      <Header />
      <div className="white-space"></div>
      <div className="container-custom-primary">
        <Row className="title mb-primary">
          <Col md={{ span: 6 }} className="page-title">
            {t('Securitysettings')}
          </Col>
          <Col md={{ span: 18 }} className="text-right">
            <Breadcrumb separator=">">
              <Breadcrumb.Item className="bold current">
                {t('share.input')}
              </Breadcrumb.Item>
              <Breadcrumb.Item>{t('share.confirm')}</Breadcrumb.Item>
              <Breadcrumb.Item>{t('share.completion')}</Breadcrumb.Item>
            </Breadcrumb>
          </Col>
        </Row>
        <Col md={{ span: 4 }} className="title-sub color-primary">
          {t('share.basicInformation')}
        </Col>
        <div className="border-default mb-primary">
          <Row className="border-bottom-default">
            <Col
              md={{ span: 6 }}
              className="bg-primary border-right-default pl-5"
            >
              {t('share.planId')}
            </Col>
            <Col md={{ span: 18 }} className="pl-5">
              {data?.planId}
            </Col>
          </Row>
          <Row>
            <Col
              md={{ span: 6 }}
              className="bg-primary border-right-default pl-5"
            >
              {t('plan.component')}
            </Col>
            <Col md={{ span: 18 }} className="pl-5">
              <img
                src={data?.component === 1 ? logoGeminiot : logoPasterios}
                className="img-logo pt-5 pb-5"
              />
            </Col>
          </Row>
        </div>
        <Form
          className="config-form"
          name="frmSecuritysettings"
          form={form}
          autoComplete="off"
          onFinish={(values) => {
            navigate('/securitysettingsConfirm', {
              state: {
                ...state,
                values: {
                  planId: data.planId,
                  component: data.component,
                  ...values
                }
              }
            });
          }}
        >
          <Col md={{ span: 4 }} className="title-sub color-primary">
            {t('Configuration')}
          </Col>
          <div className="border-default mb-primary">
            <Row>
              <Col
                md={{ span: 6 }}
                className="bg-primary border-right-default pl-5"
              >
                {t('Securitysettings.IPAddressRestrictions')}
              </Col>
              <Col md={{ span: 18 }} className="p-10">
                <div className="bg-primary mb-primary">
                  <Row>
                    <Col span={21}> {t('Securitysettings.Accessible')}</Col>
                    <Col span={3} className="text-center">
                      <Button
                        className="bg-info btn-info border-default"
                        onClick={() => {
                          const lst = form.getFieldValue('lstIpAddress') || [];
                          if (lst.length >= data?.maxItem) return;
                          form.setFieldsValue({
                            lstIpAddress: [...lst, { type: 1 }]
                          });
                          forceUpdate();
                        }}
                      >
                        {t('Securitysettings.add')}
                      </Button>
                    </Col>
                  </Row>
                </div>
                {form.getFieldValue('lstIpAddress')?.map((item, idx) => (
                  <Row>
                    <Col span={21}>
                      <div className="border-default p-10 mb-secondary">
                        <Row gutter={[8, 8]} className="mb-primary">
                          <Col span={7}>
                            {t('Securitysettings.SourceCIDR')}
                            <Form.Item
                              name={['lstIpAddress', idx, 'ipAddr']}
                              className="mb-0"
                              rules={[
                                { required: true, message: '' },
                                {
                                  validator: async (_, value) => {
                                    const re4 = v4Re({ exact: true });

                                    if (value && !re4.test(value)) {
                                      return Promise.reject(new Error(t('')));
                                    }
                                  }
                                }
                              ]}
                            >
                              <MaskedInput
                                className="input-mask"
                                mask={'0[0][0].0[0][0].0[0][0].0[0][0]/0[0]'}
                                placeholder="192.168.1.0/24"
                              />
                            </Form.Item>
                          </Col>
                          <Col span={7}>
                            {t('Securitysettings.IPProtocol')}
                            <Form.Item
                              name={['lstIpAddress', idx, 'protocol']}
                              className="mb-0"
                              // validateStatus="error"
                              rules={[{ required: true, message: '' }]}
                              initialValue={1}
                            >
                              <Select
                                options={Object.keys(PROTOCOL).map((value) => ({
                                  value: Number(value),
                                  label: PROTOCOL[value]
                                }))}
                              ></Select>
                            </Form.Item>
                          </Col>
                          <Col span={7}></Col>
                        </Row>
                        <Row gutter={[8, 8]} className="mb-primary">
                          <Col span={6}>
                            {t('Securitysettings.destinationPortNumber')}
                            <Form.Item
                              name={['lstIpAddress', idx, 'minPort']}
                              className="mb-0"
                              rules={[
                                {
                                  required: [2, 3].includes(
                                    form.getFieldValue([
                                      'lstIpAddress',
                                      idx,
                                      'type'
                                    ])
                                  ),
                                  message: ''
                                },
                                () => ({
                                  validator(_, value) {
                                    if (
                                      form.getFieldValue([
                                        'lstIpAddress',
                                        idx,
                                        'type'
                                      ]) !== 3
                                    )
                                      return Promise.resolve();
                                    form.setFields([
                                      {
                                        name: ['lstIpAddress', 0, 'maxPort'],
                                        errors: []
                                      }
                                    ]);
                                    forceUpdate();
                                    if (
                                      form.getFieldValue([
                                        'lstIpAddress',
                                        idx,
                                        'maxPort'
                                      ]) < value
                                    ) {
                                      return Promise.reject(new Error(''));
                                    }
                                    return Promise.resolve();
                                  }
                                })
                              ]}
                            >
                              <InputNumber
                                disabled={checkDisablePortInput(
                                  form.getFieldValue([
                                    'lstIpAddress',
                                    idx,
                                    'type'
                                  ]),
                                  'minPort'
                                )}
                                min={0}
                                max={65535}
                                className="number-input"
                                style={{ width: '100%' }}
                                placeholder="ALL"
                              />
                            </Form.Item>
                          </Col>
                          <Col span={18}>
                            <Form.Item
                              name={['lstIpAddress', idx, 'type']}
                              className="mb-0"
                              initialValue={1}
                            >
                              <Radio.Group
                                onChange={(elm) => {
                                  form.setFields([
                                    {
                                      name: ['lstIpAddress', idx, 'minPort'],
                                      errors: [],
                                      value: undefined
                                    },
                                    {
                                      name: ['lstIpAddress', idx, 'maxPort'],
                                      errors: [],
                                      value: undefined
                                    }
                                  ]);
                                  forceUpdate();
                                }}
                              >
                                <Radio value={1}>
                                  {t('Securitysettings.port.all')}
                                </Radio>
                                <Radio value={2}>
                                  {' '}
                                  {t('Securitysettings.port.single')}
                                </Radio>
                                <Radio value={3}>
                                  {' '}
                                  {t('Securitysettings.port.range')}
                                </Radio>
                              </Radio.Group>
                            </Form.Item>
                            <Col span={21}>
                              <Row>
                                <Col span={1} className="center">
                                  ～
                                </Col>
                                <Col span={11} style={{ paddingLeft: '8px' }}>
                                  <Form.Item
                                    name={['lstIpAddress', idx, 'maxPort']}
                                    className="mb-0"
                                    rules={[
                                      {
                                        required:
                                          form.getFieldValue([
                                            'lstIpAddress',
                                            idx,
                                            'type'
                                          ]) === 3,
                                        message: ''
                                      },
                                      () => ({
                                        validator(_, value) {
                                          if (
                                            form.getFieldValue([
                                              'lstIpAddress',
                                              idx,
                                              'type'
                                            ]) !== 3
                                          )
                                            return Promise.resolve();
                                          form.setFields([
                                            {
                                              name: [
                                                'lstIpAddress',
                                                0,
                                                'minPort'
                                              ],
                                              errors: []
                                            }
                                          ]);
                                          forceUpdate();
                                          if (
                                            form.getFieldValue([
                                              'lstIpAddress',
                                              idx,
                                              'minPort'
                                            ]) > value
                                          ) {
                                            return Promise.reject(
                                              new Error('')
                                            );
                                          }
                                          return Promise.resolve();
                                        }
                                      })
                                    ]}
                                  >
                                    <InputNumber
                                      disabled={checkDisablePortInput(
                                        form.getFieldValue([
                                          'lstIpAddress',
                                          idx,
                                          'type'
                                        ]),
                                        'maxPort'
                                      )}
                                      min={0}
                                      max={65535}
                                      className="number-input"
                                      style={{ width: '100%' }}
                                      placeholder="ALL"
                                    />
                                  </Form.Item>
                                </Col>
                              </Row>
                            </Col>
                          </Col>
                          {form.getFieldValue(['lstIpAddress', idx, 'type']) ===
                            3 &&
                            form.getFieldValue([
                              'lstIpAddress',
                              idx,
                              'minPort'
                            ]) >
                              form.getFieldValue([
                                'lstIpAddress',
                                idx,
                                'maxPort'
                              ]) && (
                              <div
                                style={{ paddingLeft: '4px' }}
                                className="ant-form-item-explain-error"
                              >
                                {t('Securitysettings.port.error')}
                              </div>
                            )}
                        </Row>

                        <Row>
                          <Col span={21}>
                            {t('Securitysettings.explanation')}
                            <Form.Item
                              name={['lstIpAddress', idx, 'description']}
                              className="mb-0"
                              rules={[{ required: true, message: '' }]}
                            >
                              <Input
                                maxLength={255}
                                placeholder={t(
                                  'Securitysettings.descriptionRequeired'
                                )}
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                      </div>
                    </Col>
                    <Col span={3} className="text-center">
                      <Button
                        className="bg-info btn-info border-default"
                        onClick={() => {
                          const lst = form.getFieldValue('lstIpAddress') || [];
                          lst.splice(idx, 1);
                          form.setFieldsValue({ lstIpAddress: [...lst] });
                          forceUpdate();
                        }}
                      >
                        {t('share.delete')}
                      </Button>
                    </Col>
                  </Row>
                ))}
              </Col>
            </Row>
          </div>
          <div class="line-primary"></div>
          <div className="text-center bg-dancer mb-primary p-5">
            <button type="submit" className="btn-submit cursor-pointer">
              {t('share.confirm')}
            </button>
          </div>
          <Button
            className="bg-info btn-info border-default"
            onClick={() => {
              navigate('/', { replace: true });
            }}
          >
            {t('share.return')}
          </Button>
        </Form>
      </div>
      <FooterComponent />
    </>
  );
}

export default SecuritysettingsInput;
