import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Header from '../components/Header';
import {
  Row,
  Col,
  Form,
  Radio,
  Button,
  Input,
  Select,
  Collapse,
  Checkbox
} from 'antd';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { CaretRightOutlined } from '@ant-design/icons';
import { RECOMMENDATION, NEWS } from '../constant';

function ProfileChange() {
  const { state } = useLocation();
  const [form] = Form.useForm();
  const { t, i18n } = useTranslation();
  const { Panel } = Collapse;
  const [profile, setProfile] = useState({});
  const navigate = useNavigate();
  const [listCountry, setListCountry] = useState([]);

  useEffect(() => {
    if (!i18n.language) return;
    if (state?.confirm && state?.values) {
      fillForm({ ...state.values });
    } else {
      axios({
        method: 'get',
        url: '/api/profile',
        params: {
          language: i18n.language
        }
      }).then((res) => {
        const profile = { ...res.data };
        fillForm(profile);
      });
    }

    axios({
      method: 'get',
      url: '/api/country',
      params: {
        language: i18n.language
      }
    }).then((res) => {
      setListCountry(res.data);
    });
  }, [i18n.language]);

  const fillForm = (profile) => {
    const settings = [];

    form.setFieldsValue({
      ...profile,
      ...(profile.countryFlag === 1
        ? { address1_japan: profile.address1 }
        : { address1_overseas: profile.address1 })
    });
    profile.newsletterDeliveries.forEach((element) => {
      if (element.deliveryFlag === 1) {
        settings.push(element.nlType);
      }
    });

    profile.countryObj = profile?.countryObj || {
      countryCode: profile?.countryCode?.countryCode,
      countryName: profile?.countryCodeName?.countryCodeName
    };
    profile.newsletterDeliverySettings = settings;
    setProfile(profile);
  };

  const onCountryFlagChange = (e) => {
    if (e.target.value === 1) {
      const japan = listCountry?.find((e) => e.countryCode === 'JP');

      setProfile({
        ...profile,
        countryFlag: e.target.value,
        country: japan.countryCode,
        countryObj: {
          countryCode: japan.countryCode,
          countryName: japan.countryCodeName
        }
      });
      form.setFieldsValue({
        country: japan.countryCode
      });
    }
    if (e.target.value === 2) {
      setProfile({ ...profile, countryFlag: e.target.value });
    }
  };

  const onChangePostalCode = () => {
    form.setFieldsValue({
      ...form.getFieldsValue(),
      prefectures: null,
      municipalities: null,
      address1_japan: null
    });
  };

  const onChangeCountry = (e) => {
    setProfile({
      ...profile,
      country: e.value,
      countryObj: { countryCode: e.value, countryName: e.label }
    });
  };

  const onChangeNewsletterDeliverySettings = (value) => {
    const newsletterDeliveries = profile.newsletterDeliveries;
    newsletterDeliveries[0].deliveryFlag = 0;
    newsletterDeliveries[1].deliveryFlag = 0;
    if (value.length === 1) {
      newsletterDeliveries.forEach((newsletterDelivery) => {
        if (newsletterDelivery.nlType === value[0]) {
          newsletterDelivery.deliveryFlag = 1;
        }
        if (newsletterDelivery.nlType === value[0]) {
          newsletterDelivery.deliveryFlag = 1;
        }
      });
    }
    if (value.length === 2) {
      newsletterDeliveries[0].deliveryFlag = 1;
      newsletterDeliveries[1].deliveryFlag = 1;
    }
    setProfile({
      ...profile,
      newsletterDeliverySettings: value,
      newsletterDeliveries
    });
  };

  const onClickBtnAddressSearch = () => {
    const postalCode = form.getFieldValue('postalCode');
    if (isNaN(postalCode) || postalCode.length !== 7) {
      //入力された郵便番号が数字7桁ではない場合、検索を行わない。
      return;
    }
    axios({
      method: 'get',
      url: '/api/postalCode?postalCode=' + postalCode
    }).then((res) => {
      form.setFieldsValue({
        //入力された郵便番号で検索を行い、「都道府県」「市区町村」「番地1」に自動入力する。
        prefectures: res.data.state,
        municipalities: res.data.city,
        address1_japan: res.data.address
      });
    });
  };

  return (
    <div>
      <Header />
      <div className="box">
        <Form
          form={form}
          onFinish={(values) => {
            switch (profile.countryFlag) {
              case 1:
                values.address1 = values.address1_japan;
                values.region = null;
                values.city = null;
                delete values.address1_japan;
                break;
              case 2:
                values.address1 = values.address1_overseas;
                values.municipalities = null;
                values.prefectures = null;
                delete values.address1_overseas;
                break;
              default:
                break;
            }

            navigate('/profileChangeConfirm', {
              state: {
                values: { ...profile, ...values },
                prevProfile: state?.prevProfile || { ...profile }
              }
            });
          }}
        >
          <div className="box-register">
            <div className="box-title">{t('profileChange.title')}</div>
            <div className="box-body">
              <div className="required text-right">{t('share.required')}</div>
              <div className="frame">
                <Row>
                  <Col md={{ span: 8 }} xs={{ span: 24 }}>
                    {t('share.surnameName')}
                    <span className="required">*</span>：
                  </Col>
                  <Col md={{ span: 16 }} xs={{ span: 24 }}>
                    <Form.Item
                      name="firstName"
                      rules={[
                        {
                          required: true,
                          whitespace: true,
                          message:
                            t('share.surnameName') + t('share.error.notEntered')
                        }
                      ]}
                    >
                      <Input className="txt-primary" />
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col md={{ span: 8 }} xs={{ span: 24 }}>
                    {t('share.Name')}
                    <span className="required">*</span>：
                  </Col>
                  <Col md={{ span: 16 }} xs={{ span: 24 }}>
                    <Form.Item
                      name="lastName"
                      rules={[
                        {
                          required: true,
                          whitespace: true,
                          message: t('share.Name') + t('share.error.notEntered')
                        }
                      ]}
                    >
                      <Input className="txt-primary" />
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col md={{ span: 8 }} xs={{ span: 24 }}>
                    {t('share.kanaSurname')}
                  </Col>
                  <Col md={{ span: 16 }} xs={{ span: 24 }}>
                    <Form.Item
                      name="kanaFirstName"
                      rules={[
                        {
                          required: true,
                          whitespace: true,
                          message:
                            t('share.kanaSurname') + t('share.error.notEntered')
                        }
                      ]}
                    >
                      <Input className="txt-primary" />
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col md={{ span: 8 }} xs={{ span: 24 }}>
                    {t('share.kanaName')}
                  </Col>
                  <Col md={{ span: 16 }} xs={{ span: 24 }}>
                    <Form.Item
                      name="kanaLastName"
                      rules={[
                        {
                          required: true,
                          whitespace: true,
                          message:
                            t('share.kanaName') + t('share.error.notEntered')
                        }
                      ]}
                    >
                      <Input className="txt-primary" />
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col md={{ span: 8 }} xs={{ span: 24 }}>
                    {t('share.companyName')}
                    <span className="required">*</span>：
                  </Col>
                  <Col md={{ span: 16 }} xs={{ span: 24 }}>
                    <Form.Item
                      name="companyName"
                      rules={[
                        {
                          required: true,
                          whitespace: true,
                          message:
                            t('share.companyName') + t('share.error.notEntered')
                        }
                      ]}
                    >
                      <Input className="txt-primary" />
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col md={{ span: 8 }} xs={{ span: 24 }}>
                    {t('share.divisionName')}
                    <span className="required">*</span>：
                  </Col>
                  <Col md={{ span: 16 }} xs={{ span: 24 }}>
                    <Form.Item
                      name="divisionName"
                      rules={[
                        {
                          required: true,
                          whitespace: true,
                          message:
                            t('share.divisionName') +
                            t('share.error.notEntered')
                        }
                      ]}
                    >
                      <Input className="txt-primary" />
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col md={{ span: 8 }} xs={{ span: 24 }}>
                    {t('share.companyAddress')}
                    <span className="required">*</span>：
                  </Col>
                  <Col md={{ span: 16 }} xs={{ span: 24 }}>
                    <Form.Item name="countryFlag">
                      <Radio.Group onChange={onCountryFlagChange}>
                        <Radio value={1}>
                          {t('share.companyAddressJapan')}
                        </Radio>
                        <Radio value={2}>
                          {t('share.companyAddressOverseas')}
                        </Radio>
                      </Radio.Group>
                    </Form.Item>
                  </Col>
                </Row>
                {profile.countryFlag === 1 && (
                  <>
                    <Row>
                      <Col md={{ span: 8 }} xs={{ span: 24 }}>
                        {t('share.country')}
                        <span className="required">*</span>：
                      </Col>
                      <Col md={{ span: 16 }} xs={{ span: 24 }}>
                        <Form.Item name="country" className="slbCountry">
                          <Select
                            defaultValue="JP"
                            disabled
                            options={listCountry.map((item) => ({
                              label: item.countryCodeName,
                              value: item.countryCode
                            }))}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={{ span: 8 }} xs={{ span: 7 }} className="f13-xs">
                        {t('share.postalCode')}
                        <span className="required">*</span>：
                      </Col>
                      <Col
                        md={{ span: 13 }}
                        xs={{ span: 7 }}
                        className="f13-xs"
                      >
                        <Form.Item
                          name="postalCode"
                          rules={[
                            {
                              required: true,
                              whitespace: true,
                              message:
                                t('share.postalCode') +
                                t('share.error.notEntered')
                            },
                            {
                              validator: async (_, value) => {
                                if (value && value.length !== 7) {
                                  return Promise.reject(
                                    new Error(t('share.error.postCode7Number'))
                                  );
                                }
                              }
                            }
                          ]}
                        >
                          <Input
                            className="txt-primary"
                            onChange={() => onChangePostalCode()}
                          />
                        </Form.Item>
                      </Col>
                      <Col md={{ span: 3 }} xs={{ span: 4 }} className="f13-xs">
                        <Button
                          className="btn-primary btn-add-search"
                          onClick={() => onClickBtnAddressSearch()}
                        >
                          {t('share.addressSearch')}
                        </Button>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={{ span: 8 }} xs={{ span: 24 }}>
                        {t('share.prefectures')}
                        <span className="required">*</span>：
                      </Col>
                      <Col md={{ span: 16 }} xs={{ span: 24 }}>
                        <Form.Item
                          name="prefectures"
                          rules={[
                            {
                              required: true,
                              whitespace: true,
                              message:
                                t('share.prefectures') +
                                t('share.error.notEntered')
                            }
                          ]}
                        >
                          <Input disabled className="txt-primary" />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={{ span: 8 }} xs={{ span: 24 }}>
                        {t('share.municipalities')}
                        <span className="required">*</span>：
                      </Col>
                      <Col md={{ span: 16 }} xs={{ span: 24 }}>
                        <Form.Item
                          name="municipalities"
                          rules={[
                            {
                              required: true,
                              whitespace: true,
                              message:
                                t('share.municipalities') +
                                t('share.error.notEntered')
                            }
                          ]}
                        >
                          <Input disabled className="txt-primary" />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={{ span: 8 }} xs={{ span: 24 }}>
                        {t('share.address1')}
                        <span className="required">*</span>：
                      </Col>
                      <Col md={{ span: 16 }} xs={{ span: 24 }}>
                        <Form.Item
                          name="address1_japan"
                          rules={[
                            {
                              required: true,
                              whitespace: true,
                              message:
                                t('share.address1') +
                                t('share.error.notEntered')
                            }
                          ]}
                        >
                          <Input disabled className="txt-primary" />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={{ span: 8 }} xs={{ span: 24 }}>
                        {t('share.address2')}
                        <span className="required">*</span>：
                      </Col>
                      <Col md={{ span: 16 }} xs={{ span: 24 }}>
                        <Form.Item
                          name="address2"
                          rules={[
                            {
                              required: true,
                              whitespace: true,
                              message:
                                t('share.address2') +
                                t('share.error.notEntered')
                            }
                          ]}
                        >
                          <Input className="txt-primary" />
                        </Form.Item>
                      </Col>
                    </Row>
                  </>
                )}
                {profile.countryFlag === 2 && (
                  <>
                    <Row>
                      <Col md={{ span: 8 }} xs={{ span: 24 }}>
                        {t('share.address1')}
                        <span className="required">*</span>：
                      </Col>
                      <Col md={{ span: 16 }} xs={{ span: 24 }}>
                        <Form.Item
                          name="address1_overseas"
                          rules={[
                            {
                              required: true,
                              whitespace: true,
                              message:
                                t('share.address1') +
                                t('share.error.notEntered')
                            }
                          ]}
                        >
                          <Input className="txt-primary" />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={{ span: 8 }} xs={{ span: 24 }}>
                        {t('share.address2Option')}
                      </Col>
                      <Col md={{ span: 16 }} xs={{ span: 24 }}>
                        <Form.Item name="address2">
                          <Input className="txt-primary" />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={{ span: 8 }} xs={{ span: 24 }}>
                        {t('share.city')}
                        <span className="required">*</span>：
                      </Col>
                      <Col md={{ span: 16 }} xs={{ span: 24 }}>
                        <Form.Item
                          name="city"
                          rules={[
                            {
                              required: true,
                              whitespace: true,
                              message:
                                t('share.city') + t('share.error.notEntered')
                            }
                          ]}
                        >
                          <Input className="txt-primary" />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={{ span: 8 }} xs={{ span: 24 }}>
                        {t('share.region')}
                        <span className="required">*</span>：
                      </Col>
                      <Col md={{ span: 16 }} xs={{ span: 24 }}>
                        <Form.Item
                          name="region"
                          rules={[
                            {
                              required: true,
                              whitespace: true,
                              message:
                                t('share.region') + t('share.error.notEntered')
                            }
                          ]}
                        >
                          <Input className="txt-primary" />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={8}>
                        {t('share.postalCode')}
                        <span className="required">*</span>：
                      </Col>
                      <Col span={16}>
                        <Form.Item
                          name="postalCode"
                          rules={[
                            {
                              required: true,
                              whitespace: true,
                              message:
                                t('share.postalCode') +
                                t('share.error.notEntered')
                            },
                            {
                              validator: async (_, value) => {
                                if (value && isNaN(value)) {
                                  return Promise.reject(
                                    new Error(t('share.error.postCodeIsNumber'))
                                  );
                                }
                              }
                            }
                          ]}
                        >
                          <Input
                            className="txt-primary"
                            onChange={() => onChangePostalCode()}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={{ span: 8 }} xs={{ span: 24 }}>
                        {t('share.country')}
                        <span className="required">*</span>：
                      </Col>
                      <Col md={{ span: 16 }} xs={{ span: 24 }}>
                        <Form.Item
                          name="country"
                          className="slbCountry"
                          rules={[
                            {
                              required: true,
                              whitespace: true,
                              message:
                                t('share.country') + t('share.error.notEntered')
                            }
                          ]}
                        >
                          <Select
                            defaultValue="JP"
                            onChange={(value, option) =>
                              onChangeCountry(option)
                            }
                            options={listCountry.map((item) => ({
                              label: item.countryCodeName,
                              value: item.countryCode
                            }))}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </>
                )}
                <Row>
                  <Col md={{ span: 8 }} xs={{ span: 24 }}>
                    {t('share.phoneNumber')}
                    <span className="required">*</span>：
                  </Col>
                  <Col md={{ span: 8 }} xs={{ span: 24 }}>
                    <Form.Item
                      name="phoneNumber"
                      rules={[
                        {
                          required: true,
                          whitespace: true,
                          message:
                            t('share.phoneNumber') + t('share.error.notEntered')
                        },
                        {
                          validator: async (_, value) => {
                            if (value && isNaN(value)) {
                              return Promise.reject(
                                new Error(t('share.error.phoneNumberIsNumber'))
                              );
                            }
                          }
                        }
                      ]}
                    >
                      <Input type={Number} className="txt-primary" />
                    </Form.Item>
                  </Col>
                </Row>
                <div className="panel-setting mb-primary">
                  <Collapse
                    bordered={false}
                    expandIcon={({ isActive }) => (
                      <CaretRightOutlined rotate={isActive ? 90 : 0} />
                    )}
                    className="site-collapse-custom-collapse"
                  >
                    <Panel
                      header={t(
                        'profileChange.emailNewsletterDeliverySettings'
                      )}
                      key="1"
                      className="site-collapse-custom-panel"
                    >
                      <Checkbox.Group
                        onChange={(value) =>
                          onChangeNewsletterDeliverySettings(value)
                        }
                        style={{ width: '100%' }}
                        value={profile.newsletterDeliverySettings}
                      >
                        <Col offset={2}>
                          {t(
                            'profileChange.stopUnstopDeliveryOfEmailNewsletter'
                          )}
                        </Col>
                        <Col offset={4}>
                          <Checkbox value={RECOMMENDATION}>
                            {t('profileChange.recommendation')}
                          </Checkbox>
                        </Col>
                        <Col offset={4}>
                          <Checkbox value={NEWS}>
                            {t('profileChange.news')}
                          </Checkbox>
                        </Col>
                      </Checkbox.Group>
                    </Panel>
                  </Collapse>
                </div>
              </div>
            </div>
          </div>
          <Form.Item className="text-right">
            <Button
              htmlType="submit"
              style={{ margin: '0 8px' }}
              className="btn-primary"
            >
              {t('profileChange.confirmInput')}
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
}
export default ProfileChange;
