import React from 'react';
import Header from '../components/Header';
import { useTranslation } from 'react-i18next';
import { Row, Form, Col, Button, Breadcrumb } from 'antd';
import FooterComponent from '../components/FooterComponent';
import { useNavigate, useLocation } from 'react-router-dom';
import { PLAN_STATUS, STATUS_PLAN_DATA } from '../constant';
import axios from 'axios';
function CancellationOfReservationConfirm() {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { state } = useLocation();

  return (
    <>
      <Header />
      <div className="white-space"></div>
      <div className="container-custom-primary cancellation-reservation-confirm">
        <Row className="title mb-primary">
          <Col md={{ span: 12 }} className="page-title">
            {t('cancellationOfReservationInput.title')}
            {' / '}
            {t('share.confirm')}
          </Col>
          <Col md={{ span: 12 }} className="text-right">
            <Breadcrumb separator=">">
              <Breadcrumb.Item>{t('share.input')}</Breadcrumb.Item>
              <Breadcrumb.Item className="bold current">
                {t('share.confirm')}
              </Breadcrumb.Item>
              <Breadcrumb.Item>{t('share.completion')}</Breadcrumb.Item>
            </Breadcrumb>
          </Col>
        </Row>
        <Form
          name=""
          form={form}
          autoComplete="off"
          onFinish={() => {
            if (state.values.display == 1) {
              state.values.status = PLAN_STATUS[1];
              state.values.cancelReserveApplyingFlag = true;
            } else if (state.values.display == 2) {
              state.values.cancelStopReserveDateFlag = true;
            }
            axios
              .post('/api/updateProcedureOfPLan', {
                ...state.values
              })
              .then(() => {
                navigate('/cancellationOfReservationCompletion', {
                  state
                });
              });
          }}
        >
          <Col className="title-sub color-primary">{t('share.usagePlan')}</Col>
          <Col
            md={{ span: 11 }}
            className="border-default ml-15 mb-primary"
            style={{ borderBottom: '0px' }}
          >
            <Row className="border-bottom-default">
              <Col
                md={{ span: 8 }}
                className="bg-primary border-right-default pl-5"
              >
                {t('share.planId')}
              </Col>
              <Col>
                <Form.Item className="mb-0 pl-15">
                  {state.values.planId}
                </Form.Item>
              </Col>
            </Row>
            <Row className="border-bottom-default">
              <Col
                md={{ span: 8 }}
                className="bg-primary border-right-default pl-5"
              >
                {t('plan.version')}
              </Col>
              <Col>
                <Form.Item className="mb-0 pl-15">
                  {state.values.planBranchNum}
                </Form.Item>
              </Col>
            </Row>
            <Row className="border-bottom-default">
              <Col
                md={{ span: 8 }}
                className="bg-primary border-right-default pl-5"
              >
                {t('planDetail.status')}
              </Col>
              <Col>
                <Form.Item className="mb-0 pl-15">
                  {STATUS_PLAN_DATA[state.values.status]}
                </Form.Item>
              </Col>
            </Row>
            <Row className="border-bottom-default">
              <Col
                md={{ span: 8 }}
                className="bg-primary border-right-default pl-5"
              >
                {t('planDetail.planUsageStartDate')}
              </Col>
              <Col>
                <Form.Item className="mb-0 pl-15">
                  {state.values.useStartDate}
                </Form.Item>
              </Col>
            </Row>
            {state.values?.stopReserveDate && (
              <Row className="border-bottom-default">
                <Col
                  md={{ span: 8 }}
                  className="bg-primary border-right-default pl-5"
                >
                  {t(
                    'TemporarySuspensionOrCancellationSuspension.planSuspensionDate'
                  )}
                </Col>
                <Col>
                  <Form.Item className="mb-0 pl-15">
                    {state.values.stopReserveDate}
                  </Form.Item>
                </Col>
              </Row>
            )}
            {state.values?.stopDate && (
              <Row className="border-bottom-default">
                <Col
                  md={{ span: 8 }}
                  className="bg-primary border-right-default pl-5"
                >
                  {t('share.planStopDate')}
                </Col>
                <Col>
                  <Form.Item className="mb-0 pl-15">
                    {state.values.stopDate}
                  </Form.Item>
                </Col>
              </Row>
            )}
            {state.values?.cancelReserveDate && (
              <Row className="border-bottom-default">
                <Col
                  md={{ span: 8 }}
                  className="bg-primary border-right-default pl-5"
                >
                  {t('share.scheduledCancellationDate')}
                </Col>
                <Col>
                  <Form.Item className="mb-0 pl-15">
                    {state.values.cancelReserveDate}
                  </Form.Item>
                </Col>
              </Row>
            )}
            {state.values?.cancelDate && (
              <Row className="border-bottom-default">
                <Col
                  md={{ span: 8 }}
                  className="bg-primary border-right-default pl-5"
                >
                  {t('share.planCancellationDate')}
                </Col>
                <Col>
                  <Form.Item className="mb-0 pl-15">
                    {state.values.cancelDate}
                  </Form.Item>
                </Col>
              </Row>
            )}
          </Col>
          <Col className="title-sub color-primary">
            {t('cancellationOfReservationInput.subTitle1')}
          </Col>
          <div className="ml-15">
            <Col md={{ span: 16 }}>
              <table className="table-stop">
                {state.values.display == 1 && (
                  <>
                    <tr>
                      <td className="bg-primary border-right-default td-label col-label-lv1">
                        {t('cancellationOfReservationInput.label1')}
                      </td>
                      <td>{t('settingStartDate.immediately')}</td>
                    </tr>
                  </>
                )}
                {state.values.display == 2 && (
                  <>
                    <tr>
                      <td className="bg-primary border-right-default col-label-lv1">
                        {t('cancellationOfReservationInput.label2')}
                      </td>
                      <td>{t('settingStartDate.immediately')}</td>
                    </tr>
                  </>
                )}
              </table>
            </Col>
          </div>
          <div className="white-space"></div>
          <div className="bg-dancer p-5">
            <Row>
              <Col md={{ span: 12 }} className="text-right">
                <button type="submit" className="btn-submit cursor-pointer">
                  {t('cancellationOfReservationInput.execution')}
                </button>
              </Col>
              <Col md={{ span: 12 }} className="text-right">
                <Button
                  onClick={() => {
                    navigate('/otherProceduresStopOrCancel', {
                      state
                    });
                  }}
                  className="btn-cancel"
                >
                  {t('share.cancel')}
                </Button>
              </Col>
            </Row>
          </div>
        </Form>

        <div className="line-primary"></div>
        <Button
          onClick={() => {
            navigate('/cancellationOfReservationInput', {
              state
            });
          }}
          className="btn-return"
        >
          {t('share.return')}
        </Button>
      </div>
      <FooterComponent />
    </>
  );
}
export default CancellationOfReservationConfirm;
