/**
 * (C) 2022 DTS CORPORATION
 */
import React, { useEffect, useState } from 'react';
import '../i18n';
import Header from '../components/Header';
import { Row, Col, Space, Form, Button, Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import logoGeminiot from '../assets/images/logo-geminiot.png';
import logoPasterios from '../assets/images/logo-pasterios.png';
import ConfigurationComponent from '../components/ConfigurationComponent';
import UseOptionComponent from '../components/UseOptionComponent';
import UserTimeComponent from '../components/UserTimeComponent';
import axios from 'axios';
import {
  PAYMENT_METHOD,
  PLAN_STATUS,
  STATUS_PLAN_DATA,
  paygentSendDataInfo
} from '../constant';
/**
 * Plan detail page
 */
function PlanDetail() {
  const { t, i18n } = useTranslation();
  const [data, setData] = useState();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (!i18n.language) return;
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const planId = params.get('planId');
    const planBranchNum = params.get('planBranchNum');
    if (!planId || !planBranchNum) {
      navigate('/');
      return;
    }

    axios
      .get('/api/getPlanDetail', {
        params: {
          planId,
          planBranchNum,
          language: i18n.language
        }
      })
      .then((res) => {
        const { data } = res;
        data.itemSetting = JSON.parse(data.itemSetting);
        setData(data);
      });
  }, [i18n.language]);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
    axios
      .post('/api/deletePlanDetail', {
        planId: data.planId,
        planBranchNum: data.planBranchNum
      })
      .then(() => {
        navigate('/');
      });
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  function renderPaymentBlock() {
    if (data?.status !== PLAN_STATUS[1]) {
      return (
        <div className="payment">
          <Row className="row-title">
            <Col md={{ span: 19 }} className="title-sub color-primary">
              {t('share.paymentInformation')}
            </Col>
            <Col md={{ span: 5 }} className="text-right">
              {renderButton(
                t('share.creditCardInfoChange'),
                [PLAN_STATUS[3], PLAN_STATUS[4]].includes(data?.status) &&
                  data?.paymentMethod === 1,
                () => {
                  navigate('/creditCardInformationInquiry', { state: data });
                },
                'btn-cardinfo-change'
              )}
              {renderButton(
                t('share.creditCardChange'),
                [PLAN_STATUS[3], PLAN_STATUS[4]].includes(data?.status) &&
                  data?.paymentMethod === 1,
                () => {
                  if (data?.isNotAllowChangeCard) {
                    navigate('/cannotCreditCardChange', { state: data });
                  } else {
                    navigate('/creditCardChange', { state: data });
                  }
                }
              )}
            </Col>
          </Row>
          <div className="border-default mb-primary ml-15">
            <Row>
              <Col
                md={{ span: 4 }}
                className="bg-primary border-right-default pl-5"
              >
                {t('share.paymentMethod')}
              </Col>
              <Col md={{ span: 20 }} className="pl-5">
                {t(PAYMENT_METHOD[data?.paymentMethod])}
              </Col>
            </Row>
          </div>
        </div>
      );
    }
  }
  function renderBaseInformationBlock() {
    const showMore =
      data?.status !== PLAN_STATUS[1] && data?.status !== PLAN_STATUS[2];
    return (
      <div className="mb-primary ml-15">
        <Col
          md={{ span: 10 }}
          className="border-default"
          style={{ borderBottom: '0px' }}
        >
          <Row className="border-bottom-default">
            <Col className="bg-primary border-right-default pl-5 col-left-lbl">
              {t('share.planId')}
            </Col>
            <Col>
              <Form.Item name="planDetail.planId" className="mb-0 pl-15">
                {data?.planId}
              </Form.Item>
            </Col>
          </Row>
          <Row className="border-bottom-default">
            <Col className="bg-primary border-right-default pl-5 col-left-lbl">
              {t('planDetail.planUsageStartDate')}
            </Col>
            <Col>
              <Form.Item className="mb-0 pl-15">{data?.useStartDate}</Form.Item>
            </Col>
          </Row>
          {showMore && (
            <>
              {data?.stopReserveDate && (
                <Row className="border-bottom-default">
                  <Col className="bg-primary border-right-default pl-5 col-left-lbl">
                    {t('share.scheduledPlanSuspensionDate')}
                  </Col>
                  <Col>
                    <Form.Item name="" className="mb-0 pl-15">
                      {data?.stopReserveDate}
                    </Form.Item>
                  </Col>
                </Row>
              )}
              {data?.stopDate && (
                <Row className="border-bottom-default">
                  <Col className="bg-primary border-right-default pl-5 col-left-lbl">
                    {t('share.planStopDate')}
                  </Col>
                  <Col>
                    <Form.Item name="" className="mb-0 pl-15">
                      {data.stopDate}
                    </Form.Item>
                  </Col>
                </Row>
              )}
              {data?.cancelReserveDate && (
                <Row className="border-bottom-default">
                  <Col className="bg-primary border-right-default pl-5 col-left-lbl">
                    {t('share.scheduledCancellationDate')}
                  </Col>
                  <Col>
                    <Form.Item name="" className="mb-0 pl-15">
                      {data.cancelReserveDate}
                    </Form.Item>
                  </Col>
                </Row>
              )}
              {data?.cancelDate && (
                <Row className="border-bottom-default">
                  <Col className="bg-primary border-right-default pl-5 col-left-lbl">
                    {t('share.planCancellationDate')}
                  </Col>
                  <Col>
                    <Form.Item name="" className="mb-0 pl-15">
                      {data.cancelDate}
                    </Form.Item>
                  </Col>
                </Row>
              )}
            </>
          )}
        </Col>
      </div>
    );
  }
  function renderUsagePlanBlock() {
    const showMore =
      data?.status !== PLAN_STATUS[1] && data?.status !== PLAN_STATUS[2];

    return (
      <div className="mb-primary ml-15">
        <Col
          md={{ span: 10 }}
          className="border-default version-block"
          style={{ borderBottom: '0px' }}
        >
          <Row className="border-bottom-default">
            <Col className="bg-primary border-right-default pl-5 col-left-lbl">
              {t('plan.version')}
            </Col>
            <Col>
              <Form.Item name="planDetailVersion" className="mb-0 pl-15">
                {data?.planBranchNum}
              </Form.Item>
            </Col>
          </Row>
          <Row className="border-bottom-default">
            <Col className="bg-primary border-right-default pl-5 col-left-lbl">
              {t('planDetail.status')}
            </Col>
            <Col>
              <Form.Item name="planDetailStatus" className="mb-0 pl-15">
                {STATUS_PLAN_DATA[data?.status]}
              </Form.Item>
            </Col>
          </Row>
          <Row className="border-bottom-default">
            <Col className="bg-primary border-right-default pl-5 col-left-lbl">
              {t('planDetail.startDate')}
            </Col>
            <Col>
              <Form.Item name="planDetailStartDate" className="mb-0 pl-15">
                {data?.startDate}
              </Form.Item>
            </Col>
          </Row>
          {showMore && data?.endDate && (
            <Row className="border-bottom-default">
              <Col className="bg-primary border-right-default pl-5 col-left-lbl">
                {t('share.endDate')}
              </Col>
              <Col>
                <Form.Item className="mb-0 pl-15">{data.endDate}</Form.Item>
              </Col>
            </Row>
          )}
        </Col>
      </div>
    );
  }
  function renderManagerInformationBlock() {
    return (
      <Row className="border-default ml-15">
        <Col md={{ span: 4 }} className="bg-primary border-right-default pl-5">
          {t('share.myPageAdmin')}
        </Col>
        <Col md={{ span: 20 }}>
          <Row
            className={
              data?.accountAdminInfoList?.length > 0
                ? 'bg-primary border-bottom-default'
                : 'bg-primary'
            }
          >
            <Col md={{ span: 8 }} className="pl-15">
              {t('share.account')}
            </Col>
            <Col md={{ span: 16 }}>{t('share.accountName')}</Col>
          </Row>
          {data?.accountAdminInfoList?.map((item) => (
            <Row>
              <Col md={{ span: 8 }} className="pl-15">
                {item.mail}
              </Col>
              <Col md={{ span: 16 }}>{item.accountName}</Col>
            </Row>
          ))}
        </Col>
      </Row>
    );
  }

  const areaButtonHeader = () => {
    if (data?.status !== PLAN_STATUS[1] && data?.status !== PLAN_STATUS[2])
      return;
    const disabledButton = data?.status === PLAN_STATUS[2];
    return (
      <div className="button-block">
        <Row className="mb-primary">
          <Col md={{ span: 4 }}>
            <button
              type="button"
              className="btn-dancer cursor-pointer"
              disabled={disabledButton}
              onClick={() => {
                if (data.planBranchNum === '001') {
                  navigate('/paymentInformationRegistration', {
                    state: { values: data }
                  });
                } else {
                  // プラン変更時（版が002以降）、編集できるのは、システム構成部分のみ。
                  navigate('/changeApplicationSettingStartDate', {
                    state: { values: data }
                  });
                }
              }}
            >
              {t('share.Application')}
            </button>
          </Col>
        </Row>
        {!data?.fixedPriceFlag && (
          <Row>
            <Space size={20}>
              <Button
                className="btn-dancer"
                disabled={disabledButton}
                onClick={() => {
                  navigate('/quoteMake', {
                    state: { values: data }
                  });
                }}
              >
                {t('share.makeQuoteication')}
              </Button>
              <button
                className="btn-dancer cursor-pointer"
                disabled={disabledButton}
                onClick={() => {
                  if (data.planBranchNum === '001') {
                    navigate('/planInput', {
                      state: { editMode: true, values: data }
                    });
                  } else {
                    // プラン変更時（版が002以降）、編集できるのは、システム構成部分のみ。
                    navigate('/planChangeApplicationInput', {
                      state: { values: data }
                    });
                  }
                }}
              >
                {t('share.edit')}
              </button>
              <button
                className="btn-dancer cursor-pointer"
                disabled={disabledButton}
                onClick={showModal}
              >
                {t('share.delete')}
              </button>
            </Space>
          </Row>
        )}
      </div>
    );
  };

  const renderButton = (buttonText, isEnable, handle, className) => {
    return (
      <Button
        onClick={handle}
        className={`btn-dancer ${className}`}
        disabled={!isEnable}
      >
        {buttonText}
      </Button>
    );
  };

  return (
    <div className="plan-detail">
      <Header />
      <div className="white-space"></div>
      <div className="container-custom-primary">
        <Row>
          <Col md={{ span: 4 }} className="color-secondary bold">
            {t('planDetail.Title')}
          </Col>
          <Col md={{ span: 12 }} className="color-secondary">
            {areaButtonHeader()}
          </Col>
        </Row>
        <div className="line-primary"></div>
        <Form name="frmPlanDetail">
          <Row className="border-default mb-primary ml-15">
            <Col
              md={{ span: 4 }}
              className="bg-primary border-right-default pl-5"
            >
              {t('share.companyName')}
            </Col>
            <Col>
              <Form.Item name="companyName" className="mb-0 pl-15">
                {data?.companyName}
              </Form.Item>
            </Col>
          </Row>

          <div className="base-information">
            <Row className="row-title">
              <Col md={{ span: 19 }} className="title-sub color-primary">
                {t('share.basicInformation')}
              </Col>
              <Col md={{ span: 5 }} className="text-right">
                {renderButton(
                  t('planDetail.usageInquiry'),
                  [3, 4, 5, 6].includes(data?.status),
                  () => {
                    navigate('/usageInquiry', {
                      state: { values: data }
                    });
                  }
                )}
              </Col>
            </Row>
            {renderBaseInformationBlock()}
          </div>

          <div className="usage-plan">
            <Row className="row-title">
              {data?.fixedPriceFlag && (
                <Col md={{ span: 24 }} className="title-sub color-primary">
                  {t('share.usagePlan')}
                </Col>
              )}
              {!data?.fixedPriceFlag && (
                <>
                  <Col md={{ span: 19 }} className="title-sub color-primary">
                    {t('share.usagePlan')}
                  </Col>
                  <Col md={{ span: 5 }} className="text-right">
                    {renderButton(
                      t('share.planChange'),
                      !data?.disableChangePlan,
                      () => {
                        navigate('/planChangeApplicationInput', {
                          state: { isNewPlanBranch: true, values: data }
                        });
                      }
                    )}
                  </Col>
                </>
              )}
            </Row>
            {renderUsagePlanBlock()}
            <div className="component border-default mb-primary ml-30">
              <Row>
                <Col
                  md={{ span: 4 }}
                  className="bg-primary border-right-default pl-5 col-label-lv1"
                >
                  {t('plan.component')}
                </Col>
                <Col className="pl-15">
                  <img
                    src={data?.component === 1 ? logoGeminiot : logoPasterios}
                    className="img-logo pt-5 pb-5"
                  />
                </Col>
              </Row>
            </div>
            <div className="ml-30">
              <div>{t('planDetail.systemConfiguration')}</div>
              <ConfigurationComponent
                type="planDetail"
                planId={data?.planId}
                isInUse={data?.status === PLAN_STATUS[3]}
                itemSetting={data?.itemSetting}
                cloud={data?.cloud}
              />
            </div>
            <div className="mb-primary">
              <Row className="row-title">
                {data?.fixedPriceFlag && (
                  <Col md={{ span: 24 }} className="title-sub color-primary">
                    {t('share.option')}
                  </Col>
                )}
                {!data?.fixedPriceFlag && (
                  <>
                    <Col md={{ span: 19 }} className="title-sub color-primary">
                      {t('share.option')}
                    </Col>
                    <Col md={{ span: 5 }} className="text-right">
                      {renderButton(
                        t('planDetail.optionChange'),
                        [PLAN_STATUS[3], PLAN_STATUS[4]].includes(data?.status),
                        () => {
                          navigate('/optionChangeInput', {
                            state: { values: data }
                          });
                        }
                      )}
                    </Col>
                  </>
                )}
              </Row>
              <div className="ml-15">
                <UseOptionComponent data={data} type="planDetail" />
              </div>
            </div>

            <div className="mb-primary">
              <Row className="row-title">
                <Col md={{ span: 19 }} className="title-sub color-primary">
                  {t('share.systemUsageTime')}
                </Col>
                <Col md={{ span: 5 }} className="text-right">
                  {renderButton(
                    t('share.changeSystemUsageTime'),
                    [PLAN_STATUS[3], PLAN_STATUS[4]].includes(data?.status),
                    () => {
                      navigate('/changeSystemUsageTime', {
                        state: { values: data }
                      });
                    }
                  )}
                </Col>
              </Row>
              <Row className="border-default ml-15">
                <Col
                  md={{ span: 4 }}
                  className="bg-primary border-right-default pl-5"
                >
                  {t('share.systemUsageTime')}
                </Col>
                <Col md={{ span: 20 }}>
                  <UserTimeComponent
                    type="planDetail"
                    systemUtilizationTimes={data?.systemUtilizationTimes}
                  />
                </Col>
              </Row>
            </div>

            <div className="mb-primary">
              <Row className="row-title">
                <Col md={{ span: 19 }} className="title-sub color-primary">
                  {t('share.managerInformation')}
                </Col>
                <Col md={{ span: 5 }} className="text-right">
                  {renderButton(
                    t('share.addOrDelete'),
                    [PLAN_STATUS[3], PLAN_STATUS[4]].includes(data?.status),
                    () => {
                      navigate('/administratorAdd', {
                        state: { values: data }
                      });
                    }
                  )}
                </Col>
              </Row>
              {renderManagerInformationBlock()}
            </div>
            {renderPaymentBlock()}
            <div className="mb-primary">
              <Row className="row-title">
                <Col md={{ span: 19 }} className="title-sub color-primary">
                  {t('share.campaignCode')}
                </Col>
                <Col md={{ span: 5 }} className="text-right">
                  {renderButton(
                    t('share.campaignCodeAdd'),
                    [PLAN_STATUS[3], PLAN_STATUS[4]].includes(data?.status),
                    () => {
                      navigate('/campaignCodeAddedInput', {
                        state: { values: data }
                      });
                    }
                  )}
                </Col>
              </Row>
              <Row className="border-default ml-15">
                <Col
                  md={{ span: 4 }}
                  className="bg-primary border-right-default pl-5"
                >
                  {t('CampaignCodeAddedConfirm.campaign')}
                  <div className="pl-5">{t('share.applicable')}</div>
                </Col>
                <Col md={{ span: 20 }}>
                  <Row className="bg-primary  border-bottom-default">
                    <Col md={{ span: 3 }} className="pl-15">
                      {' '}
                      {t('share.code')}
                    </Col>
                    <Col md={{ span: 10 }}>{t('share.campaignName')}</Col>
                    <Col md={{ span: 6 }}>{t('share.applicablePeriod')}</Col>
                    <Col md={{ span: 5 }}>{t('share.benefits')}</Col>
                  </Row>
                  {data?.campaignDtoList?.map((item) => (
                    <Row>
                      <Col md={{ span: 3 }} className="pl-15">
                        {item.campaignCode}
                      </Col>
                      <Col md={{ span: 10 }}>{item.campaignName}</Col>
                      <Col md={{ span: 6 }}>{item.campaignPeriod}</Col>
                      <Col md={{ span: 5 }}>{item.campaignBenefits}</Col>
                    </Row>
                  ))}
                </Col>
              </Row>
            </div>
            <div class="line-primary"></div>
            <div className="dealer-information">
              <Row className="row-title">
                <Col md={{ span: 24 }} className="title-sub color-primary">
                  {t('share.dealerInformation')}
                </Col>
              </Row>
              <Row className="border-default ml-15">
                <Col
                  md={{ span: 4 }}
                  className="bg-secondary border-right-default pl-5"
                >
                  {t('share.regisCode')}
                </Col>
                <Col md={{ span: 4 }} className="pl-15">
                  {data?.projectCode}
                </Col>
                <Col
                  md={{ span: 4 }}
                  className="bg-secondary border-right-default pl-5"
                >
                  {t('share.salesOutlet')}
                </Col>
                <Col md={{ span: 4 }} className="pl-15">
                  {data?.projectCode ? data?.agency?.companyName : ''}
                </Col>
              </Row>
            </div>
          </div>
        </Form>
        <div class="line-primary"></div>
        <Link to="/">
          <Button className="btn-return">{t('share.return')}</Button>
        </Link>
        {[PLAN_STATUS[2], PLAN_STATUS[3], PLAN_STATUS[4]].includes(
          data?.status
        ) && (
          <div className="text-center mb-primary">
            <Link
              to={{
                pathname: '/otherProceduresStopOrCancel'
              }}
              state={{ values: data }}
              className="hover-underline"
            >
              {t('planDetail.otherProcedures')}
            </Link>
          </div>
        )}

        <div className="text-center mb-primary">
          {paygentSendDataInfo.copy_right}
        </div>
        <Modal
          title={
            <div className="text-center bg-primary">
              {t('planDelete.draft')}
            </div>
          }
          visible={isModalVisible}
          onOk={handleOk}
          onCancel={handleCancel}
          closable={false}
          okText="OK"
          cancelText={t('share.cancel')}
          className="modal-delete"
        >
          <p className="des">{t('planDelete.confirmText')}</p>
        </Modal>
      </div>
    </div>
  );
}
export default PlanDetail;
