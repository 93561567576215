import React, { useEffect } from 'react';
import '../i18n';
import { Row, Col, Form, Space, Select, Radio } from 'antd';
import { useTranslation } from 'react-i18next';
import { BACKUP_CYCLE, BACKUP_REGION, USE_NOTUSE } from '../constant';
import { useForceUpdate } from '../utils/utils';

function OptionComponentInput(props) {
  const forceUpdate = useForceUpdate();
  const { t } = useTranslation();
  const { form } = props;

  const lstBackupGeneration = [...Array(31).keys()].map((o) => ({
    value: o + 1,
    label: o + 1
  }));

  useEffect(() => {
    forceUpdate();
  }, []);
  return (
    <div className="option-body ml-15">
      <Row className="row-item">
        <Col md={{ span: 4 }} className="bg-primary col-label">
          <label className="lbl-required">{t('planNew.vpn')} </label> <br />
          {t('planNew.free')}
        </Col>
        <Col md={{ span: 20 }} className="col-setting">
          <Form.Item
            name="useVpn"
            className="mb-0"
            initialValue={USE_NOTUSE.Use}
          >
            <Radio.Group>
              <Space direction="vertical" size={1}>
                <Radio value={USE_NOTUSE.Use}>{t('share.use')}</Radio>
                <Radio value={USE_NOTUSE.NotUse}>{t('share.doNotUse')}</Radio>
              </Space>
            </Radio.Group>
          </Form.Item>
        </Col>
      </Row>
      <Row className="row-item">
        <Col md={{ span: 4 }} className="bg-primary col-label">
          <label className="lbl-required">{t('share.dataBackup')}</label>
        </Col>
        <Col md={{ span: 20 }} className="col-setting">
          <Space align="start" size={30}>
            <Form.Item
              name="useBackup"
              className="mb-0"
              initialValue={USE_NOTUSE.NotUse}
            >
              <Radio.Group
                onChange={() => {
                  forceUpdate();
                }}
              >
                <Space direction="vertical" size={1}>
                  <Radio value={USE_NOTUSE.Use}>{t('share.use')}</Radio>
                  <Radio value={USE_NOTUSE.NotUse}>{t('share.doNotUse')}</Radio>
                </Space>
              </Radio.Group>
            </Form.Item>
            <Form.Item
              label={t('share.base')}
              name="backupRegion"
              className="mb-0 label-after-hidden"
              initialValue={1}
            >
              <Select
                disabled={form.getFieldValue('useBackup') === USE_NOTUSE.NotUse}
                options={BACKUP_REGION}
              />
            </Form.Item>
            <Form.Item
              label={t('planNew.frequency')}
              name="backupCycle"
              className="mb-0 label-after-hidden"
              initialValue={1}
            >
              <Select
                style={{ width: '100px' }}
                disabled={form.getFieldValue('useBackup') === USE_NOTUSE.NotUse}
                defaultValue="1"
                options={BACKUP_CYCLE}
              />
            </Form.Item>
            <Form.Item
              label={t('planNew.generation')}
              name="backupGeneration"
              className="mb-0 label-after-hidden"
              initialValue={3}
            >
              <Select
                disabled={form.getFieldValue('useBackup') === USE_NOTUSE.NotUse}
                options={lstBackupGeneration}
              />
            </Form.Item>
          </Space>
        </Col>
      </Row>
    </div>
  );
}

export default OptionComponentInput;
