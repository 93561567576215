import React, { useEffect, useState } from 'react';
import Header from '../components/Header';
import { useTranslation } from 'react-i18next';
import { Row, Form, Col, Button, Breadcrumb, Radio } from 'antd';
import FooterComponent from '../components/FooterComponent';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import { useForceUpdate } from '../utils/utils';
import { PLAN_STATUS, STATUS_PLAN_DATA } from '../constant';
function CancellationOfReservationInput() {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const { state } = useLocation();
  const navigate = useNavigate();
  const [data, setData] = useState();
  const forceUpdate = useForceUpdate();
  const [display, setDisplay] = useState();

  useEffect(() => {
    const params = state.values;
    const planId = params.planId;
    const planBranchNum = params.planBranchNum;
    if (!planId || !planBranchNum) {
      navigate('/');
      return;
    }
    axios
      .post('/api/getOtherProcedureInfo', {
        planId,
        planBranchNum
      })
      .then((res) => {
        const { data } = res;
        setData(data);
        if (data) {
          form.setFieldsValue(data);
          forceUpdate();
        }
        // set display
        if (data?.status == PLAN_STATUS[2]) {
          setDisplay(1);
        } else if (data?.stopReserveDate != null) {
          setDisplay(2);
        } else {
          setDisplay(3);
        }
      });

    if (state?.values) {
      form.setFieldsValue(state.values);
      forceUpdate();
    }
  }, []);
  return (
    <>
      <Header />
      <div className="white-space"></div>
      <div className="container-custom-primary cancellation-reservation-input">
        <Row className="title mb-primary">
          <Col md={{ span: 12 }} className="page-title">
            {t('cancellationOfReservationInput.title')}
          </Col>
          <Col md={{ span: 12 }} className="text-right">
            <Breadcrumb separator=">">
              <Breadcrumb.Item className="bold current">
                {t('share.input')}
              </Breadcrumb.Item>
              <Breadcrumb.Item>{t('share.confirm')}</Breadcrumb.Item>
              <Breadcrumb.Item>{t('share.completion')}</Breadcrumb.Item>
            </Breadcrumb>
          </Col>
        </Row>
        <Form
          name="frmCancellationOfReservationInput"
          form={form}
          autoComplete="off"
          onFinish={(values) => {
            state.values.display = display;
            navigate('/cancellationOfReservationConfirm', {
              state: { ...state, values: { ...state?.values, ...values } }
            });
          }}
        >
          <Col className="title-sub color-primary">{t('share.usagePlan')}</Col>
          <Col
            md={{ span: 11 }}
            className="border-default ml-15 mb-primary"
            style={{ borderBottom: '0px' }}
          >
            <Row className="border-bottom-default">
              <Col
                md={{ span: 8 }}
                className="bg-primary border-right-default pl-5"
              >
                {t('share.planId')}
              </Col>
              <Col>
                <Form.Item className="mb-0 pl-15">{data?.planId}</Form.Item>
              </Col>
            </Row>
            <Row className="border-bottom-default">
              <Col
                md={{ span: 8 }}
                className="bg-primary border-right-default pl-5"
              >
                {t('plan.version')}
              </Col>
              <Col>
                <Form.Item className="mb-0 pl-15">
                  {data?.planBranchNum}
                </Form.Item>
              </Col>
            </Row>
            <Row className="border-bottom-default">
              <Col
                md={{ span: 8 }}
                className="bg-primary border-right-default pl-5"
              >
                {t('planDetail.status')}
              </Col>
              <Col>
                <Form.Item className="mb-0 pl-15">
                  {STATUS_PLAN_DATA[data?.status]}
                </Form.Item>
              </Col>
            </Row>
            <Row className="border-bottom-default">
              <Col
                md={{ span: 8 }}
                className="bg-primary border-right-default pl-5"
              >
                {t('planDetail.planUsageStartDate')}
              </Col>
              <Col>
                <Form.Item className="mb-0 pl-15">
                  {data?.useStartDate}
                </Form.Item>
              </Col>
            </Row>
            {data?.stopReserveDate && (
              <Row className="border-bottom-default">
                <Col
                  md={{ span: 8 }}
                  className="bg-primary border-right-default pl-5"
                >
                  {t(
                    'TemporarySuspensionOrCancellationSuspension.planSuspensionDate'
                  )}
                </Col>
                <Col>
                  <Form.Item className="mb-0 pl-15">
                    {data.stopReserveDate}
                  </Form.Item>
                </Col>
              </Row>
            )}
            {data?.stopDate && (
              <Row className="border-bottom-default">
                <Col
                  md={{ span: 8 }}
                  className="bg-primary border-right-default pl-5"
                >
                  {t('share.planStopDate')}
                </Col>
                <Col>
                  <Form.Item className="mb-0 pl-15">{data.stopDate}</Form.Item>
                </Col>
              </Row>
            )}
            {data?.cancelReserveDate && (
              <Row className="border-bottom-default">
                <Col
                  md={{ span: 8 }}
                  className="bg-primary border-right-default pl-5"
                >
                  {t('share.scheduledCancellationDate')}
                </Col>
                <Col>
                  <Form.Item className="mb-0 pl-15">
                    {data.cancelReserveDate}
                  </Form.Item>
                </Col>
              </Row>
            )}
            {data?.cancelDate && (
              <Row className="border-bottom-default">
                <Col
                  md={{ span: 8 }}
                  className="bg-primary border-right-default pl-5"
                >
                  {t('share.planCancellationDate')}
                </Col>
                <Col>
                  <Form.Item className="mb-0 pl-15">
                    {data.cancelDate}
                  </Form.Item>
                </Col>
              </Row>
            )}
          </Col>
          <Col className="title-sub color-primary">
            {t('cancellationOfReservationInput.subTitle1')}
          </Col>
          <div className="ml-15">
            <Col md={{ span: 16 }}>
              <table className="table-stop">
                {display == 1 && (
                  <>
                    <tr>
                      <td className="bg-primary border-right-default col-label-lv1">
                        {t('cancellationOfReservationInput.label1')}
                      </td>
                      <td>
                        <Radio.Group value={0}>
                          <Radio value={0}>
                            {t('settingStartDate.immediately')}
                          </Radio>
                        </Radio.Group>
                      </td>
                      <td className="text-right">
                        <Button
                          htmlType="submit"
                          className="btn-dancer size-standard"
                        >
                          {t('cancellationOfReservationInput.button1')}
                        </Button>
                      </td>
                    </tr>
                  </>
                )}
                {display == 2 && (
                  <>
                    <tr>
                      <td className="bg-primary border-right-default col-label-lv1">
                        {t('cancellationOfReservationInput.label2')}
                      </td>
                      <td>
                        <Radio.Group value={0}>
                          <Radio value={0}>
                            {t('settingStartDate.immediately')}
                          </Radio>
                        </Radio.Group>
                      </td>
                      <td className="text-right">
                        <Button
                          htmlType="submit"
                          className="btn-dancer size-standard"
                        >
                          {t('cancellationOfReservationInput.button2')}
                        </Button>
                      </td>
                    </tr>
                  </>
                )}
              </table>
              {display == 3 && (
                <>
                  <div className="box-notify">
                    <p>
                      {t('TemporarySuspensionOrCancellationSuspension.notify2')}
                    </p>
                  </div>
                </>
              )}
            </Col>
            <div>{t('OptionChange.note')}</div>
            <div className="ml-15">
              <div>{t('cancellationOfReservationInput.note')}</div>
            </div>
          </div>
        </Form>
        <div className="line-primary"></div>
        <Button
          onClick={() => {
            navigate('/otherProceduresStopOrCancel', {
              state
            });
          }}
          className="btn-return"
        >
          {t('share.return')}
        </Button>
      </div>
      <FooterComponent />
    </>
  );
}
export default CancellationOfReservationInput;
