import { React, useState } from 'react';
import { Form, Input, Button } from 'antd';
import Header from '../components/Header';
import { useForm } from 'antd/lib/form/Form';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

function PasswordChange() {
  const { form } = useForm();
  const { t } = useTranslation();
  const regExp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,20}/;
  const [validateError, setValitdateError] = useState('');
  const navigate = useNavigate();
  const submit = (values) => {
    const url = '/api/changePassword';
    axios
      .post(url, {
        currentPassword: values.currentPassword,
        newPassword: values.newPassword,
        newPasswordConfirm: values.newPasswordConfirm
      })
      .then(() => {
        navigate('/passwordChangeComplete');
      })
      .catch(function (error) {
        setValitdateError(error.response.data);
      });
  };

  return (
    <>
      <Header />
      <div className="box border-default modal-primary" gutter={16}>
        <div className="box-title">{t('passwordChange.title')}</div>
        <div className="box-body">
          <Form
            form={form}
            name="nest-messages"
            onFinish={(values) => submit(values)}
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 17 }}
            labelAlign="left"
          >
            <Form.Item
              // hide required icon
              required={false}
              name="currentPassword"
              label={t('passwordChange.currentPassword')}
              rules={[
                {
                  required: true,
                  message:
                    t('passwordChange.currentPassword') +
                    t('share.error.notEntered')
                }
              ]}
            >
              <Input.Password
                className="txt-primary"
                placeholder={t('passwordChange.currentPasswordPlaceHolder')}
              />
            </Form.Item>
            {validateError == 'passwordChange.wrongPassword' && (
              <div>
                <span className="message-error mb-primary">
                  {t(validateError)}
                </span>
              </div>
            )}
            <Form.Item
              // hide required icon
              required={false}
              name="newPassword"
              label={t('passwordChange.newPassword')}
              rules={[
                {
                  required: true,
                  whitespace: true,
                  message:
                    t('passwordChange.newPassword') +
                    t('share.error.notEntered')
                },
                {
                  validator: async (_, value) => {
                    if (value && !regExp.test(value)) {
                      return Promise.reject(
                        new Error(t('register.error.passwordInvalid'))
                      );
                    }
                  }
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('currentPassword') !== value) {
                      return Promise.resolve();
                    }

                    return Promise.reject(
                      new Error(t('passwordChange.duplicatePassword'))
                    );
                  }
                })
              ]}
            >
              <Input.Password
                className="txt-primary"
                placeholder={t('passwordChange.newPasswordPlaceHolder')}
              />
            </Form.Item>
            <Form.Item
              // hide required icon
              required={false}
              name="newPasswordConfirm"
              label={t('passwordChange.newPasswordConfirm')}
              dependencies={['newPassword']}
              rules={[
                {
                  required: true,
                  message:
                    t('passwordChange.newPasswordConfirm') +
                    t('share.error.notEntered')
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('newPassword') === value) {
                      return Promise.resolve();
                    }

                    return Promise.reject(
                      new Error(t('passwordChange.confirmFail'))
                    );
                  }
                })
              ]}
            >
              <Input.Password
                className="txt-primary"
                placeholder={t('passwordChange.newPasswordConfirmPlaceHolder')}
              />
            </Form.Item>
            {!validateError == 'passwordChange.wrongPassword' && (
              <div>
                <span className="message-error mb-primary">
                  {t(validateError)}
                </span>
              </div>
            )}
            <Form.Item wrapperCol={{ offset: 0 }}>
              <div className="text-center">
                <Button htmlType="submit" className="btn-primary">
                  {t('passwordChange.title')}
                </Button>
              </div>
            </Form.Item>
          </Form>
        </div>
      </div>
    </>
  );
}
export default PasswordChange;
