/**
 * (C) 2022 DTS CORPORATION
 */
import React, { useEffect, useState } from 'react';
import '../i18n';
import { Row, Col, Breadcrumb, Space, Button } from 'antd';
import { useTranslation } from 'react-i18next';
import Header from '../components/Header';
import FooterComponent from '../components/FooterComponent';
import { useLocation, useNavigate } from 'react-router-dom';
import { paygentSendDataInfo, PAYMENT_METHOD } from '../constant';
import { getContextPathUrl, postDataToPaygent, SHA256 } from '../utils/utils';
import axios from 'axios';

/**
 * Payment infomation registration page
 */
function PaymentInformationRegistration() {
  const { t } = useTranslation();
  const { state } = useLocation();
  const navigate = useNavigate();
  const [projectCode, setProjectCode] = useState();
  const [paygentDataInfo, setPaygentDataInfo] = useState();

  const params = new URLSearchParams(window.location.search);
  const planId =
    params.get('customer_id') || params.get('planId') || state?.values?.planId;
  const planBranchNum =
    params.get('planBranchNum') || state?.values?.planBranchNum;

  useEffect(() => {
    if (!state && !planId) {
      navigate('/');
      return;
    }

    setProjectCode(state?.values?.projectCode || '');
    getPaygentInfo();
  }, []);

  const getPaygentInfo = () => {
    axios.post('/api/getPaygentDataInfo').then((res) => {
      setPaygentDataInfo(res.data);
    });
  };

  const backtoDetailScreen = () => {
    navigate(`/planDetail?planId=${planId}&planBranchNum=${planBranchNum}`, {
      replace: true
    });
  };
  return (
    <>
      <Header />
      <div className="white-space"></div>
      <div className="container-custom-primary">
        <Row className="title">
          <Col md={{ span: 12 }} className="page-title">
            {t('share.Application')}
            {' / '}
            {t('paymentInformationRegistration.title')}
          </Col>
          <Col md={{ span: 12 }} className="text-right">
            <Breadcrumb separator=">">
              <Breadcrumb.Item className="current bold">
                {t('paymentInformationRegistration.title')}
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                {t('paymentInformationRegistration.useStartDateSetting')}
              </Breadcrumb.Item>
              <Breadcrumb.Item>{t('share.confirm')}</Breadcrumb.Item>
              <Breadcrumb.Item>{t('share.completion')}</Breadcrumb.Item>
            </Breadcrumb>
          </Col>
        </Row>
        <div className="white-space"></div>

        {projectCode === '' && (
          <>
            <div className="text-center mb-primary">
              {t('paymentInformationRegistration.note')}
            </div>
            <div className="border-default mb-primary">
              <Row className="border-bottom-default">
                <Col
                  md={{ span: 4 }}
                  className="bg-primary border-right-default pl-5"
                >
                  {t('share.paymentMethod')}
                </Col>
                <Col md={{ span: 20 }} className="pl-5">
                  {t(PAYMENT_METHOD[1])}
                </Col>
              </Row>
              <Row>
                <Col
                  md={{ span: 4 }}
                  className="bg-primary border-right-default pl-5"
                >
                  {t('paymentInformationRegistration.acceptableCreditCards')}
                </Col>
                <Col md={{ span: 20 }} className="pl-5">
                  {' '}
                  VISA、Mastercard、Dinos Club、AMERICAN EXPRESS、JCB、DISCOVER
                </Col>
              </Row>
            </div>
            <div className="text-center">
              {t('paymentInformationRegistration.note1')}
            </div>
            <div className="text-center">
              {t('paymentInformationRegistration.note2')}
            </div>
            <div className="white-space"></div>
            <div className="bg-dancer text-center p-5">
              <Space size={50}>
                <button
                  type="button"
                  onClick={() => {
                    const urlContext = getContextPathUrl();
                    const data = {
                      ...paygentSendDataInfo,
                      return_url: `${urlContext}/applicationSettingStartDate?planId=${planId}&planBranchNum=${planBranchNum}`,
                      stop_return_url: `${urlContext}/paymentInformationRegistration?planId=${planId}&planBranchNum=${planBranchNum}`,
                      hc: SHA256(
                        paygentDataInfo.seq_merchant_id +
                          paygentSendDataInfo.valid_check_flg +
                          planId +
                          paygentDataInfo.hcKey
                      ),
                      seq_merchant_id: paygentDataInfo.seq_merchant_id,
                      customer_id: planId,
                      operation_type: '01',
                      inform_url: `${urlContext}/paymentInformationRegistration?planId=${planId}&planBranchNum=${planBranchNum}`
                    };
                    postDataToPaygent(data, paygentDataInfo.url);
                  }}
                  className="btn-return cursor-pointer"
                >
                  {t('share.move')}
                </button>
                <Button onClick={backtoDetailScreen} className="btn-return">
                  {t('share.return')}
                </Button>
              </Space>
            </div>
            <div className="white-space"></div>
            <div>{t('paymentInformationRegistration.pleaseNote')}</div>
            <div>{t('paymentInformationRegistration.des1')}</div>
          </>
        )}
        {projectCode != '' && (
          <>
            <div className="white-space"></div>
            <div className="text-center">
              {t('paymentInformationRegistration.des2')}
            </div>
            <div className="text-center">
              {t('paymentInformationRegistration.des3')}
            </div>
            <div className="white-space"></div>
            <div className="border-default mb-primary">
              <Row className="border-bottom-default">
                <Col
                  md={{ span: 4 }}
                  className="bg-primary border-right-default pl-5"
                >
                  {t('share.paymentMethod')}
                </Col>
                <Col md={{ span: 20 }} className="pl-5">
                  {t(PAYMENT_METHOD[state?.values?.paymentMethod])}
                </Col>
              </Row>
              <Row>
                <Col
                  md={{ span: 4 }}
                  className="bg-primary border-right-default pl-5"
                >
                  {t('share.salesOutlet')}
                </Col>
                <Col md={{ span: 20 }} className="pl-5">
                  {state?.values?.agency?.companyName}
                </Col>
              </Row>
            </div>
            <div className="white-space"></div>
            <div className="bg-dancer text-center p-5">
              <Space size={50}>
                <button
                  onClick={() => {
                    navigate('/applicationSettingStartDate', {
                      state
                    });
                  }}
                  className="btn-return cursor-pointer"
                >
                  {t('share.confirm')}
                </button>
                <Button onClick={backtoDetailScreen} className="btn-return">
                  {t('share.return')}
                </Button>
              </Space>
            </div>
          </>
        )}
      </div>
      <FooterComponent className="mb-0" />
    </>
  );
}
export default PaymentInformationRegistration;
