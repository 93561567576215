import React, { useEffect } from 'react';
import Header from '../components/Header';
import '../i18n';
import { Row, Col, Breadcrumb, Button } from 'antd';
import { useTranslation } from 'react-i18next';
import FooterComponent from '../components/FooterComponent';
import { useNavigate, useLocation } from 'react-router-dom';

function OptionChangeCompletion() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { state } = useLocation();

  useEffect(() => {
    if (!state?.values?.planId) {
      navigate('/', { replace: true });
      return;
    }
  }, []);

  return (
    <>
      <Header />
      <div className="white-space"></div>
      <div className="container-custom-primary plan-change-application-confirm">
        <Row className="title mb-primary">
          <Col md={{ span: 4 }} className="page-title">
            {t('planDetail.optionChange')}
            {' / '}
            {t('share.completion')}
          </Col>
          <Col md={{ span: 20 }} className="text-right">
            <Breadcrumb separator=">">
              <Breadcrumb.Item>{t('share.input')}</Breadcrumb.Item>
              <Breadcrumb.Item>{t('share.confirm')}</Breadcrumb.Item>
              <Breadcrumb.Item className="bold current">
                {t('share.completion')}
              </Breadcrumb.Item>
            </Breadcrumb>
          </Col>
        </Row>
        <div>{t('optionChangeCompletion.completion')}</div>
        <div className="white-space"></div>
        <div className="bg-dancer text-center p-5">
          <Button
            onClick={() => {
              navigate(
                `/planDetail?planId=${state.values.planId}&planBranchNum=${state.values.planBranchNum}`,
                {
                  replace: true
                }
              );
            }}
            className="btn-submit"
          >
            {t('optionChangeCompletion.returnToPlanDetails')}
          </Button>
        </div>
      </div>
      <FooterComponent />
    </>
  );
}
export default OptionChangeCompletion;
