import React, { useEffect, useState } from 'react';
import '../i18n';
import Header from '../components/Header';
import {
  Row,
  Col,
  Button,
  Space,
  Form,
  Input,
  Upload,
  DatePicker,
  Radio
} from 'antd';
import ImgCrop from 'antd-img-crop';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import CommonModalComponent from '../components/CommonModalComponent';
import {
  UploadOutlined,
  CheckOutlined,
  CloseOutlined,
  PlusOutlined,
  PaperClipOutlined
} from '@ant-design/icons';
import { base64ToBlob, useForceUpdate } from '../utils/utils';
import { DATE_FORMAT } from '../constant';
import moment from 'moment';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import TextArea from 'antd/lib/input/TextArea';
import mime from 'mime';

function Training() {
  const [formAddCategory] = Form.useForm();
  const [formEditCategory] = Form.useForm();
  const { t, i18n } = useTranslation();
  const [trainingCategoriesLst, setTrainingCategoriesLst] = useState([]);
  const [trainingList, setTrainingList] = useState([]);
  const [showTrainingInfo, setShowTrainingInfo] = useState({ isShow: false });
  const [showAddModal, setShowAddModal] = useState(false);
  const [delTrainingContentIds, setDelTrainingContentIds] = useState([]);
  const [errorInfo, setErrorInfo] = useState();
  const forceUpdate = useForceUpdate();

  const [modalConfirmDelete, setModalConfirmDelete] = useState({
    isShow: false,
    categoryId: undefined,
    content: t('training.deleteConfirmationContent')
  });

  const [modalConfirmDiscard, setModalConfirmDiscard] = useState({
    isShow: false,
    content: t('training.discardConfirmationContent')
  });
  useEffect(() => {
    getCategories();
  }, []);

  useEffect(() => {
    if (showTrainingInfo.categoryId) {
      getTrainingCategoryById(showTrainingInfo.categoryId);
    }
  }, [showTrainingInfo.categoryId]);

  useEffect(() => {
    formEditCategory.validateFields();
  }, [trainingList]);

  useEffect(() => {
    if (!i18n.language) return;
  }, [i18n.language]);

  const getCategories = () => {
    axios({
      method: 'get',
      url: '/api/getTrainingCategoriesList'
    }).then((res) => {
      setTrainingCategoriesLst(res.data);
    });
  };

  const getTrainingCategoryById = (categoryId) => {
    axios({
      method: 'get',
      url: '/api/getTrainingCategoryById',
      params: {
        categoryId: categoryId
      }
    }).then((res) => {
      const trainingList = res.data.trainingContents || [];
      trainingList.forEach((item) => {
        if (item.imageFile) {
          item.imageFile.url = URL.createObjectURL(
            base64ToBlob(item.imageFile.fileBase64, item.imageFile.fileName)
          );
        }
        item.isUseFile = (item.docFile && item.docFile.fileName) || false;
      });
      setDelTrainingContentIds([]);
      setTrainingList(trainingList);
      formEditCategory.setFieldsValue(res.data);
    });
  };

  const onDelete = (id) => {
    axios
      .delete(`/api/deleteTrainingCategoriesById`, {
        data: { categoryId: id }
      })
      .then(() => {
        setModalConfirmDelete({ isShow: false });
        getCategories();
      });
  };
  const onAdd = () => {
    const reqParam = formAddCategory.getFieldsValue();
    axios
      .post('/api/createTrainingCategories', {
        categoryName: reqParam.categoryName
      })
      .then(({ res }) => {
        setShowAddModal(false);
        formAddCategory.resetFields();
        getCategories();
      });
  };

  const reOrder = (lstCategoriesIds) => {
    axios
      .post('/api/reOrderTrainingCategories', {
        lstCategoriesIds
      })
      .then(({ res }) => {
        getCategories();
      });
  };

  const onUpdate = () => {
    trainingList.forEach((item) => {
      if (!item.isUseFile) {
        item.docFile = undefined;
      }

      if (item.isUseFile) {
        item.url = undefined;
      }
    });

    axios
      .post('/api/updateTrainingCategories', {
        ...formEditCategory.getFieldsValue(),
        delTrainingContentIds,
        trainingContents: trainingList
      })
      .then(({ res }) => {
        setShowTrainingInfo({ isShow: false });
        getCategories();
      });
  };

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        const base64String = fileReader.result
          .replace('data:', '')
          .replace(/^.+,/, '');

        resolve(base64String);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  return (
    <>
      <Header />
      <>
        <div className="white-space"></div>
        <div className="box-plan">
          <h2 className="bg-dancer pl-10">My page</h2>
          <div className="white-space"></div>
          <Row className="title page-title">{t('training.titleAdmin')}</Row>
          <Row className="mb-primary">
            <Col md={{ span: 20 }}>
              <Button
                className="link-newPlan"
                onClick={() => {
                  setShowAddModal(true);
                }}
              >
                {t('training.addNewCategory')}
              </Button>
            </Col>
          </Row>
          <div>{t('training.categoryList')}</div>
          <DragDropContext
            onDragEnd={(result) => {
              // dropped outside the list
              if (!result.destination) {
                return;
              }

              const data = Array.from(trainingCategoriesLst);
              const [removed] = data.splice(result.source.index, 1);
              data.splice(result.destination.index, 0, removed);

              reOrder(data.map((item) => item.categoryId));
            }}
          >
            <table className="table-parent border-default">
              <thead className="bg-primary border-default">
                <tr className="tb-header-cate">
                  <td>{t('training.number')}</td>
                  <td>{t('training.categoryName')}</td>
                  <td>{t('training.action')}</td>
                </tr>
              </thead>
              <Droppable droppableId="categories">
                {(provided, snapshot) => (
                  <tbody {...provided.droppableProps} ref={provided.innerRef}>
                    {trainingCategoriesLst?.map((item, index) => {
                      return (
                        <Draggable
                          key={(index + 1).toString()}
                          draggableId={(index + 1).toString()}
                          index={index}
                        >
                          {(provided, snapshot) => (
                            <tr
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            >
                              <td
                                width={
                                  document.getElementsByClassName(
                                    'tb-header-cate'
                                  )[0].children[0].clientWidth
                                }
                              >
                                {index + 1}
                              </td>
                              <td
                                width={
                                  document.getElementsByClassName(
                                    'tb-header-cate'
                                  )[0].children[0].clientWidth
                                }
                              >
                                {item.categoryName}
                              </td>
                              <td
                                width={
                                  document.getElementsByClassName(
                                    'tb-header-cate'
                                  )[0].children[0].clientWidth
                                }
                              >
                                <Space>
                                  <Button
                                    title={t('training.edit')}
                                    onClick={() => {
                                      setShowTrainingInfo({
                                        isShow: true,
                                        categoryId: item.categoryId
                                      });
                                    }}
                                  >
                                    <EditOutlined />
                                  </Button>
                                  <Button
                                    title={t('training.delete')}
                                    onClick={() => {
                                      setModalConfirmDelete({
                                        isShow: true,
                                        categoryId: item.categoryId,
                                        content: t(
                                          'training.deleteConfirmationContent'
                                        )
                                      });
                                    }}
                                  >
                                    <DeleteOutlined />
                                  </Button>
                                </Space>
                              </td>
                            </tr>
                          )}
                        </Draggable>
                      );
                    })}
                    {provided.placeholder}
                  </tbody>
                )}
              </Droppable>
            </table>
          </DragDropContext>
        </div>

        <CommonModalComponent
          title={t('training.addNewCategory')}
          dataModal={[
            {
              modalTextButton: t('training.add'),
              onClick: () => {
                formAddCategory.submit();
              },
              icon: <PlusOutlined />
            },
            {
              modalTextButton: t('training.discard'),
              onClick: () => {
                formAddCategory.resetFields();
                setShowAddModal(false);
              },
              icon: <CloseOutlined />
            }
          ]}
          close={() => {
            formAddCategory.resetFields();
            setShowAddModal(false);
          }}
          modalContent={
            <>
              <Form
                name="addCategory"
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
                labelAlign="left"
                form={formAddCategory}
                onFinish={onAdd}
              >
                <Form.Item
                  label={t('training.categoryName')}
                  name="categoryName"
                  rules={[
                    {
                      required: true,
                      message: t('training.required')
                    }
                  ]}
                  className="mb-0"
                >
                  <Input />
                </Form.Item>
              </Form>
            </>
          }
          isOpenModal={showAddModal}
          className="font-size-body-28"
        />

        <CommonModalComponent
          width={1200}
          title={t('training.addNewCategory')}
          dataModal={[
            {
              modalTextButton: t('training.update'),
              onClick: () => {
                formEditCategory.submit();
                // onUpdate();
              },
              icon: <CheckOutlined />
            },
            {
              modalTextButton: t('training.discard'),
              onClick: () => {
                formEditCategory.resetFields();
                setTrainingList([]);
                setShowTrainingInfo({ isShow: false });
              },
              icon: <CloseOutlined />
            }
          ]}
          close={() => {
            formEditCategory.resetFields();
            setTrainingList([]);
            setShowTrainingInfo({ isShow: false });
            // setModalAddTrainingContent({
            //   ...modalAddTrainingContent,
            //   isShow: false
            // })
          }}
          modalContent={
            <>
              <Form
                name="frmEdit"
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
                labelAlign="left"
                form={formEditCategory}
                onFinish={onUpdate}
              >
                <Form.Item hidden name="categoryId" />
                <Form.Item
                  label={t('training.categoryName')}
                  name="categoryName"
                  rules={[
                    {
                      required: true,
                      message: t('training.required')
                    }
                  ]}
                >
                  <Input />
                </Form.Item>
                <Row className="mb-primary">
                  <Col md={12}>{t('training.CategoryBlogList')}</Col>
                  <Col md={12} className="text-right">
                    <Button
                      className="mp-btn-primary"
                      onClick={() => {
                        trainingList.push({
                          trainingTitle: '',
                          trainingNewDate: undefined,
                          trainingOrder: trainingList.length + 1,
                          trainingImage: undefined
                        });
                        setTrainingList([...trainingList]);
                        // forceUpdate();
                        console.log(trainingList.length);
                      }}
                    >
                      {t('training.AddNewBlog')}
                    </Button>
                  </Col>
                </Row>
                <DragDropContext
                  onDragEnd={(result) => {
                    // dropped outside the list
                    if (!result.destination) {
                      return;
                    }

                    const data = Array.from(trainingList);
                    const [removed] = data.splice(result.source.index, 1);
                    data.splice(result.destination.index, 0, removed);

                    data.forEach((item, idx) => {
                      item.trainingOrder = idx + 1;
                    });

                    setTrainingList(data);
                  }}
                >
                  <div className="search-result1">
                    <table className="border-default full-width tbl-lesson">
                      <thead className="bg-primary border-default">
                        <tr className="tb-header">
                          <td>{t('training.Blogtitle')}</td>
                          <td>{t('training.ViewNewBlog')}</td>
                          <td>{t('training.RepresentativeImage')}</td>
                          <td>{t('training.ArticleInBlog')}</td>
                        </tr>
                      </thead>
                      <Droppable droppableId="droppable12">
                        {(provided, snapshot) => (
                          <tbody
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                          >
                            {trainingList.map((value, index) => (
                              <Draggable
                                key={(index + 1).toString()}
                                draggableId={(index + 1).toString()}
                                index={index}
                              >
                                {(provided, snapshot) => (
                                  // <div
                                  //   ref={provided.innerRef}
                                  //   {...provided.draggableProps}
                                  //   {...provided.dragHandleProps}
                                  // >
                                  //   {item.content}
                                  // </div>
                                  <tr
                                    className="training-row"
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                  >
                                    <td
                                      width={
                                        document.getElementsByClassName(
                                          'tb-header'
                                        )[0].children[0].clientWidth
                                      }
                                    >
                                      <TextArea
                                        style={{ resize: 'none' }}
                                        status={value.trainingTitleError}
                                        value={value.trainingTitle}
                                        onChange={(e) => {
                                          setTrainingList(
                                            trainingList.map((training) => {
                                              return training.trainingOrder ===
                                                value.trainingOrder
                                                ? {
                                                    ...training,
                                                    trainingTitle:
                                                      e.target.value
                                                  }
                                                : training;
                                            })
                                          );
                                        }}
                                      />
                                    </td>
                                    <td
                                      width={
                                        document.getElementsByClassName(
                                          'tb-header'
                                        )[0].children[1].clientWidth
                                      }
                                    >
                                      {' '}
                                      <DatePicker
                                        status={value.trainingNewDateError}
                                        value={
                                          value.trainingNewDate
                                            ? moment(
                                                value.trainingNewDate,
                                                DATE_FORMAT
                                              )
                                            : undefined
                                        }
                                        format={DATE_FORMAT}
                                        onChange={(date) => {
                                          setTrainingList(
                                            trainingList.map((training) => {
                                              return training.trainingOrder ===
                                                value.trainingOrder
                                                ? {
                                                    ...training,
                                                    trainingNewDate:
                                                      date.format(DATE_FORMAT)
                                                  }
                                                : training;
                                            })
                                          );
                                        }}
                                      />
                                    </td>
                                    <td
                                      width={
                                        document.getElementsByClassName(
                                          'tb-header'
                                        )[0].children[2].clientWidth
                                      }
                                    >
                                      <ImgCrop aspect={4 / 3}>
                                        <Upload
                                          accept="image/*"
                                          beforeUpload={async (file) => {
                                            const fileBase64 =
                                              await convertBase64(file);
                                            setTrainingList(
                                              trainingList.map((training) => {
                                                return training.trainingOrder ===
                                                  value.trainingOrder
                                                  ? {
                                                      ...training,
                                                      imageFile: {
                                                        fileName: file.name,
                                                        fileBase64,
                                                        url: URL.createObjectURL(
                                                          base64ToBlob(
                                                            fileBase64,
                                                            file.name
                                                          )
                                                        ),
                                                        needUdateFile: true
                                                      }
                                                    }
                                                  : training;
                                              })
                                            );

                                            return false;
                                          }}
                                          className={`ant-upload-select-picture-card-${value.trainingImageError}`}
                                          listType="picture-card"
                                          showUploadList={false}
                                          style={{
                                            borderColor: 'red'
                                          }}
                                        >
                                          {value.imageFile ? (
                                            <img
                                              src={value.imageFile.url}
                                              alt="avatar"
                                              style={{ width: '100%' }}
                                            />
                                          ) : (
                                            <div>
                                              <PlusOutlined />
                                              <div style={{ marginTop: 8 }}>
                                                Upload
                                              </div>
                                            </div>
                                          )}
                                        </Upload>
                                      </ImgCrop>
                                    </td>
                                    <td
                                      width={
                                        document.getElementsByClassName(
                                          'tb-header'
                                        )[0].children[3].clientWidth
                                      }
                                    >
                                      <Radio.Group
                                        onChange={(e) => {
                                          setTrainingList(
                                            trainingList.map((training) => {
                                              return training.trainingOrder ===
                                                value.trainingOrder
                                                ? {
                                                    ...training,
                                                    isUseFile:
                                                      e.target.value === 'file'
                                                  }
                                                : training;
                                            })
                                          );
                                        }}
                                        defaultValue={
                                          value.isUseFile ? 'file' : 'url'
                                        }
                                        buttonStyle="solid"
                                        style={{
                                          display: 'flex',
                                          marginTop: 10,
                                          marginBottom: 5
                                        }}
                                      >
                                        <Radio.Button value="file">
                                          File
                                        </Radio.Button>
                                        <Radio.Button value="url">
                                          URL
                                        </Radio.Button>
                                      </Radio.Group>
                                      {value.isUseFile && (
                                        <Upload
                                          showUploadList={false}
                                          beforeUpload={async (file) => {
                                            const fileBase64 =
                                              await convertBase64(file);
                                            const videoType =
                                              mime
                                                .getType(file.name)
                                                .indexOf('video') != -1;
                                            setTrainingList(
                                              trainingList.map((training) => {
                                                return training.trainingOrder ===
                                                  value.trainingOrder
                                                  ? {
                                                      ...training,
                                                      docFile: {
                                                        fileName: file.name,
                                                        fileBase64
                                                      },
                                                      videoType
                                                    }
                                                  : training;
                                              })
                                            );
                                            // forceUpdate();
                                            return false;
                                          }}
                                        >
                                          <Button
                                            style={{
                                              borderColor:
                                                value.trainingDocError ===
                                                'error'
                                                  ? 'red'
                                                  : ''
                                            }}
                                            icon={<UploadOutlined />}
                                          >
                                            {t('training.selectFile')}
                                          </Button>

                                          {value.docFile && (
                                            <>
                                              <div>
                                                <PaperClipOutlined />
                                                {` ${value.docFile.fileName}`}
                                              </div>
                                            </>
                                          )}
                                        </Upload>
                                      )}
                                      {!value.isUseFile && (
                                        <Form.Item
                                          initialValue={value.url}
                                          name={['value', index, 'url']}
                                          rules={[
                                            {
                                              required: true,
                                              message: 'URL is required.'
                                            },
                                            {
                                              type: 'url',
                                              message: 'URL is not a valid url'
                                            }
                                          ]}
                                        >
                                          <Input
                                            placeholder="URL"
                                            onChange={(e) => {
                                              setTrainingList(
                                                trainingList.map((training) => {
                                                  return training.trainingOrder ===
                                                    value.trainingOrder
                                                    ? {
                                                        ...training,
                                                        url: e.target.value
                                                      }
                                                    : training;
                                                })
                                              );
                                            }}
                                          />
                                        </Form.Item>
                                      )}
                                      <Radio.Group
                                        onChange={(e) => {
                                          setTrainingList(
                                            trainingList.map((training) => {
                                              return training.trainingOrder ===
                                                value.trainingOrder
                                                ? {
                                                    ...training,
                                                    videoType:
                                                      e.target.value ===
                                                      'videoType'
                                                  }
                                                : training;
                                            })
                                          );
                                        }}
                                        defaultValue={
                                          value.videoType
                                            ? 'videoType'
                                            : 'otherType'
                                        }
                                        value={
                                          value.videoType
                                            ? 'videoType'
                                            : 'otherType'
                                        }
                                        style={{
                                          display: 'flex',
                                          marginTop: 2,
                                          marginBottom: 5
                                        }}
                                      >
                                        <Radio value="otherType">
                                          Other Type
                                        </Radio>
                                        <Radio value="videoType">
                                          Video Type
                                        </Radio>
                                      </Radio.Group>
                                      <Button
                                        className="training-delete"
                                        onClick={() => {
                                          const data = trainingList.filter(
                                            (training) => {
                                              return (
                                                training.trainingOrder !==
                                                value.trainingOrder
                                              );
                                            }
                                          );

                                          data.forEach((item, idx) => {
                                            item.trainingOrder = idx + 1;
                                          });

                                          if (value.trainingId) {
                                            setDelTrainingContentIds([
                                              ...delTrainingContentIds,
                                              value.trainingId
                                            ]);
                                          }

                                          setTrainingList(data);
                                        }}
                                      >
                                        <svg
                                          color="red"
                                          viewBox="64 64 896 896"
                                          focusable="false"
                                          data-icon="delete"
                                          width="1.2em"
                                          height="1.2em"
                                          fill="currentColor"
                                          aria-hidden="true"
                                        >
                                          <path d="M360 184h-8c4.4 0 8-3.6 8-8v8h304v-8c0 4.4 3.6 8 8 8h-8v72h72v-80c0-35.3-28.7-64-64-64H352c-35.3 0-64 28.7-64 64v80h72v-72zm504 72H160c-17.7 0-32 14.3-32 32v32c0 4.4 3.6 8 8 8h60.4l24.7 523c1.6 34.1 29.8 61 63.9 61h454c34.2 0 62.3-26.8 63.9-61l24.7-523H888c4.4 0 8-3.6 8-8v-32c0-17.7-14.3-32-32-32zM731.3 840H292.7l-24.2-512h487l-24.2 512z"></path>
                                        </svg>
                                      </Button>
                                    </td>
                                  </tr>
                                )}
                              </Draggable>
                            ))}
                            {provided.placeholder}
                          </tbody>
                        )}
                      </Droppable>
                    </table>
                  </div>
                </DragDropContext>

                <Form.Item
                  name="validateCheck"
                  hidden
                  rules={[
                    () => ({
                      validator(_, value) {
                        setErrorInfo('');
                        let isPass = trainingList.length > 0;
                        if (!isPass) {
                          setErrorInfo(t('training.requiredContent'));
                        }
                        trainingList.forEach((item) => {
                          item.trainingTitleError = '';
                          item.trainingNewDateError = '';
                          item.trainingImageError = '';
                          item.trainingDocError = '';
                          if (!item.trainingTitle?.length > 0) {
                            item.trainingTitleError = 'error';
                            isPass = false;
                          }

                          if (!item.trainingNewDate?.length > 0) {
                            item.trainingNewDateError = 'error';
                            isPass = false;
                          }

                          if (!item.imageFile) {
                            item.trainingImageError = 'error';
                            isPass = false;
                          }
                          if (item.isUseFile && !item.docFile) {
                            item.trainingDocError = 'error';
                            isPass = false;
                          }
                        });

                        if (!isPass && trainingList.length > 0) {
                          setErrorInfo(t('training.required'));
                        }

                        setTrainingList(trainingList);
                        forceUpdate();
                        if (isPass) {
                          return Promise.resolve();
                        }
                        return Promise.reject(new Error());
                      }
                    })
                  ]}
                />
                {errorInfo !== '' && (
                  <div className="ant-form-item-explain-error">{errorInfo}</div>
                )}
              </Form>
            </>
          }
          isOpenModal={showTrainingInfo.isShow}
          className="font-size-body-28"
        />

        <CommonModalComponent
          title={t('training.deleteConfirmationTitle')}
          dataModal={[
            {
              modalTextButton: t('training.cancel'),
              onClick: () => {
                setModalConfirmDelete({ isShow: false });
              },
              icon: <CloseOutlined />
            },
            {
              modalTextButton: t('training.delete'),
              onClick: () => {
                onDelete(modalConfirmDelete.categoryId);
                setModalConfirmDelete({ isShow: false });
              },
              icon: <CheckOutlined />
            }
          ]}
          close={() => setModalConfirmDelete({ isShow: false })}
          modalContent={modalConfirmDelete.content}
          isOpenModal={modalConfirmDelete.isShow}
          className="font-size-body-28"
        />
        <CommonModalComponent
          title={t('training.destroyConfirmationTitle')}
          dataModal={[
            {
              modalTextButton: t('training.cancel'),
              onClick: () => {
                setModalConfirmDiscard({
                  ...modalConfirmDiscard,
                  isShow: false
                });
              },
              icon: <CloseOutlined />
            },
            {
              modalTextButton: t('training.discard'),
              onClick: () => {
                setModalConfirmDiscard({
                  ...modalConfirmDiscard,
                  isShow: false
                });
                onDelete();
              },
              icon: <CheckOutlined />
            }
          ]}
          close={() =>
            setModalConfirmDiscard({ ...modalConfirmDiscard, isShow: false })
          }
          modalContent={modalConfirmDiscard.content}
          isOpenModal={modalConfirmDiscard.isShow}
          className="font-size-body-28"
        />
      </>
    </>
  );
}

export default Training;
